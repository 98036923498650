import * as Yup from 'yup'

  const depositComeFrom = source => {
    return [
      source,
      {
        is: true,
        then: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.')
      }
    ]
  }

export const loanRequirementsStep1Schema = Yup.object().shape({
  purchase_type: Yup.string().required('This field is required.'),
  purchase_price: Yup.mixed().when(
    'purchase_type',
    {
      is: "Purchase",
      then: Yup
        .number('This field must contain numbers.')
        .min(0, 'This field must be greater than or equal 0.')
        .test({
          name: 'equaltoloanamountandsources',
          exclusive: true,
          message: 'Purchase price should be equal to sum of loan amount and sum of the sources',
          test: (value, { parent }) => {
            return +parent.loan_required
              + (parent.source_gift_amount || 0)
              + (parent.source_inheritance_amount || 0)
              + (parent.source_property_amount || 0)
              + (parent.source_help_to_buy_amount || 0)
              + (parent.source_savings_amount || 0)
              + (parent.source_other_amount || 0) === +value
          },
        })
        .required('This field is required.')
    }
  ),
  loan_required: Yup.number('This field must contain numbers.').min(100000, 'The minium loan amount with MoCo is €100,000.').max(5000000, 'This field must be less than or equal to 5 million €.').required('This field is required.'),
  deposit_comes_from: Yup.string().required('This field is required.'),
  source_gift_amount: Yup.mixed().when(...depositComeFrom('source_gift')),
  source_inheritance_amount: Yup.mixed().when(...depositComeFrom('source_inheritance')),
  source_property_amount: Yup.mixed().when(...depositComeFrom('source_property')),
  source_help_to_buy_amount: Yup.mixed().when(...depositComeFrom('source_help_to_buy')),
  source_savings_amount: Yup.mixed().when(...depositComeFrom('source_savings')),
  source_other_amount: Yup.mixed().when(...depositComeFrom('source_other')),
  if_other_details: Yup.string().when(
    'source_other',
    {
      is: true,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
});