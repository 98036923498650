import React, { useCallback } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { CurrentBrokerProvider } from '../contexts/CurrentBrokerContext'
import CurrentBorrowerProvider from '../contexts/CurrentBorrowerContext'
import BorrowerLogin from './Borrower/BorrowerLogin'
import BrokerDashboard from './Broker/BrokerDashboard'
import BrokerLogin from './Broker/BrokerLogin'
import FormContainer from './DataCapture/FormContainer'
import BorrowerRegistrationPage from './Borrower/BorrowerRegistrationPage'
import BorrowerDashboard from './Borrower/BorrowerDashboard'
import NoMatch from './NoMatch'
import '../utils/MobxConfig'
import BorrowerForgottenPassword from './Borrower/BorrowerForgottenPassword'
import BorrowerResetPassword from './Borrower/BorrowerResetPassword'
import IdentityVerificationMobile from './DataCapture/IdentityVerificationMobile'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BrokerSummary from './Broker/BrokerSummary'
import ApplicationProvider from '../contexts/ApplicationContext'
import EmailVerification from './DataCapture/PersonalDetails/EmailVerification'
import Freshdesk from './Broker/Freshdesk'
import SessionTimeoutDialog from '../components/SessionTimeoutDialog'
import { rootStore, RootStoreProvider } from '../contexts/RootStoreContext'
import ScrollToTop from './ScrollToTop'
import LoadingScreen from './LoadingScreen'
import BorrowerSummary from './Borrower/BorrowerSummary'

/**
 * Routes
 *
 * === Broker vs Borrower
 * Routes should be prefixed with 'broker' or 'borrower' (unless unauthenticated
 * use-case like 'email_verification'), so user type can be detected in RootStore.
 */
const routes = {
  // Broker - Unauthenticated
  brokerLogin: "/broker/login",
  // Broker - Authenticated
  brokerApplicationShowSummary: "/broker/applications/:application_id/summary",
  brokerApplicationShow: "/broker/applications/:application_id",
  brokerCatchAll: "/broker",
  // Borrower - Unauthenticated
  borrowerLogin: "/borrower/login",
  borrowerForgottenPassword: "/borrower/forgotten-password",
  borrowerResetPassword: "/borrower/reset-password/:token",
  borrowerRegistration: "/borrower/registration/:token",
  borrowerApplicationsIdentityVerificationMobile: "/borrower/applications/:application_id/applicants/:id_verification_token/identity-verification-mobile",
  borrowerApplicationEmailVerification: "/email_verification/:application_id/:applicant_id/:signature",
  borrowerApplicationShowSummary: "/borrower/applications/:application_id/summary",
  // Borrower - Authenticated
  borrowerApplicationShow: "/borrower/applications/:application_id",
  borrowerCatchAll: "/borrower",
}

/**
 * RouterComponent
 * This is the entry for the frontend app.
 *
 * === Bootstrapping
 * When the frontend app first loads, the RootStore checks if the
 * current user is authenticated and determines their user type
 * eg 'broker' or 'borrower' (based on the URL).
 *
 * During this, on authenticated routes, we show a loading screen,
 * as we don't want to show them if the user is unauthenticated.
 *
 * When the RootStore is finished bootstrapping/checking the user,
 * we handle here in the routes if we need to redirect the user
 * to a login page and so on.
 */
const RouterComponent = observer(() => {
  const loginRouteForUserType = useCallback(() => ((rootStore.userType.get() == 'broker') ? routes.brokerLogin : routes.borrowerLogin))

  return (
    <Router>
      <ScrollToTop />

      <RootStoreProvider>
        <CurrentBorrowerProvider>
          <CurrentBrokerProvider>
            <Switch>
              {/* Unauthenticated Routes - Broker */}
              <Route exact path={routes.brokerLogin}>
                <BrokerLogin />
              </Route>

              {/* Unauthenticated Routes - Borrower */}
              <Route exact path={routes.borrowerLogin}>
                <BorrowerLogin />
              </Route>
              <Route exact path={routes.borrowerForgottenPassword}>
                <BorrowerForgottenPassword />
              </Route>
              <Route exact path={routes.borrowerResetPassword}>
                <BorrowerResetPassword />
              </Route>
              <Route exact path={routes.borrowerRegistration}>
                <BorrowerRegistrationPage />
              </Route>
              <Route path={routes.borrowerApplicationsIdentityVerificationMobile}>
                <IdentityVerificationMobile />
              </Route>
              <Route path={routes.borrowerApplicationEmailVerification}>
                <EmailVerification />
              </Route>

              {/* Authenticated Routes - Bootstrapping */}
              {!rootStore.isBootstrapped.get() && (
                <Route path={["/broker/*", "/borrower/*"]}>
                  <LoadingScreen />
                </Route>
              )}

              {/* Authenticated Routes - Bootstrapped & User Unauthenticated */}
              {rootStore.isBootstrapped.get() && !rootStore.userIsAuthenticated.get() && (
                <Route path={["/broker/*", "/borrower/*"]}>
                  <Redirect to={`${loginRouteForUserType()}?n=ltc`} />
                </Route>
              )}

              {/* Authenticated Routes - Bootstrapped & User Authenticated */}
              {rootStore.isBootstrapped.get() && rootStore.userIsAuthenticated.get() && (
                <Route path={["/broker/*", "/borrower/*"]}>
                  <Switch>
                    {/* Broker */}
                    <Route path={routes.brokerApplicationShowSummary}>
                      <ApplicationProvider>
                        <BrokerSummary />
                      </ApplicationProvider>
                    </Route>
                    <Route path={routes.brokerApplicationShow}>
                      <FormContainer type="broker" />
                    </Route>
                    <Route path={routes.brokerCatchAll}>
                      <BrokerDashboard />
                    </Route>

                    {/* Borrower */}
                    <Route path={routes.borrowerApplicationShowSummary}>
                      <ApplicationProvider>
                        <BorrowerSummary />
                      </ApplicationProvider>
                    </Route>
                    <Route path={routes.borrowerApplicationShow}>
                      <FormContainer type="borrower" />
                    </Route>
                    <Route path={routes.borrowerCatchAll}>
                      <BorrowerDashboard />
                    </Route>
                  </Switch>
                </Route>
              )}

              {/* Not Found */}
              <Route>
                <NoMatch />
              </Route>
            </Switch>

            <ToastContainer />
            <Freshdesk />
            <SessionTimeoutDialog />
          </CurrentBrokerProvider>
        </CurrentBorrowerProvider>
      </RootStoreProvider>
    </Router>
  )
})
export default RouterComponent
