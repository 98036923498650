import React from 'react'
import BankSelect from './BankSelect'

export default function BankSelectScreen({
  setState,
  state,
}) {
  return (
    <>
      <p className="mb-10">
        Please select your bank below. Once you have successfully shared your accounts you can select another bank and repeat the process. If you share a joint account with another applicant on this mortgage application, please do not share accounts already shared by the other applicant.
      </p>

      <BankSelect
        setState={setState}
        state={state}
      />

      <div className="flex justify-center mt-16">
        <button
          className="btn btn--primary lg:w-auto w-full"
          onClick={(e) => {
            e.preventDefault();
            setState({
              ...state,
              currentStep: 1,
            })
          }}
          type="button"
        >
          Back
        </button>
      </div>
    </>
  )
}
