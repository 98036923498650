import * as Yup from 'yup'

export const EmploymentStep1SelfEmployedSchema = Yup.object().shape({
  business_type: Yup.string().nullable().required('This field is required.'),
  nature_of_business: Yup.string().nullable().required('This field is required.'),
  name_of_business: Yup.string().required('This field is required.'),
  percentage_of_shareholding: Yup.mixed().when('business_type', {
    is: business_type => business_type === '1',
    then: Yup.string(),
    otherwise: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.')
  }),
  date_established: Yup.string().when('business_type', {
    is: business_type => business_type === '1',
    then: Yup.string(),
    otherwise: Yup.string().required('This field is required.')
  }),
  date_owned_or_part_owned: Yup.string().when('business_type', {
    is: business_type => business_type === '1',
    then: Yup.string(),
    otherwise: Yup.string().required('This field is required.')
  }),
  current_year_end: Yup.string().when('business_type', {
    is: business_type => business_type === '1',
    then: Yup.string(),
    otherwise: Yup.string().required('This field is required.')
  }),
  self_employed_date: Yup.string().when('business_type', {
    is: business_type => business_type === '4',
    then: Yup.string(),
    otherwise: Yup.string().required('This field is required.')
  }),
});