import React, { useEffect } from 'react'
import Application from '../models/Application'
import DirectDebit from '../models/DirectDebit'
import Mortgage from '../models/Mortgage'
import Security from '../models/Security'
import Solicitor from '../models/Solicitor'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
export const ApplicationContext = React.createContext({})

export default observer(function ApplicationProvider({ children, type }) {
  const location = useLocation()
  const applicationState = useLocalObservable(() =>
    ({
      application: new Application(),
      applicants: [],
      properties: [],
      security: new Security(),
      mortgage: new Mortgage(),
      solicitor: new Solicitor(),
      directDebit: new DirectDebit(),
      loading: false,
      disabled: false,
      backend_url_part: type === 'borrower' ? 'borrower' : 'apprivo_broker',
      type,
      identityVerification: {
        documentType: {},
        failedAutoCaptureCount: 0,
        manualCaptureCount: 0,
        disableAutoCapture: false,
        apiCallCount: 0,
        resultImages: [],
        resultStatus: {
          code: {}, // Custom
          title: {}, // Verification pending, Verification error, Verification complete
          topText: {}, // Custom
          icon: {}, // iconVerificationPending, iconVerificationError, iconVerificationOk
          bottomText: {}, // Please contact our customer support: 892-22222-111, '', ''
          hints: {}, // false, true, false
          buttonText: {} // Call supprt, Retry, ''
        }
      }
    })
  )

  useEffect(() => {
    applicationState.loading = true
    setTimeout(() => applicationState.loading = false, 100)
  }, [location.pathname])

  useEffect(() => {
    if(applicationState.application.state === 'submitted') return applicationState.disabled = true
    if(applicationState.application.state === 'for_review' && type === 'borrower') return applicationState.disabled = true
    if(applicationState.application.state === 'failed' && type === 'borrower') return applicationState.disabled = true
    applicationState.disabled = false
  }, [type, applicationState.application.state])

  return (
    <ApplicationContext.Provider value={{ applicationState }}>
      {children}
    </ApplicationContext.Provider>
  )
})
