import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../models/Applicant'
import NextStepButton from '../../Form/NextStepButton'
import ProgressIndicator from '../ProgressIndicator'

export default observer(function OtherIncomeActions({ save }) {
  const { application_id, applicant_id } = useParams()
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)
  const { applicationState: { applicants, type, disabled }} = useContext(ApplicationContext)

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const url = `/${type}/applications/${application_id}/applicants/${applicant_id}/other-income`

  const otherIncomeStepsErrors = useMemo(() => {
    return [
      { errors: applicantErrors[currentApplicant.id]?.otherIncomeStep1Errors, visited: currentApplicant.steps.other_income_step1_visited },
      { errors: applicantErrors[currentApplicant.id]?.otherIncomeStep2Errors, visited: currentApplicant.steps.other_income_step2_visited },
      { errors: applicantErrors[currentApplicant.id]?.otherIncomeStep3Errors, visited: currentApplicant.steps.other_income_step3_visited },
  ]}, [
    currentApplicant,
    applicantErrors[currentApplicant.id]?.otherIncomeStep1Errors,
    applicantErrors[currentApplicant.id]?.otherIncomeStep2Errors,
    applicantErrors[currentApplicant.id]?.otherIncomeStep3Errors,
    currentApplicant.steps.other_income_step1_visited,
    currentApplicant.steps.other_income_step2_visited,
    currentApplicant.steps.other_income_step3_visited,
  ])

  return (
    <Fragment>
      <ProgressIndicator url={url} steps={3} save={disabled ? () => {} : save} errors={otherIncomeStepsErrors} />

      <div className="flex justify-between mt-16">
        {!disabled && <button className="btn btn--secondary" type="submit">Save Progress</button>}
        <NextStepButton steps={3} url={url} nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/pension`} save={disabled ? () => {} : save} />
      </div>
    </Fragment>
  )
})