import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../models/Applicant'
import { PersonalDetailsStep3Schema } from '../../../schemas/PersonalDetailsSchemas/PersonalDetailsStep3Schema'
import axiosService from '../../../utils/AxiosService'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import Input from '../../Form/Input'
import { notifyProgressSaved } from '../../Form/Notifications'
import ReactSelect from '../../Form/ReactSelect'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import PersonalDetailsActions from './PersonalDetailsActions'

export default observer(function PersonalDetailsStep3() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = [
    "first_time_buyer",
    "retirement_age",
    "relation_with_applicant1",
    "relation_description_with_applicant1",
    "relation_with_applicant2",
    "relation_description_with_applicant2",
    "number_of_dependants",
    "dependant1_age",
    "dependant2_age",
    "dependant3_age",
    "dependant4_age",
    "dependant5_age",
    "dependant6_age",
    "dependant7_age",
    "dependant8_age",
    "dependant9_age",
    "dependant10_age",
    "current_applicant_number"
  ]

  const numberOfDependantsOptions = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
  ]

  const relationshipOptions = [
    { label: 'Partner or Spouse', value: '1' },
    { label: 'Other', value: '2' },
  ]

  const save = applicant => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant })
    .then(() => {
      notifyProgressSaved()
      Object.keys(applicant).forEach(key => currentApplicant[key] = applicant[key])
      applicationErrorsContext.validatePersonalDetailsStep3(currentApplicant, applicant_id)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  const currentApplicantFields = useMemo(() => {
    return fields.reduce((result, field) => {
      result[field] = currentApplicant[field]
      return result
    }, {})
  }, [currentApplicant])

  useEffect(() => {
    if(currentApplicant.steps.personal_details_step3_visited) touchFields()
    applicationErrorsContext.validatePersonalDetailsStep3(currentApplicant, applicant_id)
  }, [loading])

  return (
    <div>
      <h2 className="form-title mb-40">Personal details</h2>
      { !disabled && <ApplicantStepUpdater step="personal_details_step3_visited" /> }

      <Formik
        initialValues={currentApplicantFields}
        validationSchema={PersonalDetailsStep3Schema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setTouched, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <YesNoBtnGroup
                    error={errors.first_time_buyer && touched.first_time_buyer && errors.first_time_buyer}
                    label="Are you a first time buyer?"
                    tooltip="A First Time Buyer is defined as a person who has never before, either on his or her own or with others, purchased a house, a site to build a house, or an apartment, in Ireland or abroad."
                    name="first_time_buyer"
                    onChange={(name, value) => setFieldValue(name, value, true)}
                    onBlur={handleBlur}
                    value={values.first_time_buyer}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.retirement_age && touched.retirement_age && errors.retirement_age}
                    label="Retirement Age"
                    name="retirement_age"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.retirement_age}
                    disabled={disabled}
                  />
                </div>

                {applicants.length > 1 && (
                  <>
                    {applicants[0].id !== currentApplicant.id && (
                      <div className="mb-40">
                        <ReactSelect
                          error={errors.relation_with_applicant1 && touched.relation_with_applicant1 && errors.relation_with_applicant1}
                          label="Relationship with 1st applicant"
                          name="relation_with_applicant1"
                          options={relationshipOptions}
                          onChange={value => {
                            setFieldValue('relation_with_applicant1', (value?.value || ''), true)
                            setFieldValue('relation_description_with_applicant1', '', true)
                          }}
                          onBlur={handleBlur}
                          value={values.relation_with_applicant1}
                          disabled={disabled}
                        />
                      </div>
                    )}

                    { values.relation_with_applicant1 === '2' &&
                      <div className="mb-40">
                        <Input
                          error={errors.relation_description_with_applicant1 && touched.relation_description_with_applicant1 && errors.relation_description_with_applicant1}
                          label="Describe the relationship"
                          name="relation_description_with_applicant1"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.relation_description_with_applicant1}
                          disabled={disabled}
                        />
                      </div>
                    }

                    {applicants[1].id !== currentApplicant.id && (
                      <div className="mb-40">
                        <ReactSelect
                          error={errors.relation_with_applicant2 && touched.relation_with_applicant2 && errors.relation_with_applicant2}
                          label="Relationship with 2nd applicant"
                          name="relation_with_applicant2"
                          options={relationshipOptions}
                          onChange={value => {
                            setFieldValue('relation_with_applicant2', (value?.value || ''), true)
                            setFieldValue('relation_description_with_applicant2', '', true)
                          }}
                          onBlur={handleBlur}
                          value={values.relation_with_applicant2}
                          disabled={disabled}
                        />
                      </div>
                    )}

                    {values.relation_with_applicant2 === '2' &&
                      <div className="mb-40">
                        <Input
                          error={errors.relation_description_with_applicant2 && touched.relation_description_with_applicant2 && errors.relation_description_with_applicant2}
                          label="Describe the relationship"
                          name="relation_description_with_applicant2"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.relation_description_with_applicant2}
                          disabled={disabled}
                        />
                      </div>
                    }
                  </>
                )}
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <ReactSelect
                    error={errors.number_of_dependants && touched.number_of_dependants && errors.number_of_dependants}
                    label="Number of Dependants"
                    name="number_of_dependants"
                    options={numberOfDependantsOptions}
                    onChange={value => {
                      if(+value?.value === 0) {
                        setFieldValue('number_of_dependants', 0, true)
                      } else {
                        setFieldValue('number_of_dependants', (value.value || ''), true)
                      }
                      if(+value.value || +value.value === 0) {
                        [...Array(10 - +value.value)].forEach((_, amount) => {
                          setFieldValue(`dependant${10 - amount}_age`, '', true)
                        })
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.number_of_dependants}
                    disabled={disabled}
                    tooltip="For joint applications, do not include dependents already included by the other applicant"
                  />
                </div>

                {[...Array(+values.number_of_dependants)].map((_, amount) => (
                  <div key={amount} className="mb-40">
                    <Input
                      error={errors[`dependant${amount + 1}_age`] && touched[`dependant${amount + 1}_age`] && errors[`dependant${amount + 1}_age`]}
                      label={`Age dependant ${amount + 1}`}
                      name={`dependant${amount + 1}_age`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[`dependant${amount + 1}_age`]}
                      disabled={disabled}
                    />
                  </div>
                ))}

              </div>
            </div>
            <PersonalDetailsActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})
