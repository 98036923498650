import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'
import consumer from '../../../channels/consumer'
import InProgress from '../../InProgress'
import Tooltip from '../../Tooltip'

export default observer(function OpenBankingPdf({ tooltip, type }) {
  const { application_id, applicant_id } = useParams()
  const [documents, setDocuments] = useState([])

  const getOpenBankingPdf = () => {
    axiosService.get(`/api/borrower/applications/${application_id}/applicants/${applicant_id}/pdf_statements`)
      .then(response => setDocuments(response))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    if (type === 'borrower') getOpenBankingPdf()

    consumer.subscriptions.create({ channel: 'PdfStatementChannel', applicant_id },
      {
        connected() { console.log('PdfStatementChannel connected') },
        disconnected() { console.log('PdfStatementChannel disconnected') },
        received(data) { setDocuments(data) }
      }
    )

    return () => {}
  }, [])

  if (documents.length === 0) return null

  return (
    <>
      {type === 'borrower'
        && (
          <div className="document-box">
            <div className="w-1/3 border-r border-timber-green-o-16 flex items-center pr-8 py-3">
              <div className="w-5/6">
                <p className="font-bold">Bank Statement Creation Status</p>
              </div>

              <div className="flex justify-end w-1/6">
                {tooltip && <Tooltip tooltip={tooltip} />}
              </div>
            </div>

            <div className="pl-8 py-3 w-2/3">
              <InProgress />
            </div>
          </div>
        )}
    </>
  )
})
