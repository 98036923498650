import * as Yup from 'yup'

export const CommitmentsStep1Schema = Yup.object().shape({
  loan_commitments: Yup.array()
    .of(
      Yup.object().shape({
        loan_paid_on_or_before: Yup.boolean().required('This field is required.'),
        loan_type: Yup.number('This field must contain numbers.').typeError('You have to select one').required('This field is required.'),
        remaining_term: Yup.number('This field must contain numbers.').typeError('It must be a number').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
        outstanding_balance: Yup.number('This field must contain numbers.').typeError('It must be a number').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
        monthly_payment: Yup.number('This field must contain numbers.').typeError('It must be a number').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
        lender_name: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
        mortgage_account_number: Yup.number('This field must contain numbers.').typeError('It must be a number').min(0, 'This field must be greater than or equal 0.').max(999, 'This field should contain max 3 digits').required('This field is required.'),
        purpose: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
      })
    )
});
