import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'
import SendEmailToTheBorrowerModal from './SendEmailToTheBorrowerModal';
import { toastError, toastInfo, toastSuccess } from '../../Form/Toasts';

export default function SendBackForTheBorrowerButton() {
  const { application_id } = useParams()
  const [open, setOpen] = useState(false)
  const history = useHistory()

  const sendBackToTheBorrower = () => {
    toastInfo('Sending back to the borrower')
    axiosService.patch(`/api/apprivo_broker/applications/${application_id}`, { application: { state: 'draft' }})
    .then(() => {
      toastSuccess('Sent back to the borrower')
      history.push('/broker/applications')
    })
    .catch(({ data: { error }}) => {
      toastError(error)
    })
  }

  const openMessageModal = () => setOpen(true)

  return (
    <div className="px-40 py-4">
      <button className={`btn btn--primary text-center w-full`} onClick={openMessageModal}>Send back to the Borrower</button>
      {open && <SendEmailToTheBorrowerModal action={sendBackToTheBorrower} setOpen={setOpen} />}
    </div>
  )
}