import React from 'react';

import image404 from 'images/404.svg'

export default function NoMatch() {
  return (
    <div className="flex items-center justify-center text-center" style={{ minHeight: 'calc(100vh - 67px)' }}>
      <div className="py-16">
        <h1 className="title mb-4">We are sorry...</h1>

        <p className="mb-8 text-xl">Looks like this page doesn’t exist, but<br /> don’t let that stop you</p>

        <div className="lg:hidden mb-8">
          <img alt="404" src={image404} />
        </div>

        <a className="btn btn--primary" href="/">Go to Homapage</a>

        <div className="hidden lg:block">
          <img alt="404" src={image404} />
        </div>
      </div>
    </div>
  );
};
