import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import AffordabilityCalculatorForm from './Form'
import AprcCalculatorList from './List'
import { AffordabilityCalculatorSchema } from '../../../schemas/AffordabilityCalculatorSchema'
import { ExcelFormulas } from '../../calculations'
import axiosService from '../../../utils/AxiosService'

export default function AffordabilityCalculator() {
  const [dataServiceRatios, setDatadataServiceRatios] = useState([])
  const [incomeMultiples, setIncomeMultiples] = useState([])
  const [netDisposableIncomes, setNetDisposableIncomes] = useState([])

  const initialValues = {
    adult_dependants: '0',
    applicant_type: '',
    dependants: '0',
    estimated_loan_requested: '',
    existing_mortgages: 0,
    first_time_buyer: null,
    gross_annual_income: '',
    house_value: '',
    loans: 0,
    main_net_monthly_income: '',
    mortgage_term: '',
    rate: '',
    revolving_credit_balance: 0,
    secondary_net_monthly_income: 0,
  }

  const getDatadataServiceRatios = () => {
    axiosService.get('/api/apprivo_broker/data_service_ratios')
      .then(response => setDatadataServiceRatios(response))
      .catch(error => console.log(error))
  }

  const getIncomeMultiples = () => {
    axiosService.get('/api/apprivo_broker/income_multiples')
      .then(response => setIncomeMultiples(response))
      .catch(error => console.log(error))
  }

  const getNetDisposableIncomes = () => {
    axiosService.get('/api/apprivo_broker/net_disposable_incomes')
      .then(response => setNetDisposableIncomes(response))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    getDatadataServiceRatios()
    getIncomeMultiples()
    getNetDisposableIncomes()
  }, [])

  const mocoExtraRate = 2

  const calculatePmt = (values, loan) => {
    return (
      ExcelFormulas.PMT(
        (parseFloat(values.rate) + mocoExtraRate) / 100 / 12,
        values.mortgage_term * 12,
        loan
      ).toFixed(2) * -1
    )
  }

  const calculateLoanRepayment = (values) => {
    let loan = 100000
    const minLoan = 100000
    const maxLoan = 2000000
    const loanSteps = 50
    const items = (maxLoan - minLoan) / loanSteps

    const loanArray = new Array((items)).fill().map((e, i) => {
      loan += loanSteps

      return {
        repayment: Math.floor(calculatePmt(values, loan) / 50) * 50,
        loan,
        term: values.mortgage_term,
        rate: parseFloat(values.rate) + mocoExtraRate,
      }
    })

    return loanArray
  }

  const calculateMaxLtv = (values) => {
    const houseValueModifier = values.first_time_buyer ? 0.9 : 0.8
    return values.house_value * houseValueModifier
  }

  const calculateIncomeMultiple = (values) => {
    if (incomeMultiples.length === 0) return

    const ltv = values.house_value ? (values.estimated_loan_requested / values.house_value) * 100 : 0
    const minMortgageValue = incomeMultiples.find(item => item.min_mortgage_value > 0).min_mortgage_value
    const minMortgageMultiple = incomeMultiples.find(item => item.min_mortgage_value > 0).income_multiple
    const netDisposableIncomesWithoutMortgageValue = incomeMultiples.filter(item => item.min_mortgage_value !== minMortgageValue)

    return values.estimated_loan_requested > minMortgageValue
      ? minMortgageMultiple
      : netDisposableIncomesWithoutMortgageValue
        .reduce((prev, current) => current.max_ltv_percent >= ltv && current.min_ltv_percent <= ltv ? current : prev).income_multiple
  }

  const calculateMaxIm = (values) => values.gross_annual_income * calculateIncomeMultiple(values)

  const calculateDebtServiceRatio = (values) => {
    if (dataServiceRatios.length === 0) return

    const maxGrossAnnualIncome = dataServiceRatios.reduce((prev, current) => prev = prev > current.min_gross_application_annual_income ? prev : current.min_gross_application_annual_income, 0)
    if (values.gross_annual_income > maxGrossAnnualIncome) return dataServiceRatios.find(item => item.min_gross_application_annual_income === maxGrossAnnualIncome).debt_service_ratio_allowable / 100

    return dataServiceRatios.reduce((prev, current) => current.max_gross_application_annual_income >= values.gross_annual_income && current.min_gross_application_annual_income < values.gross_annual_income ? current : prev).debt_service_ratio_allowable / 100
  }

  const calculateMaxDsr = (values) => {
    const income = parseFloat(values.main_net_monthly_income) + (values.secondary_net_monthly_income * 0.75)
    const dsrMortgage = (parseFloat(values.existing_mortgages) + parseFloat(values.loans) + parseFloat(values.revolving_credit_balance * 0.05)) / income
    const dsrLimit = Math.floor(((calculateDebtServiceRatio(values) - dsrMortgage) * income) / 50) * 50

    const array = calculateLoanRepayment(values)
    const lookup = array.filter(item => item.repayment === dsrLimit)

    return lookup[lookup.length - 1]?.loan || 0
  }

  const calculateCostOfLiving = (values) => {
    if (!values.dependants || values.dependants == 0) return null

    const firstChildCost = netDisposableIncomes.find(item => item.household === '1st Child Dependant').cost_of_living
    const secondChildCost = netDisposableIncomes.find(item => item.household === '2nd Child Dependant').cost_of_living
    const thirdChildCost = netDisposableIncomes.find(item => item.household === '3rd or more Child Dependants').cost_of_living

    if (values.dependants == 1) return firstChildCost
    if (values.dependants == 2) return firstChildCost + secondChildCost
    if (values.dependants >= 3) return firstChildCost + secondChildCost + ((values.dependants - 2) * thirdChildCost)
  }

  const calculateNetIncome = (values) => {
    if (!values.applicant_type) return

    const typeCost = netDisposableIncomes.find(item => item.household === values.applicant_type).cost_of_living
    const adultCost = netDisposableIncomes.find(item => item.household === 'Adult Dependant').cost_of_living * values.adult_dependants
    const youngCost = calculateCostOfLiving(values)

    return typeCost + adultCost + youngCost
  }

  const calculateMaxNdi = (values) => {
    const income = parseFloat(values.main_net_monthly_income) + (values.secondary_net_monthly_income * 0.75)
    const disposableMortgage = income - (parseFloat(values.existing_mortgages) + parseFloat(values.loans) + parseFloat(values.revolving_credit_balance * 0.05)) - calculateNetIncome(values)
    const ndiLimit = Math.floor(disposableMortgage / 50) * 50

    const array = calculateLoanRepayment(values)
    const lookup = array.filter(item => item.repayment === ndiLimit)

    return lookup[lookup.length - 1]?.loan || 0
  }

  const calculateMaxMortgage = (values) => values.house_value
    ? Math.min(calculateMaxNdi(values), calculateMaxDsr(values), calculateMaxIm(values), calculateMaxLtv(values))
    : Math.min(calculateMaxNdi(values), calculateMaxDsr(values), calculateMaxIm(values))

  const calculateLtv = (values) => ((calculateMaxMortgage(values) / values.house_value) * 100).toFixed(2)

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={AffordabilityCalculatorSchema}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        isValid,
        resetForm,
        setFieldValue,
        touched,
        values,
      }) => {
        return (
          <>
            <h1 className="sub-title mb-16 text-center">Affordability Calculator</h1>

            <div className="box-shadow mb-8 p-6 rounded-lg">
              <AffordabilityCalculatorForm
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
              />

              <AprcCalculatorList
                calculateLtv={calculateLtv}
                calculateMaxDsr={calculateMaxDsr}
                calculateMaxIm={calculateMaxIm}
                calculateMaxLtv={calculateMaxLtv}
                calculateMaxMortgage={calculateMaxMortgage}
                calculateMaxNdi={calculateMaxNdi}
                dirty={dirty}
                isValid={isValid}
                resetForm={resetForm}
                values={values}
              />
            </div>
          </>
        )
      }}
    </Formik>
  )
}
