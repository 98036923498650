import React from 'react'
import Flatpickr from 'react-flatpickr'
import Label from './Label'
import moment from 'moment'
import ErrorMessage from './ErrorMessage'

export default function DatePicker({
  label,
  name,
  error,
  onChange = () => {},
  optional,
  tooltip,
  value,
  disabled,
}) {
  return (
    <>
      <Label label={label} tooltip={tooltip} />

      <div className="relative">
        <Flatpickr
          name={name}
          className={`flatpickr-input ${error && 'error'}`}
          onChange={(selectedDates) => onChange(new Date(moment(selectedDates[0]).format('YYYY-MM-DD')))}
          options={{ dateFormat: 'd-m-Y', allowInput: true }}
          value={value ? new Date(moment(value).toISOString()) : ''}
          disabled={disabled}
        />

        {optional ? <p className="optional-text">Optional</p> : <i className="far fa-calendar-alt absolute right-32 top-1/2 transform -translate-y-1/2" />}
      </div>

      <ErrorMessage text={error} />
    </>
  )
}