import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ApplicationContext } from '../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../contexts/ApplicationErrorsContext'
import axiosService from '../../utils/AxiosService'
import moment from 'moment'
import SendEmailToTheBrokerModal from './SendEmailToTheBrokerModal'
import { toastError, toastSuccess } from '../Form/Toasts'

export default observer(function ApplyForMortgageButton() {
  const { applicationState: { application, applicants } } = useContext(ApplicationContext)
  const { applicationErrorsState: { solicitorErrors, directDebitErrors, mainPropertyDetailsErrors, portfolioErrors, loanRequirementsStep1Errors, loanRequirementsStep2Errors, applicantErrors, documentUploadErrors } } = useContext(ApplicationErrorsContext)
  let history = useHistory()
  const [open, setOpen] = useState(false)

  const applyForMortgage = () => {
    axiosService.patch(`/api/borrower/applications/${application.id}/send_for_review`)
    .then(() => {
      toastSuccess('Application has been submitted successfully')
      history.push('/borrower/applications')
    })
    .catch(({ data: { error } }) => toastError(error))
  }

  const invalid = () => {
    if(!checkAllStepsVisited()) return true
    if(!checkAllStepsAreValid()) return true
    return false
  }

  const checkAllStepsVisited = () => {
    let allStepsVisited = true
    Object.keys(application.steps).forEach(key => {
      if(key === 'loan_requirements_step2_visited' && application.purchase_type === 'Purchase') {

      } else {
        allStepsVisited &&= application.steps[key]
      }
    })
    applicants.forEach(applicant => {
      Object.keys(applicant.steps).forEach(key => {
        if(['employment_step2_visited', 'employment_step3_visited', 'employment_step4_visited'].includes(key)) {
          if(applicant.employment.are_you === 'Self Employed') {
            return allStepsVisited &&= applicant.steps[key]
          }
          if(applicant.employment.are_you === 'Employed') {
            const jobStartDate = applicant.employment.employment_type === '2' ? applicant.employment.date_joined : applicant.employment.date_contract_started
            if(moment(jobStartDate) > moment().subtract(3, 'years')) {
              return allStepsVisited &&= applicant.steps[key]
            }
            if(key !== 'employment_step4_visited') return allStepsVisited &&= applicant.steps[key]
          }

        } else {
          allStepsVisited &&= applicant.steps[key]
        }
      })
    })
    return allStepsVisited
  }

  const checkAllStepsAreValid = () => {
    let allStepsAreValid = true
    allStepsAreValid &&= solicitorErrors === 0
    allStepsAreValid &&= directDebitErrors === 0
    allStepsAreValid &&= mainPropertyDetailsErrors === 0
    allStepsAreValid &&= portfolioErrors === 0
    allStepsAreValid &&= loanRequirementsStep1Errors === 0
    allStepsAreValid &&= loanRequirementsStep2Errors === 0
    allStepsAreValid &&= documentUploadErrors === 0
    Object.values(applicantErrors).forEach(errors => Object.values(errors).forEach(error => allStepsAreValid &&= error === 0) )
    return allStepsAreValid
  }

  const openMessageModal = () => setOpen(true)

  return (
    <div className="px-40 py-8">
      <button className={`btn btn--primary text-center w-full ${invalid() && 'opacity-50'}`} onClick={openMessageModal} disabled={invalid()}>Submit to broker</button>
      {open && <SendEmailToTheBrokerModal action={applyForMortgage} setOpen={setOpen} />}
    </div>
  )
})
