import axios from 'axios'
import { rootStore } from '../contexts/RootStoreContext'

axios.default.defaults.xsrfCookieName = "CSRF-TOKEN"
axios.default.defaults.xsrfHeaderName = "X-CSRF-Token"
axios.default.defaults.withCredentials = true

// Basic Auth
// - If basic auth is being used, then add credentials to requests
if (gon.basic_auth_username) {
  axios.defaults.auth = {
    username: gon.basic_auth_username,
    password: gon.basic_auth_password
  }
}

const axiosService = axios.create({
  headers: { 'Content-Type': 'application/json' }
})
axiosService.interceptors.response.use(
  // Success
  (response) => {
    // Always return the data
    return response.data
  },
  // Failure
  ({ response }) => {
    // If error response has code 'not-authenticated', means user must sign in! 
    if (response.data.code === 'not-authenticated') {
      // Use throttled version as this may be called several times at once
      rootStore.deauthenticateUserThrottled('not-authenticated')
    }
    // If error response has code 'session-timed-out', means we 
    // need to show a dialog to for the user to reauthenticate
    else if (response.data.code === 'session-timed-out') {
      // Use throttled version as this may be called several times at once
      rootStore.deauthenticateUserThrottled('session-timed-out')
    }

    // Always return error response
    return Promise.reject(response)
  }
)

export default axiosService