import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import NextStepButton from '../../Form/NextStepButton'
import ProgressIndicator from '../ProgressIndicator'
import axiosService from '../../../utils/AxiosService'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Applicant from '../../../models/Applicant'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

export default observer(function IdentityVerification() {
  const { application_id, applicant_id } = useParams()
  const { applicationState: { applicants, type } } = useContext(ApplicationContext)
  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants])
  const url = `/borrower/applications/${application_id}/applicants/${applicant_id}/identity-verification`

  const [state, setState] = useState({
    identityVerificationState: null,
    retryAllowed: false,
    textsToDisplay: null,
    consent: null
  })

  const getIdentityVerificationStatus = () => {
    axiosService.get('/api/borrower/identity_verification_status/' + application_id + '/' + applicant_id)
    .then(identityVerificationStatus => {
      console.log(identityVerificationStatus, window.location.href)

      if (!identityVerificationStatus.consent && !window.location.href.match('step1')) {
        window.location.replace(url + '/step1')
      }
      else {
        setState({
          identityVerificationState: identityVerificationStatus.status,
          retryAllowed: (identityVerificationStatus.status.status.includes('retry') || identityVerificationStatus.status.status == 'first_try'),
          textsToDisplay: buildTextsToDisplay(identityVerificationStatus),
          consent: identityVerificationStatus.consent
        })
      }
    })
    .catch(error => console.log(error)) // Should this be a retry case, like first_try ?
  }

  const buildTextsToDisplay = (identityVerificationStatus) => {
    const status = identityVerificationStatus.status.status

    if (status == 'success') {
      return {
        'title': 'Successful verification',
        'topText': 'Your identity has been successfully verified. You can continue with the next steps of your mortgage application.',
        'icon': 'success',
        'bottomText': ''
      }
    }
    else if (status == 'success_pending') {
      return {
        'title': 'Verification pending',
        'topText': 'Your documents have been sent for verification. The results will be available on this screen shortly.',
        'icon': 'pending',
        'bottomText': ''
      }
    }
    else if (status == 'backend_max_attempts_reached') {
      return {
        'title': 'Verification pending',
        'topText': 'An unexpected error occurred, please contact your mortgage broker.',
        'icon': 'pending',
        'bottomText': ''
      }
    }
    else if (status == 'retry_could_not_connect' || status == 'retry_tech_error' || status == 'retry_contact_support') {
      return {
        'topText': 'An unexpected error occurred, please try again.'
      }
    }
    else if (status == 'retry_selfie_not_authentic') {
      return {
        'topText': 'An unexpected error occurred, please try again.'
      }
    }
    else if (status == 'retry_document_image_quality') {
      return {
        'topText': 'We were unable to verify your identity due to an issue with the images you have provided. Please try again.'
      }
    }
    else if (status == 'not_authentic') {
      return {
        'title': 'Verification error',
        'topText': 'An unexpected error occurred, please contact your mortgage broker.',
        'icon': 'error',
        'bottomText': ''
      }
    }
    else if (status == 'retry_not_irish_drivers') {
      return {
        'topText': 'We can only accept an Irish driver’s license. If you do not have an Irish drivers license, please select another document type to verify your identity.'
      }
    }
    else if (status == 'first_try') {
      return {
        'topText': 'When applying for a mortgage, all applicants are required to verify their identity. We’ve partnered with Mitek Systems to make verifying your identity quick and easy. Click <a href="/videos/id-verification.mp4" target="_blank" class="underline text-turquoise">here</a> to find out more on how it works.'
      }
    }
    else {
      return {
        'topText': 'An unexpected error occurred, please try again.'
      }
    }
  }

  const toggleConsent = (value) => {
    axiosService.post('/api/borrower/identity_verification/consent', { id: applicant_id, application_id: application_id, consent: value })
      .then(response => { console.log(response) })
      .catch(error => console.log(error))

    setState({
      identityVerificationState: state.identityVerificationState,
      retryAllowed: state.retryAllowed,
      textsToDisplay: state.textsToDisplay,
      consent: value
    })
  }

  const save = () => {

  }

  useEffect(() => {
    if(type === 'borrower') {
      getIdentityVerificationStatus()
    }

    return () => {
      console.log('useEffect return')
    }
  }, [])

  return (
    <>
      { (type != 'borrower' || !state.identityVerificationState) &&
        <div>
          { type != 'borrower' &&
            <div className="mx-auto w-1/2 mb-40 text-center">
              <YesNoBtnGroup
                disabled={true}
                label="I consent to the processing of my digital image for the purpose of verifying my identity."
                name="idv_consent"
                value={currentApplicant.identity_verification_consent}
              />
            </div>
          }
          <div className="flex justify-center mt-16">
            <NextStepButton nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/personal-details/step1`} save={save}/>
          </div>
        </div>
      }

      { type == 'borrower' && state.identityVerificationState && !state.retryAllowed &&
        <div>
          <Step3 textsToDisplay={state.textsToDisplay}/>
          <div className="flex justify-center mt-16">
            <NextStepButton nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/personal-details/step1`} save={save}/>
          </div>
        </div>
      }

      { type == 'borrower' && state.identityVerificationState && state.retryAllowed &&
        <div>
          <Switch>
            <Route path={`/borrower/applications/:application_id/applicants/:applicant_id/identity-verification/step1`}>
              <Step1 textsToDisplay={state.textsToDisplay}/>
              <div className="mx-auto w-1/2 mb-40 text-center">
                <YesNoBtnGroup
                  disabled={!state.retryAllowed}
                  label="I consent to the processing of my digital image for the purpose of verifying my identity."
                  name="idv_consent"
                  onChange={(name, value) => {
                    console.log(name, value)
                    toggleConsent(value)
                  }}
                  value={state.consent}
                />
              </div>
              <ProgressIndicator url={url} steps={3} save={save} disableNavigation={true} />
              <div className="flex justify-center mt-16">
                <NextStepButton steps={state.consent ? 3 : null} nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/personal-details/step1`} url={url} save={save}/>
              </div>
            </Route>
            <Route path={`/borrower/applications/:application_id/applicants/:applicant_id/identity-verification/step2`}>
              <Step2 />
              <ProgressIndicator url={url} steps={3} save={save} disableNavigation={true} />
            </Route>
            <Route path={`/borrower/applications/:application_id/applicants/:applicant_id/identity-verification/step3`}>
              <Step3 />
              <ProgressIndicator url={url} steps={3} save={save} disableNavigation={true} />
              <div className="flex justify-center mt-16">
                <NextStepButton steps={3} nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/personal-details/step1`} url={url} save={save}/>
              </div>
            </Route>
          </Switch>
        </div>
      }
    </>
  )
})
