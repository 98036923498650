import React, { useContext, useEffect, useMemo, useRef } from 'react'
import Input from '../../Form/Input'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import Divider from '../../Divider'
import NextStepButton from '../../Form/NextStepButton'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Assets from '../../../models/Assets'
import { Formik } from 'formik'
import { assetsSchema } from '../../../schemas/AssetsSchema'
import Applicant from '../../../models/Applicant'
import axiosService from '../../../utils/AxiosService'
import { observer } from 'mobx-react-lite'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'

export default observer(function AssetsPage() {
  const { application_id, applicant_id } = useParams()
  const { applicationState: { applicants, disabled, type, backend_url_part, loading }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicants.length, applicant_id])

  const save = assets => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { assets }})
    .then(() => {
      currentApplicant.assets = new Assets(assets)
      notifyProgressSaved()
    })
  }

  useEffect(() => {
    if(currentApplicant.steps.assets_visited) touchFields()
    applicationErrorsContext.validateAssets(currentApplicant.assets, applicant_id)
  }, [loading, currentApplicant.assets])

  const touchFields = () => formikRef.current.setTouched(Object.keys(currentApplicant.assets).reduce((result, field) => { result[field] = true; return result }, {}))

  return (
    <>
      { !disabled && <ApplicantStepUpdater step="assets_visited" /> }

      <Divider text="Asset Details" />

      <Formik
        initialValues={currentApplicant.assets}
        validationSchema={assetsSchema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
      {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
        <form onSubmit={save(values)}>
          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField
                  addon="€"
                  addonPosition="start"
                  error={errors.cash_deposits && touched.cash_deposits && errors.cash_deposits}
                  label="Cash Deposits"
                  name="cash_deposits"
                  autoFocus={true}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.cash_deposits}
                  disabled={disabled}
                />
              </div>

              <div className="mb-40">
                <YesNoBtnGroup
                  error={errors.cash_deposits_jointly_owned && touched.cash_deposits_jointly_owned && errors.cash_deposits_jointly_owned}
                  label="Are cash deposits jointly owned?"
                  name="cash_deposits_jointly_owned"
                  onChange={(name, value) => setFieldValue(name, value, true)}
                  value={values.cash_deposits_jointly_owned}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField
                  addon="€"
                  addonPosition="start"
                  error={errors.stocks_shares && touched.stocks_shares && errors.stocks_shares}
                  label="Shares"
                  name="stocks_shares"
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.stocks_shares}
                  disabled={disabled}
                  tooltip="Please provide the current market value"
                />
              </div>

              <div className="mb-40">
                <YesNoBtnGroup
                  error={errors.stock_share_jointly_owned && touched.stock_share_jointly_owned && errors.stock_share_jointly_owned}
                  label="Are shares jointly owned?"
                  name="stock_share_jointly_owned"
                  onChange={(name, value) => setFieldValue(name, value, true)}
                  value={values.stock_share_jointly_owned}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>

          <Divider text="Other Asset Details" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="btn-group-container flex items-center justify-between mb-40">
                <div className="w-2/3">
                  <button
                    className={`btn btn--group btn--primary w-full ${values.assets_amount > 9 && 'opacity-50'}`}
                    disabled={values.assets_amount > 9}
                    onClick={() => setFieldValue('assets_amount', values.assets_amount + 1, true)}
                    type="button"
                    disabled={disabled}
                  >
                    Add Asset
                    <i className="far fa-plus ml-10" />
                  </button>
                </div>

                <span className="pr-8">{values.assets_amount}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-40 -mx-12">
            {[...Array(+values.assets_amount)].map((_, amount) => (
              <div className="w-full" key={amount}>
                <div className="flex items-center mb-40 px-12 w-full">
                  <p className="mr-6">
                    <i className="fa fa-chevron-down mr-2" />
                    Asset {amount + 1}
                  </p>

                  <div className="bg-timber-green-o-16 flex-auto mr-6" style={{ height: 2 }} />

                  <button
                    className="bg-punch circle fs-9 text-white"
                    onClick={() => {
                      setFieldValue('assets_amount', values.assets_amount - 1, true)
                      setFieldValue(`other_assets${amount + 1}`, '', true)
                      setFieldValue(`asset_type${amount + 1}`, '', true)
                    }}
                    style={{ height: 20, width: 20 }}
                    type="button"
                    disabled={disabled}
                  >
                    <i className="fa fa-trash-alt" />
                  </button>
                </div>

                <div className="px-12 w-full flex justify-between">
                  <div className="mb-40 w-full pr-12">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={errors[`other_assets${amount + 1}`] && touched[`other_assets${amount + 1}`] && errors[`other_assets${amount + 1}`]}
                      label={`Other Assets ${amount + 1}`}
                      name={`other_assets${amount + 1}`}
                      optional={!values[`other_assets${amount + 1}`] || values[`other_assets${amount + 1}`] === 0}
                      optional={!values[`other_assets${amount + 1}`]}
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      value={values[`other_assets${amount + 1}`]}
                      disabled={disabled}
                    />
                  </div>

                  <div className="mb-40 w-full pl-12">
                    <Input
                      error={errors[`asset_type${amount + 1}`] && touched[`asset_type${amount + 1}`] && errors[`asset_type${amount + 1}`]}
                      label={`Specify Asset ${amount + 1}`}
                      name={`asset_type${amount + 1}`}
                      optional={!values[`asset_type${amount + 1}`]}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[`asset_type${amount + 1}`]}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-between mt-16">
            {!disabled && <button className="btn btn--secondary" type="submit">Save Progress</button>}
            <NextStepButton nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/commitments/step1`} save={disabled ? () => {} : save(values)}/>
          </div>

        </form>
      )}
      </Formik>
    </>
  )
})
