import React, { Fragment } from 'react'
import CurrencyTextField from '../../CurrencyTextField'
import TitleRow from '../../TitleRow'
import ApplicantRow from './ApplicantRow'

export default function LoanCommitmentsPart({ applicants }) {

  const loanTypeOptions = [
    { label: 'Secured', value: '1' },
    { label: 'Unsecured', value: '2' },
  ]

  const getMaxLoansAmount = () => {
    return applicants.reduce((maximum_cards, { loan_commitments }) => loan_commitments.length > maximum_cards ? loan_commitments.length : maximum_cards, 0)
  }

  const getloanType = loan_type => loanTypeOptions.find(option => option.value === loan_type)?.label

  return (
    <Fragment>
      <TitleRow label="Loan Commitments" />
      {[...Array(+getMaxLoansAmount())].map((_, amount) => (
        <Fragment key={amount}>
          <TitleRow label={`Loan Commitment ${amount + 1}`} />
          <ApplicantRow name="Loan type" tdContent={applicant => getloanType(applicant.loan_commitments[amount]?.loan_type)} />
          <ApplicantRow name="Remaining Term (months)" tdContent={applicant => applicant.loan_commitments[amount]?.remaining_term} />
          <ApplicantRow name="Outstanding balance" tdContent={applicant => <CurrencyTextField value={applicant.loan_commitments[amount]?.outstanding_balance}/>} />
          <ApplicantRow name="To be repaid prior to drawdown" tdContent={applicant => !applicant.loan_commitments[amount] ? '' : applicant.loan_commitments[amount]?.loan_paid_on_or_before ? 'Yes' : 'No'} />
          <ApplicantRow name="Monthly payment" tdContent={applicant => <CurrencyTextField value={applicant.loan_commitments[amount]?.monthly_payment}/>} />
          <ApplicantRow name="Lender Name" tdContent={applicant => applicant.loan_commitments[amount]?.lender_name} />
          <ApplicantRow name="Account Number" tdContent={applicant => applicant.loan_commitments[amount]?.mortgage_account_number} />
          <ApplicantRow name="Loan Purpose" tdContent={applicant => applicant.loan_commitments[amount]?.purpose} />
        </Fragment>
      ))}
    </Fragment>
  )
}