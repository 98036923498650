import { observer } from 'mobx-react-lite'
import React, { useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ApplicationContext } from '../../contexts/ApplicationContext'
import { CurrentBrokerContext } from '../../contexts/CurrentBrokerContext'
import Applicant from '../../models/Applicant'
import Application from '../../models/Application'
import Property from '../../models/Property'
import Security from '../../models/Security'
import Mortgage from '../../models/Mortgage'
import Solicitor from '../../models/Solicitor'
import DirectDebit from '../../models/DirectDebit'
import Summary from '../Summary/Summary'
import axiosService from '../../utils/AxiosService'

export default observer(function BrokerSummary() {
  const { application_id } = useParams()
  const history = useHistory()
  const currentBrokerContext = useContext(CurrentBrokerContext)
  const applicationContext = useContext(ApplicationContext)

  useEffect(() => {
    getCurrentBroker()
    getApplication()
  }, [])

  const getCurrentBroker = () => {
    axiosService.get('/api/apprivo_broker/profile/current')
    .then(broker => currentBrokerContext.setCurrentBroker(broker))
    .catch(() => {})
  }


  const getApplication = () => {
    applicationContext.applicationState.loading = true
    axiosService.get(`/api/apprivo_broker/applications/${application_id}`)
    .then(application => {
      applicationContext.applicationState.application = new Application(application)
      applicationContext.applicationState.applicants = application.applicants.map(applicant => new Applicant(applicant))
      applicationContext.applicationState.properties = application.properties.map(property => new Property(property))
      applicationContext.applicationState.security = new Security(application.security || {})
      applicationContext.applicationState.mortgage = new Mortgage(application.mortgage || {})
      applicationContext.applicationState.solicitor = new Solicitor(application.solicitor || {})
      applicationContext.applicationState.directDebit = new DirectDebit(application.direct_debit || {})
      applicationContext.applicationState.loading = false
    })
    .catch(({ data: { error } }) => {
      toastError(error)
      history.push('/broker/applications')
    })
  }

  return <Summary />
})