import React from 'react'
import Input from '../../Form/Input'
import CurrencyField from '../../Form/CurrencyField'
import RcSlider from '../../Form/RcSlider'
import ReactSelect from '../../Form/ReactSelect'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import Divider from '../../Divider'

export default function AffordabilityCalculatorForm({
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  touched,
  values,
}) {
  const applicantTypeOptions = [
    { label: 'Sole', value: 'Sole' },
    { label: 'Joint', value: 'Joint' },
  ]

  const dependantsOptions = [
    { label: 0, value: '0' },
    { label: 1, value: '1' },
    { label: 2, value: '2' },
    { label: 3, value: '3' },
    { label: 4, value: '4' },
    { label: 5, value: '5' },
    { label: 6, value: '6' },
    { label: 7, value: '7' },
    { label: 8, value: '8' },
    { label: 9, value: '9' },
  ]

  const adultDependantsOptions = [
    { label: 0, value: '0' },
    { label: 1, value: '1' },
    { label: 2, value: '2' },
    { label: 3, value: '3' },
    { label: 4, value: '4' },
    { label: 5, value: '5' },
    { label: 6, value: '6' },
    { label: 7, value: '7' },
    { label: 8, value: '8' },
    { label: 9, value: '9' },
  ]

  return (
    <form>
      <div className="box-shadow mb-8 rounded-lg">
        <div className="bg-alabaster px-14 py-2 rounded-lg">
          <p className="font-bold text-2xl">Customer Details</p>
        </div>

        <div className="px-14 py-6">
          <div className="lg:flex flex-wrap -mx-3">
            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <ReactSelect
                  error={errors.applicant_type && touched.applicant_type && errors.applicant_type}
                  label="Applicant type"
                  name="applicant_type"
                  onBlur={handleBlur}
                  onChange={value => setFieldValue('applicant_type', (value?.value || ''), true)}
                  options={applicantTypeOptions}
                  placeholder="Select an Applicant type"
                  showAsterisk
                  value={values.applicant_type}
                />
              </div>
            </div>

            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <ReactSelect
                  error={errors.dependants && touched.dependants && errors.dependants}
                  label="Dependents (<21 Years Old)"
                  name="dependants"
                  onBlur={handleBlur}
                  onChange={value => setFieldValue('dependants', (value?.value || ''), true)}
                  options={dependantsOptions}
                  placeholder="Select dependants"
                  showAsterisk
                  value={values.dependants}
                />
              </div>
            </div>

            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <ReactSelect
                  error={errors.adult_dependants && touched.adult_dependants && errors.adult_dependants}
                  label="Adult Dependents (>=21 Years Old)"
                  name="adult_dependants"
                  onBlur={handleBlur}
                  onChange={value => setFieldValue('adult_dependants', (value?.value || ''), true)}
                  options={adultDependantsOptions}
                  placeholder="Select adult dependants"
                  showAsterisk
                  value={values.adult_dependants}
                />
              </div>
            </div>

            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <YesNoBtnGroup
                  error={errors.first_time_buyer && touched.first_time_buyer && errors.first_time_buyer}
                  label="First Time Buyer(s)"
                  name="first_time_buyer"
                  onChange={(name, value) => setFieldValue(name, value, true)}
                  showAsterisk
                  value={values.first_time_buyer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-shadow mb-8 rounded-lg">
        <div className="bg-alabaster px-14 py-2 rounded-lg">
          <p className="font-bold text-2xl">Financial Details</p>
        </div>

        <div className="px-14 py-6">
          <Divider text="Monthly Commitments" tooltip="Please include all monthly commitments including revolving credit card and overdraft balances. Please do not include any borrowings to be cleared prior to drawdown of this new mortgage " />

          <div className="lg:flex flex-wrap mb-40 -mx-3">
            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <CurrencyField
                  addon="€"
                  addonPosition="start"
                  error={errors.existing_mortgages && touched.existing_mortgages && errors.existing_mortgages}
                  label="Existing Mortgage(s)"
                  name="existing_mortgages"
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  showAsterisk
                  value={values.existing_mortgages}
                />
              </div>
            </div>

            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <CurrencyField
                  addon="€"
                  addonPosition="start"
                  error={errors.loans && touched.loans && errors.loans}
                  label="Loans"
                  name="loans"
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  showAsterisk
                  value={values.loans}
                />
              </div>
            </div>

            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <CurrencyField
                  addon="€"
                  addonPosition="start"
                  error={errors.revolving_credit_balance && touched.revolving_credit_balance && errors.revolving_credit_balance}
                  label="Revolving Credit Balance"
                  name="revolving_credit_balance"
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  showAsterisk
                  value={values.revolving_credit_balance}
                />
              </div>
            </div>
          </div>

          <Divider text="Income" tooltip="For joint assessments please include combined income." />

          <div className="lg:flex flex-wrap -mx-3">
            <div className="lg:mb-0 lg:w-full mb-8 px-3">
              <div className="flex -mx-3">
                <div className="mb-6 px-3 w-1/2">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.gross_annual_income && touched.gross_annual_income && errors.gross_annual_income}
                    label="Gross Annual Income"
                    name="gross_annual_income"
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    showAsterisk
                    tooltip="
                      <p>Total gross annual income (Combined if a joint application).</p>
                      <p>This is 100% of the gross basic salary PLUS 100% of guaranteed allowances (i.e. Travel/Car).</p>
                      <p>In addition, 75% of variable income should also be included in this figure, i.e.</p>
                      <ul class='list-disc pl-4'>
                        <li>Commission</li>
                        <li>Overtime</li>
                        <li>Bonus payments</li>
                        <li>Restricted Stock Units (Vested Equity) 15% accepted</li>
                      </ul>
                    "
                    value={values.gross_annual_income}
                  />
                </div>
              </div>
            </div>

            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <CurrencyField
                  addon="€"
                  addonPosition="start"
                  error={errors.main_net_monthly_income && touched.main_net_monthly_income && errors.main_net_monthly_income}
                  label="Main Net Monthly Income"
                  name="main_net_monthly_income"
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  showAsterisk
                  tooltip="Net monthly income from borrower’s guaranteed income (basic PLUS guaranteed allowances) 
                  This figure should be combined if a joint application and can include 100% guaranteed car allowance and 100% permanent state benefits (for example, disability or carer's allowance). "
                  value={values.main_net_monthly_income}
                />
              </div>
            </div>

            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <CurrencyField
                  addon="€"
                  addonPosition="start"
                  error={errors.secondary_net_monthly_income && touched.secondary_net_monthly_income && errors.secondary_net_monthly_income}
                  label="Additional Net Monthly Income"
                  name="secondary_net_monthly_income"
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  showAsterisk
                  tooltip="
                    <p>Net monthly income arising from additional income (average of the last 2 calendar years)</p>
                    <ul class='list-disc pl-4'>
                      <li>Commission (up to 75%)</li>
                      <li>Overtime (up to 75%)</li>
                      <li>Bonus payments (up to 75%)</li>
                      <li>Rental income (up to 75%)</li>
                      <li>Restricted Stock Units (Vested Equity) 15% accepted</li>
                      <li>Second and subsequent jobs (up to 75%)</li>
                      <li>Maintenance Income (up to 75%)</li>
                      <li>Private Pension Income (up to 75%)</li>
                    </ul>
                  "
                  value={values.secondary_net_monthly_income}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-shadow mb-8 rounded-lg">
        <div className="bg-alabaster px-14 py-2 rounded-lg">
          <p className="font-bold text-2xl">Loan Details</p>
        </div>

        <div className="px-14 py-6">
          <div className="lg:flex flex-wrap -mx-3">
            {/*
            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <CurrencyField
                  addon="€"
                  addonPosition="start"
                  error={errors.estimated_loan_requested && touched.estimated_loan_requested && errors.estimated_loan_requested}
                  label="Loan Amount Requested (optional)"
                  name="estimated_loan_requested"
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.estimated_loan_requested}
                />
              </div>
            </div>
            */}
            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <CurrencyField
                  addon="%"
                  addonPosition="end"
                  error={errors.rate && touched.rate && errors.rate}
                  label="Rate"
                  name="rate"
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  showAsterisk
                  tooltip='Please insert rate in order to assess affordability. You can view our rates and products available <a href="/" target="_blank">here</a>.'
                  value={values.rate}
                />
              </div>

              <CurrencyField
                addon="€"
                addonPosition="start"
                error={errors.house_value && touched.house_value && errors.house_value}
                label="Property Value (optional)"
                name="house_value"
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                value={values.house_value}
              />
            </div>

            <div className="lg:mb-0 lg:w-1/2 mb-8 px-3">
              <div className="mb-6">
                <div className="mb-8">
                  <CurrencyField
                    addon="years"
                    addonPosition="end"
                    error={errors.mortgage_term && touched.mortgage_term && errors.mortgage_term}
                    label="Mortgage Term"
                    max={35}
                    min={10}
                    name="mortgage_term"
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    showAsterisk
                    value={values.mortgage_term}
                  />
                </div>

                <div className="px-8">
                  <RcSlider
                    handleChange={value => setFieldValue('mortgage_term', value, true)}
                    max={35}
                    min={10}
                    value={values.mortgage_term}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
