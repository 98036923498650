import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'

import logoYapilyconnect from 'images/icons/Full-Yapily Connect.svg'

export default function YapilyConnectScreen({
  setState,
  state,
}) {
  const { application_id, applicant_id } = useParams()
  const [boxOpen, setBoxOpen] = useState()
  const [errors, setErrors] = useState()
  const [loading, setLoading] = useState(false)

  const handleBankConnect = () => {
    setErrors()
    setLoading(true)

    const loadingText = '<p>Please wait and do not close this window.</p>'
    let tab = window.open('', '_blank', '')
    tab.document.write(loadingText)

    axiosService.get(`/api/borrower/applications/${application_id}/applicants/${applicant_id}/ob_institutions/${state.selectedBank.value}/select`)
      .then((results) => {
        setLoading(false)
        tab.location = results.authorisation_url
        setState({
          ...state,
          currentStep: 4,
        })
      })
      .catch(({ data: { error } }) => {
        setLoading(false)
        setErrors(error)
      })
  }

  return (
    <>
      <img alt="YapilyConnect" className="mb-10" src={logoYapilyconnect} />

      <div className="bg-white border border-timber-green-o-16 mb-6 px-8 py-6 rounded-lg">
        <div className="flex items-center justify-between relative">
          <a
            className="absolute inset-0 z1"
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setBoxOpen(boxOpen !== 1 ? 1 : '')
            }}
          />

          <p>Data sharing</p>

          <i className={`fa fa-${boxOpen === 1 ? 'minus' : 'info'}-circle`} />
        </div>

        <div className={`${boxOpen !== 1 && 'hidden'} bg-alabaster border border-timber-green-o-16 mt-4 p-4 rounded-lg`}>
          <p className="text-sm">
            Yapily Connect will retrieve your bank data based on your request and provide this information to <span className="text-turquoise">MoCo</span>.
          </p>
        </div>
      </div>

      <div className="bg-white border border-timber-green-o-16 mb-6 px-8 py-6 rounded-lg">
        <div className="flex items-center justify-between relative">
          <a
            className="absolute inset-0 z1"
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setBoxOpen(boxOpen !== 2 ? 2 : '')
            }}
          />

          <p>Secure Connection</p>

          <i className={`fa fa-${boxOpen === 2 ? 'minus' : 'info'}-circle`} />
        </div>

        <div className={`${boxOpen !== 2 && 'hidden'} bg-alabaster border border-timber-green-o-16 mt-4 p-4 rounded-lg`}>
          <p className="text-sm">
            Data is securely retrieved in read-only format and only for the duration agreed with you. You have the right to withdraw your consent at any time.
          </p>
        </div>
      </div>

      <div className="bg-white border border-timber-green-o-16 mb-6 px-8 py-6 rounded-lg">
        <div className="flex items-center justify-between relative">
          <a
            className="absolute inset-0 z1"
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setBoxOpen(boxOpen !== 3 ? 3 : '')
            }}
          />

          <p>Authorisation</p>

          <i className={`fa fa-${boxOpen === 3 ? 'minus' : 'info'}-circle`} />
        </div>

        <div className={`${boxOpen !== 3 && 'hidden'} bg-alabaster border border-timber-green-o-16 mt-4 p-4 rounded-lg`}>
          <p className="text-sm">
            Yapily Connect UAB is authorised by the EU domiciled Bank of Lithuania to engage in account information and payment initiation services. Yapily Connect UAB’s activities are supervised by the Bank of Lithuania, which is located at Gedimino avenue 6, 01103, Vilnius, the Republic of Lithuania.
          </p>
        </div>
      </div>

      <p className="mb-6 text-timber-green-o-60">
        In order to share your {state.selectedBank.label} data with MoCo, you will now be securely redirected to your bank to confirm your consent for Yapily Connect to retrieve the following information:
      </p>

      <div className="bg-white border border-timber-green-o-16 mb-6 px-8 py-6 rounded-lg">
        <ul className="list-disc px-8 text-turquoise">
          <li className="mb-4"><span className="text-timber-green-o-60">Identification details</span></li>
          <li className="mb-4"><span className="text-timber-green-o-60">Account(s) details</span></li>
          <li className="mb-4"><span className="text-timber-green-o-60">Balances</span></li>
          <li className="mb-4"><span className="text-timber-green-o-60">Transaction Statements for up to 13 months</span></li>
          <li className="mb-4"><span className="text-timber-green-o-60">Credit Card(s) Statements for up to 13 months</span></li>
        </ul>
      </div>

      <div className="bg-white border border-timber-green-o-16 mb-6 px-8 py-6 rounded-lg">
        <div className="flex items-center justify-between relative">
          <a
            className="absolute inset-0 z1"
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setBoxOpen(boxOpen !== 4 ? 4 : '')
            }}
          />

          <p>About the access</p>

          <i className={`fa fa-${boxOpen === 4 ? 'minus' : 'info'}-circle`} />
        </div>

        <div className={`${boxOpen !== 4 && 'hidden'} bg-alabaster border border-timber-green-o-16 mt-4 p-4 rounded-lg`}>
          <p className="text-sm">
            Yapily Connect will use these details on a one-off basis to provide a snapshot of your transaction history to <span className="text-turquoise">MoCo</span>. <span className="text-turquoise">MoCo</span> will then use this snapshot for the duration of the mortgage application assessment. You can cancel your consent at any time by using the process set out in <a className="landing-link" href="/policies#customer-data-privacy" target="_blank">MoCo's Data Privacy Notice</a>
            <br />
            <br />
            Please read <a className="landing-link" href="https://www.yapily.com/legal/end-user-terms" target="_blank">YAPILY CONNECT UAB: TERMS & CONDITIONS | Yapily</a>
          </p>
        </div>
      </div>

      {errors && <p className="mb-4 text-center text-punch">{errors}</p>}

      <div className="flex justify-center">
        <div className="w-1/2">
          <button
            className="btn btn--primary w-full"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault()
              handleBankConnect()
            }}
            type="button"
          >
            {loading ? 'Please wait...' : 'Next'}
          </button>
        </div>
      </div>

      <div className="mt-8 text-center">
        <button
          className="text-turquoise"
          onClick={(e) => {
            e.preventDefault()
            setState({
              ...state,
              currentStep: Object.keys(state.openBankingAccounts).length > 0 ? 4 : 2,
            })
          }}
          type="button"
        >
          Cancel
        </button>
      </div>
    </>
  )
}
