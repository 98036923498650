import React from 'react'
import MoverFeaturesBox1 from './Box1.jsx'
import MoverFeaturesBox2 from './Box2.jsx'
import MoverFeaturesBox3 from './Box3.jsx'
import MoverFeaturesBox4 from './Box4.jsx'
import Slider from 'react-slick'

export default function MortgageFeatures() {
  const settings = {
    arrows: false,
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <div className="hidden lg:block">
        <div className="flex flex-wrap -mx-4">
          <MoverFeaturesBox1 />
          <MoverFeaturesBox2 />
          <MoverFeaturesBox3 />
          <MoverFeaturesBox4 />
        </div>
      </div>

      <div className="slick-flex lg:hidden">
        <Slider {...settings}>
          <div className="slick-item-wrapper">
            <MoverFeaturesBox1 />
          </div>

          <div className="slick-item-wrapper">
            <MoverFeaturesBox2 />
          </div>

          <div className="slick-item-wrapper">
            <MoverFeaturesBox3 />
          </div>

          <div className="slick-item-wrapper">
            <MoverFeaturesBox4 />
          </div>
        </Slider>
      </div>
    </>
  )
}