import React from 'react'

import iconRates from 'images/landing/ftb-rates.svg'

export default function MortgageFeaturesBox2() {
  return (
    <div className="landing-feature-column">
      <div className="landing-feature-box">
        <div className="landing-feature-icon-container">
          <img alt="Rates" className="landing-feature-icon" src={iconRates} />
        </div>

        <h3 className="landing-feature-title">The Basics</h3>

        <p>Use our product tools to determine your LTV and see our lowest rate offers</p>
      </div>
    </div>
  )
}
