import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../../models/Applicant'
import Employment from '../../../../models/Employment'
import { EmploymentStep1EmployedSchema } from '../../../../schemas/EmploymentSchemas/EmploymentStep1EmployedSchema'
import axiosService from '../../../../utils/AxiosService'
import { notifyProgressSaved } from '../../../Form/Notifications'
import EmploymentActions from '../EmploymentActions'
import EmploymentSelector from './EmploymentSelector'

export default observer(function OtherFormPart() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()

  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const save = employment => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { employment, income: currentApplicant.income, steps: {...currentApplicant.steps, employment_step1_visited: true } } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.employment = new Employment(employment)
      currentApplicant.steps.employment_step1_visited = true
    })
  }

  const touchFields = () => formikRef.current.setTouched({ are_you: true })

  useEffect(() => {
    if(currentApplicant.steps.employment_step1_visited) touchFields()
    applicationErrorsContext.validateEmploymentStep1Employed(currentApplicant.employment, applicant_id)
  }, [loading, currentApplicant.employment])

  return (
    <Formik
      initialValues={{ are_you: currentApplicant.employment.are_you }}
      validationSchema={EmploymentStep1EmployedSchema}
      enableReinitialize={loading}
      innerRef={formikRef}
    >
      {({ errors, touched, handleBlur, values, setFieldValue }) => (
        <form onSubmit={save(values)}>

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <EmploymentSelector applicant={currentApplicant} disabled={disabled} setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} handleBlur={handleBlur} />
            </div>
          </div>
          <EmploymentActions save={save(values)} />
        </form>
      )}
    </Formik>
  )
})