import React, { useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Input from '../../Form/Input'
import NextStepButton from '../../Form/NextStepButton'
import ReactSelect from '../../Form/ReactSelect'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import axiosService from '../../../utils/AxiosService'
import { observer } from 'mobx-react-lite'
import { Formik } from 'formik'
import { directDebitSchema } from '../../../schemas/DirectDebitSchema'
import ApplicationStepUpdater from '../../Form/ApplicationStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import { notifyProgressSaved } from '../../Form/Notifications'
import LoqateWrapper from '../../Form/LoqateWrapper'

export default observer(function DirectDebit() {
  const { applicationState } = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id } = useParams()
  const formikRef = useRef()

  const save = directDebit => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${applicationState.backend_url_part}/applications/${application_id}`, { application: { direct_debit_attributes: directDebit }})
    .then(() => {
      notifyProgressSaved()
      applicationState.directDebit = directDebit
    })
  }

  const touchFields = () => formikRef.current.setTouched(Object.keys(applicationState.directDebit).reduce((result, key) => { result[key] = true; return result },{}))

  useEffect(() => {
    if(applicationState.application.steps.direct_debit_visited) touchFields()
    applicationErrorsContext.validateDirectDebit()
  }, [applicationState.loading, applicationState.directDebit])

  const paymentDayOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
    { label: '24', value: 24 },
    { label: '25', value: 25 },
    { label: '26', value: 26 },
    { label: '27', value: 27 },
    { label: '28', value: 28 },
    { label: '29', value: 29 },
    { label: '30', value: 30 },
    { label: '31', value: 31 },
  ]

  return (
    <div>
      <h2 className="form-title mb-40">Bank Details</h2>
      {!applicationState.disabled && <ApplicationStepUpdater step="direct_debit_visited" />}

      <p className="mb-40">If your mortgage application is approved, we will send you a direct debit mandate to sign. No direct debit will be set up until the direct debit mandate is signed.</p>

      <Formik
        initialValues={applicationState.directDebit}
        validationSchema={directDebitSchema}
        enableReinitialize={applicationState.loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    autoFocus={true}
                    error={errors.name_of_account_holder && touched.name_of_account_holder && errors.name_of_account_holder}
                    label="Name(s) of account holder(s)"
                    name="name_of_account_holder"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name_of_account_holder}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.account_number && touched.account_number && errors.account_number}
                    label="IBAN"
                    name="account_number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.account_number}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.sort_code && touched.sort_code && errors.sort_code}
                    label="BIC"
                    name="sort_code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.sort_code}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.institution && touched.institution && errors.institution}
                    label="Institution"
                    name="institution"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.institution}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.branch && touched.branch && errors.branch}
                    label="Branch"
                    name="branch"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.branch}
                    disabled={applicationState.disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <LoqateWrapper
                    label="Search Address"
                    tooltip="Type address or Eircode to lookup address"
                    onSelect={address => {
                      setFieldValue('contact_postcode', address.PostalCode, true)
                      setFieldValue('address_line1', address.Line1, true)
                      setFieldValue('address_line2', address.Line2, true)
                      setFieldValue('city', address.City, true)
                      address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                        ? setFieldValue('county', 'Dublin', true)
                        : setFieldValue('county', address.Province, true)
                    }}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.contact_postcode && touched.contact_postcode && errors.contact_postcode}
                    label="Eircode"
                    name="contact_postcode"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const postCodeValue = e.target.value
                      if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('county', 'Dublin', true)
                      handleChange(e)
                    }}
                    type="text"
                    value={values.contact_postcode}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.address_line1 && touched.address_line1 && errors.address_line1}
                    label="Address Line 1"
                    name="address_line1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.address_line1}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.address_line2 && touched.address_line2 && errors.address_line2}
                    label="Address Line 2"
                    name="address_line2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    optional
                    type="text"
                    value={values.address_line2}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.city && touched.city && errors.city}
                    label="City"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.city}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.county && touched.county && errors.county}
                    label="County"
                    name="county"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.county}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <ReactSelect
                    error={errors.paymentday && touched.paymentday && errors.paymentday}
                    label="Preferred Payment Day"
                    name="paymentday"
                    onChange={paymentday => setFieldValue('paymentday', paymentday.value, true)}
                    options={paymentDayOptions}
                    placeholder="Select Number"
                    value={values.paymentday}
                    disabled={applicationState.disabled}
                    tooltip="Preferred date in month for Direct Debit Payment to be drawn from your account"
                  />
                </div>

                <div className="hidden mb-40">
                  <YesNoBtnGroup
                    error={errors.is_confirm_declaration && touched.is_confirm_declaration && errors.is_confirm_declaration}
                    label="Please confirm you are happy for the direct debit to be set up once the mortgage account has been opened"
                    name="is_confirm_declaration"
                    onChange={(is_confirm_declarationName, is_confirm_declarationValue) => setFieldValue(is_confirm_declarationName, is_confirm_declarationValue, true)}
                    value={values.is_confirm_declaration}
                    disabled={applicationState.disabled}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-16">
              {!applicationState.disabled && <button className="btn btn--secondary" type="submit">Save Progress</button>}
              <NextStepButton nextUrl={`/${applicationState.type}/applications/${application_id}/solicitor`} save={applicationState.disabled ? () => {} : save(values)}/>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
})