import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BankSelect from './BankSelect'
import consumer from '../../../channels/consumer'
import InProgress from '../../InProgress'

export default function ResultScreen(props) {
  const { applicant_id } = useParams()

  useEffect(() => {
    consumer.subscriptions.create({ channel: 'ObAccountChannel', applicant_id },
      {
        connected() { console.log('ObAccountChannel connected') },
        disconnected() { console.log('ObAccountChannel disconnected') },
        received(data) {
          if (data?.message === 'ob_account_statuses_updated') {
            props.setState({ ...props.state, openBankingAccounts: data.ob_accounts })
          }
        }
      }
    )

    return () => {}
  }, [])

  return (
    <>
      {props.userType === 'borrower' && (
        <p className="mb-8">
          Please select your bank below. Once you have successfully shared your accounts you can select another bank and repeat the process. If you share a joint account with another applicant on this mortgage application, please do not share accounts already shared by the other applicant.
        </p>
      )}

      {Object.entries(props.openBankingAccounts).map((item, i) => (
        <div className="bg-white border border-timber-green-o-16 mb-6 px-8 py-6 rounded-lg" key={i}>
          <div className="border-b border-timber-green-o-8 pb-4">
            <p className="font-bold">{item[0]}</p>
          </div>

          {item[1].map((account, idx) => (
            <div className="flex justify-between mt-4 text-sm text-timber-green-o-60" key={idx}>
              <p className="pr-2">
                {account.account_type}
                {account.status === 'connected' && <i className="fa fa-check-circle ml-2 text-turquoise" />}
                {account.status === 'failed' && <i className="fa fa-times-circle ml-2 text-punch" />}
                {account.status === 'pending' && <InProgress />}
              </p>

              <div className="flex">
                <p
                  className={
                    account.status === 'connected' && 'text-turquoise'
                    || account.status === 'failed' && 'text-punch'
                    || account.status === 'pending' && 'text-bright-sun'
                  }
                >
                  {account.currency}
                  <span className="mx-2">-</span>
                  {account.camouflaged_account_number}
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}

      {props.userType === 'borrower' && <BankSelect setState={props.setState} state={props.state} />}
    </>
  )
}
