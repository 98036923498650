import React, { Fragment, useMemo } from 'react'
import BtnGroup from '../../../../Form/BtnGroup'
import BusinessSectorSelector from '../../../../Form/BusinessSectorSelector'
import CurrencyField from '../../../../Form/CurrencyField'
import DatePicker from '../../../../Form/DatePicker'
import Input from '../../../../Form/Input'
import PhoneField from '../../../../Form/PhoneField'
import ReactSelect from '../../../../Form/ReactSelect'
import YesNoBtnGroup from '../../../../Form/YesNoBtnGroup'
import EmploymentSelectorPart from './EmploymentSelectorPart'
import moment from 'moment'
import Divider from '../../../../Divider'

export default function EmployedPart({ applicant }) {
  const employmentSectorOptions = [
    { label: 'Private', value: '2' },
    { label: 'Public', value: '1' },
  ]

  const employmentTypeOptions = [
    { label: 'Temporary', value: '1' },
    { label: 'Permanent', value: '2' },
    { label: 'Fixed Term', value: '3' },
  ]

  const jobStartDate = useMemo(() => applicant.employment.employment_type === '2' ? applicant.employment.date_joined : applicant.employment.date_contract_started, [applicant.employment.date_joined, applicant.employment.date_contract_started])

  return (
    <div>

      <div className="flex flex-wrap -mx-12">
        <div className="px-12 w-1/2">
          <EmploymentSelectorPart applicant={applicant} />
          <div className="mb-40">
            <Input label="Job Title" value={applicant.employment.job_title} disabled />
          </div>

          <div className="mb-40">
            <Input label="Employer Name" value={applicant.employment.employer_name} disabled />
          </div>

          <div className="mb-40">
            <PhoneField label="Employer Phone Number" optional value={applicant.employment.employer_telephone} disabled />
          </div>

          <div className="mb-40">
            <BtnGroup label="Employment Sector" options={employmentSectorOptions} value={applicant.employment.business_sector} disabled />
          </div>
        </div>

        <div className="px-12 w-1/2">

          <div className="mb-40">
            <BusinessSectorSelector value={applicant.employment.nature_of_business} disabled />
          </div>

          <div className="mb-40">
            <ReactSelect label="Employment Type" options={employmentTypeOptions} value={applicant.employment.employment_type} disabled />
          </div>

          { applicant.employment.employment_type && applicant.employment.employment_type === '2' &&
            <div className="mb-40">
              <DatePicker label="Start Date" value={applicant.employment.date_joined} disabled />
            </div>
          }

          { applicant.employment.employment_type && applicant.employment.employment_type !== '2' &&
            <Fragment>
              <div className="mb-40">
                <DatePicker label="Start Date" value={applicant.employment.date_contract_started} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="End Date" optional value={applicant.employment.date_contract_ends} disabled />
              </div>
            </Fragment>
          }

          <div className="mb-40">
            <YesNoBtnGroup label="Within Probationary period?" value={applicant.employment.on_probation} disabled />
          </div>

          { applicant.employment.on_probation && <div className="mb-40">
              <DatePicker label="Date Probation ends" value={applicant.employment.probation_end_date} disabled />
            </div>
          }

          <div className="mb-40">
            <YesNoBtnGroup label="Under notice of termination or redundancy?" value={applicant.employment.redundancy_notice} disabled />
          </div>

          {applicant.employment.redundancy_notice &&
            <div className="mb-40">
              <Input label="Please Provide Details" value={applicant.employment.redundancy_remaining_details} disabled />
            </div>
          }
        </div>
      </div>

      <div className="flex flex-wrap -mx-12">
        <div className="px-12 w-1/2">
          <div className="mb-40">
            <Input label="Eircode" value={applicant.employment.address_post_code} disabled />
          </div>

          <div className="mb-40">
            <Input label="Address Line 1" value={applicant.employment.address_line1} disabled />
          </div>

          <div className="mb-40">
            <Input label="Address Line 2" optional value={applicant.employment.address_line2} disabled />
          </div>

          <div className="mb-40">
            <Input label="Address Line 3" optional value={applicant.employment.address_line3} disabled />
          </div>
        </div>

        <div className="px-12 w-1/2">
          <div className="mb-40">
            <Input label="City" optional value={applicant.employment.address_city} disabled />
          </div>

          <div className="mb-40">
            <Input label="County" value={applicant.employment.address_county} disabled />
          </div>

          <div className="mb-40">
            <ReactSelect countrySelect label="Country" value={applicant.employment.address_country} disabled />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap -mx-12">
        <div className="px-12 w-1/2">
          <div className="mb-40">
            <CurrencyField addon="€" addonPosition="start" label="Basic pay (p.a.)" value={applicant.employment.basic_salary} disabled />
          </div>

          <div className="mb-40">
            <CurrencyField addon="€" addonPosition="start" label="Guranteed Overtime (p.a.)" value={applicant.employment.guaranteed_overtime} disabled />
          </div>

          <div className="mb-40">
            <CurrencyField addon="€" addonPosition="start" label="Average Annual Bonus" value={applicant.employment.guaranteed_bonus} disabled />
          </div>

          <div className="mb-40">
            <Input label="Other, Please specify" value={applicant.employment.others_name} disabled />
          </div>

          <div className="mb-40">
            <CurrencyField addon="€" addonPosition="start" label="Regular Overtime (p.a.)" value={applicant.employment.regular_overtime} disabled />
          </div>

          <div className="mb-40">
            <CurrencyField addon="€" addonPosition="start" label="Average Annual Commission" value={applicant.employment.commission} disabled />
          </div>
        </div>

        <div className="px-12 w-1/2">
          <div className="mb-40">
            <CurrencyField addon="€" addonPosition="start" label="Car Allowance (p.a.)" value={applicant.employment.car_allowance} disabled />
          </div>

          <div className="mb-40">
            <CurrencyField addon="€" addonPosition="start" label="Other Allowances (p.a.)" value={applicant.employment.allowance} disabled />
          </div>

          <div className="mb-40">
            <CurrencyField addon="€" addonPosition="start" label="Dividends" value={applicant.employment.dividends} disabled />
          </div>

          <div className="mb-40">
            <CurrencyField addon="€" addonPosition="start" label="Other" value={applicant.employment.other_income} disabled />
          </div>
        </div>
      </div>

      { jobStartDate && moment(jobStartDate) > moment().subtract(3, 'years') &&
        <Fragment>

          <Divider text="Previous Employment" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Job Title/Occupation" value={applicant.employment.previous_job_title} disabled />
              </div>

              <div className="mb-40">
                <Input label="Company Name" value={applicant.employment.previous_employer_name} disabled />
              </div>

              <div className="mb-40">
                <Input label="Eircode" value={applicant.employment.previous_address_post_code} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 1" value={applicant.employment.previous_address_line1} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 2" optional value={applicant.employment.previous_address_line2} disabled />
              </div>
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Address Line 3" optional value={applicant.employment.previous_address_line3} disabled />
              </div>

              <div className="mb-40">
                <Input label="City" value={applicant.employment.previous_address_city} disabled />
              </div>

              <div className="mb-40">
                <Input label="County" value={applicant.employment.previous_address_county} disabled />
              </div>

              <div className="mb-40">
                <ReactSelect countrySelect label="Country" value={applicant.employment.previous_address_country} disabled />
              </div>

              <div className="mb-40">
                <BusinessSectorSelector value={applicant.employment.previous_nature_of_business} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Joined Company" value={applicant.employment.previous_date_joined} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Left" value={applicant.employment.previous_date_left} disabled />
              </div>
            </div>
          </div>
        </Fragment>
      }


      {applicant.employment.previous_date_joined && moment(applicant.employment.previous_date_joined) > moment().subtract(3, 'year') &&
        <Fragment>
          <Divider text="Previous Employment 2" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Job Title/Occupation" value={applicant.employment.previous_job_title2} disabled />
              </div>

              <div className="mb-40">
                <Input label="Company Name" value={applicant.employment.previous_employer_name2} disabled />
              </div>

              <div className="mb-40">
                <Input label="Eircode" value={applicant.employment.previous_address_post_code2} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 1" value={applicant.employment.previous2_address_line1} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 2" optional value={applicant.employment.previous2_address_line2} disabled />
              </div>
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Address Line 3" optional value={applicant.employment.previous2_address_line3} disabled />
              </div>

              <div className="mb-40">
                <Input label="City" value={applicant.employment.previous_address_city2} disabled />
              </div>

              <div className="mb-40">
                <Input label="County" value={applicant.employment.previous_address_county2} disabled />
              </div>

              <div className="mb-40">
                <ReactSelect countrySelect label="Country" value={applicant.employment.previous_address_country2} disabled />
              </div>

              <div className="mb-40">
                <BusinessSectorSelector value={applicant.employment.previous_nature_of_business2} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Joined Company" value={applicant.employment.previous_date_joined2} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Left" value={applicant.employment.previous_date_left2} disabled />
              </div>
            </div>
          </div>
        </Fragment>
      }

      {applicant.employment.previous_date_joined2 && moment(applicant.employment.previous_date_joined2) > moment().subtract(3, 'year') &&
        <Fragment>
          <Divider text="Previous Employment 3" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Job Title/Occupation" value={applicant.employment.previous_job_title3} disabled />
              </div>

              <div className="mb-40">
                <Input label="Company Name" value={applicant.employment.previous_employer_name3} disabled />
              </div>

              <div className="mb-40">
                <Input label="Eircode" value={applicant.employment.previous_address_post_code3} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 1" value={applicant.employment.previous3_address_line1} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 2" optional value={applicant.employment.previous3_address_line2} disabled />
              </div>
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Address Line 3" optional value={applicant.employment.previous3_address_line3} disabled />
              </div>

              <div className="mb-40">
                <Input label="City" value={applicant.employment.previous_address_city3} disabled />
              </div>

              <div className="mb-40">
                <Input label="County" value={applicant.employment.previous_address_county3} disabled />
              </div>

              <div className="mb-40">
                <ReactSelect countrySelect label="Country" value={applicant.employment.previous_address_country3} disabled />
              </div>

              <div className="mb-40">
                <BusinessSectorSelector value={applicant.employment.previous_nature_of_business3} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Joined Company" value={applicant.employment.previous_date_joined3} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Left" value={applicant.employment.previous_date_left3} disabled />
              </div>
            </div>
          </div>
        </Fragment>
      }

      { applicant.employment.previous_date_joined3 && moment(applicant.employment.previous_date_joined3) > moment().subtract(3, 'year') &&
        <Fragment>
          <Divider text="Previous Employment 4" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Job Title/Occupation" value={applicant.employment.previous_job_title4} disabled />
              </div>

              <div className="mb-40">
                <Input label="Company Name" value={applicant.employment.previous_employer_name4} disabled />
              </div>

              <div className="mb-40">
                <Input label="Eircode" value={applicant.employment.previous_address_post_code4} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 1" value={applicant.employment.previous4_address_line1} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 2" optional value={applicant.employment.previous4_address_line2} disabled />
              </div>
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Address Line 3" optional value={applicant.employment.previous4_address_line3} disabled />
              </div>

              <div className="mb-40">
                <Input label="City" value={applicant.employment.previous_address_city4} disabled />
              </div>

              <div className="mb-40">
                <Input label="County" value={applicant.employment.previous_address_county4} disabled />
              </div>

              <div className="mb-40">
                <ReactSelect countrySelect label="Country" value={applicant.employment.previous_address_country4} disabled />
              </div>

              <div className="mb-40">
                <BusinessSectorSelector value={applicant.employment.previous_nature_of_business4} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Joined Company" value={applicant.employment.previous_date_joined4} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Left" value={applicant.employment.previous_date_left4} disable />
              </div>
            </div>
          </div>
        </Fragment>
      }

      { applicant.employment.previous_date_joined4 && moment(applicant.employment.previous_date_joined4) > moment().subtract(3, 'year') &&
        <Fragment>
          <Divider text="Previous Employment 5" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Job Title/Occupation" value={applicant.employment.previous_job_title5} disabled />
              </div>

              <div className="mb-40">
                <Input label="Company Name" value={applicant.employment.previous_employer_name5} disabled />
              </div>

              <div className="mb-40">
                <Input label="Eircode" value={applicant.employment.previous_address_post_code5} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 1" value={applicant.employment.previous5_address_line1} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 2" optional value={applicant.employment.previous5_address_line2} disabled />
              </div>
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Address Line 3" optional value={applicant.employment.previous5_address_line3} disabled />
              </div>

              <div className="mb-40">
                <Input label="City" value={applicant.employment.previous_address_city5} disabled />
              </div>

              <div className="mb-40">
                <Input label="County" value={applicant.employment.previous_address_county5} disabled />
              </div>

              <div className="mb-40">
                <ReactSelect countrySelect label="Country" value={applicant.employment.previous_address_country5} disabled />
              </div>

              <div className="mb-40">
                <BusinessSectorSelector value={applicant.employment.previous_nature_of_business5} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Joined Company" value={applicant.employment.previous_date_joined5} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date Left" value={applicant.employment.previous_date_left5} disabled />
              </div>
            </div>
          </div>
        </Fragment>
      }

    </div>
  )

}