import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../../models/Applicant'
import Employment from '../../../../models/Employment'
import { EmploymentStep4EmployedSchema } from '../../../../schemas/EmploymentSchemas/EmploymentStep4EmployedSchema'
import axiosService from '../../../../utils/AxiosService'
import Divider from '../../../Divider'
import BusinessSectorSelector from '../../../Form/BusinessSectorSelector'
import DatePicker from '../../../Form/DatePicker'
import Input from '../../../Form/Input'
import ReactSelect from '../../../Form/ReactSelect'
import EmploymentActions from '../EmploymentActions'
import moment from 'moment'
import { notifyProgressSaved } from '../../../Form/Notifications'
import LoqateWrapper from '../../../Form/LoqateWrapper'

export default observer(function EmployedFormPart() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = [
    'date_joined',
    'date_contract_started',
    'employment_type',
    'previous_job_title',
    'previous_employer_name',
    'previous_address_post_code',
    'previous_address_line1',
    'previous_address_line2',
    'previous_address_line3',
    'previous_address_city',
    'previous_address_county',
    'previous_address_country',
    'previous_nature_of_business',
    'previous_date_joined',
    'previous_date_left',
    'previous_job_title2',
    'previous_employer_name2',
    'previous_address_post_code2',
    'previous2_address_line1',
    'previous2_address_line2',
    'previous2_address_line3',
    'previous_address_city2',
    'previous_address_county2',
    'previous_address_country2',
    'previous_nature_of_business2',
    'previous_date_joined2',
    'previous_date_left2',
    'previous_job_title3',
    'previous_employer_name3',
    'previous_address_post_code3',
    'previous3_address_line1',
    'previous3_address_line2',
    'previous3_address_line3',
    'previous_address_city3',
    'previous_address_county3',
    'previous_address_country3',
    'previous_nature_of_business3',
    'previous_date_joined3',
    'previous_date_left3',
    'previous_job_title4',
    'previous_employer_name4',
    'previous_address_post_code4',
    'previous4_address_line1',
    'previous4_address_line2',
    'previous4_address_line3',
    'previous_address_city4',
    'previous_address_county4',
    'previous_address_country4',
    'previous_nature_of_business4',
    'previous_date_joined4',
    'previous_date_left4',
    'previous_job_title5',
    'previous_employer_name5',
    'previous_address_post_code5',
    'previous5_address_line1',
    'previous5_address_line2',
    'previous5_address_line3',
    'previous_address_city5',
    'previous_address_county5',
    'previous_address_country5',
    'previous_nature_of_business5',
    'previous_date_joined5',
    'previous_date_left5',
  ]

  const save = employment => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { employment } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.employment = new Employment(employment)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  useEffect(() => {
    if(currentApplicant.steps.employment_step4_visited) touchFields()
    applicationErrorsContext.validateEmploymentStep4Employed(currentApplicant.employment, applicant_id)
  }, [loading, currentApplicant.employment])

  const clearPreviousEmploymentFields = (setFieldValue, index, oldValue, newValue) => {
    if(oldValue && moment(oldValue) > moment().subtract(3, 'year') && moment(newValue) < moment().subtract(3, 'year')) {
      setFieldValue(`previous_job_title${index}`, '', true)
      setFieldValue(`previous_employer_name${index}`, '', true)
      setFieldValue(`previous_address_post_code${index}`, '', true)
      setFieldValue(`previous${index}_address_line1`, '', true)
      setFieldValue(`previous${index}_address_line2`, '', true)
      setFieldValue(`previous${index}_address_line3`, '', true)
      setFieldValue(`previous_address_city${index}`, '', true)
      setFieldValue(`previous_address_county${index}`, '', true)
      setFieldValue(`previous_address_country${index}`, null, true)
      setFieldValue(`previous_nature_of_business${index}`, null, true)
      setFieldValue(`previous_date_joined${index}`, '', true)
      setFieldValue(`previous_date_left${index}`, '', true)
    }
  }

  return (
    <div>
      <Divider text="Previous Employment" />

      <Formik
        initialValues={currentApplicant.employment}
        validationSchema={EmploymentStep4EmployedSchema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    error={errors.previous_job_title && touched.previous_job_title && errors.previous_job_title}
                    label="Job Title/Occupation"
                    name="previous_job_title"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.previous_job_title}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.previous_employer_name && touched.previous_employer_name && errors.previous_employer_name}
                    label="Company Name"
                    name="previous_employer_name"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.previous_employer_name}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <LoqateWrapper
                    label="Search Address"
                    tooltip="Type address or Eircode to lookup address"
                    onSelect={address => {
                      setFieldValue('previous_address_post_code', address.PostalCode, true)
                      setFieldValue('previous_address_line1', address.Line1, true)
                      setFieldValue('previous_address_line2', address.Line2, true)
                      setFieldValue('previous_address_line3', address.Line3, true)
                      setFieldValue('previous_address_city', address.City, true)
                      address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                        ? setFieldValue('previous_address_county', 'Dublin', true)
                        : setFieldValue('previous_address_county', address.Province, true)
                      setFieldValue('previous_address_country', address.CountryIso3, true)
                    }}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.previous_address_post_code && touched.previous_address_post_code && errors.previous_address_post_code}
                    label="Eircode"
                    name="previous_address_post_code"
                    type="text"
                    onChange={(e) => {
                      const postCodeValue = e.target.value
                      if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous_address_county', 'Dublin', true)
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    value={values.previous_address_post_code}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.previous_address_line1 && touched.previous_address_line1 && errors.previous_address_line1}
                    label="Address Line 1"
                    name="previous_address_line1"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.previous_address_line1}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.previous_address_line2 && touched.previous_address_line2 && errors.previous_address_line2}
                    label="Address Line 2"
                    name="previous_address_line2"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.previous_address_line2}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    error={errors.previous_address_line3 && touched.previous_address_line3 && errors.previous_address_line3}
                    label="Address Line 3"
                    name="previous_address_line3"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.previous_address_line3}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.previous_address_city && touched.previous_address_city && errors.previous_address_city}
                    label="City"
                    name="previous_address_city"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.previous_address_city}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.previous_address_county && touched.previous_address_county && errors.previous_address_county}
                    label="County"
                    name="previous_address_county"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.previous_address_county}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <ReactSelect
                    countrySelect
                    label="Country"
                    name="previous_address_country"
                    placeholder="Select Country"
                    onChange={previous_address_country => setFieldValue('previous_address_country', previous_address_country.value, true)}
                    value={values.previous_address_country}
                    error={errors.previous_address_country && touched.previous_address_country && errors.previous_address_country}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <BusinessSectorSelector
                    error={errors.previous_nature_of_business && touched.previous_nature_of_business && errors.previous_nature_of_business}
                    name="previous_nature_of_business"
                    onChange={previous_nature_of_business => setFieldValue('previous_nature_of_business', previous_nature_of_business.value, true)}
                    value={values.previous_nature_of_business}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <DatePicker
                    error={errors.previous_date_joined && touched.previous_date_joined && errors.previous_date_joined}
                    label="Date Joined Company"
                    name="previous_date_joined"
                    onChange={value => {
                      setFieldValue('previous_date_joined', value, true)
                      clearPreviousEmploymentFields(setFieldValue, 2, values.previous_date_joined, value)
                      clearPreviousEmploymentFields(setFieldValue, 3, values.previous_date_joined, value)
                      clearPreviousEmploymentFields(setFieldValue, 4, values.previous_date_joined, value)
                      clearPreviousEmploymentFields(setFieldValue, 5, values.previous_date_joined, value)
                    }}
                    value={values.previous_date_joined}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <DatePicker
                    error={errors.previous_date_left && touched.previous_date_left && errors.previous_date_left}
                    label="Date Left"
                    name="previous_date_left"
                    onChange={value => setFieldValue('previous_date_left', value, true)}
                    value={values.previous_date_left}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>

            {values.previous_date_joined && moment(values.previous_date_joined) > moment().subtract(3, 'year') &&
              <Fragment>
                <Divider text="Previous Employment 2" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous_job_title2 && touched.previous_job_title2 && errors.previous_job_title2}
                        label="Job Title/Occupation"
                        name="previous_job_title2"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_job_title2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_employer_name2 && touched.previous_employer_name2 && errors.previous_employer_name2}
                        label="Company Name"
                        name="previous_employer_name2"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_employer_name2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('previous_address_post_code2', address.PostalCode, true)
                          setFieldValue('previous2_address_line1', address.Line1, true)
                          setFieldValue('previous2_address_line2', address.Line2, true)
                          setFieldValue('previous2_address_line3', address.Line3, true)
                          setFieldValue('previous_address_city2', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('previous_address_county2', 'Dublin', true)
                            : setFieldValue('previous_address_county2', address.Province, true)
                          setFieldValue('previous_address_country2', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_post_code2 && touched.previous_address_post_code2 && errors.previous_address_post_code2}
                        label="Eircode"
                        name="previous_address_post_code2"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous_address_county2', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.previous_address_post_code2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous2_address_line1 && touched.previous2_address_line1 && errors.previous2_address_line1}
                        label="Address Line 1"
                        name="previous2_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous2_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous2_address_line2 && touched.previous2_address_line2 && errors.previous2_address_line2}
                        label="Address Line 2"
                        name="previous2_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous2_address_line2}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous2_address_line3 && touched.previous2_address_line3 && errors.previous2_address_line3}
                        label="Address Line 3"
                        name="previous2_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous2_address_line3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_city2 && touched.previous_address_city2 && errors.previous_address_city2}
                        label="City"
                        name="previous_address_city2"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_address_city2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_county2 && touched.previous_address_county2 && errors.previous_address_county2}
                        label="County"
                        name="previous_address_county2"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_address_county2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        label="Country"
                        name="previous_address_country2"
                        placeholder="Select Country"
                        onChange={previous_address_country2 => setFieldValue('previous_address_country2', previous_address_country2.value, true)}
                        value={values.previous_address_country2}
                        error={errors.previous_address_country2 && touched.previous_address_country2 && errors.previous_address_country2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <BusinessSectorSelector
                        error={errors.previous_nature_of_business2 && touched.previous_nature_of_business2 && errors.previous_nature_of_business2}
                        name="previous_nature_of_business2"
                        onChange={previous_nature_of_business2 => setFieldValue('previous_nature_of_business2', previous_nature_of_business2.value, true)}
                        value={values.previous_nature_of_business2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous_date_joined2 && touched.previous_date_joined2 && errors.previous_date_joined2}
                        label="Date Joined Company"
                        name="previous_date_joined2"
                        onChange={value => {
                          setFieldValue('previous_date_joined2', value, true)
                          clearPreviousEmploymentFields(setFieldValue, 3, values.previous_date_joined2, value)
                          clearPreviousEmploymentFields(setFieldValue, 4, values.previous_date_joined2, value)
                          clearPreviousEmploymentFields(setFieldValue, 5, values.previous_date_joined2, value)
                        }}
                        value={values.previous_date_joined2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous_date_left2 && touched.previous_date_left2 && errors.previous_date_left2}
                        label="Date Left"
                        name="previous_date_left2"
                        onChange={value => setFieldValue('previous_date_left2', value, true)}
                        value={values.previous_date_left2}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }

            {values.previous_date_joined2 && moment(values.previous_date_joined2) > moment().subtract(3, 'year') &&
              <Fragment>
                <Divider text="Previous Employment 3" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous_job_title3 && touched.previous_job_title3 && errors.previous_job_title3}
                        label="Job Title/Occupation"
                        name="previous_job_title3"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_job_title3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_employer_name3 && touched.previous_employer_name3 && errors.previous_employer_name3}
                        label="Company Name"
                        name="previous_employer_name3"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_employer_name3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('previous_address_post_code3', address.PostalCode, true)
                          setFieldValue('previous3_address_line1', address.Line1, true)
                          setFieldValue('previous3_address_line2', address.Line2, true)
                          setFieldValue('previous3_address_line3', address.Line3, true)
                          setFieldValue('previous_address_city3', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('previous_address_county3', 'Dublin', true)
                            : setFieldValue('previous_address_county3', address.Province, true)
                          setFieldValue('previous_address_country3', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_post_code3 && touched.previous_address_post_code3 && errors.previous_address_post_code3}
                        label="Eircode"
                        name="previous_address_post_code3"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous_address_county3', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.previous_address_post_code3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous3_address_line1 && touched.previous3_address_line1 && errors.previous3_address_line1}
                        label="Address Line 1"
                        name="previous3_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous3_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous3_address_line2 && touched.previous3_address_line2 && errors.previous3_address_line2}
                        label="Address Line 2"
                        name="previous3_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous3_address_line2}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous3_address_line3 && touched.previous3_address_line3 && errors.previous3_address_line3}
                        label="Address Line 3"
                        name="previous3_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous3_address_line3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_city3 && touched.previous_address_city3 && errors.previous_address_city3}
                        label="City"
                        name="previous_address_city3"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_address_city3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_county3 && touched.previous_address_county3 && errors.previous_address_county3}
                        label="County"
                        name="previous_address_county3"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_address_county3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        label="Country"
                        name="previous_address_country3"
                        placeholder="Select Country"
                        onChange={previous_address_country3 => setFieldValue('previous_address_country3', previous_address_country3.value, true)}
                        value={values.previous_address_country3}
                        disabled={disabled}
                        error={errors.previous_address_country3 && touched.previous_address_country3 && errors.previous_address_country3}
                      />
                    </div>

                    <div className="mb-40">
                      <BusinessSectorSelector
                        error={errors.previous_nature_of_business3 && touched.previous_nature_of_business3 && errors.previous_nature_of_business3}
                        name="previous_nature_of_business3"
                        onChange={previous_nature_of_business3 => setFieldValue('previous_nature_of_business3', previous_nature_of_business3.value, true)}
                        value={values.previous_nature_of_business3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous_date_joined3 && touched.previous_date_joined3 && errors.previous_date_joined3}
                        label="Date Joined Company"
                        name="previous_date_joined3"
                        onChange={value => {
                          setFieldValue('previous_date_joined3', value, true)
                          clearPreviousEmploymentFields(setFieldValue, 4, values.previous_date_joined3, value)
                          clearPreviousEmploymentFields(setFieldValue, 5, values.previous_date_joined3, value)
                        }}
                        value={values.previous_date_joined3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous_date_left3 && touched.previous_date_left3 && errors.previous_date_left3}
                        label="Date Left"
                        name="previous_date_left3"
                        onChange={value => setFieldValue('previous_date_left3', value, true)}
                        value={values.previous_date_left3}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }

            { values.previous_date_joined3 && moment(values.previous_date_joined3) > moment().subtract(3, 'year') &&
              <Fragment>
                <Divider text="Previous Employment 4" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous_job_title4 && touched.previous_job_title4 && errors.previous_job_title4}
                        label="Job Title/Occupation"
                        name="previous_job_title4"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_job_title4}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_employer_name4 && touched.previous_employer_name4 && errors.previous_employer_name4}
                        label="Company Name"
                        name="previous_employer_name4"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_employer_name4}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('previous_address_post_code4', address.PostalCode, true)
                          setFieldValue('previous4_address_line1', address.Line1, true)
                          setFieldValue('previous4_address_line2', address.Line2, true)
                          setFieldValue('previous4_address_line3', address.Line3, true)
                          setFieldValue('previous_address_city4', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('previous_address_county4', 'Dublin', true)
                            : setFieldValue('previous_address_county4', address.Province, true)
                          setFieldValue('previous_address_country4', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_post_code4 && touched.previous_address_post_code4 && errors.previous_address_post_code4}
                        label="Eircode"
                        name="previous_address_post_code4"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous_address_county4', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.previous_address_post_code4}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous4_address_line1 && touched.previous4_address_line1 && errors.previous4_address_line1}
                        label="Address Line 1"
                        name="previous4_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous4_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous4_address_line2 && touched.previous4_address_line2 && errors.previous4_address_line2}
                        label="Address Line 2"
                        name="previous4_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous4_address_line2}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous4_address_line3 && touched.previous4_address_line3 && errors.previous4_address_line3}
                        label="Address Line 3"
                        name="previous4_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous4_address_line3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_city4 && touched.previous_address_city4 && errors.previous_address_city4}
                        label="City"
                        name="previous_address_city4"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_address_city4}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_county4 && touched.previous_address_county4 && errors.previous_address_county4}
                        label="County"
                        name="previous_address_county4"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_address_county4}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        label="Country"
                        name="previous_address_country4"
                        placeholder="Select Country"
                        onChange={previous_address_country4 => setFieldValue('previous_address_country4', previous_address_country4.value, true)}
                        value={values.previous_address_country4}
                        disabled={disabled}
                        error={errors.previous_address_country4 && touched.previous_address_country4 && errors.previous_address_country4}
                      />
                    </div>

                    <div className="mb-40">
                      <BusinessSectorSelector
                        error={errors.previous_nature_of_business4 && touched.previous_nature_of_business4 && errors.previous_nature_of_business4}
                        name="previous_nature_of_business4"
                        onChange={previous_nature_of_business4 => setFieldValue('previous_nature_of_business4', previous_nature_of_business4.value, true)}
                        value={values.previous_nature_of_business4}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous_date_joined4 && touched.previous_date_joined4 && errors.previous_date_joined4}
                        label="Date Joined Company"
                        name="previous_date_joined4"
                        onChange={value => {
                          setFieldValue('previous_date_joined4', value, true)
                          clearPreviousEmploymentFields(setFieldValue, 5, values.previous_date_joined4, value)
                        }}
                        value={values.previous_date_joined4}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous_date_left4 && touched.previous_date_left4 && errors.previous_date_left4}
                        label="Date Left"
                        name="previous_date_left4"
                        onChange={value => setFieldValue('previous_date_left4', value, true)}
                        value={values.previous_date_left4}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }

            { values.previous_date_joined4 && moment(values.previous_date_joined4) > moment().subtract(3, 'year') &&
              <Fragment>
                <Divider text="Previous Employment 5" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous_job_title5 && touched.previous_job_title5 && errors.previous_job_title5}
                        label="Job Title/Occupation"
                        name="previous_job_title5"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_job_title5}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_employer_name5 && touched.previous_employer_name5 && errors.previous_employer_name5}
                        label="Company Name"
                        name="previous_employer_name5"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_employer_name5}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('previous_address_post_code5', address.PostalCode, true)
                          setFieldValue('previous5_address_line1', address.Line1, true)
                          setFieldValue('previous5_address_line2', address.Line2, true)
                          setFieldValue('previous5_address_line3', address.Line3, true)
                          setFieldValue('previous_address_city5', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('previous_address_county5', 'Dublin', true)
                            : setFieldValue('previous_address_county5', address.Province, true)
                          setFieldValue('previous_address_country5', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_post_code5 && touched.previous_address_post_code5 && errors.previous_address_post_code5}
                        label="Eircode"
                        name="previous_address_post_code5"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous_address_county5', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.previous_address_post_code5}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous5_address_line1 && touched.previous5_address_line1 && errors.previous5_address_line1}
                        label="Address Line 1"
                        name="previous5_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous5_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous5_address_line2 && touched.previous5_address_line2 && errors.previous5_address_line2}
                        label="Address Line 2"
                        name="previous5_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous5_address_line2}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous5_address_line3 && touched.previous5_address_line3 && errors.previous5_address_line3}
                        label="Address Line 3"
                        name="previous5_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous5_address_line3}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_city5 && touched.previous_address_city5 && errors.previous_address_city5}
                        label="City"
                        name="previous_address_city5"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_address_city5}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous_address_county5 && touched.previous_address_county5 && errors.previous_address_county5}
                        label="County"
                        name="previous_address_county5"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous_address_county5}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        label="Country"
                        name="previous_address_country5"
                        placeholder="Select Country"
                        onChange={previous_address_country5 => setFieldValue('previous_address_country5', previous_address_country5.value, true)}
                        value={values.previous_address_country5}
                        disabled={disabled}
                        error={errors.previous_address_country5 && touched.previous_address_country5 && errors.previous_address_country5}
                      />
                    </div>

                    <div className="mb-40">
                      <BusinessSectorSelector
                        error={errors.previous_nature_of_business5 && touched.previous_nature_of_business5 && errors.previous_nature_of_business5}
                        name="previous_nature_of_business5"
                        onChange={previous_nature_of_business5 => setFieldValue('previous_nature_of_business5', previous_nature_of_business5.value, true)}
                        value={values.previous_nature_of_business5}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous_date_joined5 && touched.previous_date_joined5 && errors.previous_date_joined5}
                        label="Date Joined Company"
                        name="previous_date_joined5"
                        onChange={value => setFieldValue('previous_date_joined5', value, true)}
                        value={values.previous_date_joined5}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous_date_left5 && touched.previous_date_left5 && errors.previous_date_left5}
                        label="Date Left"
                        name="previous_date_left5"
                        onChange={value => setFieldValue('previous_date_left5', value, true)}
                        value={values.previous_date_left5}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }
            <EmploymentActions save={save(values)} />
          </form>
        )}
      </Formik>

    </div>
  )
})