import * as Yup from 'yup'

const fieldIsTrue = field => {
  return [
    field,
    {
      is: true,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ]
}

export const generalSchema = Yup.object().shape({
  have_payday_loan: Yup.boolean().required('This field is required.'),
  payday_loan_details: Yup.string().when(...fieldIsTrue('have_payday_loan')),
});