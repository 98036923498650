import React, { useContext, useMemo } from 'react'
import iconPersonalDetailsGray from 'images/icons/personal-details-gray.svg'
import { observer } from 'mobx-react-lite'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'

export default observer(function PersonalDetailsIcon({ applicant }) {
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const errorClass = useMemo(() => {
    if  (applicant.steps.personal_details_step1_visited && !applicantErrors[applicant.id]?.personalDetailsStep1Errors
      && applicant.steps.personal_details_step2_visited && !applicantErrors[applicant.id]?.personalDetailsStep2Errors
      && applicant.steps.personal_details_step3_visited && !applicantErrors[applicant.id]?.personalDetailsStep3Errors
      && applicant.steps.personal_details_step4_visited && !applicantErrors[applicant.id]?.personalDetailsStep4Errors) {
      return 'bg-green-500'
    }
    if  ((!applicant.steps.personal_details_step1_visited || !applicantErrors[applicant.id]?.personalDetailsStep1Errors)
      && (!applicant.steps.personal_details_step2_visited || !applicantErrors[applicant.id]?.personalDetailsStep2Errors)
      && (!applicant.steps.personal_details_step3_visited || !applicantErrors[applicant.id]?.personalDetailsStep3Errors)
      && (!applicant.steps.personal_details_step4_visited || !applicantErrors[applicant.id]?.personalDetailsStep4Errors))
    {
      return 'bg-white'
    } else {
      return 'bg-red-500'
    }
  }, [applicant, applicantErrors[applicant.id]?.personalDetailsStep1Errors, applicantErrors[applicant.id]?.personalDetailsStep2Errors, applicantErrors[applicant.id]?.personalDetailsStep3Errors, applicantErrors[applicant.id]?.personalDetailsStep4Errors])

  return (
    <div className={`applicant-image ${errorClass}`}>
      <img alt="Personal Details" src={iconPersonalDetailsGray} />
    </div>
  )
})