import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'
import TitleRow from '../../TitleRow'
import ApplicantRow from './ApplicantRow'
import moment from 'moment'
import CurrencyTextField from '../../CurrencyTextField'

export default observer(function EmploymentPart({ applicants }) {
  const employmentOptions = [
    { label: 'Employed', value: 'Employed' },
    { label: 'Self Employed', value: 'Self Employed' },
    { label: 'Retired', value: 'Retired' },
    { label: 'Not Employed', value: 'Not Employed' },
    { label: 'None of the above', value: 'None of the above' },
  ]

  const employmentSectorOptions = [
    { label: 'Private', value: '2' },
    { label: 'Public', value: '1' },
  ]

  const businessSectorOptions = [
    { label: 'Agriculture, Forestry and Fishing', value: '1' },
    { label: 'Mining and Quarrying', value: '2' },
    { label: 'Manufacturing', value: '3' },
    { label: 'Electricity, Gas, Steam and Air Conditioning Supply', value: '4' },
    { label: 'Water Supply; Sewerage, Waste Management and Remediation Activities', value: '5' },
    { label: 'Construction', value: '6' },
    { label: 'Wholesale and Retail Trade', value: '7' },
    { label: 'Repair of Motor Vehicles and Motorcycles', value: '24' },
    { label: 'Transportation and Storage', value: '8' },
    { label: 'Accommodation and Food Service Activities', value: '9' },
    { label: 'Information and Communication', value: '10' },
    { label: 'Financial and Insurance Activities', value: '11' },
    { label: 'Real Estate Activities', value: '12' },
    { label: 'Professional, Scientific and Technical Activities', value: '13' },
    { label: 'Administrative and Support Service Activities', value: '14' },
    { label: 'Public Administration and Defence', value: '15' },
    { label: 'Compulsory Social Security', value: '23' },
    { label: 'Education', value: '16' },
    { label: 'Human Health and Social Work Activities', value: '17' },
    { label: 'Arts, Entertainment and Recreation', value: '18' },
    { label: 'Other Service Activities', value: '19' },
    { label: 'Activities of Households as Employers', value: '20' },
    { label: 'Undifferentiated Goods and Services Producing Activities of Households for Own Use', value: '22' },
    { label: 'Activities of Extraterritorial Organisations and Bodies', value: '21' },
  ]

  const employmentTypeOptions = [
    { label: 'Temporary', value: '1' },
    { label: 'Permanent', value: '2' },
    { label: 'Fixed Term', value: '3' },
  ]

  const getEmployment = are_you => employmentOptions.find(option => option.value === are_you)?.label
  const getEmploymentSector = business_sector => employmentSectorOptions.find(option => option.value === business_sector)?.label
  const getBusinessSector = nature_of_business => businessSectorOptions.find(option => option.value === nature_of_business)?.label
  const getEmploymentType = nature_of_business => employmentTypeOptions.find(option => option.value === nature_of_business)?.label

  return (
    <Fragment>
      <TitleRow label="Employment" />
      <ApplicantRow name="Employment Status" tdContent={applicant => getEmployment(applicant.employment.are_you)} />
      { (applicants[0]?.employment?.are_you === 'Employed' || applicants[1]?.employment?.are_you === 'Employed') &&
        <Fragment>
          <ApplicantRow name="Job Title" tdContent={applicant => applicant.employment.job_title} />
          <ApplicantRow name="Employer Name" tdContent={applicant => applicant.employment.employer_name} />
          <ApplicantRow name="Employer Phone Number" tdContent={applicant => applicant.employment.employer_telephone} />
          <ApplicantRow name="Employment Sector" tdContent={applicant => getEmploymentSector(applicant.employment.business_sector)} />
          <ApplicantRow name="Business Sector" tdContent={applicant => getBusinessSector(applicant.employment.nature_of_business)} />
          <ApplicantRow name="Employment Type" tdContent={applicant => getEmploymentType(applicant.employment.employment_type)} />
          <ApplicantRow name="Start Date" tdContent={applicant => (applicant.employment.date_joined || applicant.employment.date_contract_started) ? moment(applicant.employment.employment_type === "2" ? applicant.employment.date_joined : applicant.employment.date_contract_started).format('DD/MM/YYYY') : '' } />
          {(applicants[0]?.employment?.employment_type !== "2" || applicants[1]?.employment?.employment_type !== "2") &&
            <ApplicantRow name="End Date" tdContent={applicant => applicant.employment.employment_type !== "2" && applicant.employment.date_contract_ends ? moment(applicant.employment.date_contract_ends).format('DD/MM/YYYY') : '' } />
          }
          <ApplicantRow name="Under notice of termination or redundancy?" tdContent={applicant => applicant.employment.redundancy_notice ? 'Yes' : 'No'} />
          <ApplicantRow name="Employer Eircode" tdContent={applicant => applicant.employment.address_post_code} />
          <ApplicantRow name="Basic Pay" tdContent={applicant => <CurrencyTextField value={applicant.employment.basic_salary} />} />
          <ApplicantRow name="Guranteed Overtime" tdContent={applicant => <CurrencyTextField value={applicant.employment.guaranteed_overtime} />} />
          <ApplicantRow name="Average Annual Bonus" tdContent={applicant => <CurrencyTextField value={applicant.employment.guaranteed_bonus} />} />
          <ApplicantRow name="Other" tdContent={applicant => applicant.employment.others_name} />
          <ApplicantRow name="Other Amount" tdContent={applicant => <CurrencyTextField value={applicant.employment.other_income} />} />
          <ApplicantRow name="Regular Overtime" tdContent={applicant => <CurrencyTextField value={applicant.employment.regular_overtime} />} />
          <ApplicantRow name="Average Annual Commission" tdContent={applicant => <CurrencyTextField value={applicant.employment.commission} />} />
          <ApplicantRow name="Car Allowance" tdContent={applicant => <CurrencyTextField value={applicant.employment.car_allowance} />} />
          <ApplicantRow name="Other Allowance" tdContent={applicant => <CurrencyTextField value={applicant.employment.allowance} />} />
          <ApplicantRow name="Dividends" tdContent={applicant => <CurrencyTextField value={applicant.employment.dividends} />} />
        </Fragment>
      }
    </Fragment>
  )
})