import React from 'react'

export default function Switch({
  name,
  onChange,
  value,
}) {
  return (
    <div className="relative">
      <label className="switch">
        <input
          checked={value}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <span className="slider"></span>
      </label>
    </div>
  )
}
