import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import Input from '../../Form/Input'
import ReactSelect from '../../Form/ReactSelect'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import Divider from '../../Divider'
import OtherIncomeActions from './OtherIncomeActions'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Applicant from '../../../models/Applicant'
import { OtherIncomeStep1Schema } from '../../../schemas/OtherIncomeSchemas/OtherIncomeStep1Schema'
import axiosService from '../../../utils/AxiosService'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import OtherIncome from '../../../models/OtherIncome'
import PayFrequencySelector from '../../Form/PayFrequencySelector'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'

export default observer(function OtherIncomeStep1() {
  const { applicationState: { applicants, loading, disabled, backend_url_part } } = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ["do_you_have_income_1", "amount1", "pay_frequency1", "guaranteed1", "do_you_have_income_2", "amount2", "pay_frequency2", "guaranteed2"]

  const save = other_income => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { other_income } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.other_income = new OtherIncome(other_income)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  useEffect(() => {
    if(currentApplicant.steps.other_income_step1_visited) touchFields()
    applicationErrorsContext.validateOtherIncomeStep1(currentApplicant.other_income, applicant_id)
  }, [loading, currentApplicant.other_income])

  return (
    <div>
      <h2 className="form-title mb-40">Other Income</h2>
      { !disabled && <ApplicantStepUpdater step="other_income_step1_visited" /> }

      <Formik
        initialValues={currentApplicant.other_income}
        validationSchema={OtherIncomeStep1Schema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <Divider text="Permanent State Benefits" tooltip="If you are in receipt of any permanent state benefits please provide details here. Please do not include state pension" />

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <YesNoBtnGroup
                    error={errors.do_you_have_income_1 && touched.do_you_have_income_1 && errors.do_you_have_income_1}
                    label="Do you have permanent state benefits?"
                    name="do_you_have_income_1"
                    onChange={(name, value) => {
                      setFieldValue(name, value, true)
                      setFieldValue('amount1', 0, true)
                      setFieldValue('pay_frequency1', null, true)
                      setFieldValue('guaranteed1', false, true)
                    }}
                    value={values.do_you_have_income_1}
                    disabled={disabled}
                  />
                </div>
                { values.do_you_have_income_1 &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={errors.amount1 && touched.amount1 && errors.amount1}
                      label="Amount"
                      name="amount1"
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      value={values.amount1}
                      disabled={disabled}
                    />
                  </div>
                }

              </div>

              <div className="px-12 w-1/2">
                { values.do_you_have_income_1 &&
                  <Fragment>
                    <div className="mb-40">
                      <PayFrequencySelector
                        error={errors.pay_frequency1 && touched.pay_frequency1 && errors.pay_frequency1}
                        name="pay_frequency1"
                        onChange={value => setFieldValue('pay_frequency1', (value?.value || ''), true)}
                        onBlur={handleBlur}
                        value={values.pay_frequency1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <YesNoBtnGroup
                        error={errors.guaranteed1 && touched.guaranteed1 && errors.guaranteed1}
                        label="Guaranteed"
                        name="guaranteed1"
                        onChange={(name, value) => setFieldValue(name, value, true)}
                        value={values.guaranteed1}
                        disabled={disabled}
                      />
                    </div>
                  </Fragment>
                }
              </div>
            </div>

            <Divider text="Annual Stock Vesting" tooltip="Please provide the value of the vested equity over the past 12 months" />

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <YesNoBtnGroup
                    error={errors.do_you_have_income_2 && touched.do_you_have_income_2 && errors.do_you_have_income_2}
                    label="Do you have annual stock vesting?"
                    name="do_you_have_income_2"
                    onChange={(name, value) => {
                      setFieldValue(name, value, true)
                      setFieldValue('amount2', 0, true)
                      setFieldValue('pay_frequency2', null, true)
                      setFieldValue('guaranteed2', false, true)
                    }}
                    value={values.do_you_have_income_2}
                    disabled={disabled}
                  />
                </div>
                { values.do_you_have_income_2 &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={errors.amount2 && touched.amount2 && errors.amount2}
                      label="Amount"
                      name="amount2"
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      value={values.amount2}
                      disabled={disabled}
                    />
                  </div>
                }
              </div>

              <div className="px-12 w-1/2">
                { values.do_you_have_income_2 &&
                  <Fragment>
                    <div className="mb-40">
                      <PayFrequencySelector
                        error={errors.pay_frequency2 && touched.pay_frequency2 && errors.pay_frequency2}
                        name="pay_frequency2"
                        onChange={value => setFieldValue('pay_frequency2', (value?.value || ''), true)}
                        onBlur={handleBlur}
                        value={values.pay_frequency2}
                        disabled={disabled}
                      />
                    </div>
                    <div className="mb-40">
                      <YesNoBtnGroup
                        error={errors.guaranteed2 && touched.guaranteed2 && errors.guaranteed2}
                        label="Guaranteed"
                        name="guaranteed2"
                        onChange={(name, value) => setFieldValue(name, value, true)}
                        value={values.guaranteed2}
                        disabled={disabled}
                      />
                    </div>
                  </Fragment>
                }
              </div>
            </div>

            <OtherIncomeActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})
