import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup'
import axiosService from '../../utils/AxiosService';
import Input from '../Form/Input';

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(10, 'The password has to be at least 10 characters long.')
    .test({
      test: password => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&_])[^\s]{10,}$/.test(password),
      message: "Password should contains at least one lowercase letter, one uppercase letter, one number and one special character"
    }),
  password_confirmation: Yup.string()
    .required('Required')
    .when('password', (password, schema) => {
      return schema.test({
        test: password_confirmation => !!password && password_confirmation === password,
        message: "Should be equal to password"
      })
    }),
});

export default function BorrowerResetPassword() {
  const { token } = useParams()
  const history = useHistory()
  const [error, setError] = useState({ invalid: false, message: '' })

  const resetPassword = borrower => {
    setError({ invalid: false, message: '' })
    axiosService.patch(`/api/borrower/passwords/${token}`, { borrower })
    .then(() => {
      history.push('/borrower/login')
    })
    .catch(({ data: { error }}) => setError({ invalid: true, message: error }))
  }

  return (
    <div className="bg-registration items-center flex w-full" style={{ height: 'calc(100vh - 62px)'}}>
      <div className="container container--main">
        <div className="container--devise">
          <h2 className="font-bold mb-40 text-2xl">Don't forget your password</h2>
          <p className="mb-10">Please set your new password. You have to use at least 10 characters, a capital letter and a special character!</p>

          <Formik
              initialValues={{
                password: '',
                password_confirmation: '',
              }}
            validationSchema={resetPasswordSchema}
            onSubmit={resetPassword}
          >
            {({ errors, touched, handleBlur, handleChange, values }) => (
              <Form>
                <div className="form-item-container">
                  <Input error={errors.password && touched.password && errors.password} label="New password" type="password" name="password" onChange={handleChange} onBlur={handleBlur} autoComplete="off" value={values.password} />
                </div>
                <div className="form-item-container">
                  <Input error={errors.password_confirmation && touched.password_confirmation && errors.password_confirmation} label="Confirm new password" type="password" name="password_confirmation" onChange={handleChange} autoComplete="off" onBlur={handleBlur} value={values.password_confirmation} />
                </div>

                { error.message &&
                  <div className="flex justify-center mb-6">
                    <p className={`${error.invalid ? "error-text" : "success-text"}`}>{error.message}</p>
                  </div>
                }
                <button type="submit" className="submit btn btn--primary text-left w-full">Save your new password</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}