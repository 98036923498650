const countries = [
  {
    "label": "Afghanistan",
    "value": "AFG"
  },
  {
    "label": "Albania",
    "value": "ALB"
  },
  {
    "label": "Algeria",
    "value": "DZA"
  },
  {
    "label": "American Samoa",
    "value": "ASM"
  },
  {
    "label": "Andorra",
    "value": "AND"
  },
  {
    "label": "Angola",
    "value": "AGO"
  },
  {
    "label": "Anguilla",
    "value": "AIA"
  },
  {
    "label": "Antigua and Barbuda",
    "value": "ATG"
  },
  {
    "label": "Argentina",
    "value": "ARG"
  },
  {
    "label": "Armenia",
    "value": "ARM"
  },
  {
    "label": "Aruba",
    "value": "ABW"
  },
  {
    "label": "Australia",
    "value": "AUS"
  },
  {
    "label": "Austria",
    "value": "AUT"
  },
  {
    "label": "Azerbaijan",
    "value": "AZE"
  },
  {
    "label": "Bahamas",
    "value": "BHS"
  },
  {
    "label": "Bahrain",
    "value": "BHR"
  },
  {
    "label": "Bangladesh",
    "value": "BGD"
  },
  {
    "label": "Barbados",
    "value": "BRB"
  },
  {
    "label": "Belarus",
    "value": "BLR"
  },
  {
    "label": "Belgium",
    "value": "BEL"
  },
  {
    "label": "Belize",
    "value": "BLZ"
  },
  {
    "label": "Benin",
    "value": "BEN"
  },
  {
    "label": "Bermuda",
    "value": "BMU"
  },
  {
    "label": "Bhutan",
    "value": "BTN"
  },
  {
    "label": "Bolivia",
    "value": "BOL"
  },
  {
    "label": "Bosnia and Herzegovina",
    "value": "BIH"
  },
  {
    "label": "Botswana",
    "value": "BWA"
  },
  {
    "label": "Brazil",
    "value": "BRA"
  },
  {
    "label": "Brunei",
    "value": "BRN"
  },
  {
    "label": "Bulgaria",
    "value": "BGR"
  },
  {
    "label": "Burkina Faso",
    "value": "BFA"
  },
  {
    "label": "Burundi",
    "value": "BDI"
  },
  {
    "label": "Cabo Verde - Cape Verde",
    "value": "CPV"
  },
  {
    "label": "Cambodia",
    "value": "KHM"
  },
  {
    "label": "Cameroon",
    "value": "CMR"
  },
  {
    "label": "Canada",
    "value": "CAN"
  },
  {
    "label": "Cayman Islands",
    "value": "CYM"
  },
  {
    "label": "Central African Republic",
    "value": "CAF"
  },
  {
    "label": "Chad",
    "value": "TCD"
  },
  {
    "label": "Chile",
    "value": "CHL"
  },
  {
    "label": "China",
    "value": "CHN"
  },
  {
    "label": "Colombia",
    "value": "COL"
  },
  {
    "label": "Comoros",
    "value": "COM"
  },
  {
    "label": "Congo Brazzaville",
    "value": "COB"
  },
  {
    "label": "Congo, Democratic Republic",
    "value": "COG"
  },
  {
    "label": "Cook Islands",
    "value": "COK"
  },
  {
    "label": "Costa Rica",
    "value": "CRI"
  },
  {
    "label": "Cote Divoire",
    "value": "CIV"
  },
  {
    "label": "Croatia",
    "value": "HRV"
  },
  {
    "label": "Cuba",
    "value": "CUB"
  },
  {
    "label": "Curacao",
    "value": "CUW"
  },
  {
    "label": "Cyprus",
    "value": "CYP"
  },
  {
    "label": "Czech Republic",
    "value": "CZE"
  },
  {
    "label": "Democratic Peoples Rep of Korea",
    "value": "PRK"
  },
  {
    "label": "Denmark",
    "value": "DNK"
  },
  {
    "label": "Djibouti",
    "value": "DJI"
  },
  {
    "label": "Dominica",
    "value": "DMA"
  },
  {
    "label": "Dominican Republic",
    "value": "DOM"
  },
  {
    "label": "Ecuador",
    "value": "ECU"
  },
  {
    "label": "Egypt",
    "value": "EGY"
  },
  {
    "label": "El Salvador",
    "value": "SLV"
  },
  {
    "label": "Equatorial Guinea",
    "value": "GNQ"
  },
  {
    "label": "Eritrea",
    "value": "ERI"
  },
  {
    "label": "Estonia",
    "value": "EST"
  },
  {
    "label": "Ethiopia",
    "value": "ETH"
  },
  {
    "label": "Fiji",
    "value": "FJI"
  },
  {
    "label": "Finland",
    "value": "FIN"
  },
  {
    "label": "France",
    "value": "FRA"
  },
  {
    "label": "Gabon",
    "value": "GAB"
  },
  {
    "label": "Gambia",
    "value": "GMB"
  },
  {
    "label": "Gaza Strip",
    "value": "GAZ"
  },
  {
    "label": "Georgia",
    "value": "GEO"
  },
  {
    "label": "Germany",
    "value": "DEU"
  },
  {
    "label": "Ghana",
    "value": "GHA"
  },
  {
    "label": "Gibral Tar",
    "value": "GIB"
  },
  {
    "label": "Greece",
    "value": "GRC"
  },
  {
    "label": "Grenada",
    "value": "GRD"
  },
  {
    "label": "Guam",
    "value": "GUM"
  },
  {
    "label": "Guatemala",
    "value": "GTM"
  },
  {
    "label": "Guernsey",
    "value": "GGY"
  },
  {
    "label": "Guinea",
    "value": "GIN"
  },
  {
    "label": "Guinea Bissau",
    "value": "GNB"
  },
  {
    "label": "Guyana",
    "value": "GUY"
  },
  {
    "label": "Haiti",
    "value": "HTI"
  },
  {
    "label": "Holy See Vatican City State",
    "value": "VAT"
  },
  {
    "label": "Honduras",
    "value": "HND"
  },
  {
    "label": "Hong Kong",
    "value": "HKG"
  },
  {
    "label": "Hungary",
    "value": "HUN"
  },
  {
    "label": "Iceland",
    "value": "ISL"
  },
  {
    "label": "India",
    "value": "IND"
  },
  {
    "label": "Indonesia",
    "value": "IDN"
  },
  {
    "label": "Iran",
    "value": "IRN"
  },
  {
    "label": "Iraq",
    "value": "IRQ"
  },
  {
    "label": "Ireland",
    "value": "IRL"
  },
  {
    "label": "Isle Of Man",
    "value": "IMN"
  },
  {
    "label": "Israel",
    "value": "ISR"
  },
  {
    "label": "Italy",
    "value": "ITA"
  },
  {
    "label": "Jamaica",
    "value": "JAM"
  },
  {
    "label": "Japan",
    "value": "JPN"
  },
  {
    "label": "Jersey",
    "value": "JEY"
  },
  {
    "label": "Jordan",
    "value": "JOR"
  },
  {
    "label": "Kazakhstan",
    "value": "KAZ"
  },
  {
    "label": "Kenya",
    "value": "KEN"
  },
  {
    "label": "Kiribati",
    "value": "KIR"
  },
  {
    "label": "Korea, Republic of",
    "value": "KOR"
  },
  {
    "label": "Kosovo",
    "value": "XKX"
  },
  {
    "label": "Kuwait",
    "value": "KWT"
  },
  {
    "label": "Kyrgyzstan",
    "value": "KGZ"
  },
  {
    "label": "Laos",
    "value": "LAO"
  },
  {
    "label": "Latvia",
    "value": "LVA"
  },
  {
    "label": "Lebanon",
    "value": "LBN"
  },
  {
    "label": "Lesotho",
    "value": "LSO"
  },
  {
    "label": "Liberia",
    "value": "LBR"
  },
  {
    "label": "Libya",
    "value": "LBY"
  },
  {
    "label": "Liechtenstein",
    "value": "LIE"
  },
  {
    "label": "Lithuania",
    "value": "LTU"
  },
  {
    "label": "Luxembourg",
    "value": "LUX"
  },
  {
    "label": "Macao, China",
    "value": "MAC"
  },
  {
    "label": "Macedonia",
    "value": "MKD"
  },
  {
    "label": "Madagascar",
    "value": "MDG"
  },
  {
    "label": "Malawi",
    "value": "MWI"
  },
  {
    "label": "Malaysia",
    "value": "MYS"
  },
  {
    "label": "Maldives",
    "value": "MDV"
  },
  {
    "label": "Mali",
    "value": "MLI"
  },
  {
    "label": "Malta",
    "value": "MLT"
  },
  {
    "label": "Marshall Islands",
    "value": "MHL"
  },
  {
    "label": "Mauritania",
    "value": "MRT"
  },
  {
    "label": "Mauritius",
    "value": "MUS"
  },
  {
    "label": "Mexico",
    "value": "MEX"
  },
  {
    "label": "Micronesia",
    "value": "FSM"
  },
  {
    "label": "Moldova",
    "value": "MDA"
  },
  {
    "label": "Monaco",
    "value": "MCO"
  },
  {
    "label": "Mongolia",
    "value": "MNG"
  },
  {
    "label": "Montenegro",
    "value": "MNE"
  },
  {
    "label": "Montserrat",
    "value": "MSR"
  },
  {
    "label": "Morocco",
    "value": "MAR"
  },
  {
    "label": "Mozambique",
    "value": "MOZ"
  },
  {
    "label": "Myanmar",
    "value": "MMR"
  },
  {
    "label": "Namibia",
    "value": "NAM"
  },
  {
    "label": "Nauru",
    "value": "NRU"
  },
  {
    "label": "Nepal",
    "value": "NPL"
  },
  {
    "label": "Netherlands",
    "value": "NLD"
  },
  {
    "label": "New Caledonia",
    "value": "NCL"
  },
  {
    "label": "New Zealand",
    "value": "NZL"
  },
  {
    "label": "Nicaragua",
    "value": "NIC"
  },
  {
    "label": "Niger",
    "value": "NER"
  },
  {
    "label": "Nigeria",
    "value": "NGA"
  },
  {
    "label": "Niue",
    "value": "NIU"
  },
  {
    "label": "Norway",
    "value": "NOR"
  },
  {
    "label": "Oman",
    "value": "OMN"
  },
  {
    "label": "Pakistan",
    "value": "PAK"
  },
  {
    "label": "Palau",
    "value": "PLW"
  },
  {
    "label": "Palestinian Authority West Bank",
    "value": "PSE"
  },
  {
    "label": "Panama",
    "value": "PAN"
  },
  {
    "label": "Papua New Guinea",
    "value": "PNG"
  },
  {
    "label": "Paraguay",
    "value": "PRY"
  },
  {
    "label": "Peru",
    "value": "PER"
  },
  {
    "label": "Philippines",
    "value": "PHL"
  },
  {
    "label": "Poland",
    "value": "POL"
  },
  {
    "label": "Portugal",
    "value": "PRT"
  },
  {
    "label": "Puerto Rico",
    "value": "PRI"
  },
  {
    "label": "Qatar",
    "value": "QAT"
  },
  {
    "label": "Romania",
    "value": "ROU"
  },
  {
    "label": "Russia",
    "value": "RUS"
  },
  {
    "label": "Rwanda",
    "value": "RWA"
  },
  {
    "label": "Saint Kitts and Nevis",
    "value": "KNA"
  },
  {
    "label": "Saint Lucia",
    "value": "LCA"
  },
  {
    "label": "Saint Martin",
    "value": "MAF"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "value": "VCT"
  },
  {
    "label": "Samoa",
    "value": "WSM"
  },
  {
    "label": "San Marino",
    "value": "SMR"
  },
  {
    "label": "Sao Tome and Principe",
    "value": "STP"
  },
  {
    "label": "Saudi Arabia",
    "value": "SAU"
  },
  {
    "label": "Senegal",
    "value": "SEN"
  },
  {
    "label": "Serbia",
    "value": "SRB"
  },
  {
    "label": "Seychelles",
    "value": "SYC"
  },
  {
    "label": "Sierra Leone",
    "value": "SLE"
  },
  {
    "label": "Singapore",
    "value": "SGP"
  },
  {
    "label": "Slovak Republic",
    "value": "SVK"
  },
  {
    "label": "Slovenia",
    "value": "SVN"
  },
  {
    "label": "Solomon Islands",
    "value": "SLB"
  },
  {
    "label": "Somalia",
    "value": "SOM"
  },
  {
    "label": "South Africa",
    "value": "ZAF"
  },
  {
    "label": "Spain",
    "value": "ESP"
  },
  {
    "label": "Sri Lanka",
    "value": "LKA"
  },
  {
    "label": "Sudan",
    "value": "SDN"
  },
  {
    "label": "Sudan South",
    "value": "SDS"
  },
  {
    "label": "Suriname",
    "value": "SUR"
  },
  {
    "label": "Swaziland",
    "value": "SWZ"
  },
  {
    "label": "Sweden",
    "value": "SWE"
  },
  {
    "label": "Switzerland",
    "value": "CHE"
  },
  {
    "label": "Syria",
    "value": "SYR"
  },
  {
    "label": "Taiwan",
    "value": "TWN"
  },
  {
    "label": "Tajkistan",
    "value": "TJK"
  },
  {
    "label": "Tanzania",
    "value": "TZA"
  },
  {
    "label": "Thailand",
    "value": "THA"
  },
  {
    "label": "Timor Leste",
    "value": "TLS"
  },
  {
    "label": "Togo",
    "value": "TGO"
  },
  {
    "label": "Tonga",
    "value": "TON"
  },
  {
    "label": "Trinidad and Tobago",
    "value": "TTO"
  },
  {
    "label": "Tunisia",
    "value": "TUN"
  },
  {
    "label": "Turkey",
    "value": "TUR"
  },
  {
    "label": "Turkmenistan",
    "value": "TKM"
  },
  {
    "label": "Turks and Caicos Islands",
    "value": "TCA"
  },
  {
    "label": "Tuvalu",
    "value": "TUV"
  },
  {
    "label": "Uganda",
    "value": "UGA"
  },
  {
    "label": "Ukraine",
    "value": "UKR"
  },
  {
    "label": "United Arab Emirates",
    "value": "ARE"
  },
  {
    "label": "United Kingdom",
    "value": "GBR"
  },
  {
    "label": "United States",
    "value": "USA"
  },
  {
    "label": "Uruguay",
    "value": "URY"
  },
  {
    "label": "Uzbekistan",
    "value": "UZB"
  },
  {
    "label": "Vanuatu",
    "value": "VUT"
  },
  {
    "label": "Venezuela",
    "value": "VEN"
  },
  {
    "label": "Vietnam",
    "value": "VNM"
  },
  {
    "label": "Virgin Islands UK",
    "value": "VGB"
  },
  {
    "label": "Virgin Islands US",
    "value": "VIR"
  },
  {
    "label": "Yemen",
    "value": "YEM"
  },
  {
    "label": "Zambia",
    "value": "ZMB"
  },
  {
    "label": "Zimbabwe",
    "value": "ZWE"
  }
 ]

 export default countries