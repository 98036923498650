import React, { Fragment } from 'react'
import CurrencyTextField from '../../CurrencyTextField'
import TitleRow from '../../TitleRow'
import ApplicantRow from './ApplicantRow'

export default function CreditCardCommitmentsPart({ applicants }) {

  const creditCardOptions = [
    { label: 'Repay in Full each Month', value: '3' },
    { label: 'Monthly Payment (Greater than minimum Payment)', value: '2' },
    { label: 'Monthly Payment (At least minimum Payment)', value: '1' },
  ]

  const getMaxCreditCardsAmount = () => {
    return applicants.reduce((maximum_cards, { credit_card_commitments }) => credit_card_commitments.length > maximum_cards ? credit_card_commitments.length : maximum_cards, 0)
  }

  const getPaymentOption = credit_card_repayment_type => creditCardOptions.find(option => option.value === credit_card_repayment_type)?.label

  return (
    <Fragment>
      <TitleRow label="Credit Card Commitments" />
      {[...Array(+getMaxCreditCardsAmount())].map((_, amount) => (
        <Fragment key={amount}>
          <TitleRow label={`Credit Card Commitment ${amount + 1}`} />
          <ApplicantRow name="Outstanding balance" tdContent={applicant => <CurrencyTextField value={applicant.credit_card_commitments[amount]?.outstanding_balance}/>} />
          <ApplicantRow name="Payment Frequency" tdContent={applicant => getPaymentOption(applicant.credit_card_commitments[amount]?.credit_card_repayment_type)} />
          <ApplicantRow name="To be repaid prior to drawdown" tdContent={applicant => !applicant.credit_card_commitments[amount] ? '' : applicant.credit_card_commitments[amount]?.loan_paid_on_or_before ? 'Yes' : 'No'} />
          <ApplicantRow name="Monthly payment" tdContent={applicant => <CurrencyTextField value={applicant.credit_card_commitments[amount]?.monthly_payment}/>} />
          <ApplicantRow name="Card Issuer" tdContent={applicant => applicant.credit_card_commitments[amount]?.lender_name} />
          <ApplicantRow name="Account Number" tdContent={applicant => applicant.credit_card_commitments[amount]?.credit_account_number} />
          <ApplicantRow name="Credit Limit" tdContent={applicant => <CurrencyTextField value={applicant.credit_card_commitments[amount]?.credit_limit}/>} />
          <ApplicantRow name="Missed payment in past 2 years" tdContent={applicant => !applicant.credit_card_commitments[amount] ? '' : applicant.credit_card_commitments[amount]?.payments_missed ? 'Yes' : 'No'} />
        </Fragment>
      ))}
    </Fragment>
  )
}