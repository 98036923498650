import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useMemo } from 'react'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import CurrencyTextField from '../CurrencyTextField'
import TitleRow from '../TitleRow'

export default observer(function LoanRequirementsPart() {
  const { applicationState: { mortgage, application } } = useContext(ApplicationContext)

  const mortgageTypeOptions = [
    { label: 'Purchase', value: 'Purchase' },
    { label: 'Switcher', value: 'Remortgage' },
  ]

  const purchaseType = useMemo(() => mortgageTypeOptions.find(option => option.value === application.purchase_type)?.label)

  return (
    <Fragment>
      <TitleRow label="Loan Requirements" />
      <tr>
        <td className="w-1/4">Mortgage Type</td>
        <td className="text-center" colSpan="2">{purchaseType}</td>
      </tr>
      <tr>
        <td className="w-1/4">Purchase price/value of property</td>
        <td className="text-center" colSpan="2"><CurrencyTextField value={mortgage.purchase_price}/></td>
      </tr>
      <tr>
        <td className="w-1/4">Loan Required</td>
        <td className="text-center" colSpan="2"><CurrencyTextField value={mortgage.loan_required}/></td>
      </tr>
      { mortgage.source_gift &&
        <Fragment>
          <tr>
            <td className="w-1/4">Deposit source</td>
            <td className="text-center" colSpan="2">Gift</td>
          </tr>
          <tr>
            <td className="w-1/4">Gift amount</td>
            <td className="text-center" colSpan="2"><CurrencyTextField value={mortgage.source_gift_amount}/></td>
          </tr>
        </Fragment>
      }
      { mortgage.source_inheritance &&
        <Fragment>
          <tr>
            <td className="w-1/4">Deposit source</td>
            <td className="text-center" colSpan="2">Inheritance</td>
          </tr>
          <tr>
            <td className="w-1/4">Inheritance amount</td>
            <td className="text-center" colSpan="2"><CurrencyTextField value={mortgage.source_inheritance_amount}/></td>
          </tr>
        </Fragment>
      }
      { mortgage.source_property &&
        <Fragment>
          <tr>
            <td className="w-1/4">Deposit source</td>
            <td className="text-center" colSpan="2">Property Sale</td>
          </tr>
          <tr>
            <td className="w-1/4">Property Sale amount</td>
            <td className="text-center" colSpan="2"><CurrencyTextField value={mortgage.source_property_amount}/></td>
          </tr>
        </Fragment>
      }
      { mortgage.source_help_to_buy &&
        <Fragment>
          <tr>
            <td className="w-1/4">Deposit source</td>
            <td className="text-center" colSpan="2">Help to Buy Scheme</td>
          </tr>
          <tr>
            <td className="w-1/4">Help to Buy Scheme amount</td>
            <td className="text-center" colSpan="2"><CurrencyTextField value={mortgage.source_help_to_buy_amount}/></td>
          </tr>
        </Fragment>
      }
      { mortgage.source_savings &&
        <Fragment>
          <tr>
            <td className="w-1/4">Deposit source</td>
            <td className="text-center" colSpan="2">Savings</td>
          </tr>
          <tr>
            <td className="w-1/4">Savings amount</td>
            <td className="text-center" colSpan="2"><CurrencyTextField value={mortgage.source_savings_amount}/></td>
          </tr>
        </Fragment>
      }
      { mortgage.source_other &&
        <Fragment>
          <tr>
            <td className="w-1/4">Deposit source</td>
            <td className="text-center" colSpan="2">{mortgage.if_other_details}</td>
          </tr>
          <tr>
            <td className="w-1/4">{mortgage.if_other_details} amount</td>
            <td className="text-center" colSpan="2"><CurrencyTextField value={mortgage.source_other_amount}/></td>
          </tr>
        </Fragment>
      }
    </Fragment>
  )
})