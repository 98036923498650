import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import CommitmentsStep1 from './Step1'
import CommitmentsStep2 from './Step2'

export default function Commitments() {
  const { applicationState: { type } } = useContext(ApplicationContext)

  return (
    <div>
      <h2 className="form-title mb-40">Commitments</h2>
      <Switch>
        <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/commitments/step1`}>
          <CommitmentsStep1 />
        </Route>
        <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/commitments/step2`}>
          <CommitmentsStep2 />
        </Route>
      </Switch>
    </div>
  )
}