import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import iconCommitmentsGray from 'images/icons/commitments-gray.svg'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'

export default observer(function CommitmentsIcon({ applicant }) {
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const errorClass = useMemo(() => {
    if(applicant.steps.commitments_step1_visited && !applicantErrors[applicant.id]?.commitmentsStep1Errors && applicant.steps.commitments_step2_visited && !applicantErrors[applicant.id]?.commitmentsStep2Errors) return 'bg-green-500'
    if  ((!applicant.steps.commitments_step1_visited || !applicantErrors[applicant.id]?.commitmentsStep1Errors)
      && (!applicant.steps.commitments_step2_visited || !applicantErrors[applicant.id]?.commitmentsStep2Errors))
    {
      return 'bg-white'
    } else {
      return 'bg-red-500'
    }
  }, [applicant, applicantErrors[applicant.id]?.commitmentsStep1Errors, applicantErrors[applicant.id]?.commitmentsStep2Errors])

  return (
    <div className={`applicant-image ${errorClass}`}>
      <img alt="Commitments" src={iconCommitmentsGray} />
    </div>
  )
})