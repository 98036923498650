import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Applicant from '../../../models/Applicant'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'

import openBankingProcess1 from 'images/icons/open-banking-process1.svg'
import openBankingProcess2 from 'images/icons/open-banking-process2.svg'
import openBankingProcess3 from 'images/icons/open-banking-process3.svg'
import openBankingProcess4 from 'images/icons/open-banking-process4.svg'

export default function DefaultScreen({
  state,
  toggleConsent,
}) {
  const { applicant_id } = useParams()
  const { applicationState: { applicants } } = useContext(ApplicationContext)
  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants])

  return (
    <>
      {(!currentApplicant.email || currentApplicant.email == '')
        && <p className="mb-40"><strong>Please fill in your email address in the Personal Details section before using Open Banking.</strong></p>}

      <p className="mb-40">We’ve partnered with Yapily Connect to allow you to provide details of your bank transactions to us without having to provide bank statements. Follow the steps on screen to select your bank and then simply login as usual to your bank’s online banking page. You can then select the account(s) you wish to share. A one off snapshot of your transaction history will be taken and provided to us. Click <a href="/videos/open-banking.mp4" target="_blank" className="landing-link">here</a> to find out more on how it works.</p>

      <div className="flex flex-wrap mb-40 -mx-4 text-center text-sm">
        <div className="px-4 w-1/4">
          <div className="bg-white border border-timber-green-o-16 mb-4 p-4 rounded-lg" style={{ minHeight: 150 }}>
            <img alt="" className="mb-2 mx-auto" src={openBankingProcess1} />
          </div>

          <p>Select <span className="text-turquoise">‘Yes’</span> below if you want to use Open Banking.</p>
        </div>

        <div className="px-4 w-1/4">
          <div className="bg-white border border-timber-green-o-16 mb-4 p-4 rounded-lg" style={{ minHeight: 150 }}>
            <img alt="" className="mb-2 mx-auto" src={openBankingProcess2} />
          </div>

          <p>You will then select your bank(s).</p>
        </div>

        <div className="px-4 w-1/4">
          <div className="bg-white border border-timber-green-o-16 mb-4 p-4 rounded-lg" style={{ minHeight: 150 }}>
            <img alt="" className="mb-2 mx-auto" src={openBankingProcess3} />
          </div>

          <p>Login to your Bank and select the relevant account(s).</p>
        </div>

        <div className="px-4 w-1/4">
          <div className="bg-white border border-timber-green-o-16 mb-4 p-4 rounded-lg" style={{ minHeight: 150 }}>
            <img alt="" className="mb-2 mx-auto" src={openBankingProcess4} />
          </div>

          <p>After you have completed the process you will be returned to your application. </p>
        </div>
      </div>

      <div className="mb-40 text-sm text-timber-green-o-60">
        <p>Open banking with MoCo is currently available for AIB, Bank of Ireland, PTSB, Ulster Bank and Revolut customers. It may take up to a minute to complete. If the connection fails, then please retry. If the issue persists, then please contact your broker.</p>
      </div>

      <div className="mx-auto w-1/2">
        <YesNoBtnGroup
          disabled={state.openBankingStatus == 'complete'}
          label="Do you want to use Open Banking?"
          name="open_banking_consent"
          onChange={(name, value) => toggleConsent(value)}
          value={state.openBankingConsent !== null ? state.openBankingConsent : currentApplicant.open_banking_consent}
        />
      </div>
    </>
  )
}
