import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../../models/Applicant'
import Employment from '../../../../models/Employment'
import { EmploymentStep3EmployedSchema } from '../../../../schemas/EmploymentSchemas/EmploymentStep3EmployedSchema'
import axiosService from '../../../../utils/AxiosService'
import CurrencyField from '../../../Form/CurrencyField'
import Input from '../../../Form/Input'
import { notifyProgressSaved } from '../../../Form/Notifications'
import EmploymentActions from '../EmploymentActions'

export default observer(function EmployedFormPart() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ['basic_salary', 'guaranteed_overtime', 'guaranteed_bonus', 'others_name', 'regular_overtime', 'commission', 'car_allowance', 'allowance', 'dividends', 'other_income']

  const save = employment => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { employment } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.employment = new Employment(employment)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  useEffect(() => {
    if(currentApplicant.steps.employment_step3_visited) touchFields()
    applicationErrorsContext.validateEmploymentStep3Employed(currentApplicant.employment, applicant_id)
  }, [loading, currentApplicant.employment])

  return (
    <div>
      <Formik
        initialValues={currentApplicant.employment}
        validationSchema={EmploymentStep3EmployedSchema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.basic_salary && touched.basic_salary && errors.basic_salary}
                    label="Basic pay (p.a.)"
                    name="basic_salary"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    value={values.basic_salary}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.guaranteed_overtime && touched.guaranteed_overtime && errors.guaranteed_overtime}
                    label="Guranteed Overtime (p.a.)"
                    name="guaranteed_overtime"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    value={values.guaranteed_overtime}
                    disabled={disabled}
                    tooltip="Include overtime here only if stated in employment contract"
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.guaranteed_bonus && touched.guaranteed_bonus && errors.guaranteed_bonus}
                    label="Average Annual Bonus"
                    name="guaranteed_bonus"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    value={values.guaranteed_bonus}
                    disabled={disabled}
                    tooltip="Average of your annual bonus payments over the past 24 months"
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.others_name && touched.others_name && errors.others_name}
                    label="Other, Please specify"
                    name="others_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.others_name}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.regular_overtime && touched.regular_overtime && errors.regular_overtime}
                    label="Regular Overtime (p.a.)"
                    name="regular_overtime"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    value={values.regular_overtime}
                    disabled={disabled}
                    tooltip="Average per year of non-guaranteed overtime earned in the previous 24 months"
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.commission && touched.commission && errors.commission}
                    label="Average Annual Commission"
                    name="commission"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    value={values.commission}
                    disabled={disabled}
                    tooltip="Average of your annual commission payments over the past 24 months"
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.car_allowance && touched.car_allowance && errors.car_allowance}
                    label="Car Allowance (p.a.)"
                    name="car_allowance"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    tooltip="Please do not include mileage or travel expenses."
                    value={values.car_allowance}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.allowance && touched.allowance && errors.allowance}
                    label="Other Allowances (p.a.)"
                    name="allowance"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    value={values.allowance}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.dividends && touched.dividends && errors.dividends}
                    label="Dividends"
                    name="dividends"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    value={values.dividends}
                    disabled={disabled}
                    tooltip="All gross dividends from investments or shares over the past 12 months"
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.other_income && touched.other_income && errors.other_income}
                    label="Other"
                    name="other_income"
                    setFieldValue={setFieldValue}
                    onBlur={handleBlur}
                    value={values.other_income}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <EmploymentActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})