import * as Yup from 'yup'
import Texts from './Texts'

export const AffordabilityCalculatorSchema = Yup.object().shape({
  adult_dependants:
    Yup.string()
      .required(Texts.required),
  applicant_type:
    Yup.string()
      .required(Texts.required),
  dependants:
    Yup.string()
      .required(Texts.required),
  estimated_loan_requested:
    Yup.number()
      .typeError(Texts.number),
  existing_mortgages:
    Yup.number()
      .typeError(Texts.number)
      .required(Texts.required),
  first_time_buyer:
    Yup.boolean()
      .required(Texts.required),
  gross_annual_income:
    Yup.number()
      .typeError(Texts.number)
      .required(Texts.required),
  house_value:
    Yup.number()
      .typeError(Texts.number)
      .min(112000, `${Texts.min} €112,000.`),
  loans:
    Yup.number()
      .typeError(Texts.number)
      .required(Texts.required),
  main_net_monthly_income:
    Yup.number()
      .typeError(Texts.number)
      .required(Texts.required),
  mortgage_term:
    Yup.number()
      .typeError(Texts.number)
      .max(35, `${Texts.max} 35.`)
      .min(10, `${Texts.min} 10.`)
      .required(Texts.required),
  rate:
    Yup.number()
      .typeError(Texts.number)
      .required(Texts.required),
  revolving_credit_balance:
    Yup.number()
      .typeError(Texts.number)
      .required(Texts.required),
  secondary_net_monthly_income:
    Yup.number()
      .typeError(Texts.number)
      .required(Texts.required),
});
