import React, { useState } from 'react'
import { Formik } from 'formik'
import AprcCalculatorForm from './Form'
import AprcCalculatorList from './List'
import AprcCalculatorFilter from './Filter'
import { AprcCalculatorSchema } from '../../../schemas/AprcCalculatorSchema'
import { ExcelFormulas } from '../../calculations'

export default function AprcCalculator({
  cash_backs,
  ltvs,
  overpayments,
  terms,
}) {
  const minTerm = terms.reduce((prev, current) => (prev.term < current.term) ? prev : current).term

  const [state, setState] = useState({
    currentCashback: [],
    currentFixedTerm: minTerm,
    submitted: false,
  })

  const {
    currentCashback,
    currentFixedTerm,
  } = state

  const initialValues = {
    property_value: '',
    mortgage_amount: '',
    mortgage_term: '',
    overpayment: true,
  }

  const filteredCashbacks = cash_backs
    .filter(item => item.term === currentFixedTerm)
    .filter(item => currentCashback.length > 0 ? currentCashback.includes(parseInt(item.cash_back)) : item)

  const calculateLtv = (values) => {
    if (!values) return 0
    return Math.floor((values.mortgage_amount / values.property_value) * 10000) / 100
  }

  const calculateOverpayment = (values, type) => {
    if (values.overpayment === null) return

    return overpayments.find(item => values.overpayment ? item.overpayment.toLowerCase() === 'yes' : item.overpayment.toLowerCase() === 'no'
      && item.max >= parseFloat(calculateLtv(values))
      && item.min <= parseFloat(calculateLtv(values))
      && item.term === currentFixedTerm
    )[type]
  }

  const calculateBasicRate = (rateLtv, rateFixedTerm, type, values, cashback) => {
    const termsExtraRate = terms.find(item => item.term === rateFixedTerm
      && item.max >= parseFloat(rateLtv)
      && item.min <= parseFloat(rateLtv)
      && parseFloat(item.cash_back) === cashback
    ) || 0
    const termsExtraRateLoading = parseFloat(termsExtraRate[type] || 0)
    const overpaymentLoading = parseFloat(calculateOverpayment(values, type))
    const ltvLoading = parseFloat(ltvs.find(item => item.max >= parseFloat(rateLtv)
      && item.min <= parseFloat(rateLtv)
      && item.term === currentFixedTerm
    )[type])

    return ltvLoading + termsExtraRateLoading + overpaymentLoading
  }

  const calculateFixedRate = (fixed_rate_loading, values, cashback) => (calculateBasicRate(calculateLtv(values), currentFixedTerm, 'fixed_rate_loading', values, cashback) + parseFloat(fixed_rate_loading)).toFixed(2)

  const calculatePmt = (fixed_rate_loading, values, cashback) => {
    return (
      ExcelFormulas.PMT(
        (calculateBasicRate(calculateLtv(values), currentFixedTerm, 'fixed_rate_loading', values, cashback) + parseFloat(fixed_rate_loading)) / 100 / 12,
        values.mortgage_term * 12,
        values.mortgage_amount
      ).toFixed(2) * -1
    ).toLocaleString('en', { minimumFractionDigits: 2 })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={AprcCalculatorSchema}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        isValid,
        setFieldValue,
        touched,
        values,
      }) => {
        return (
          <div className="lg:flex -mx-8">
            <div className="flex lg:mb-0 lg:w-1/3 mb-8 px-8">
              <div className="box-shadow flex p-6 rounded-lg">
                <AprcCalculatorForm
                  calculateLtv={calculateLtv}
                  dirty={dirty}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isValid={isValid}
                  setFieldValue={setFieldValue}
                  setState={setState}
                  state={state}
                  touched={touched}
                  values={values}
                />
              </div>
            </div>

            <div className="lg:w-2/3 px-8">
              <AprcCalculatorFilter
                calculateLtv={calculateLtv}
                cash_backs={cash_backs}
                errors={errors}
                setFieldValue={setFieldValue}
                setState={setState}
                state={state}
                submitted={state.submitted}
                terms={terms}
                touched={touched}
                values={values}
              />

              <AprcCalculatorList
                calculateBasicRate={calculateBasicRate}
                calculateFixedRate={calculateFixedRate}
                calculateLtv={calculateLtv}
                calculatePmt={calculatePmt}
                filteredCashbacks={filteredCashbacks}
                state={state}
                values={values}
              />
            </div>
          </div>
        )
      }}
    </Formik>
  )
}
