import React, { useState } from 'react'
export const CurrentBrokerContext = React.createContext({})

export function CurrentBrokerProvider(props) {
  const [currentBroker, setCurrentBroker] = useState()

  return (
    <CurrentBrokerContext.Provider value={{ currentBroker, setCurrentBroker }}>
      {props.children}
    </CurrentBrokerContext.Provider>
  )
}
