import React, { useEffect } from 'react'
import axiosService from '../utils/AxiosService'
import consumer from '../channels/consumer'

export default function Connection({ url }) {
  const userUuid = new URL(url).searchParams.get('user-uuid')

  useEffect(() => {
    consumer.subscriptions.create({ channel: 'ObAccountChannel', userUuid },
      {
        connected() { console.log('ObAccountChannel connected') },
        disconnected() { console.log('ObAccountChannel disconnected') },
        received(data) {
          if (data?.message === 'ob_account_flow_changed') {
            window.location = data.target_path
          }
        }
      }
    )

    axiosService.get(url)
      .then(response => {
        console.log(response)
        if (response.target_path) {
          window.location = response.target_path
        }
      })
      .catch(error => console.log(error))

    return () => {}
  }, [])

  return (
    <></>
  )
}
