import React, { useContext, useMemo } from 'react'
import iconAssetsGray from 'images/icons/assets-gray.svg'
import { observer } from 'mobx-react-lite'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'

export default observer(function AssetsIcon({ applicant }) {
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const errorClass = useMemo(() => {
    if(!applicant.steps.assets_visited) return 'bg-white'
    if(applicantErrors[applicant.id]?.assetsErrors) return 'bg-red-500'
    return 'bg-green-500'
  }, [applicant, applicantErrors[applicant.id]?.assetsErrors])

  return (
    <div className={`applicant-image ${errorClass}`}>
      <img alt="Assets" src={iconAssetsGray} />
    </div>
  )
})