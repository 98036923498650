import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axiosService from '../../../../utils/AxiosService';
import Input from '../../../Form/Input';
import { toastError, toastSuccess } from '../../../Form/Toasts';
import RootStoreContext from '../../../../contexts/RootStoreContext'

/**
 * Notifies success, authenticates the user in store
 * (now that session is created) and redirects to application page.
 * Broken into function, as can be used in BrokerLogin also,
 * if skipping 2FA (eg automated test on staging).
 * See See doc/qa-automated-testing.md
 */
export const onLoginSuccess = (rootStore, history) => {
  // Notify succcess
  toastSuccess('Successfully logged in')
  // Set user as authenticated on RootStore, so it does relevant actions
  rootStore.authenticateUser()
  // Show Applications page
  history.push('/broker/applications')
}

export default function Broker2fa({ brokerId, email, password, signInAndSend2FaCode }) {
  const rootStore = useContext(RootStoreContext)
  const [isLoading, setIsLoading] = useState(false)
  let history = useHistory()

  const confirm2fa = values => {
    setIsLoading(true)
    axiosService.post(
      '/api/apprivo_broker/sessions/two_factor_authentication',
      {
        apprivo_broker: {
          two_factor_authentication_code: values.two_factor_authentication_code,
          id: brokerId,
          email: email,
          password: password
        }
      })
    .then(() => {
      onLoginSuccess(rootStore, history)
    })
    .catch(({ data: { error }}) => {
      setIsLoading(false)
      toastError(error)
    })
  }

  const resend2FaCode = async () => {
    setIsLoading(true)
    try {
      await signInAndSend2FaCode(email, password) // These details should be still valid, as just validated prior to this
      toastSuccess('Authorization code resent to your phone')
      setIsLoading(false)
    }
    catch (errorResponse) {
      toastError(error)
      setIsLoading(false)
    }
  }

  return (
    <div className="bg-2fa items-center flex w-full" style={{ height: '91vh'}}>
      <div className="container container--main">
        <div className="container--devise">
          <h2 className="font-bold mb-40 text-2xl">Authenticate Your Account</h2>
          <p className="mb-40">Please confirm your account by entering the authorization code sent to your mobile phone.</p>

          <Formik
            initialValues={{ two_factor_authentication_code: '' }}
            onSubmit={confirm2fa}
            disabled={isLoading}
          >
            {({ handleBlur, handleChange, values }) => (
              <Form>
                <div className="form-item-container flex justify-center">
                  <Input autoFocus maxLength="6" type="password" name="two_factor_authentication_code" onChange={handleChange} onBlur={handleBlur} autoComplete="off" value={values.two_factor_authentication_code} />
                </div>

                {/* Submit Button - Looading: https://flowbite.com/docs/components/spinner/#buttons */}
                {!isLoading && (
                  <div className="mb-6 relative">
                    <button type="submit" className="submit btn btn--primary text-left w-full">Submit code</button>
                    <i className="fa fa-arrow-right absolute right-32 text-white top-1/2 -translate-y-1/2 transform" />
                  </div>
                )}
                {isLoading && (
                  <div className="mb-6 relative">
                    <button disabled type="button" className="submit btn btn--primary w-full px-5 py-2.5 text-left mr-2 items-center justify-between" style={{ display: 'flex' }}>
                      Loading...
                      <svg role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                      </svg>
                    </button>
                  </div>
                )}
              </Form>
            )}
          </Formik>

          <div className="flex justify-between text-sm">
            <p>Haven’t received it?</p>

            <button className="text-turquoise" onClick={resend2FaCode}>Resend a new code</button>
          </div>
        </div>
      </div>
    </div>
  )
}