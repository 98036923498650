import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'
import { nationalityOptions } from '../../../Form/NationalitySelector'
import moment from 'moment'
import ApplicantRow from './ApplicantRow'
import countries from '../../../Form/Countries'
import CurrencyTextField from '../../CurrencyTextField'
import TitleRow from '../../TitleRow'

export default observer(function PersonalDetailsPart({ applicants }) {

  const titleOptions = [
    { label: 'Mr', value: '1' },
    { label: 'Mrs', value: '2' },
    { label: 'Miss', value: '3' },
    { label: 'Ms', value: '4' },
    { label: 'Dr', value: '5' },
  ]

  const maritalOptions = [
    { label: 'Single', value: '5' },
    { label: 'Married / Civil Partnership / Living with Partner', value: '1' },
    { label: 'Divorced / Civil Partnership has been dissolved', value: '2' },
    { label: 'Widowed / Surviving Civil Partner', value: '3' },
    { label: 'Separated', value: '4' },
  ]

  const relationshipOptions = [
    { label: 'Partner or Spouse', value: '1' },
    { label: 'Other', value: '2' },
  ]

  const residentialStatusOptions = [
    { label: 'Owner with Mortgage', value: '1' },
    { label: 'Owner no Mortgage', value: '2' },
    { label: 'Tenant', value: '3' },
    { label: 'With Family or Friend', value: '4' },
  ]

  const getRelationship = (applicant, index) => {
    if(index === 0) {
      return applicant.relation_with_applicant2 === '2' ? applicant.relation_description_with_applicant2 : relationshipOptions.find(option => option.value === applicant.relation_with_applicant2)?.label
    } else {
      return applicant.relation_with_applicant1 === '2' ? applicant.relation_description_with_applicant1 : relationshipOptions.find(option => option.value === applicant.relation_with_applicant1)?.label
    }
  }

  return (
    <Fragment>
      <TitleRow label="Personal Details" />
      <ApplicantRow name="Title" tdContent={applicant => titleOptions.find(option => option.value === applicant.title)?.label} />
      <ApplicantRow name="First Name" tdContent={applicant => applicant.first_name} />
      <ApplicantRow name="Middle Name" tdContent={applicant => applicant.middle_name} />
      <ApplicantRow name="Last Name" tdContent={applicant => applicant.last_name} />

      {(applicants[0]?.known_by_other_name || applicants[1]?.known_by_other_name) &&
        <tr>
          <td className="w-1/4">Other Name</td>
          {applicants.map(applicant => <td key={`Other Name-${applicant.id}`} className="text-center">{titleOptions.find(option => option.value === applicant.other_title)?.label} {applicant.other_first_name} {applicant.other_last_name}</td>)}
        </tr>
      }
      <ApplicantRow name="Date of Birth" tdContent={applicant => applicant.date_of_birth ? moment(applicant.date_of_birth).format('DD/MM/YYYY') : ''} />
      <ApplicantRow name="Marital Status" tdContent={applicant => maritalOptions.find(option => option.value == applicant.marital_status)?.label} />
      <ApplicantRow name="Nationality" tdContent={applicant => nationalityOptions.find(option => option.value == applicant.nationality)?.label} />
      <ApplicantRow name="PPSN" tdContent={applicant => applicant.ppsn} />
      <ApplicantRow name="Email Address" tdContent={applicant => applicant.email} />
      <ApplicantRow name="Country of Residence" tdContent={applicant => countries.find(country => country.value === applicant.country_of_residence)?.label} />
      <ApplicantRow name="Mobile Phone Number" tdContent={applicant => applicant.mobile_number} />
      <ApplicantRow name="Alternative Phone Number" tdContent={applicant => applicant.phone_number} />
      <ApplicantRow name="First Time Buyer" tdContent={applicant => applicant.first_time_buyer ? 'Yes' : 'No'} />
      <ApplicantRow name="Retirement Age" tdContent={applicant => applicant.retirement_age} />
      {applicants.length >= 2 &&
        <Fragment>
          <tr>
            <td className="w-1/4">Relationship with other applicant</td>
            {applicants.map((applicant, index) => <td key={`Monthly Rent-${applicant.id}`} className="text-center">{getRelationship(applicant, index)}</td>)}
          </tr>

        </Fragment>
      }
      <ApplicantRow name="Number of Dependants" tdContent={applicant => applicant.number_of_dependants} />
      <ApplicantRow name="Current Address Eircode" tdContent={applicant => applicant.address_post_code} />
      <ApplicantRow name="Date moved to address" tdContent={applicant => applicant.address_moved_date ? moment(applicant.address_moved_date).format('DD/MM/YYYY') : ''} />
      <ApplicantRow name="Residential Status" tdContent={applicant => residentialStatusOptions.find(option => option.value === applicant.residential_status)?.label} />

      {(applicants[0]?.residential_status === '3' || applicants[1]?.residential_status === '3') &&
        <tr>
          <td className="w-1/4">Monthly Rent</td>
          {applicants.map(applicant => <td key={`Monthly Rent-${applicant.id}`} className="text-center"><CurrencyTextField value={applicant.monthly_rent_amount}/></td>)}
        </tr>
      }
      <tr><td></td><td></td><td></td></tr>
      <ApplicantRow titleClass="font-bold" name="Open Banking" tdContent={applicant => applicant?.open_banking_details?.status === 'complete' ? 'Yes' : 'No'} />
    </Fragment>
  )
})