import React, { useMemo } from 'react'
import Select from 'react-select'
import Label from './Label'
import countries from './Countries'
import ErrorMessage from './ErrorMessage'

export default function ReactSelect({
  components,
  countrySelect,
  disabled,
  error,
  handleBlur,
  label,
  name,
  onChange = () => {},
  options,
  placeholder,
  showAsterisk,
  tooltip,
  value,
}) {
  return (
    <>
      <Label
        label={label}
        showAsterisk={showAsterisk}
        tooltip={tooltip}
      />

      <div className={error ? 'error' : ''}>
        <Select
          classNamePrefix="react-select"
          components={components}
          isDisabled={disabled}
          name={name}
          onChange={onChange}
          onBlur={handleBlur}
          options={countrySelect ? countries : options}
          placeholder={placeholder}
          value={(value || value === 0) && { label: (countrySelect ? countries : options).find(option => option.value === value)?.label, value }}
        />
      </div>

      <ErrorMessage text={error} />
    </>
  );
}
