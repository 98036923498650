import React from 'react'
import MiSnap from './MiSnap'

export default function CaptureStep2({url}) {
  return (
    <>
      <MiSnap url={url} />
    </>
  )
}
