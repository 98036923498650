import { makeAutoObservable } from "mobx"

export default class LoanCommitment {
  constructor(loan_commitments={}) {
    this.loan_type = loan_commitments.loan_type || ''
    this.remaining_term = loan_commitments.remaining_term || 0
    this.loan_paid_on_or_before = !!loan_commitments.loan_paid_on_or_before
    this.outstanding_balance = loan_commitments.outstanding_balance || ''
    this.monthly_payment = loan_commitments.monthly_payment || ''
    this.lender_name = loan_commitments.lender_name || ''
    this.mortgage_account_number = loan_commitments.mortgage_account_number || ''
    this.purpose = loan_commitments.purpose || ''
    makeAutoObservable(this)
  }
}