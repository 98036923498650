import React from 'react'

import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import RcSlider from '../../Form/RcSlider'

export default function AprcCalculatorFilter({
  calculateLtv,
  cash_backs,
  errors,
  setFieldValue,
  setState,
  state,
  submitted,
  terms,
  touched,
  values,
}) {
  const {
    currentCashback,
    currentFixedTerm,
  } = state

  const availableCashBacksByTerm = cash_backs.filter(item => item.term === currentFixedTerm
    && parseFloat(item.min_loan || 0) < parseFloat(values.mortgage_amount)
    && item.max >= parseFloat(calculateLtv(values))
    && item.min <= parseFloat(calculateLtv(values))
  ).sort((a, b) => a.cash_back - b.cash_back)

  const uniqCashbacks = [...new Map(cash_backs.map(item => [item.cash_back, item])).values()]
    .sort((a, b) => a.cash_back - b.cash_back)

  const uniqTerms = [...new Map(terms.map(item => [item.term, item])).values()]
    .sort((a, b) => a.term - b.term)

  return (
    <div className="sm:flex justify-between mb-8">
      <div>
        <p className="font-bold fs-13 mb-2">Cashback</p>

        <div className="flex mb-6">
          {uniqCashbacks.map((item, index) => (
            <button
              className={`
                btn btn--small mr-1
                ${currentCashback.includes(parseInt(item.cash_back)) ? 'btn--primary' : 'btn--outlined-primary'}
                ${availableCashBacksByTerm.find(cashback => parseInt(cashback.cash_back) === parseInt(item.cash_back)) && submitted ? '' : 'disabled'}
              `}
              key={index}
              onClick={() => {
                const tempCashback = currentCashback.includes(parseInt(item.cash_back))
                  ? currentCashback.filter(cashback => cashback !== parseInt(item.cash_back))
                  : [...currentCashback, parseInt(item.cash_back)]

                setState({ ...state, currentCashback: tempCashback })
              }}
              type="button"
            >
              € {parseInt(item.cash_back).toLocaleString('en', { minimumFractionDigits: 0 })}
            </button>
          ))}
        </div>

        <p className="font-bold fs-13 mb-2">Fixed term</p>

        <div className="lg:flex justify-between">
          <div className="flex lg:mb-0 mb-8">
            {uniqTerms.map((term, index) => (
              <button
                className={`
                  btn btn--small mr-1
                  ${term.term === currentFixedTerm ? 'btn--primary' : 'btn--outlined-primary'}
                `}
                key={index}
                onClick={() => setState({
                  ...state,
                  currentCashback: [],
                  currentFixedTerm: term.term
                })}
                type="button"
              >
                {term.term}-year
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="sm:w-1/3">
        <div className="sm:flex justify-end">
          <div className="w-2/3">
            <label className="block font-bold fs-13 sm:text-right text-sm">Overpayment Facility</label>

            <YesNoBtnGroup
              error={errors.overpayment && touched.overpayment && errors.overpayment}
              name="overpayment"
              onChange={(name, value) => setFieldValue(name, value, true)}
              size="small"
              value={values.overpayment}
            />
          </div>
        </div>

        <div className="sm:-mt-6">
          <label className="block font-bold fs-13 mb-2 sm:text-right text-sm">Mortgage term</label>

          <RcSlider
            handleChange={value => setFieldValue('mortgage_term', value, true)}
            max={35}
            min={10}
            value={values.mortgage_term}
          />
        </div>
      </div>
    </div>
  )
}
