import React from 'react'
import Tooltip from '../Tooltip'

export default function Label({
  label,
  noRightPadding,
  showAsterisk,
  tooltip,
  marginBottom = "mb-2"
}) {
  if (!label) return null

  return (
    <div className={`flex justify-between ${marginBottom} ${noRightPadding ? 'pl-8' : 'px-8'}`}>
      <label className="label">
        {label}
        {showAsterisk && <span className="text-punch">*</span>}
      </label>
      {tooltip && <Tooltip tooltip={tooltip} />}
    </div>
  )
}
