import * as Yup from 'yup'
import { applicantHasOtherIncomeAmount, applicantHasOtherIncomeFrequency } from './OtherIncomeHelpers';

export const OtherIncomeStep2Schema = Yup.object().shape({
  amount3: Yup.mixed().when(...applicantHasOtherIncomeAmount(3)),
  pay_frequency3: Yup.mixed().nullable().when(...applicantHasOtherIncomeFrequency(3)),
  guaranteed3: Yup.boolean(),
  amount4: Yup.mixed().when(...applicantHasOtherIncomeAmount(4)),
  pay_frequency4: Yup.mixed().nullable().when(...applicantHasOtherIncomeFrequency(4)),
  guaranteed4: Yup.boolean(),
});