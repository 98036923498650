import * as Yup from 'yup'
import Texts from './Texts'

export const AprcCalculatorSchema = Yup.object().shape({
  property_value:
    Yup.number()
      .typeError(Texts.number)
      .min(1, `${Texts.min} 0.`)
      .required(Texts.required),
  mortgage_amount:
    Yup.number()
      .typeError(Texts.number)
      .min(100000, 'The minimum mortgage amount is €100,000')
      .required(Texts.required)
      .when('property_value', (property_value, schema) => {
        return schema.test({
          test: mortgage_amount => (mortgage_amount / property_value) * 100 <= 90,
          message: "LTV can not be greater than 90%"
        })
      }),
  mortgage_term:
    Yup.number()
      .typeError(Texts.number)
      .max(35, `${Texts.max} 35.`)
      .min(10, `${Texts.min} 10.`)
      .required(Texts.required),
  overpayment:
    Yup.boolean()
      .required(Texts.required),
});
