import React, { useEffect, useRef, useState } from 'react'

export default function Tooltip({ tooltip }) {
  const [visible, setVisible] = useState(false)
  const node = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  })

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      setVisible(true)
      return
    }
    setVisible(false)
  }

  return (
    <div className="relative" ref={node}>
      <span onClick={handleClick}>
        <i className={visible ? 'fas fa-info-circle' : 'far fa-info-circle'} />
      </span>
      <div className={`tooltip ${visible ? 'active' : ''}`}>
        <b>Tooltip Help</b>
        <br />
        <p dangerouslySetInnerHTML={{ __html: tooltip }} style={{ whiteSpace: 'pre-line'}} />
      </div>
    </div>
  )
}
