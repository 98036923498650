import { observer } from 'mobx-react-lite'
import React from 'react'
import ReactSelect from '../../../../Form/ReactSelect'

export default observer(function EmploymentSelector({ applicant }) {

  const employmentOptions = [
    { label: 'Employed', value: 'Employed' },
    { label: 'Self Employed', value: 'Self Employed' },
    { label: 'Retired', value: 'Retired' },
    { label: 'Not Employed', value: 'Not Employed' },
    { label: 'None of the above', value: 'None of the above' },
  ]

  return (
    <div className="mb-40">
      <ReactSelect label="Employment Status" options={employmentOptions} placeholder="Select Employment Status" value={applicant.employment.are_you} disabled />
    </div>
  )
})