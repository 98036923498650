import { makeAutoObservable } from "mobx"

export default class OtherIncome {
  constructor(other_income={}) {
    this.do_you_have_income_1 = !!other_income.do_you_have_income_1
    this.amount1 = other_income.amount1 || 0
    this.pay_frequency1 = other_income.pay_frequency1 || null
    this.guaranteed1 = !!other_income.guaranteed1
    this.do_you_have_income_2 = !!other_income.do_you_have_income_2
    this.amount2 = other_income.amount2 || 0
    this.pay_frequency2 = other_income.pay_frequency2 || null
    this.guaranteed2 = !!other_income.guaranteed2
    this.do_you_have_income_3 = !!other_income.do_you_have_income_3
    this.amount3 = other_income.amount3 || 0
    this.pay_frequency3 = other_income.pay_frequency3 || null
    this.guaranteed3 = !!other_income.guaranteed3
    this.do_you_have_income_4 = !!other_income.do_you_have_income_4
    this.amount4 = other_income.amount4 || 0
    this.pay_frequency4 = other_income.pay_frequency4 || null
    this.guaranteed4 = !!other_income.guaranteed4
    this.do_you_have_income_5 = !!other_income.do_you_have_income_5
    this.amount5 = other_income.amount5 || 0
    this.pay_frequency5 = other_income.pay_frequency5 || null
    this.guaranteed5 = !!other_income.guaranteed5
    this.source_details5 = other_income.source_details5 || ''

    makeAutoObservable(this)
  }
}