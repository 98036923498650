import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../../models/Applicant'
import Employment from '../../../../models/Employment'
import { EmploymentStep2EmployedSchema } from '../../../../schemas/EmploymentSchemas/EmploymentStep2EmployedSchema'
import axiosService from '../../../../utils/AxiosService'
import Divider from '../../../Divider'
import Input from '../../../Form/Input'
import LoqateWrapper from '../../../Form/LoqateWrapper'
import { notifyProgressSaved } from '../../../Form/Notifications'
import ReactSelect from '../../../Form/ReactSelect'
import EmploymentActions from '../EmploymentActions'

export default observer(function EmployedFormPart() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ["address_post_code", "address_line1", "address_line2", "address_line3", "address_city", "address_county", "address_country"]

  const save = employment => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { employment } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.employment = new Employment(employment)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  useEffect(() => {
    if(currentApplicant.steps.employment_step2_visited) touchFields()
    applicationErrorsContext.validateEmploymentStep2Employed(currentApplicant.employment, applicant_id)
  }, [loading, currentApplicant.employment])

  return (
    <div>
      <Divider text="Address of Employer" />

      <Formik
      initialValues={currentApplicant.employment}
      validationSchema={EmploymentStep2EmployedSchema}
      enableReinitialize={loading}
      innerRef={formikRef}
    >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <LoqateWrapper
                    label="Search Address"
                    tooltip="Type address or Eircode to lookup address"
                    onSelect={address => {
                      setFieldValue('address_post_code', address.PostalCode, true)
                      setFieldValue('address_line1', address.Line1, true)
                      setFieldValue('address_line2', address.Line2, true)
                      setFieldValue('address_line3', address.Line3, true)
                      setFieldValue('address_city', address.City, true)
                      address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                        ? setFieldValue('address_county', 'Dublin', true)
                        : setFieldValue('address_county', address.Province, true)
                      setFieldValue('address_country', address.CountryIso3, true)
                    }}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.address_post_code && errors.address_post_code}
                    label="Eircode"
                    name="address_post_code"
                    type="text"
                    onChange={(e) => {
                      const postCodeValue = e.target.value
                      if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('address_county', 'Dublin', true)
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    value={values.address_post_code}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.address_line1 && errors.address_line1}
                    label="Address Line 1"
                    name="address_line1"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_line1}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.address_line2 && errors.address_line2}
                    label="Address Line 2"
                    name="address_line2"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_line2}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.address_line3 && errors.address_line3}
                    label="Address Line 3"
                    name="address_line3"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_line3}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    error={touched.address_city && errors.address_city}
                    label="City"
                    name="address_city"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_city}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.address_county && errors.address_county}
                    label="County"
                    name="address_county"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_county}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <ReactSelect
                    countrySelect
                    error={touched.address_country && errors.address_country}
                    label="Country"
                    name="address_country"
                    placeholder="Select country"
                    onChange={address_country => setFieldValue('address_country', address_country.value, true)}
                    value={values.address_country}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          <EmploymentActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})