import React from 'react'

export default function Checkbox({
  label,
  name,
  checked = false,
  change = () => {}
}) {
  return (
    <div className="flex">
      <input
        className="custom-checkbox hidden"
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={change}
      />


      <label htmlFor={name}>{label}</label>
    </div>
  )
}
