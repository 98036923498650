import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import TitleRow from '../TitleRow'

export default observer(function SolicitorPart() {

  const { applicationState: { solicitor }} = useContext(ApplicationContext)

  return (
    <Fragment>
      <TitleRow label="Solicitor" />
      <tr>
        <td className="w-1/4">Solicitor appointed</td>
        <td className="text-center" colSpan="2">{solicitor.do_you_have_a_solicitor ? 'Yes' : 'No'}</td>
      </tr>
      { solicitor.do_you_have_a_solicitor &&
        <Fragment>
          <tr>
            <td className="w-1/4">Name of firm</td>
            <td className="text-center" colSpan="2">{solicitor.name_of_firm}</td>
          </tr>
          <tr>
            <td className="w-1/4">Solicitor acting</td>
            <td className="text-center" colSpan="2">{solicitor.solicitor_acting}</td>
          </tr>
          <tr>
            <td className="w-1/4">Telephone</td>
            <td className="text-center" colSpan="2">{solicitor.telephone}</td>
          </tr>
          <tr>
            <td className="w-1/4">Email</td>
            <td className="text-center" colSpan="2">{solicitor.email}</td>
          </tr>
          <tr>
            <td className="w-1/4">Solicitor Eircode</td>
            <td className="text-center" colSpan="2">{solicitor.address_post_code}</td>
          </tr>
        </Fragment>
      }
    </Fragment>
  )
})