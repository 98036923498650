import React from 'react'
import Tooltip from '../../Tooltip'

export default function AprcCalculatorList({
  calculateLtv,
  calculateMaxDsr,
  calculateMaxIm,
  calculateMaxLtv,
  calculateMaxMortgage,
  calculateMaxNdi,
  dirty,
  isValid,
  resetForm,
  values,
}) {
  const { house_value } = values

  return (
    <div className="bg-alabaster border border-timber-green-o-16 p-3 rounded-lg sm:px-14 sm:py-6">
      <div className="flex justify-between mb-8">
        <div className="flex items-center">
          <p className="font-bold mr-4 text-2xl">Result</p>
          <Tooltip tooltip="The maximum mortgage limit is indicative only and may vary based on verification of financial details and specific credit assessments. The limit is also based on an CBI macroprudential rules; a higher loan limit may be offered if the application qualifies for an exception" />
        </div>

        <div>
          <button
            className="btn btn--primary"
            onClick={resetForm}
            type="button"
          >
            Reset
          </button>
        </div>
      </div>

      <div className="flex mb-8 -mx-4">
        <div className="px-4 w-1/4">
          <p className="calculator-table-title calculator-table-title--large">€ {(!dirty || !isValid) ? '-' : calculateMaxMortgage(values)?.toLocaleString('en', { minimumFractionDigits: 0 })}</p>
          <p className="calculator-table-text">Max Mortgage to Offer</p>
        </div>

        <div className="px-4 w-1/4">
          {house_value && (
            <div className="mb-4">
              <p className="calculator-table-title calculator-table-title--large">{(!dirty || !isValid) ? '-' : calculateLtv(values)}%</p>
              <p className="calculator-table-text">LTV</p>
            </div>
          )}
        </div>
      </div>

      <div className="flex -mx-4">
        <div className="px-4 w-1/4">
          <div className="mb-4">
            <p className="calculator-table-title">€ {(!dirty || !isValid) ? '-' : calculateMaxIm(values).toLocaleString('en', { minimumFractionDigits: 0 })}</p>
            <p className="calculator-table-text">Max LTI Allowance</p>
          </div>
        </div>

        <div className="px-4 w-1/4">
          <div className="mb-4">
            <p className="calculator-table-title">€ {(!dirty || !isValid) ? '-' : calculateMaxNdi(values)?.toLocaleString('en', { minimumFractionDigits: 0 })}</p>
            <p className="calculator-table-text">Max NDI Allowance</p>
          </div>
        </div>

        <div className="px-4 w-1/4">
          <div className="mb-4">
            <p className="calculator-table-title">€ {(!dirty || !isValid) ? '-' : calculateMaxDsr(values)?.toLocaleString('en', { minimumFractionDigits: 0 })}</p>
            <p className="calculator-table-text">Max DSR Allowance</p>
          </div>
        </div>

        <div className="px-4 w-1/4">
          {house_value && (
            <div className="mb-4">
              <p className="calculator-table-title">€ {(!dirty || !isValid) ? '-' : calculateMaxLtv(values).toLocaleString('en', { minimumFractionDigits: 0 })}</p>
              <p className="calculator-table-text">Max LTV Allowance</p>
            </div>
          )}
        </div>
      </div>

      {dirty && isValid && calculateMaxMortgage(values) < 100000 && (
        <p className="mt-8 text-center text-punch text-sm">Please note the minimum mortgage amount with MoCo is €100,000</p>
      )}

      {dirty && isValid && calculateMaxMortgage(values) > 1000000 && (
        <p className="mt-8 text-center text-punch text-sm">Please note the maximum mortgage amount with MoCo is €1,000,000</p>
      )}
    </div>
  )
}
