import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup'
import axiosService from '../../utils/AxiosService';
import Input from '../Form/Input';

const forgottenPasswordSchema = Yup.object().shape({
  email: Yup.string().required('Required').email('Invalid email'),
});

export default function BorrowerForgottenPassword() {
  const history = useHistory()
  const [error, setError] = useState({ invalid: false, message: '' })

  const sendForgottenPasswordEmail = borrower => {
    setError({ invalid: false, message: '' })
    axiosService.patch('/api/borrower/passwords/forgotten_password', { borrower })
    .then(({ message }) => setError({ invalid: false, message }))
    .catch(({ data: { error }}) => setError({ invalid: true, message: error }))
  }

  const navigateToLogin = () => history.push('/borrower/login')

  return (
    <div className="bg-registration items-center flex w-full" style={{ height: 'calc(100vh - 62px)'}}>
      <div className="container container--main">
        <div className="container--devise">
          <h2 className="font-bold mb-40 text-2xl">Forgot your password?</h2>
          <p className="mb-10">Please enter the email address you’d like your password reset information sent to!</p>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={forgottenPasswordSchema}
            onSubmit={sendForgottenPasswordEmail}
          >
            {({ errors, touched, handleBlur, handleChange, values }) => (
              <Form>
                <div className="form-item-container">
                  <Input error={errors.email && touched.email && errors.email} label="Email" autoFocus={true} name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                </div>

                { error.message &&
                  <div className="flex justify-center mb-6">
                    <p className={`${error.invalid ? "error-text" : "success-text"}`}>{error.message}</p>
                  </div>
                }
                <button type="submit" className="submit btn btn--primary text-left w-full">Request reset link</button>
              </Form>
            )}
          </Formik>
          <button className="btn bg-white w-full text-green-500" onClick={navigateToLogin}>Back to login</button>
        </div>
      </div>
    </div>
  )
}
