import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../contexts/ApplicationContext'
import axiosService from '../../utils/AxiosService'

export default observer(function ApplicationStepUpdater({ step }) {
  const { applicationState: { application, backend_url_part }} = useContext(ApplicationContext)
  const { application_id } = useParams()

  useEffect(() => {
    return function cleanup() {
      if(!application.steps[step] && application.id) {
        axiosService.patch(`/api/${backend_url_part}/applications/${application_id}`, { application: { steps: {...application.steps, [step]: true } } })
        .then(() => application.steps[step] = true)
      }
    }
  }, [application.id])

  return (
    <div>

    </div>
  )
})