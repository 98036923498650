import { observer } from 'mobx-react-lite'
import React, {useEffect, useState} from 'react'
import Modal from '../Modal'
import axiosService from '../../utils/AxiosService'
import {useParams} from "react-router-dom";
import { toastError, toastSuccess } from '../Form/Toasts'

export default observer(function BorrowerSendCodeModal({ setOpen, values }) {
    const { token } = useParams()
    const [staticText, setStaticText] = useState('');
    const [scrolledToBottom, setScrolledToBottom] = useState(false);

    const handleScroll = e => {
        const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 30;
        setScrolledToBottom(bottom);
    }

    const getTwoFactorAuthenticationCode = ({ phone }) => () => {
        axiosService.post(`/api/borrower/passwords/${token}/set_two_factor_authentication_code`, { phone })
            .then(({ message }) => toastSuccess(message, { autoClose: 4000 }))
            .catch(({ data: { error }}) => toastError(error, { autoClose: 4000 }))
    }

    useEffect(() => {
        axiosService.get('/api/static_texts/austrian_bank_secrecy')
            .then(response => {
                setStaticText(response.text);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);

    return (
        <Modal>
            <div className="flex justify-right pt-30">
                <button
                    className="p-1 ml-auto border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}>
                  <span className="absolute mr-4 right-0 text-black h-6 w-6 text-lg block outline-none focus:outline-none">
                    <i className="fa fa-times" />
                  </span>
                </button>
            </div>
            <div className="mb-6 px-16">
                <div className="mb-4 overflow-auto text-center" onScroll={handleScroll}>
                    <p dangerouslySetInnerHTML={{ __html: staticText }} className="max-h-80" />
                </div>
            </div>
            <div className="pb-30 px-50">
                <div className="flex justify-between -mx-4">
                    <div className="px-4 w-1/2">
                        <button
                            className="btn btn--primary w-full"
                            type="button"
                            onClick={() => setOpen(false)}
                        >
                            <i className="fa fa-arrow-left text-white mr-3" />
                            Go back
                        </button>
                    </div>

                    <div className="flex items-center px-4 w-1/2">
                        <button type="button" className="btn btn--primary w-full"
                                onClick={() => {
                                    getTwoFactorAuthenticationCode(values)();
                                    setOpen(false);
                                }}
                                disabled={!scrolledToBottom}>
                            Accept
                            <i className="fa fa-arrow-right text-white ml-3" />
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
})
