import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import LoanRequirementsStep1 from './Step1'
import LoanRequirementsStep2 from './Step2'

export default function LoanRequirements() {
  const { applicationState: { type } } = useContext(ApplicationContext)

  return (
    <>
      <Switch>
        <Route path={`/${type}/applications/:application_id/loan-requirements/step1`}>
          <LoanRequirementsStep1 />
        </Route>
        <Route path={`/${type}/applications/:application_id/loan-requirements/step2`}>
          <LoanRequirementsStep2 />
        </Route>
      </Switch>
    </>
  )
}