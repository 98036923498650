import { Formik } from 'formik'
import React, { Fragment, useContext, useEffect, useRef } from 'react'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { solicitorSchema } from '../../../schemas/SolicitorSchema'
import Input from '../../Form/Input'
import PhoneField from '../../Form/PhoneField'
import { observer } from 'mobx-react-lite'
import axiosService from '../../../utils/AxiosService'
import { useParams } from 'react-router-dom'
import ApplicationStepUpdater from '../../Form/ApplicationStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import ReactSelect from '../../Form/ReactSelect'
import { notifyProgressSaved } from '../../Form/Notifications'
import LoqateWrapper from '../../Form/LoqateWrapper'

export default observer(function SolicitorPage() {
  const { applicationState } = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id } = useParams()
  const formikRef = useRef()

  const save = solicitor => e => {
    e?.preventDefault()
    touchFields()

    //if user doesn't change default phone number, don't save it
    if (solicitor.telephone === '+353') solicitor.telephone = ''

    axiosService.patch(`/api/${applicationState.backend_url_part}/applications/${application_id}`, { application: { solicitor_attributes: solicitor, steps: {...applicationState.application.steps, solicitor_visited: true } }})
    .then(() => {
      notifyProgressSaved()
      //if user doesn't change default phone number, change back empty string to default one after saving
      if (solicitor.telephone === '') solicitor.telephone = '+353'
      applicationState.solicitor = solicitor
      applicationState.application.steps.solicitor_visited = true
    })
  }

  const touchFields = () => formikRef.current.setTouched(Object.keys(applicationState.solicitor).reduce((result, key) => { result[key] = true; return result },{}))

  useEffect(() => {
    if(applicationState.application.steps.solicitor_visited) touchFields()
    applicationErrorsContext.validateSolicitor()
  }, [applicationState.loading, applicationState.solicitor])

  return (
    <div>
      <h2 className="form-title mb-40">Solicitor</h2>
      {!applicationState.disabled && <ApplicationStepUpdater step="solicitor_visited" />}

      <Formik
        initialValues={applicationState.solicitor}
        validationSchema={solicitorSchema}
        enableReinitialize={applicationState.loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue, setValues }) => (
          <form onSubmit={save(values)}>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <YesNoBtnGroup
                    error={errors.do_you_have_a_solicitor && touched.do_you_have_a_solicitor && errors.do_you_have_a_solicitor}
                    label="Have you appointed a solicitor for this transaction?"
                    name="do_you_have_a_solicitor"
                    onChange={(name, value) => {
                      setValues({
                        [name]: value,
                        name_of_firm: '',
                        solicitor_acting: '',
                        telephone: '+353',
                        email: '',
                        address_post_code: '',
                        address_line1: '',
                        address_line2: '',
                        address_line3: '',
                        address_city: '',
                        address_county: '',
                        address_country: 'IRL',
                      }, true)
                    }}
                    value={values.do_you_have_a_solicitor}
                    disabled={applicationState.disabled}
                  />
                </div>
                { values.do_you_have_a_solicitor &&
                  <Fragment>
                    <div className="mb-40">
                      <Input error={errors.name_of_firm && touched.name_of_firm && errors.name_of_firm} disabled={applicationState.disabled} label="Name of Firm" type="text" name="name_of_firm" autoFocus={true} onChange={handleChange} onBlur={handleBlur} value={values.name_of_firm} />
                    </div>
                    <div className="mb-40">
                      <Input error={errors.solicitor_acting && touched.solicitor_acting && errors.solicitor_acting} label="Solicitor acting" type="text" name="solicitor_acting" disabled={applicationState.disabled} onChange={handleChange} onBlur={handleBlur} value={values.solicitor_acting} />
                    </div>
                    <div className="mb-40">
                      <PhoneField error={errors.telephone && touched.telephone && errors.telephone} disabled={applicationState.disabled} label="Telephone" name="telephone" onChange={phone => setFieldValue('telephone', phone, true)} onBlur={handleBlur} value={values.telephone} />
                    </div>
                    <div className="mb-40">
                      <Input error={errors.email && touched.email && errors.email} disabled={applicationState.disabled} label="Email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                    </div>

                  </Fragment>
                }
              </div>

              <div className="px-12 w-1/2">
                { values.do_you_have_a_solicitor &&
                  <Fragment>
                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('address_post_code', address.PostalCode, true)
                          setFieldValue('address_line1', address.Line1, true)
                          setFieldValue('address_line2', address.Line2, true)
                          setFieldValue('address_line3', address.Line3, true)
                          setFieldValue('address_city', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('address_county', 'Dublin', true)
                            : setFieldValue('address_county', address.Province, true)
                          setFieldValue('address_country', address.CountryIso3, true)
                        }}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.address_post_code && touched.address_post_code && errors.address_post_code}
                        disabled={applicationState.disabled}
                        label="Eircode"
                        type="text"
                        name="address_post_code"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('address_county', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.address_post_code}
                      />
                    </div>

                    <div className="mb-40">
                      <Input error={errors.address_line1 && touched.address_line1 && errors.address_line1} disabled={applicationState.disabled} label="Address Line 1" type="text" name="address_line1" onChange={handleChange} onBlur={handleBlur} value={values.address_line1} />
                    </div>

                    <div className="mb-40">
                      <Input error={errors.address_line2 && touched.address_line2 && errors.address_line2} disabled={applicationState.disabled} label="Address Line 2" optional type="text" name="address_line2" onChange={handleChange} onBlur={handleBlur} value={values.address_line2} />
                    </div>

                    <div className="mb-40">
                      <Input error={errors.address_line3 && touched.address_line3 && errors.address_line3} disabled={applicationState.disabled} label="Address Line 3" optional type="text" name="address_line3" onChange={handleChange} onBlur={handleBlur} value={values.address_line3} />
                    </div>

                    <div className="mb-40">
                      <Input error={errors.address_city && touched.address_city && errors.address_city} disabled={applicationState.disabled} label="City" type="text" name="address_city" onChange={handleChange} onBlur={handleBlur} value={values.address_city} />
                    </div>

                    <div className="mb-40">
                      <Input error={errors.address_county && touched.address_county && errors.address_county} disabled={applicationState.disabled} label="County" type="text" name="address_county" onChange={handleChange} onBlur={handleBlur} value={values.address_county} />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        error={touched.address_country && errors.address_country}
                        label="Country"
                        name="address_country"
                        placeholder="Select country"
                        onChange={address_country => setFieldValue('address_country', address_country.value, true)}
                        value={values.address_country}
                        disabled={applicationState.disabled}
                      />
                    </div>
                  </Fragment>
                }
              </div>
            </div>

            <div className="flex justify-between mt-16">
              {!applicationState.disabled && <button className="btn btn--secondary" type="submit">Save Progress</button>}
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
})