import * as Yup from 'yup'

const propertyIsMortgaged = () => {
  return [
    'is_property_mortgaged',
    {
      is: true,
      then: Yup.number('This field must contain numbers.').typeError('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.')
    }
  ]
}

export const PropertySchema = Yup.object().shape({
  properties: Yup.array()
    .of(
      Yup.object().shape({
        post_code: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
        address_line1: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
        address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
        address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
        city: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
        county: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
        country: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
        house_hold_property_type: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
        monthly_rental: Yup.number('This field must contain numbers.').typeError('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
        estimated_current_value: Yup.number('This field must contain numbers.').typeError('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
        is_property_mortgaged: Yup.boolean().required('This field is required.'),
        existing_lender_name: Yup.string().when(
          'is_property_mortgaged',
          {
            is: true,
            then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
          }
        ),
        outstanding_mortgage_amount: Yup.mixed().when(...propertyIsMortgaged()),
        interest_rate: Yup.mixed().when(
          'is_property_mortgaged',
          {
            is: true,
            then: Yup.number('This field must contain numbers.').typeError('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').lessThan(100, 'This field must be less than 100.').required('This field is required.')
          }),
        monthly_repayment: Yup.mixed().when(...propertyIsMortgaged()),
        other_parties_switch: Yup.boolean().required('This field is required.'),
        other_ownership_name: Yup.string().when(
          'other_parties_switch',
          {
            is: true,
            then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
          }
        ),
        other_ownership_percentage: Yup.mixed().when(
          'other_parties_switch',
          {
            is: true,
            then: Yup.number().typeError('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.')
          }
        ),
      })
    )
});
