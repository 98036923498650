import React from 'react'
import Slider from 'react-slick'

export default function AboutusCarousel() {
  const settings = {
    arrows: true,
    centerMode: true,
    centerPadding: '30%',
    dots: false,
    infinite: true,
    pauseOnHover: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
        }
      },
    ]
  }

  return (
    <Slider {...settings}>
      <div className="slick-item-wrapper px-6 lg:px-2 py-10">
        <div className="bg-white box-shadow p-8 rounded-lg w-full">
          <h3 className="font-bold mb-2 text-2xl text-turquoise">Time for something new</h3>

          <p>
            The traditional mortgage process is confusing, opaque and stressful, characteristed by mountains of paperwork, rigid products, delays, frustration and jargon (we could go on). We don't think it needs to be this way.
          </p>
        </div>
      </div>

      <div className="slick-item-wrapper px-6 lg:px-2 py-10">
        <div className="bg-white box-shadow p-8 rounded-lg w-full">
          <h3 className="font-bold mb-2 text-2xl text-turquoise">Our approach </h3>

          <p>
            MoCo is a retail credit firm regulated by the Central Bank of Ireland. We source mortgage funding from NIBC Bank N.V, one of the leading mortgage lenders in the Netherlands. Once MoCo has approved your mortgage application and your loan has drawn down, the Pepper Ireland team will work with MoCo from that point to manage your mortgage and answer any queries you may have on our behalf. Pepper already provides this service to thousands of Irish borrowers. We work closely with Pepper to ensure the MoCo ethos is maintained throughout all our customer engagements.
          </p>
        </div>
      </div>

      <div className="slick-item-wrapper px-6 lg:px-2 py-10">
        <div className="bg-white box-shadow p-8 rounded-lg w-full">
          <h3 className="font-bold mb-2 text-2xl text-turquoise">Shifting the status quo</h3>

          <p>
            Our goal is clear: make the complicated mortgage process simpler by combining innovative technology with a passionate team. To achieve this, we've brought together the best innovations in design, technology and customer service from local and foreign mortgage markets.
          </p>
        </div>
      </div>
    </Slider>
  )
}
