import { makeAutoObservable } from "mobx"

export default class Income {
  constructor(income={}) {
    this.business_type = income.business_type || null
    this.name_of_business = income.name_of_business || ''
    this.nature_of_business = income.nature_of_business || null
    this.percentage_of_shareholding = income.percentage_of_shareholding || 0
    this.date_established = income.date_established || ''
    this.self_employed_date = income.self_employed_date || ''
    this.current_year_end = income.current_year_end || ''
    this.registered_post_code = income.registered_post_code || ''
    this.registered_address_line1 = income.registered_address_line1 || ''
    this.registered_address_line2 = income.registered_address_line2 || ''
    this.registered_address_line3 = income.registered_address_line3 || ''
    this.registered_city = income.registered_city || ''
    this.registered_county = income.registered_county || ''
    this.registered_country = income.registered_country || 'IRL'
    this.self_employed_company_number = income.self_employed_company_number || ''
    this.is_business_address_different = !!income.is_business_address_different
    this.business_post_code = income.business_post_code || ''
    this.business_address_line1 = income.business_address_line1 || ''
    this.business_address_line2 = income.business_address_line2 || ''
    this.business_address_line3 = income.business_address_line3 || ''
    this.business_city = income.business_city || ''
    this.business_county = income.business_county || ''
    this.business_country = income.business_country || 'IRL'
    this.do_you_have_accountant = !!income.do_you_have_accountant
    this.accountants_firm = income.accountants_firm || ''
    this.accountant_email = income.accountant_email || ''
    this.accountants_telephone_number = income.accountants_telephone_number || '+353'
    this.accountant_qualification = income.accountant_qualification || ''
    this.accountant_qualification_other = income.accountant_qualification_other || ''
    this.contact_name = income.contact_name || ''
    this.accountants_post_code = income.accountants_post_code || ''
    this.accountants_address_line1 = income.accountants_address_line1 || ''
    this.accountants_address_line2 = income.accountants_address_line2 || ''
    this.accountants_address_line3 = income.accountants_address_line3 || ''
    this.accountants_city = income.accountants_city || ''
    this.accountants_county = income.accountants_county || ''
    this.accountants_country = income.accountants_country || 'IRL'
    this.net_assets1 = income.net_assets1 || 0
    this.dividend1 = income.dividend1 || 0
    this.salary1 = income.salary1 || 0
    this.year1 = income.year1 || 0
    this.limited_company_salary1 = income.limited_company_salary1 || 0
    this.net_assets2 = income.net_assets2 || 0
    this.dividend2 = income.dividend2 || 0
    this.salary2 = income.salary2 || 0
    this.year2 = income.year2 || 0
    this.limited_company_salary2 = income.limited_company_salary2 || 0
    this.net_assets3 = income.net_assets3 || 0
    this.dividend3 = income.dividend3 || 0
    this.salary3 = income.salary3 || 0
    this.year3 = income.year3 || 0
    this.limited_company_salary3 = income.limited_company_salary3 || 0
    this.date_owned_or_part_owned = income.date_owned_or_part_owned || ''

    this.turnover = income.turnover || 0
    this.turnover2 = income.turnover2 || 0
    this.net_profit = income.net_profit || 0
    this.net_profit2 = income.net_profit2 || 0
    this.deprecation = income.deprecation || 0
    this.deprecation2 = income.deprecation2 || 0
    this.interest_paid = income.interest_paid || 0
    this.interest_paid2 = income.interest_paid2 || 0
    this.director_remuneration = income.director_remuneration || 0
    this.director_remuneration2 = income.director_remuneration2 || 0
    this.trading_profit = income.trading_profit || 0
    this.trading_profit2 = income.trading_profit2 || 0
    this.annual_loan_repayments = income.annual_loan_repayments || 0
    this.annual_loan_repayments2 = income.annual_loan_repayments2 || 0
    makeAutoObservable(this)
  }
}