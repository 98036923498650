import { ExcelFormulas } from '../../calculations'

const calculateStartingRepayment = (currentFixedTerm, i, mortgageAmount, totalPrinciple) => i - 1 === currentFixedTerm * 12 ? -(mortgageAmount - totalPrinciple) : 0

const calculateAdjustedPrincipal = (tempOpeningBalance, tempPrincipal) => {
  if (tempOpeningBalance < 0) return 0
  return tempPrincipal > tempOpeningBalance ? tempOpeningBalance : tempPrincipal
}

const calculateAdjustgedInterest = (tempInterest, tempOpeningBalance, tempPrincipal) => calculateAdjustedPrincipal(tempOpeningBalance, tempPrincipal) > 0 ? tempInterest : 0

export const calculateAprc = (
  fixedRate,
  variableRate,
  currentFixedTerm,
  mortgageTerm,
  mortgageAmount,
) => {
  if (!mortgageTerm) return

  let totalPrinciple = 0
  let totalAdjustedPrinciple = 0
  let maxStartingRepayment = 0

  const balanceArray = new Array((mortgageTerm * 12) + 1).fill().map((e, i) => {
    maxStartingRepayment+= calculateStartingRepayment(currentFixedTerm, i, mortgageAmount, totalPrinciple)

    const rate = i - 1 < currentFixedTerm * 12 ? fixedRate / 100 / 12 : variableRate / 100 / 12
    const per = i - 1 < currentFixedTerm * 12 ? i : i - (currentFixedTerm * 12)
    const nper = i - 1 < currentFixedTerm * 12 ? mortgageTerm * 12 : (mortgageTerm - currentFixedTerm ) * 12
    const pv = i - 1 < currentFixedTerm * 12 ? -mortgageAmount : maxStartingRepayment

    const tempPrincipal = ExcelFormulas.PPMT(rate, per, nper, pv)
    const tempInterest = ExcelFormulas.IPMT(pv, ExcelFormulas.PMT(rate, nper, pv), rate, per - 1)
    totalPrinciple+= tempPrincipal
    const tempClosingBalance = mortgageAmount - totalPrinciple
    const tempOpeningBalance = mortgageAmount - totalPrinciple + tempPrincipal

    totalAdjustedPrinciple+= calculateAdjustedPrincipal(tempOpeningBalance, tempPrincipal)
    const tempAdjustedClosingBalance = mortgageAmount - Math.round(totalAdjustedPrinciple)
    const tempAdjustedOpeningBalance = mortgageAmount - Math.round(totalAdjustedPrinciple) + calculateAdjustedPrincipal(tempOpeningBalance, tempPrincipal)

    return {
      adjustedClosingBalance: tempAdjustedClosingBalance < 0 ? 0 : tempAdjustedClosingBalance,
      adjustedOpeningBalance: tempAdjustedOpeningBalance < 0 ? 0 : tempAdjustedOpeningBalance,
      adjustgedInterest: calculateAdjustgedInterest(tempInterest, tempOpeningBalance, tempPrincipal),
      adjustgedPrincipal: calculateAdjustedPrincipal(tempOpeningBalance, tempPrincipal),
      closingBalance: tempClosingBalance,
      id: i,
      interest: tempInterest,
      netCashFlow: calculateAdjustedPrincipal(tempOpeningBalance, tempPrincipal) + calculateAdjustgedInterest(tempInterest, tempOpeningBalance, tempPrincipal),
      openingBalance: tempOpeningBalance,
      principal: tempPrincipal,
      total: tempPrincipal + tempInterest,
    }
  });

  const xirrArrayDate = new Array((mortgageTerm * 12) + 1).fill().map((e,i) => {
    const calculatedDate = new Date(new Date().setMonth(new Date().getMonth() + i))

    return new Date(calculatedDate.getFullYear(), calculatedDate.getMonth() + 1, 0)
  });

  const xirrArrayValue = new Array((mortgageTerm * 12) + 1).fill().map((e,i) => {
    const valuationCost = 185
    const securityFee = 95

    return i === 0 ? - mortgageAmount + valuationCost - securityFee : parseFloat(balanceArray[i].netCashFlow.toFixed(2))
  });

  return (ExcelFormulas.XIRR(xirrArrayValue, xirrArrayDate) * 100).toFixed(2)
};