import * as Yup from 'yup'

const numberOfDependantsIsHigherThan = number => {
  return [
    'number_of_dependants',
    {
      is: number_of_dependants => +number_of_dependants > number,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
    }
  ]
}

export const PersonalDetailsStep3Schema = Yup.object().shape({
  first_time_buyer: Yup.boolean().required('This field is required.'),
  retirement_age: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  relation_with_applicant1: Yup.string().when(
    'current_applicant_number',
    {
      is: 2,
      then: Yup.string().required('This field is required.')
    }
  ),
  relation_description_with_applicant1: Yup.string().when(
    ['current_applicant_number', 'relation_with_applicant1'],
    {
      is: (current_applicant_number, relation_with_applicant1) => current_applicant_number === 2 && relation_with_applicant1 === '2',
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
  relation_with_applicant2: Yup.string().when(
    'current_applicant_number',
    {
      is: 1,
      then: Yup.string().required('This field is required.')
    }
  ),
  relation_description_with_applicant2: Yup.string().when(
    ['current_applicant_number', 'relation_with_applicant2'],
    {
      is: (current_applicant_number, relation_with_applicant2) => current_applicant_number === 1 && relation_with_applicant2 === '2',
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
  number_of_dependants: Yup.number('This field must contain numbers.').required('This field is required.'),
  dependant1_age: Yup.string().when(...numberOfDependantsIsHigherThan(0)),
  dependant2_age: Yup.string().when(...numberOfDependantsIsHigherThan(1)),
  dependant3_age: Yup.string().when(...numberOfDependantsIsHigherThan(2)),
  dependant4_age: Yup.string().when(...numberOfDependantsIsHigherThan(3)),
  dependant5_age: Yup.string().when(...numberOfDependantsIsHigherThan(4)),
  dependant6_age: Yup.string().when(...numberOfDependantsIsHigherThan(5)),
  dependant7_age: Yup.string().when(...numberOfDependantsIsHigherThan(6)),
  dependant8_age: Yup.string().when(...numberOfDependantsIsHigherThan(7)),
  dependant9_age: Yup.string().when(...numberOfDependantsIsHigherThan(8)),
  dependant10_age: Yup.string().when(...numberOfDependantsIsHigherThan(9)),
});