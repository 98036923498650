import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'

export default observer(function SidebarStatusIcon({ applicant, errors }) {

  const errorsCount = useMemo(() => {
    return errors.reduce((result, error) => {
      if(error.visited) result += error.errors
      return result
    }, 0)
  }, [errors, errors.length])

  const allVisited = useMemo(() => errors.reduce((result, error) => result && error.visited, true))

  return (
    <>
      { !allVisited && errorsCount === 0 && <span></span>
        || errorsCount !== 0 && <span className={`data-capture-sidebar-icon ${applicant ? 'data-capture-sidebar-icon--applicant' : null } data-capture-sidebar-icon--error`}></span>
        || <span className={`data-capture-sidebar-icon ${applicant ? 'data-capture-sidebar-icon--applicant' : null }  data-capture-sidebar-icon--success`}>
          <i className="fa fa-check" />
        </span>
      }
    </>
  )
})