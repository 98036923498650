import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useMemo } from 'react'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import CurrencyTextField from '../CurrencyTextField'
import TitleRow from '../TitleRow'

export default observer(function MainPropertyDetailsPart() {
  const { applicationState: { security } } = useContext(ApplicationContext)

  const propertyTenureOptions = [
    { label: 'Freehold', value: 'FH' },
    { label: 'Leasehold', value: 'LH' },
  ]

  const propertyTypeOptions = [
    { label: 'Apartment', value: 'PURBFA' },
    { label: 'Bungalow', value: 'DTBLW' },
    { label: 'Detached', value: 'DTDH' },
    { label: 'Semi-Detached', value: 'SEMDTDH' },
    { label: 'Terraced', value: 'TCDH' },
  ]

  const propertyType = useMemo(() => propertyTypeOptions.find(option => option.value === security.property_type)?.label, [security.property_type])
  const propertyTenure = useMemo(() => propertyTenureOptions.find(option => option.value === security.property_tenure)?.label, [security.property_tenure])

  return (
    <Fragment>
      <TitleRow label="Main Property Details" />
      <tr>
        <td className="w-1/4">Specific Property in mind</td>
        <td className="text-center" colSpan="2">{security.specific_property_in_mind ? 'Yes' : 'No'}</td>
      </tr>
      { security.specific_property_in_mind &&
        <Fragment>
          <tr>
            <td className="w-1/4">Property Eircode</td>
            <td className="text-center" colSpan="2">{security.property_address_post_code}</td>
          </tr>
          <tr>

            <td className="w-1/4">Property Type</td>
            <td className="text-center" colSpan="2">{propertyType}</td>
          </tr>
          <tr>
            <td className="w-1/4">BER Rating</td>
            <td className="text-center" colSpan="2">{security.energy_performance_certificate}</td>
          </tr>
          <tr>
            <td className="w-1/4">Property over commercial unit</td>
            <td className="text-center" colSpan="2">{security.around_commercial_premises ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td className="w-1/4">Property Tenure</td>
            <td className="text-center" colSpan="2">{propertyTenure}</td>
          </tr>
          { security.property_tenure === 'LH' &&
            <Fragment>
              <tr>
                <td className="w-1/4">Service Charge</td>
                <td className="text-center" colSpan="2"><CurrencyTextField value={security.service_charge}/></td>
              </tr>
              <tr>
                <td className="w-1/4">Ground Rent</td>
                <td className="text-center" colSpan="2"><CurrencyTextField value={security.ground_rent}/></td>
              </tr>
              <tr>
                <td className="w-1/4">Remaining Lease in years</td>
                <td className="text-center" colSpan="2">{security.unexpired_remaining_lease}</td>
              </tr>
            </Fragment>
          }
        </Fragment>
      }
    </Fragment>
  )
})