import React from 'react'
import Tooltip from './Tooltip'

export default function Divider({
  text,
  tooltip,
}) {
  return (
    <div className="flex items-center mb-40">
      {text && <p className="mr-6">{text}</p>}

      <div className="bg-timber-green-o-16 flex-auto mr-6" style={{ height: 2 }} />

      {tooltip && <Tooltip tooltip={tooltip} />}
    </div>
  )
}
