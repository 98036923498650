import React, { useContext, useMemo } from 'react'
import iconGeneralQuestionsGray from 'images/icons/general-questions-gray.svg'
import { observer } from 'mobx-react-lite'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'

export default observer(function GeneralQuestionsIcon({ applicant }) {
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const errorClass = useMemo(() => {
    if(!applicant.steps.general_questions_visited) return 'bg-white'
    if(applicantErrors[applicant.id]?.generalQuestionsErrors) return 'bg-red-500'
    return 'bg-green-500'
  }, [applicant, applicantErrors[applicant.id]?.generalQuestionsErrors])

  return (
    <div className={`applicant-image ${errorClass}`}>
      <img alt="General Questions" src={iconGeneralQuestionsGray} />
    </div>
  )
})