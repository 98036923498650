import * as Yup from 'yup'

export const EmploymentStep2EmployedSchema = Yup.object().shape({
  address_post_code: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_line1: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  address_city: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_county: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_country: Yup.string().required('This field is required.'),
});