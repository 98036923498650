import React from 'react'
import ReactSelect from './ReactSelect'

export const nationalityOptions = [
  { label:  'Afghan', value: '1' },
  { label: 'Albanian', value: '108' },
  { label: 'Algerian', value: '13' },
  { label: 'American', value: '40' },
  { label: 'Angolan', value: '14' },
  { label: 'Antiguans', value: '109' },
  { label: 'Argentine', value: '15' },
  { label: 'Argentinean', value: '110' },
  { label: 'Armenian', value: '111' },
  { label: 'Australian', value: '2' },
  { label: 'Austrian', value: '16' },
  { label: 'Azerbaijani', value: '112' },
  { label: 'Bahamian', value: '113' },
  { label: 'Bangladeshi', value: '17' },
  { label: 'Barbadian', value: '114' },
  { label: 'Barbudans', value: '115' },
  { label: 'Batswana', value: '116' },
  { label: 'Belarusian', value: '18' },
  { label: 'Belgian', value: '19' },
  { label: 'Belizean', value: '120' },
  { label: 'Beninese', value: '121' },
  { label: 'Bhutanese', value: '122' },
  { label: 'Bolivian', value: '20' },
  { label: 'Bosnian', value: '123' },
  { label: 'Bosnian/Herzegovinian', value: '21' },
  { label: 'Brazilian', value: '3' },
  { label: 'British', value: 'GBR' },
  { label: 'Bruneian', value: '124' },
  { label: 'Bulgarian', value: '22' },
  { label: 'Burkinabe', value: '126' },
  { label: 'Burmese', value: '127' },
  { label: 'Burundian', value: '128' },
  { label: 'Cambodian', value: '23' },
  { label: 'Cameroonian', value: '24' },
  { label: 'Canadian', value: '4' },
  { label: 'Central African', value: '25' },
  { label: 'Chadian', value: '26' },
  { label: 'Chinese', value: '27' },
  { label: 'Colombian', value: '29' },
  { label: 'Congolese', value: '33' },
  { label: 'Costa Rican', value: '30' },
  { label: 'Croatian', value: '31' },
  { label: 'Czech', value: '32' },
  { label: 'Danish', value: '5' },
  { label: 'Dutch', value: '39' },
  { label: 'Ecuadorian', value: '34' },
  { label: 'Egyptian', value: '6' },
  { label: 'Emirati', value: '101' },
  { label: 'Estonian', value: '36' },
  { label: 'Ethiopian', value: '37' },
  { label: 'Finnish', value: '133' },
  { label: 'French', value: '7' },
  { label: 'German', value: '8' },
  { label: 'Ghanaian', value: '134' },
  { label: 'Greek', value: '38' },
  { label: 'Guatemalan', value: '131' },
  { label: 'Honduran', value: '132' },
  { label: 'Hungarian', value: '41' },
  { label: 'Icelandic', value: '42' },
  { label: 'Indian', value: '9' },
  { label: 'Indonesian', value: '44' },
  { label: 'Iranian', value: '45' },
  { label: 'Iraqi', value: '46' },
  { label: 'Irish', value: '47' },
  { label: 'Israeli', value: '48' },
  { label: 'Italian', value: '49' },
  { label: 'Ivorian', value: '50' },
  { label: 'Jamaican', value: '51' },
  { label: 'Japanese', value: '10' },
  { label: 'Jordanian', value: '53' },
  { label: 'Kazakh', value: '54' },
  { label: 'Kenyan', value: '55' },
  { label: 'Lao', value: '56' },
  { label: 'Latvian', value: '57' },
  { label: 'Libyan', value: '58' },
  { label: 'Lithuanian', value: '59' },
  { label: 'Luxembourgish', value: 'Luxembourgish' },
  { label: 'Malagasy', value: '60' },
  { label: 'Malaysian', value: '61' },
  { label: 'Malian', value: '62' },
  { label: 'Mauritanian', value: '63' },
  { label: 'Mexican', value: '64' },
  { label: 'Moroccan', value: '65' },
  { label: 'Namibian', value: '66' },
  { label: 'New Zealand', value: '67' },
  { label: 'Nicaraguan', value: '68' },
  { label: 'Nigerian', value: '70' },
  { label: 'Nigerien', value: '69' },
  { label: 'Norwegian', value: '71' },
  { label: 'Omani', value: '72' },
  { label: 'Pakistani', value: '73' },
  { label: 'Panamanian', value: '74' },
  { label: 'Paraguayan', value: '75' },
  { label: 'Peruvian', value: '76' },
  { label: 'Philippine', value: '77' },
  { label: 'Polish', value: '78' },
  { label: 'Portuguese', value: '11' },
  { label: 'Romanian', value: '81' },
  { label: 'Russian', value: '82' },
  { label: 'Salvadoran', value: '35' },
  { label: 'Saudi, Saudi Arabian', value: '83' },
  { label: 'Senegalese', value: '85' },
  { label: 'Serbian', value: '86' },
  { label: 'Singaporean', value: '87' },
  { label: 'Slovak', value: '88' },
  { label: 'Somalian', value: '89' },
  { label: 'South African', value: '12' },
  { label: 'South Korean', value: 'South_Korean' },
  { label: 'Spanish', value: '91' },
  { label: 'Sudanese', value: '92' },
  { label: 'Swedish', value: '93' },
  { label: 'Swiss', value: '94' },
  { label: 'Syrian', value: '95' },
  { label: 'Thai', value: '96' },
  { label: 'Tunisian', value: '97' },
  { label: 'Turkish', value: '98' },
  { label: 'Turkmen', value: '99' },
  { label: 'Ukranian', value: '100' },
  { label: 'Uruguayan', value: '103' },
  { label: 'Venezuelan', value: '129' },
  { label: 'Vietnamese', value: '104' },
  { label: 'Yemenite', value: '130' },
  { label: 'Zambian', value: '106' },
  { label: 'Zimbabwean', value:  '107' }
]

export default function NationalitySelector({ disabled, error, nationality, handleBlur, handleChange, label = 'Nationality', name = 'nationality', placeholder = 'Select a Nationality' }) {

  return (
    <ReactSelect
      error={error}
      label={label}
      name={name}
      placeholder={placeholder}
      options={nationalityOptions}
      onChange={handleChange}
      onBlur={handleBlur}
      value={nationality}
      disabled={disabled}
    />
  )
}