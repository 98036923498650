import React, { useRef, useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Input from '../../Form/Input'
import { toastError } from '../../Form/Toasts'
import Broker2fa, { onLoginSuccess } from './components/Broker2fa'
import _ from 'lodash'
import RootStoreContext from '../../../contexts/RootStoreContext'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .email('Invalid email'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

/**
 * Broker Login component, when user/broker uses 
 * correct email and password, it then shows a 2FA view.
 * 
 * Notices/messages/alerts can be shown via the 'n' param,
 * see the supported values in the `useEffect` below.
 */
export default function BrokerLogin() {
  const [isLoading, setIsLoading] = useState(false)
  const [show2FAScreen, setShow2FAScreen] = useState(false)
  const [notice, setNotice] = useState(undefined)
  const brokerDetails = useRef()
  const rootStore = useContext(RootStoreContext)
  const history = useHistory()

  // This is also used by the 2FA component
  const signInAndSend2FaCode = async (email, password) => {
    return await axiosService.post(
      '/api/apprivo_broker/sessions/sign_in', 
      { apprivo_broker: { email, password  } }
    )
  }

  const submit = async (formData) => {
    setIsLoading(true) // Set loading to prevent double submits and two 2FA codes being generated

    try {
      const response = await signInAndSend2FaCode(formData.email, formData.password)
      // If skipping 2FA (eg automated test on staging), 
      // then session already created, so we can just move on with our lives
      // See doc/qa-automated-testing.md
      if (response.skip_2fa) {
        onLoginSuccess(rootStore, history)
        return
      }

      // Show 2FA Step
      brokerDetails.current = { 
        brokerId: response.id, // This is the broker id
        email: formData.email,
        password: formData.password,
      }
      setShow2FAScreen(true)
      setIsLoading(false)
    }
    catch (errorResponse) {
      setIsLoading(false)

      // If no data in response (eg '' or undefined), then probably a expired 
      // CSRF token or something, which will be reset in the header, so give generic error message
      if (_.isEmpty(errorResponse.data) || _.isEmpty(errorResponse.data.error)) {
        toastError('An error occurred, please try again')
      }
      // Otherwise set error message
      else {
        toastError(errorResponse.data.error) 
      }
    }
  }

  /**
   * On mount, checks if has 'n' ("notification") param,
   * and if value is 'ltc' ("Login to continue") or 'to' ("Timed out"), then
   * shows a notice with that message, then removes param from URL
   */
   useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const notificationParam = urlParams.get('n')
    if (notificationParam === 'ltc' || notificationParam === 'to') {
      // Remove params from URL incase user refreshes
      window.history.replaceState(null, null, window.location.pathname)
      // Show notice with appropriate message
      setNotice(
        notificationParam === 'to' ? 'Session expired, login to continue' : 'Login to continue', 
      )
    }
  }, []) 

  if (show2FAScreen) {
    return (
      <Broker2fa
        brokerId={brokerDetails.current.brokerId}
        email={brokerDetails.current.email}
        password={brokerDetails.current.password}
        signInAndSend2FaCode={signInAndSend2FaCode}
      />
    )
  }

  return (
    <div className="bg-registration items-center flex w-full" style={{ height: 'calc(100vh - 62px)'}}>
      <div className="container container--main">
        <div className="container--devise">
          {/* Notice */}
          {notice && (
            <div className="p-3 rounded-lg bg-blue-500 mb-4">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <p className="ml-3 font-medium text-white">
                    <span>{notice}</span>
                  </p>
                </div>
              </div>
            </div>
          )}

          <h2 className="font-bold mb-40 text-2xl">Login to Your Account</h2>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={loginSchema}
            onSubmit={submit}
            disabled={isLoading}
          >
            {({ errors, touched, handleBlur, handleChange, values }) => (
              <Form>
                <div className="form-item-container">
                  <Input error={errors.email && touched.email && errors.email} label="Email" autoFocus={true} name="email" onChange={handleChange} onBlur={handleBlur} autoComplete="off" value={values.email} />
                </div>
                <div className="form-item-container">
                  <Input error={errors.password && touched.password && errors.password} label="Password" type="password" name="password" onChange={handleChange} onBlur={handleBlur} autoComplete="off" value={values.password} />
                </div>

                {/* Submit Button - Looading: https://flowbite.com/docs/components/spinner/#buttons */}
                {!isLoading && (
                  <button type="submit" className="submit btn btn--primary text-left w-full">Login to Your Account</button>
                )}
                {isLoading && (
                  <button disabled type="button" className="submit btn btn--primary w-full px-5 py-2.5 text-left mr-2 items-center justify-between" style={{ display: 'flex' }}>
                    Loading...
                    <svg role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                    </svg>
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}