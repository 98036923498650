import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'
import moment from 'moment'
import { ApplicationContext } from '../../../contexts/ApplicationContext'

export default observer(function DocumentViewer({ currentDocument, currentApplicant }) {
  const { application_id, applicant_id } = useParams()
  const { applicationState: { disabled, backend_url_part } } = useContext(ApplicationContext)

  const deleteDocument = () => {
    axiosService.delete(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}/documents/${currentDocument.id}`)
    .then(() => {
      const index = currentApplicant.documents.findIndex(document => document.id === currentDocument.id)
      currentApplicant.documents.splice(index, 1)
    })
  }

  return (
    <div className="flex items-center mb-3">
      <div className="w-3/4">
        <p>{currentDocument.file_name}</p>
        <p className="text-timber-green-o-40">
          <span className="font-bold">Uploaded: </span>
          {moment(currentDocument.updated_at).format('DD/MM/YYYY hh:mm:ss A')}
        </p>
      </div>
      <div className="flex-auto text-right">
        <a className="text-turquoise text-xl" href={`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}/documents/${currentDocument.id}`} target="_blank" rel="noopener noreferrer">
          <i className="fa fa-eye" />
        </a>
        { !disabled && !currentDocument.from_api &&
          <button className="ml-6 text-red-500 text-xl" onClick={deleteDocument}>
            <i className="fa fa-trash" />
          </button>
        }
      </div>
    </div>
  )
})
