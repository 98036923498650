import React, { useContext, useEffect, useMemo, useRef } from 'react'
import Input from '../../Form/Input'
import ReactSelect from '../../Form/ReactSelect'
import { Formik, FieldArray } from 'formik'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import CommitmentsActions from './CommitmentsActions'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { CommitmentsStep1Schema } from '../../../schemas/CommitmentsSchema/CommitmentsStep1Schema'
import axiosService from '../../../utils/AxiosService'
import Applicant from '../../../models/Applicant'
import LoanCommitment from '../../../models/LoanCommitment'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'

export default observer(function CommitmentsStep1() {
  const { applicationState: { applicants, loading, backend_url_part, disabled }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicant_id, applicants, applicants.length])

  const loanTypeOptions = [
    { label: 'Secured', value: '1' },
    { label: 'Unsecured', value: '2' },
  ]

  const fields = ["loan_type", "remaining_term", "outstanding_balance", "loan_paid_on_or_before", "monthly_payment", "lender_name", "mortgage_account_number", "purpose"]

  const save = loan_commitments => e => {
    e?.preventDefault()
    touchFields(loan_commitments)

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { loan_commitments } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.loan_commitments = loan_commitments
    })
  }

  const touchFields = loan_commitments => {
    formikRef.current.setTouched(loan_commitments.reduce((result, _, index) => {
      result.loan_commitments[index] ||= {}
      fields.forEach(field => result.loan_commitments[index][field] = true)
      return result
    },{ loan_commitments: [] }))
  }

  useEffect(() => {
    if(currentApplicant.steps.commitments_step1_visited) touchFields(currentApplicant.loan_commitments)
    applicationErrorsContext.validateCommitmentsStep1(currentApplicant.loan_commitments, applicant_id)
  }, [loading, currentApplicant.loan_commitments])

  return (
    <div>
      <p>Please provide details of any borrowings you are named on <b>excluding mortgages</b>. Please include all loans, car finance, car leasing, point of sale etc. including any lending you are named on as a guarantor.</p>
      <br/>
      <p className="mb-40">If borrowings are held jointly held with other applicants, please only enter once under one of the applicants associated with that commitment.</p>

      { !disabled && <ApplicantStepUpdater step="commitments_step1_visited" /> }

      <Formik
        initialValues={{ loan_commitments: currentApplicant.loan_commitments }}
        validationSchema={CommitmentsStep1Schema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values.loan_commitments)}>
            <FieldArray name="loan_commitments">
              {({ insert, remove, push }) => (
                <div>
                  <div className="flex flex-wrap -mx-12">
                    <div className="px-12 w-1/2">
                      <div className="btn-group-container flex items-center justify-between mb-40">
                        <button
                          className="btn btn--group btn--primary w-2/3"
                          onClick={() => push(new LoanCommitment())}
                          type="button"
                          disabled={disabled}
                        >
                          Add Loan
                          <i className="far fa-plus ml-10" />
                        </button>

                        {values.loan_commitments.length > 0 && <span className="pr-8">{values.loan_commitments.length}</span>}
                      </div>
                    </div>
                  </div>

                  { values.loan_commitments.map((loan_commitment, index) => (
                    <div key={index}>
                      <div className="flex items-center mb-40">
                        <p className="mr-6">
                          <i className="fa fa-chevron-down mr-2" />
                          Loan {index + 1} Details
                        </p>

                        <div className="bg-timber-green-o-16 flex-auto mr-6" style={{ height: 2 }} />

                        <button
                          className="bg-punch circle fs-9 text-white"
                          onClick={() => remove(index)}
                          style={{ height: 20, width: 20 }}
                          type="button"
                          disabled={disabled}
                        >
                          <i className="fa fa-trash-alt" />
                        </button>
                      </div>

                      <div className="flex flex-wrap -mx-12">
                        <div className="px-12 w-1/2">
                          <div className="mb-40">
                            <ReactSelect
                              error={errors?.loan_commitments?.[index]?.loan_type && touched?.loan_commitments?.[index]?.loan_type && errors?.loan_commitments?.[index]?.loan_type}
                              label="Loan type"
                              name={`loan_commitments.${index}.loan_type`}
                              options={loanTypeOptions}
                              placeholder="Select a Loan type"
                              onChange={value => setFieldValue(`loan_commitments.${index}.loan_type`, (value?.value || ''), true)}
                              onBlur={handleBlur}
                              value={loan_commitment.loan_type}
                              disabled={disabled}
                              tooltip="A secured loan is a loan secured against an assest such a car or land"
                            />
                          </div>

                          <div className="mb-40">
                            <Input
                              error={errors?.loan_commitments?.[index]?.remaining_term && touched?.loan_commitments?.[index]?.remaining_term && errors?.loan_commitments?.[index]?.remaining_term}
                              label="Remaining terms (months)"
                              name={`loan_commitments.${index}.remaining_term`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={loan_commitment.remaining_term}
                              disabled={disabled}
                            />
                          </div>

                          <div className="mb-40">
                            <CurrencyField
                              addon="€"
                              addonPosition="start"
                              error={errors?.loan_commitments?.[index]?.outstanding_balance && touched?.loan_commitments?.[index]?.outstanding_balance && errors?.loan_commitments?.[index]?.outstanding_balance}
                              label="Outstanding balance"
                              name={`loan_commitments.${index}.outstanding_balance`}
                              setFieldValue={setFieldValue}
                              onBlur={handleBlur}
                              value={loan_commitment.outstanding_balance}
                              disabled={disabled}
                            />
                          </div>

                          <div className="mb-40">
                            <YesNoBtnGroup
                              tooltip="Will the balance be repaid on or before commencement of this new mortgage?"
                              error={errors?.loan_commitments?.[index]?.loan_paid_on_or_before && touched?.loan_commitments?.[index]?.loan_paid_on_or_before && errors?.loan_commitments?.[index]?.loan_paid_on_or_before}
                              label="To be repaid prior to drawdown?"
                              name={`loan_commitments.${index}.loan_paid_on_or_before`}
                              onChange={(name, value) => setFieldValue(name, value, true)}
                              value={loan_commitment.loan_paid_on_or_before}
                              disabled={disabled}
                            />
                          </div>

                          <div className="mb-40">
                            <CurrencyField
                              addon="€"
                              addonPosition="start"
                              error={errors?.loan_commitments?.[index]?.monthly_payment && touched?.loan_commitments?.[index]?.monthly_payment && errors?.loan_commitments?.[index]?.monthly_payment}
                              label="Monthly payment"
                              name={`loan_commitments.${index}.monthly_payment`}
                              setFieldValue={setFieldValue}
                              onBlur={handleBlur}
                              tooltip="If making weekly repayments, multiply your repayment by 52 and divide by 12. If making fortnightly repayments, multiply your repayments by 26 and divide by 12."
                              value={loan_commitment.monthly_payment}
                              disabled={disabled}
                            />
                          </div>
                          {/*
                          <div className="mb-40">
                            <YesNoBtnGroup
                              label="Shared responsibility for this loan?"
                              name="[0].do_have_shared_responsibility"
                              tooltip="Do other applicants have shared responsibility
                              for this loan"
                            />
                          </div>
                          */}
                        </div>

                        <div className="px-12 w-1/2">

                          <div className="mb-40">
                            <Input
                              error={errors?.loan_commitments?.[index]?.lender_name && touched?.loan_commitments?.[index]?.lender_name && errors?.loan_commitments?.[index]?.lender_name}
                              label="Lender name"
                              name={`loan_commitments.${index}.lender_name`}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={loan_commitment.lender_name}
                              disabled={disabled}
                            />
                          </div>

                          <div className="mb-40">
                            <Input
                              error={errors?.loan_commitments?.[index]?.mortgage_account_number && touched?.loan_commitments?.[index]?.mortgage_account_number && errors?.loan_commitments?.[index]?.mortgage_account_number}
                              label="Last 3 digits of account number"
                              name={`loan_commitments.${index}.mortgage_account_number`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={loan_commitment.mortgage_account_number}
                              disabled={disabled}
                            />
                          </div>

                          <div className="mb-40">
                            <Input
                              error={errors?.loan_commitments?.[index]?.purpose && touched?.loan_commitments?.[index]?.purpose && errors?.loan_commitments?.[index]?.purpose}
                              label="Loan Purpose"
                              name={`loan_commitments.${index}.purpose`}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={loan_commitment.purpose}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>

            <CommitmentsActions save={save(values.loan_commitments)} />
          </form>
        )}
      </Formik>
    </div>
  )
})