import React from 'react'
import EmploymentSelectorPart from './EmploymentSelectorPart'

export default function OtherPart({ applicant }) {

  return (
    <div>
      <div className="flex flex-wrap -mx-12">
        <div className="px-12 w-1/2">
          <EmploymentSelectorPart applicant={applicant} />
        </div>
      </div>
    </div>
  )

}