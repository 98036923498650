import * as Yup from 'yup'

const youHaveASolicitor = () => {
  return [
    'do_you_have_a_solicitor',
    {
      is: true,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ]

}

export const solicitorSchema = Yup.object().shape({
  do_you_have_a_solicitor: Yup.boolean(),
  name_of_firm: Yup.string().when(...youHaveASolicitor()),
  solicitor_acting: Yup.string().when(...youHaveASolicitor()),
  telephone: Yup.string().when(...youHaveASolicitor()),
  email: Yup.string().when('do_you_have_a_solicitor', {
    is: true,
    then: Yup.string().required('This field is required.').email('Enter a valid email address.')
  }),
  address_post_code: Yup.string().when(...youHaveASolicitor()),
  address_line1: Yup.string().when(...youHaveASolicitor()),
  address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  address_city: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  address_county: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  address_country: Yup.string().when(...youHaveASolicitor()),
});