import React from 'react'
import BtnGroup from './BtnGroup'

export default function YesNoBtnGroup(props) {
  const yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  return (
    <div className={props.size === 'small' ? 'btn-group--small mb-6' : 'mb-40'}>
      <BtnGroup
        {...props}
        options={props.options ? props.options : yesNoOptions}
      />
  </div>
  )
}