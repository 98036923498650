import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { observer, useLocalObservable } from 'mobx-react-lite'

export default observer (function NextStepButton({
  className,
  nextUrl,
  save = () => {},
  steps,
  text = 'Next step',
  url,
  disabled = false
}) {
  let history = useHistory()
  const location = useLocation()

  const saveAndNavigateToNextStep = () => {
    const saveResponse = save()
    if(saveResponse?.then) return saveResponse.then(navigate)
    navigate()
  }

  const navigate = () => {
    if(steps) {
      const currentStep = +/step(\d+)/.exec(location.pathname)?.[1]
      currentStep == steps ? history.push(nextUrl) : history.push(`${url}/step${currentStep + 1}`)
    } else {
      history.push(nextUrl)
    }
  }

  return (
    <button className={`btn btn--primary ${className} ${disabled && 'opacity-50'}`} onClick={saveAndNavigateToNextStep} type="button" disabled={disabled}>{text}</button>
  )
})
