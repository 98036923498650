import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import Employment from '../../../../models/Employment'
import Income from '../../../../models/Income'
import ReactSelect from '../../../Form/ReactSelect'
import EmploymentTypeChangeModal from './EmploymentTypeChangeModal'

export default observer(function EmploymentSelector({ applicant, values, errors, touched, handleBlur, disabled }) {

  const { applicationState } = useContext(ApplicationContext)

  const employmentOptions = [
    { label: 'Employed', value: 'Employed' },
    { label: 'Self Employed', value: 'Self Employed' },
    { label: 'Retired', value: 'Retired' },
    { label: 'Not Employed', value: 'Not Employed' },
    // { label: 'None of the above', value: 'None of the above' },
  ]

  const modalState = useLocalObservable(() => ({ show: false, next_are_you: '', are_you: '' }))

  const confirm = () => {
    applicationState.loading = true
    applicant.employment = new Employment({ are_you: modalState.next_are_you })
    applicant.income = new Income()
    applicant.steps = {...applicant.steps, employment_step1_visited: false, employment_step2_visited: false, employment_step3_visited: false, employment_step4_visited: false }
    modalState.show = false
    modalState.next_are_you = ''
    modalState.are_you = ''
    setTimeout(() => applicationState.loading = false, 1000)
  }

  return (
    <div className="mb-40">
      <ReactSelect
        error={errors.are_you && touched.are_you && errors.are_you}
        name="are_you"
        label="Employment Status"
        options={employmentOptions}
        placeholder="Select Employment Status"
        onChange={value => {
          if(applicant.employment.are_you !== 'Employed' && applicant.employment.are_you !== 'Self Employed') {
            modalState.next_are_you = value?.value
            confirm()
          } else {
            modalState.are_you = applicant.employment.are_you
            modalState.next_are_you = value?.value
            modalState.show = true
          }
        }}
        onBlur={handleBlur}
        value={values.are_you}
        disabled={disabled}
      />
      <EmploymentTypeChangeModal modalState={modalState} confirm={confirm} />
    </div>
  )
})