import React from 'react'

import iconFast from 'images/landing/ftb-fast.svg'

export default function MortgageFeaturesBox1() {
  return (
    <div className="landing-feature-column">
      <div className="landing-feature-box">
        <div className="landing-feature-icon-container">
          <img alt="Fast & Flexible" className="landing-feature-icon" src={iconFast} />
        </div>

        <h3 className="landing-feature-title">Quick Decision</h3>

        <p>Get a decision on your mortgage application in days, not weeks</p>
      </div>
    </div>
  )
}
