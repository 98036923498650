import * as Yup from 'yup'

export const applicantHasOtherIncomeAmount = n => (
  [
    `do_you_have_income_${n}`,
    {
      is: true,
      then: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required')
    }
  ]
)

export const applicantHasOtherIncomeFrequency = n => (
  [
    `do_you_have_income_${n}`,
    {
      is: true,
      then: Yup.mixed().required('This field is required')
    }
  ]
)