import React, { useContext, useMemo } from 'react'
import iconEmploymentGray from 'images/icons/employment-gray.svg'
import { observer } from 'mobx-react-lite'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import moment from 'moment'

export default observer(function EmploymentIcon({ applicant }) {
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const errorClass = useMemo(() => {
    let validateOnly3step = false
    if(applicant.employment.are_you === 'Employed') {
      const jobStartDate = applicant.employment.employment_type === '2' ? applicant.employment.date_joined : applicant.employment.date_contract_started
      if(moment(jobStartDate) <= moment().subtract(3, 'years')) {
        validateOnly3step = true
      }
    }

    if(applicant.steps.employment_step1_visited && ['Retired', 'Not Employed', 'None of the above'].includes(applicant.employment.are_you)) return 'bg-green-500'
    if  (applicant.steps.employment_step1_visited && !applicantErrors[applicant.id]?.employmentStep1Errors
      && applicant.steps.employment_step2_visited && !applicantErrors[applicant.id]?.employmentStep2Errors
      && applicant.steps.employment_step3_visited && !applicantErrors[applicant.id]?.employmentStep3Errors
      && (validateOnly3step || applicant.steps.employment_step4_visited && !applicantErrors[applicant.id]?.employmentStep4Errors)) {
        return 'bg-green-500'
      }
    if  ((!applicant.steps.employment_step1_visited || !applicantErrors[applicant.id]?.employmentStep1Errors)
      && (!applicant.steps.employment_step2_visited || !applicantErrors[applicant.id]?.employmentStep2Errors)
      && (!applicant.steps.employment_step3_visited || !applicantErrors[applicant.id]?.employmentStep3Errors)
      && (validateOnly3step || !applicant.steps.employment_step4_visited || !applicantErrors[applicant.id]?.employmentStep4Errors))
    {
      return 'bg-white'
    } else {
      return 'bg-red-500'
    }
  }, [applicant, applicantErrors[applicant.id]?.employmentStep1Errors, applicantErrors[applicant.id]?.employmentStep2Errors, applicantErrors[applicant.id]?.employmentStep3Errors, applicantErrors[applicant.id]?.employmentStep4Errors])

  return (
    <div className={`applicant-image ${errorClass}`}>
      <img alt="Employment" src={iconEmploymentGray} />
    </div>
  )
})