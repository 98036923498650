import React from 'react'

import iconCashback from 'images/landing/ftb-cashback.svg'

export default function MortgageFeaturesBox3() {
  return (
    <div className="landing-feature-column">
      <div className="landing-feature-box">
        <div className="landing-feature-icon-container">
          <img alt="Cashback" className="landing-feature-icon" src={iconCashback} />
        </div>

        <h3 className="landing-feature-title">Cashback</h3>

        <p>Choose from our cash back options to help towards the cost of obtaining your mortgage</p>
      </div>
    </div>
  )
}
