import React from 'react'
import ReactSelect from './ReactSelect'

export default function BusinessSectorSelector({ disabled, label = 'Business Sector', error, name = 'business_sector', placeholder = 'Select Business Sector', value, onChange }) {

  const businessSectorOptions = [
    { label: 'Agriculture, Forestry and Fishing', value: '1' },
    { label: 'Mining and Quarrying', value: '2' },
    { label: 'Manufacturing', value: '3' },
    { label: 'Electricity, Gas, Steam and Air Conditioning Supply', value: '4' },
    { label: 'Water Supply; Sewerage, Waste Management and Remediation Activities', value: '5' },
    { label: 'Construction', value: '6' },
    { label: 'Wholesale and Retail Trade', value: '7' },
    { label: 'Repair of Motor Vehicles and Motorcycles', value: '24' },
    { label: 'Transportation and Storage', value: '8' },
    { label: 'Accommodation and Food Service Activities', value: '9' },
    { label: 'Information and Communication', value: '10' },
    { label: 'Financial and Insurance Activities', value: '11' },
    { label: 'Real Estate Activities', value: '12' },
    { label: 'Professional, Scientific and Technical Activities', value: '13' },
    { label: 'Administrative and Support Service Activities', value: '14' },
    { label: 'Public Administration and Defence', value: '15' },
    { label: 'Compulsory Social Security', value: '23' },
    { label: 'Education', value: '16' },
    { label: 'Human Health and Social Work Activities', value: '17' },
    { label: 'Arts, Entertainment and Recreation', value: '18' },
    { label: 'Other Service Activities', value: '19' },
    { label: 'Activities of Households as Employers', value: '20' },
    { label: 'Undifferentiated Goods and Services Producing Activities of Households for Own Use', value: '22' },
    { label: 'Activities of Extraterritorial Organisations and Bodies', value: '21' },
  ]

  return (
    <ReactSelect
      error={error}
      label={label}
      name={name}
      options={businessSectorOptions}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  )

}