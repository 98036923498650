import React from 'react'
import { Route, Switch } from 'react-router-dom'

import IdVerification from './IdVerification'
import DocumentType from './DocumentType'
import Capture from './Capture'
import Summary from './Summary'
import Status from './Status'
import { observer, useLocalObservable } from 'mobx-react-lite'
import ApplicationProvider from '../../../contexts/ApplicationContext'

export default observer (function IdentityVerificationMobile() {
  return (
    <ApplicationProvider>
      <div className="container--form py-16">
        <Switch>
          <Route path='/borrower/applications/:application_id/applicants/:id_verification_token/identity-verification-mobile/id-verification'>
            <IdVerification />
          </Route>
          <Route path='/borrower/applications/:application_id/applicants/:id_verification_token/identity-verification-mobile/document-type'>
            <DocumentType />
          </Route>
          <Route path='/borrower/applications/:application_id/applicants/:id_verification_token/identity-verification-mobile/capture'>
            <Capture />
          </Route>
          <Route path='/borrower/applications/:application_id/applicants/:id_verification_token/identity-verification-mobile/document-summary'>
            <Summary />
          </Route>
          <Route path='/borrower/applications/:application_id/applicants/:id_verification_token/identity-verification-mobile/status'>
            <Status />
          </Route>
        </Switch>
      </div>
    </ApplicationProvider>
  )
})
