import { observer } from 'mobx-react-lite'
import React, { Fragment, useMemo, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import DocumentViewer from './DocumentViewer'
import axiosService from '../../../utils/AxiosService'
import iconInProgress from 'images/icons/id-verification-in-progress.svg'
import DocumentUploadSection from './DocumentUploadSection'

export default observer(function ProofOfIdSection({ type, backendUrlPart, currentApplicant, disabled }) {
  const { application_id, applicant_id } = useParams()
  let history = useHistory()

  const proofOfIdDocuments = useMemo(() => {
    return currentApplicant.documents.filter(applicantDocument => applicantDocument.document_type === 'Proof of ID')
  }, [currentApplicant, currentApplicant?.documents, currentApplicant?.documents?.length])

  const navigateToIdVerification = () => {
    history.push(`/${backendUrlPart}/applications/${application_id}/applicants/${applicant_id}/identity-verification/step1`)
  }

  const [state, setState] = useState({
    identityVerificationState: currentApplicant.identity_verification_backend.status,
    identityVerificationConsent: currentApplicant.identity_verification_consent
  })

  const getIdentityVerificationStatus = () => {
    axiosService.get('/api/borrower/identity_verification_status/' + application_id + '/' + applicant_id)
    .then(response => {
      setState({
        identityVerificationState: response.status.status,
        identityVerificationConsent: response.consent
      })
      currentApplicant.identity_verification_backend = response.status
      currentApplicant.identity_verification_consent = response.consent
    })
    .catch(error => console.log(error))
  }

  useEffect(() => {
    if(type === 'borrower') {
      getIdentityVerificationStatus()
    }
    return () => {}
  }, [])

  return (
    <>
      { type === 'borrower' && state.identityVerificationConsent !== false &&
        <div className="document-box">
          <div className="w-1/3 border-r border-timber-green-o-16 flex items-center pr-8 py-3">
            <div className="w-5/6">
              <p className="font-bold">Proof of ID</p>
            </div>
          </div>

          {(function() {
            if (state.identityVerificationConsent && state.identityVerificationState == 'success_pending') {
              return <div className="pl-8 py-3 w-2/3"><div className="flex items-center"><img alt="" src={iconInProgress} /><p className="ml-4 pr-4">Identity Verification is under review</p></div></div>
            }
            else if (state.identityVerificationConsent && state.identityVerificationState == 'success') {
              return (
                <div className="pl-8 py-3 w-2/3">
                  { proofOfIdDocuments.map(document => (
                      <DocumentViewer key={document.id} currentDocument={document} currentApplicant={currentApplicant} />
                    ))
                  }
                  <div className="flex items-center text-turquoise">
                    <i className="far fa-check-circle text-4xl" />
                    <p className="ml-4 pr-4">Identity Verification successfully approved!</p>
                  </div>
                </div>
              )
            }
            else if (state.identityVerificationConsent){
              return <div className="pl-8 py-3 w-2/3"><p className="mb-4 pr-4 w-2/3">Identity Verification has not yet been validated</p><div><button className="btn btn--primary" type="button" onClick={navigateToIdVerification} disabled={disabled || type === 'broker'}>Start ID verification</button></div></div>
            }
            else if (state.identityVerificationConsent === null){
              return <div className="pl-8 py-3 w-2/3"><p className="mb-4 pr-4 w-2/3">Identity Verification has not yet been validated</p><div><button className="btn btn--primary" type="button" onClick={navigateToIdVerification} disabled={disabled || type === 'broker'}>Go to ID verification</button></div></div>
            }
          })()}
        </div>
      }

      {type === 'broker' &&
        <Fragment>
          <DocumentUploadSection
            currentApplicant={currentApplicant}
            documentType="Proof of ID"
            documentTypeName="Proof of ID"
            tooltip="There is no requirement to upload proof of ID if the borrower has completed identity verification process on the broker platform. If the borrower has not consented to completing identity verification on the broker platform, please manually verify the borrower's identity and upload photo of ID used to verify."
          />
        </Fragment>
      }
    </>
  )
})
