const autoCaptureHints = {
  MISNAP_HEAD_OUTSIDE: 'Place Face in Oval',
  MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
  MISNAP_AXIS_ANGLE: 'Hold Phone Upright',
  MISNAP_HEAD_TOO_CLOSE: 'Move Farther Away',
  MISNAP_HEAD_TOO_FAR: 'Get Closer',
  MISNAP_STAY_STILL: 'Hold Still',
  MISNAP_SUCCESS: 'Success',
  MISNAP_STOP_SMILING: 'Stop Smiling',
  MISNAP_SMILE: 'Smile!',
  MISNAP_READY_POSE: 'Hold it There',
  NO_FACE_FOUND: 'Face not found',
  MITEK_ERROR_GLARE: 'Reduce Glare',
  MITEK_ERROR_FOUR_CORNER: 'Document Not Found',
  MITEK_ERROR_TOO_DARK: 'Too Dark. Use good lighting',
  MITEK_ERROR_FOCUS: 'Hold Steady',
  MITEK_ERROR_MRZ_MISSING: 'Can\'t read every element on the photo page of your passport',
  CV_NO_BARCODE_FOUND: 'Scanning for barcode',
  MITEK_ERROR_TOO_FAR: 'Document Too Far',
  MITEK_ERROR_TOO_CLOSE: 'Document Too Close',
  MITEK_ERROR_NOT_CENTERED: 'Document Not Centered',
  MITEK_ERROR_MIN_PADDING:  'Move further away',
  MITEK_ERROR_HORIZONTAL_FILL: 'Move closer',
  MITEK_ERROR_LOW_CONTRAST: 'Center document on a dark background',
  MITEK_ERROR_BUSY_BACKGROUND: 'Center document on a plain background',
  MITEK_ERROR_SKEW_ANGLE: 'Reduce angle'
}

module.exports = autoCaptureHints
