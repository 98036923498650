import React, { useContext } from 'react'
import OtherIncomeStep1 from './Step1'
import OtherIncomeStep2 from './Step2'
import OtherIncomeStep3 from './Step3'
import { Route, Switch } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'

export default function OtherIncome() {
  const { applicationState: { type } } = useContext(ApplicationContext)

  return (
    <Switch>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/other-income/step1`}>
        <OtherIncomeStep1 />
      </Route>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/other-income/step2`}>
        <OtherIncomeStep2 />
      </Route>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/other-income/step3`}>
        <OtherIncomeStep3 />
      </Route>
    </Switch>
  )
}
