import * as Yup from 'yup'
import { applicantHasOtherIncomeAmount, applicantHasOtherIncomeFrequency } from './OtherIncomeHelpers';

export const OtherIncomeStep1Schema = Yup.object().shape({
  amount1: Yup.mixed().when(...applicantHasOtherIncomeAmount(1)),
  pay_frequency1: Yup.mixed().nullable().when(...applicantHasOtherIncomeFrequency(1)),
  guaranteed1: Yup.boolean(),
  amount2: Yup.mixed().when(...applicantHasOtherIncomeAmount(2)),
  pay_frequency2: Yup.mixed().nullable().when(...applicantHasOtherIncomeFrequency(2)),
  guaranteed2: Yup.boolean(),
});