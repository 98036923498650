import { observer } from "mobx-react-lite";
import React, { Fragment } from 'react'
import axiosService from "../../utils/AxiosService";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import Textarea from "../Form/Textarea";

export default observer(function SendEmailToTheBrokerModal({ setOpen, action }) {
  const { application_id } = useParams()

  const sendEmailToTheBroker = message => {
    axiosService.post(`/api/borrower/applications/${application_id}/application_submit_to_broker_notification_email`, message)
    .then(action)
  }

  return (
    <Fragment>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative my-6 w-2/3">
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <div className="flex justify-right pt-30 w-1/2">
              <button
                className="p-1 ml-auto border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setOpen(false)}
              >
                <span className="absolute mr-4 right-0 text-black h-6 w-6 text-lg block outline-none focus:outline-none">
                  <i className="fa fa-times" />
                </span>
              </button>
            </div>
            <Formik
              initialValues={{ message: '' }}
              onSubmit={sendEmailToTheBroker}
            >
              {({ handleBlur, handleChange, values }) => (
                <Form>
                  <div className="mb-6 px-16 text-center">
                    <div className="form-item-container">
                      <Textarea className="h-56" label="Message to Broker" autoFocus={true} name="message" onChange={handleChange} onBlur={handleBlur} value={values.message} />
                    </div>
                  </div>
                  <div className="pb-30 px-50">
                    <div className="flex justify-between -mx-4">
                      <div className="px-4 w-1/2">
                        <button
                          className="btn btn--secondary w-full"
                          type="button"
                          onClick={() => setOpen(false)}
                        >
                          Back
                        </button>
                      </div>

                      <div className="flex items-center px-4 w-1/2">
                        <button
                          className="w-full btn btn--primary"
                          type="submit"
                        >
                          Submit to broker
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-timber-green-o-40" />
    </Fragment>
  );
})