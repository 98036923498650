import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import moment from 'moment'

export default observer(function DocumentViewer({ documentTypeName, documentType, applicant }) {

  const documents = useMemo(() => {
    return applicant.documents.filter(document => document.document_type === documentType)
  }, [applicant, applicant?.documents, applicant?.documents?.length])

  return (
    <div className="document-box">
      <div className="w-1/3 border-r border-timber-green-o-16 flex items-center pr-8 py-3">
        <div className="w-5/6">
          <p className="font-bold">{documentTypeName}</p>
        </div>
      </div>
      <div className="pl-8 py-3 w-2/3">
        { documents.map(document => (
          <div key={document.id} className="flex items-center">
            <div className="w-3/4">
              <p>{document.file_name}</p>
              <p className="text-timber-green-o-40">
                <span className="font-bold">Uploaded: </span>
                {moment(document.updated_at).format('DD/MM/YYYY hh:mm:ss A')}
              </p>
            </div>
            <div className="flex-auto text-right">
              <a className="text-turquoise text-xl" href={document.url} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-eye" />
              </a>
            </div>
          </div>
          ))
        }
      </div>
    </div>
  )
})