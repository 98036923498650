import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import Input from '../../Form/Input'
import NextStepButton from '../../Form/NextStepButton'
import ReactSelect from '../../Form/ReactSelect'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import axiosService from '../../../utils/AxiosService'
import { observer } from 'mobx-react-lite'
import { Formik } from 'formik'
import { productSchema } from '../../../schemas/ProductSchema'

export default observer(function Product() {
  const applicationContext = useContext(ApplicationContext)
  const { application_id } = useParams()

  const productOptions = [
    { label: 'product1', value: 'product1' },
    { label: 'product2', value: 'product2' },
  ]

  const save = (setTouched, product) => (e) => {
    e.preventDefault()
    setTouched(Object.keys(applicationContext.applicationState.application.product).reduce((result, key) => { result[key] = true; return result },{}))

    axiosService.patch(`/api/borrower/applications/${application_id}`, { application: { product }})
    .then(application => {
      console.log(application)
      applicationContext.applicationState.application.product = product
    })
  }

  return (
    <div>
      <h2 className="form-title mb-40">Product</h2>

      <Formik
        initialValues={applicationContext.applicationState.application.product}
        validationSchema={productSchema}
        enableReinitialize={true}
      >
        {({ errors, touched, handleBlur, handleChange, values, setTouched, setFieldValue }) => (
          <form noValidate onSubmit={save(setTouched, values)}>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    error={errors.number_of_years_to_repay && touched.number_of_years_to_repay && errors.number_of_years_to_repay}
                    label="Number of years to repay the loan"
                    max={35}
                    min={10}
                    name="number_of_years_to_repay"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.number_of_years_to_repay}
                  />
                </div>

                <div className="mb-40">
                  (!!!)
                  <ReactSelect
                    label="Please Select Appropriate Product"
                    options={productOptions}
                    placeholder="Select a Product"
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    error={errors.fixed_amount && touched.fixed_amount && errors.fixed_amount}
                    label="Broker fee payable"
                    name="fixed_amount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    tooltip="Enter Broker fee payable by the borrower"
                    value={values.fixed_amount}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-16">
              <button className="btn btn--secondary" type="submit">Save Progress</button>
              <NextStepButton nextUrl={`/borrower/applications/${application_id}/direct-debit`} save={save}/>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
})