import React, { useContext } from 'react'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'

export default function ApplicantRow({ titleClass, name, tdContent = () => {} }) {
  const { applicationState: { applicants }} = useContext(ApplicationContext)

  return (
    <tr>
      <td className={`w-1/4 ${titleClass}`}>{name}</td>
      {applicants.map(applicant => <td key={`${name}-${applicant.id}`} className="text-center">{tdContent(applicant)}</td>)}
    </tr>
  )

}