import { observer } from "mobx-react-lite";
import React, { Fragment } from 'react'
import axiosService from "../../../utils/AxiosService";
import { useParams } from "react-router-dom";

export default observer(function SendEmailToTheBorrowerModal({ setOpen, action }) {
  const { application_id } = useParams()

  const sendEmailToTheBorrower = () => {
    axiosService.post(`/api/apprivo_broker/applications/${application_id}/application_sent_back_notification_email`)
    .then(action)
  }

  return (
    <Fragment>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative my-6 w-2/5">
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <div className="flex justify-right pt-30 w-1/2">
              <button
                className="p-1 ml-auto border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setOpen(false)}
              >
                <span className="absolute mr-4 right-0 text-black h-6 w-6 text-lg block outline-none focus:outline-none">
                  <i className="fa fa-times" />
                </span>
              </button>
            </div>
            <div className="mb-6 px-16 text-center">
              <h3 className="font-bold mb-6">Send application back to the borrower?</h3>
            </div>
            <div className="pb-30 px-50">
              <div className="flex justify-between -mx-4">
                <div className="px-4 w-1/2">
                  <button
                    className="btn btn--secondary w-full"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Back
                  </button>
                </div>

                <div className="flex items-center px-4 w-1/2">
                  <button
                    className="w-full btn btn--primary"
                    onClick={sendEmailToTheBorrower}
                  >
                    Send back to the borrower
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-timber-green-o-40" />
    </Fragment>
  );
})