import React from 'react'
import { calculateAprc } from './AprcCalculation'

import emptyState from 'images/landing/calculator-empty-state.png'

export default function AprcCalculatorList({
  calculateBasicRate,
  calculateFixedRate,
  calculateLtv,
  calculatePmt,
  filteredCashbacks,
  state,
  values,
}) {
  const {
    currentFixedTerm,
    submitted,
  } = state

  if (!submitted) return <img alt="" src={emptyState} />

  const filteredCashbacksByLtv = () => filteredCashbacks
    .filter(item => item.max >= parseFloat(calculateLtv(values)) && item.min <= parseFloat(calculateLtv(values)))
    .sort((a, b) => a.cash_back - b.cash_back)

  return (
    <>
      <div className="overflow-y-auto pr-6 scrollbar-green" style={{ maxHeight: 570 }}>
        {
          submitted
          && filteredCashbacksByLtv()
            .map((item, index) => {
              const fixedRate = (calculateBasicRate(calculateLtv(values), currentFixedTerm, 'fixed_rate_loading', values, parseInt(item.cash_back)) + parseFloat(item.fixed_rate_loading)).toFixed(2)
              const variableRate = (calculateBasicRate(calculateLtv(values), currentFixedTerm, 'roll_off_variable_rate_loading', values, parseInt(item.cash_back)) + parseFloat(item.roll_off_variable_rate_loading)).toFixed(2)

              const isMortgageUnderMinLoan = parseFloat(item.min_loan || 0) >= parseFloat(values.mortgage_amount)

              if (isMortgageUnderMinLoan) {
                if (index === 0) {
                  return (
                    <div className="last-item-no-margin mb-8" key={index}>
                      <div className="bg-alabaster border border-timber-green-o-16 flex flex-wrap justify-between p-3 rounded-lg sm:p-6 text-center">
                        <p className="text-punch text-xs">The minimum mortgage amount is €{parseInt(item.min_loan)}</p>
                      </div>
                    </div>
                  )
                }

                return
              }

              return (
                <div className="last-item-no-margin mb-8" key={index}>
                  <div className="bg-alabaster border border-timber-green-o-16 flex flex-wrap justify-between p-3 rounded-lg sm:p-6 text-center">
                    <div className="mb-6 sm:mb-0 sm:w-auto w-full">
                      <p className="calculator-table-title">{item.term} year</p>
                      <p className="calculator-table-text">Fixed term</p>
                    </div>

                    <div>
                      <p className="calculator-table-title">€ {parseInt(item.cash_back).toLocaleString('en', { minimumFractionDigits: 0 })}</p>
                      <p className="calculator-table-text">Cashback</p>
                    </div>

                    <div>
                      <p className="calculator-table-title">{calculateFixedRate(item.fixed_rate_loading, values, parseInt(item.cash_back))} %</p>
                      <p className="calculator-table-text">Fixed rate</p>
                    </div>

                    <div>
                      <p className="calculator-table-title">
                        {
                          calculateAprc(
                            fixedRate,
                            variableRate,
                            currentFixedTerm,
                            parseInt(values.mortgage_term),
                            values.mortgage_amount,
                          )
                        } %
                      </p>
                      <p className="calculator-table-text">APRC</p>
                    </div>

                    <div>
                      <p className="calculator-table-title">
                        € {calculatePmt(item.fixed_rate_loading, values, parseInt(item.cash_back))}
                      </p>
                      <p className="calculator-table-text">Monthly Payment</p>
                    </div>
                  </div>
                </div>
              )
            })
        }
      </div>
    </>
  )
}
