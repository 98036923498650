import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import iconAssetsWhite from 'images/icons/assets-white.svg'
import iconCommitmentsWhite from 'images/icons/commitments-white.svg'
import iconEmploymentWhite from 'images/icons/employment-white.svg'
import iconGeneralQuestionsWhite from 'images/icons/general-questions-white.svg'
import iconIdentityVerification from 'images/icons/identity-verification.svg'
import iconOtherIncomeWhite from 'images/icons/other-income-white.svg'
import iconPensionIncomeWhite from 'images/icons/pension-income-white.svg'
import iconPersonalDetailsWhite from 'images/icons/personal-details-white.svg'
import iconOpenBanking from 'images/icons/open-banking.svg'
import iconUploadGray from 'images/icons/doc-upload-gray.svg'
import { observer } from 'mobx-react-lite'
import { ApplicationErrorsContext } from '../../contexts/ApplicationErrorsContext'
import SidebarStatusIcon from './SidebarStatusIcon'
import moment from 'moment'
import consumer from "../../channels/consumer"

export default observer(function SidebarDropdown({ navigateTo, applicant, index, selected, open, type = "borrower" }) {
  const [submenuOpen, setSubmenuOpen] = useState()
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)
  const { application_id } = useParams()

  const [identityVerificationBackend, setIdentityVerificationBackend] = useState()
  const [identityVerificationConsent, setIdentityVerificationConsent] = useState()
  const [openBankingDetails, setOpenBankingDetails] = useState()
  const [openBankingConsent, setOpenBankingConsent] = useState()
  const [documents, setDocuments] = useState()

  useEffect(() => {
    setSubmenuOpen(open)
  }, [open])

  useEffect(() => {
    setIdentityVerificationBackend(applicant.identity_verification_backend)
    setIdentityVerificationConsent(applicant.identity_verification_consent)
    setOpenBankingDetails(applicant.open_banking_details)
    setOpenBankingConsent(applicant.open_banking_consent)

    consumer.subscriptions.create({ channel: 'IdVerificationChannel', applicant_id: applicant.id },
      {
        connected() { console.log('connected') },
        disconnected() { console.log('disconnected') },
        received(data) {
          console.log('IdVerificationChannel', data)
          if (data?.message == 'id_verification_success') {
            applicant.identity_verification_backend = data.identity_verification_backend
            applicant.identity_verification_consent = data.identity_verification_consent
            setIdentityVerificationBackend(data.identity_verification_backend)
            setIdentityVerificationConsent(data.identity_verification_consent)
          }
        }
      }
    )

    consumer.subscriptions.create({ channel: 'OpenBankingChannel', applicant_id: applicant.id },
      {
        connected() { console.log('connected') },
        disconnected() { console.log('disconnected') },
        received(data) {
          console.log('OpenBankingChannel', data)
          if (data?.message == 'open_banking_flow_ended_success' || data?.message == 'open_banking_consent_changed') {
            applicant.open_banking_details = data.open_banking_details
            applicant.open_banking_consent = data.open_banking_consent
            setOpenBankingDetails(data.open_banking_details)
            setOpenBankingConsent(data.open_banking_consent)
          }
          if (data?.message == 'documents_changed') {
            applicant.documents = data.documents
            setDocuments(data.documents)
          }
        }
      }
    )

    consumer.subscriptions.create({ channel: 'ObAccountChannel', applicant_id: applicant.id },
      {
        connected() { console.log('ObAccountChannel connected') },
        disconnected() { console.log('ObAccountChannel disconnected') },
        received(data) {
          if (data?.message === 'ob_account_statuses_updated') {
            applicant.open_banking_details.status = 'complete'
            setOpenBankingDetails({ ...openBankingDetails, status: 'complete' })
          }
        }
      }
    )
  }, [])

  const employmentErrors = () => {
    if(applicant.employment.are_you === 'Self Employed') {
      return [
        { errors: applicantErrors[applicant.id]?.employmentStep1Errors, visited: applicant.steps.employment_step1_visited },
        { errors: applicantErrors[applicant.id]?.employmentStep2Errors, visited: applicant.steps.employment_step2_visited },
        { errors: applicantErrors[applicant.id]?.employmentStep3Errors, visited: applicant.steps.employment_step3_visited },
        { errors: applicantErrors[applicant.id]?.employmentStep4Errors, visited: applicant.steps.employment_step4_visited },
      ]
    }
    if(applicant.employment.are_you !== 'Employed') {
      return [
        { errors: applicantErrors[applicant.id]?.employmentStep1Errors, visited: applicant.steps.employment_step1_visited },
      ]
    }
    if(applicant.employment.are_you === 'Employed') {
      const jobStartDate = applicant.employment.employment_type === '2' ? applicant.employment.date_joined : applicant.employment.date_contract_started
      if(moment(jobStartDate) > moment().subtract(3, 'years')) {
        return [
          { errors: applicantErrors[applicant.id]?.employmentStep1Errors, visited: applicant.steps.employment_step1_visited },
          { errors: applicantErrors[applicant.id]?.employmentStep2Errors, visited: applicant.steps.employment_step2_visited },
          { errors: applicantErrors[applicant.id]?.employmentStep3Errors, visited: applicant.steps.employment_step3_visited },
          { errors: applicantErrors[applicant.id]?.employmentStep4Errors, visited: applicant.steps.employment_step4_visited },
        ]
      }
      return [
        { errors: applicantErrors[applicant.id]?.employmentStep1Errors, visited: applicant.steps.employment_step1_visited },
        { errors: applicantErrors[applicant.id]?.employmentStep2Errors, visited: applicant.steps.employment_step2_visited },
        { errors: applicantErrors[applicant.id]?.employmentStep3Errors, visited: applicant.steps.employment_step3_visited },
      ]
    }
  }

  return (
    <div className="border-b border-white-o-15">
      <button
        className="px-10 py-4 text-white w-full"
        onClick={() => { submenuOpen ? setSubmenuOpen(false) : setSubmenuOpen(true) }}
        type="button"
      >
        <span className="flex items-center justify-between relative">
          Applicant {index + 1}
          <div className="flex items-center">
            <i className={`fa fa-chevron-${submenuOpen ? 'up' : 'down'} text-xs`} />
            { applicantErrors[applicant.id] &&
              <SidebarStatusIcon
                applicant
                errors={[
                  { errors: applicantErrors[applicant.id]?.idVerificationErrors, visited: true },
                  { errors: applicantErrors[applicant.id]?.openBankingErrors, visited: true },
                  { errors: applicantErrors[applicant.id]?.personalDetailsStep1Errors, visited: applicant.steps.personal_details_step1_visited },
                  { errors: applicantErrors[applicant.id]?.personalDetailsStep2Errors, visited: applicant.steps.personal_details_step2_visited },
                  { errors: applicantErrors[applicant.id]?.personalDetailsStep3Errors, visited: applicant.steps.personal_details_step3_visited },
                  { errors: applicantErrors[applicant.id]?.personalDetailsStep4Errors, visited: applicant.steps.personal_details_step4_visited },
                  ...employmentErrors(),
                  { errors: applicantErrors[applicant.id]?.otherIncomeStep1Errors, visited: applicant.steps.other_income_step1_visited },
                  { errors: applicantErrors[applicant.id]?.otherIncomeStep2Errors, visited: applicant.steps.other_income_step2_visited },
                  { errors: applicantErrors[applicant.id]?.otherIncomeStep3Errors, visited: applicant.steps.other_income_step3_visited },
                  { errors: applicantErrors[applicant.id]?.pensionErrors, visited: applicant.steps.pension_visited },
                  { errors: applicantErrors[applicant.id]?.assetsErrors, visited: applicant.steps.assets_visited },
                  { errors: applicantErrors[applicant.id]?.commitmentsStep1Errors, visited: applicant.steps.commitments_step1_visited },
                  { errors: applicantErrors[applicant.id]?.commitmentsStep2Errors, visited: applicant.steps.commitments_step2_visited },
                  { errors: applicantErrors[applicant.id]?.generalQuestionsErrors, visited: applicant.steps.general_questions_visited },
                ]}
              />
            }
          </div>
        </span>
      </button>

      {submenuOpen && (
        <div className="submenu-container">
          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `identityVerification${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/identity-verification/step1`)}
          >
            <img
              alt="Identity Verification"
              className="mr-4"
              src={iconIdentityVerification}
            />
            Identity Verification
            { applicantErrors[applicant.id]?.idVerificationErrors === 0 && <span className="data-capture-sidebar-icon data-capture-sidebar-icon--success"><i className="fa fa-check" /></span> }
            { (identityVerificationConsent !== false && identityVerificationBackend?.status != 'success') && <span className="data-capture-sidebar-icon data-capture-sidebar-icon--error"></span> }
          </button>

          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `personalDetails${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/personal-details/step1`)}
          >
            <img
              alt="Personal Details"
              className="mr-4"
              src={iconPersonalDetailsWhite}
            />
            Personal Details
            { applicantErrors[applicant.id] &&
              <SidebarStatusIcon errors={[
                  { errors: applicantErrors[applicant.id].personalDetailsStep1Errors, visited: applicant.steps.personal_details_step1_visited },
                  { errors: applicantErrors[applicant.id].personalDetailsStep2Errors, visited: applicant.steps.personal_details_step2_visited },
                  { errors: applicantErrors[applicant.id].personalDetailsStep3Errors, visited: applicant.steps.personal_details_step3_visited },
                  { errors: applicantErrors[applicant.id].personalDetailsStep4Errors, visited: applicant.steps.personal_details_step4_visited },
                ]}
              />
            }
          </button>

          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `openBanking${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/open-banking/step1`)}
          >
            <img
              alt="Open Banking"
              className="mr-4"
              src={iconOpenBanking}
            />
            Open Banking
            { applicantErrors[applicant.id]?.openBankingErrors === 0 && <span className="data-capture-sidebar-icon data-capture-sidebar-icon--success"><i className="fa fa-check" /></span> }
            { (openBankingConsent !== false && openBankingDetails?.status != 'complete') && <span className="data-capture-sidebar-icon data-capture-sidebar-icon--error"></span> }
          </button>

          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `employment${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/employment/step1`)}
          >
            <img
              alt="Employment"
              className="mr-4"
              src={iconEmploymentWhite}
            />
            Employment
            { applicantErrors[applicant.id] && <SidebarStatusIcon errors={employmentErrors()} /> }
          </button>

          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `otherIncome${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/other-income/step1`)}
          >
            <img
              alt="Other Income"
              className="mr-4"
              src={iconOtherIncomeWhite}
            />
            Other Income
            { applicantErrors[applicant.id] &&
              <SidebarStatusIcon errors={[
                  { errors: applicantErrors[applicant.id].otherIncomeStep1Errors, visited: applicant.steps.other_income_step1_visited },
                  { errors: applicantErrors[applicant.id].otherIncomeStep2Errors, visited: applicant.steps.other_income_step2_visited },
                  { errors: applicantErrors[applicant.id].otherIncomeStep3Errors, visited: applicant.steps.other_income_step3_visited },
                ]}
              />
            }
          </button>

          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `pension${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/pension`)}
          >
            <img
              alt="Pension Income"
              className="mr-4"
              src={iconPensionIncomeWhite}
            />
            Pension Income
            { applicantErrors[applicant.id] &&
              <SidebarStatusIcon errors={[{ errors: applicantErrors[applicant.id].pensionErrors, visited: applicant.steps.pension_visited }]} />
            }
          </button>

          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `assets${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/assets`)}
          >
            <img
              alt="Assets"
              className="mr-4"
              src={iconAssetsWhite}
            />
            Assets
            { applicantErrors[applicant.id] &&
              <SidebarStatusIcon errors={[{ errors: applicantErrors[applicant.id].assetsErrors, visited: applicant.steps.assets_visited }]} />
            }
          </button>

          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `commitments${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/commitments/step1`)}
          >
            <img
              alt="Commitments"
              className="mr-4"
              src={iconCommitmentsWhite}
            />
            Commitments
            { applicantErrors[applicant.id] &&
              <SidebarStatusIcon errors={[
                  { errors: applicantErrors[applicant.id].commitmentsStep1Errors, visited: applicant.steps.commitments_step1_visited },
                  { errors: applicantErrors[applicant.id].commitmentsStep2Errors, visited: applicant.steps.commitments_step2_visited },
                ]}
              />
            }
          </button>

          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `generalQuestions${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/general-questions`)}
          >
            <img
              alt="General Questions"
              className="mr-4"
              src={iconGeneralQuestionsWhite}
            />
            General Questions
            { applicantErrors[applicant.id] &&
              <SidebarStatusIcon errors={[{ errors: applicantErrors[applicant.id].generalQuestionsErrors, visited: applicant.steps.general_questions_visited }]} />
            }
          </button>

          <button
            className={`
              data-capture-sidebar-link data-capture-sidebar-link--submenu
              ${selected === `documentUpload${applicant.id}` ? 'active' : ''}
            `}
            onClick={navigateTo(`/${type}/applications/${application_id}/applicants/${applicant.id}/document-upload`)}
          >
            <img
              alt="Document Upload"
              className="mr-4"
              src={iconUploadGray}
            />
            Document Upload
            { applicantErrors[applicant.id] &&
              <SidebarStatusIcon errors={[{ errors: applicantErrors[applicant.id].documentUploadErrors, visited: applicant.steps.document_upload_visited }]} />
            }
          </button>
        </div>
      )}
    </div>
  )
})
