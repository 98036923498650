import { observer } from 'mobx-react-lite'
import { observable, transaction } from 'mobx'
import React, { useMemo, useState } from 'react'
import Tooltip from '../../Tooltip'
import DocumentUploader from './DocumentUploader'
import DocumentViewer from './DocumentViewer'
import DocumentUploadingRow from './DocumentUploadingRow'
import DocumentFailedUploadRow from './DocumentFailedUploadRow'

export default observer(function DocumentUploadSection({
  currentApplicant,
  documentType,
  documentTypeName,
  hideUpload,
  optional,
  tooltip,
}) {
  const currentDocuments = useMemo(() => {
    return currentApplicant.documents.filter(applicantDocument => applicantDocument.document_type === documentType)
  }, [currentApplicant, currentApplicant?.documents, currentApplicant?.documents?.length])

  // Use mobx `observerable`, since already using `observer`, and some
  // challenges using setState due to promise usage in DocumentUploader.uploadFile
  const [documentsCurrentlyUploading] = useState(observable([]))
  const [failedDocumentUploads] = useState(observable([]))

  const onBeginUploadingDocument = (documentUploading) => {
    // Clear any failed document uploads, not to confuse user
    failedDocumentUploads.clear()
    // Add to the document currently uploading to show progress
    documentsCurrentlyUploading.push(documentUploading)
  }
  const onFinishUploadingDocument = (success, tempDocumentId) => {
    // If sucessful, will be added to `currentDocuments`,
    // so can just remove from `documentsCurrentlyUploading`
    if (success) {
      documentsCurrentlyUploading.remove(documentsCurrentlyUploading.find(item => item.id === tempDocumentId))
    }
    // If failed (eg failed virus scan or invalid), we add to
    // `failedDocumentUploads` so user knows it failed (this should rarely happen),
    // also remove from currently uploading docs
    else {
      failedDocumentUploads.push(documentsCurrentlyUploading.find(item => item.id === tempDocumentId))
      documentsCurrentlyUploading.remove(documentsCurrentlyUploading.find(item => item.id === tempDocumentId))
    }
  }

  return (
    <div className="document-box">
      <div className="w-1/3 border-r border-timber-green-o-16 flex items-center pr-8 py-3">
        <div className="w-5/6">
          <p className="font-bold">{documentTypeName}</p>
          {optional && <p className="text-xs">(Optional)</p>}
        </div>
        <div className="flex justify-end w-1/6">
          {tooltip && <Tooltip tooltip={tooltip} />}
        </div>
      </div>
      <div className="pl-8 py-3 w-2/3">
        {/* Uploaded Documents */}
        {currentDocuments.length === 0 && <p>No documents found</p>}
        { currentDocuments.map(document => (
            <DocumentViewer key={document.id} currentDocument={document} currentApplicant={currentApplicant} />
          ))
        }

        {/* Failed Uploading Documents (eg failed antivirus check) */}
        {failedDocumentUploads.map(failedDocumentUpload => (
          <DocumentFailedUploadRow key={failedDocumentUpload.id} fileName={failedDocumentUpload.fileName} />
        ))}

        {/* Current Uploading Documents */}
        {documentsCurrentlyUploading.map(documentUploading => {
          return (
            <DocumentUploadingRow key={documentUploading.id} fileName={documentUploading.fileName} />
          )
        })}

        {/* Document Uploader */}
        {!hideUpload && (
          <div className="mt-4">
            <DocumentUploader
              documentType={documentType}
              currentApplicant={currentApplicant}
              onBeginUploadingDocument={onBeginUploadingDocument}
              onFinishUploadingDocument={onFinishUploadingDocument}
            />
          </div>
        )}
      </div>
    </div>
  )
})