import React, { useContext, useMemo } from 'react'
import iconUploadGray from 'images/icons/doc-upload-gray.svg'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'

export default function DocumentUploadIcon({ applicant }) {
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const errorClass = useMemo(() => {
    if(!applicant.steps.document_upload_visited) return 'bg-white'
    if(applicantErrors[applicant.id]?.documentUploadErrors) return 'bg-red-500'
    return 'bg-green-500'
  }, [applicant, applicantErrors[applicant.id]?.documentUploadErrors])

  return (

    <div className={`applicant-image ${errorClass}`}>
      <img alt="Document Upload" src={iconUploadGray} />
    </div>
  )
}