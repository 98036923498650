import React, { useContext, useEffect } from 'react'
import NextStepButton from '../../Form/NextStepButton'

import bgCard from 'images/card.png'
import { useHistory, useParams } from 'react-router-dom'

import axiosService from '../../../utils/AxiosService'
import { ApplicationContext } from '../../../contexts/ApplicationContext'

export default function Summary() {
  const { application_id, id_verification_token } = useParams()
  const applicationContext = useContext(ApplicationContext)
  let history = useHistory()
  const idVerifyMobileBaseUrl = `/borrower/applications/${application_id}/applicants/${id_verification_token}/identity-verification-mobile`

  console.log('images: ');
  console.log(applicationContext.applicationState.identityVerification.resultImages);

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {}
  }, [])

  const uploadDocuments = () => {
    const uploadButton = document.querySelector('#upload-button')
    uploadButton.setAttribute('disabled', 'disabled')
    uploadButton.innerHTML = 'Processing...'

    axiosService.post('/api/borrower/identity_verification', { id_verification_token: id_verification_token, application_id: application_id, images: applicationContext.applicationState.identityVerification.resultImages })
      .then(response => {
        console.log(response)

        if (response.success) {
          setContext({'title': 'Verification pending',
            'topText': 'Your documents have been sent for verification. You can now close this window on your mobile. The results will be available on the Identity Verification section of your mortgage application',
            'icon': 'iconVerificationPending', 'bottomText': '', 'hints': false, 'buttonText': ''})
          history.push(idVerifyMobileBaseUrl + '/status')
        }
        else if (response.errors) {
          setContext({'title': 'Verification error', 'topText': response.errors.join(' - '), 'icon': 'iconVerificationError',
            'bottomText': '', 'hints': true, 'buttonText': 'Retry'})
          history.push(idVerifyMobileBaseUrl + '/status')
        }
        else {
          setContext({'title': 'Verification pending', 'topText': 'An unexpected error occured.', 'icon': 'iconVerificationPending',
            'bottomText': '', 'hints': false, 'buttonText': ''})
          history.push(idVerifyMobileBaseUrl + '/status')
        }
      })
      .catch(error => {
        console.log(error)
        setContext({'title': 'Verification pending', 'topText': 'An unexpected error occured...', 'icon': 'iconVerificationPending',
          'bottomText': '', 'hints': false, 'buttonText': ''})
        history.push(idVerifyMobileBaseUrl + '/status')
      })
  }

  const setContext = (details) => {
    applicationContext.applicationState.identityVerification.resultStatus.title = details.title
    applicationContext.applicationState.identityVerification.resultStatus.topText = details.topText
    applicationContext.applicationState.identityVerification.resultStatus.icon = details.icon
    applicationContext.applicationState.identityVerification.resultStatus.bottomText = details.bottomText
    applicationContext.applicationState.identityVerification.resultStatus.hints = details.hints
    applicationContext.applicationState.identityVerification.resultStatus.buttonText = details.buttonText
  }

  const restartVerification = () => {
    applicationContext.applicationState.identityVerification.resultImages = []
    history.push(idVerifyMobileBaseUrl + '/document-type')
  }

  return (
    <>
      <h2 className="form-title mb-40">Document summary </h2>

      <div className="mb-40 text-center" style={{ maxWidth: 343 }}>
        <div className="border border-turquoise mb-6 relative rounded-lg w-full">
          <img alt="" className="mx-auto" src={applicationContext.applicationState.identityVerification.resultImages[0]} />
        </div>

        <div className="border border-turquoise mb-6 relative rounded-lg w-full">
          <img alt="" className="mx-auto" src={applicationContext.applicationState.identityVerification.resultImages[1]} />
        </div>

        <div className={`border border-turquoise mb-8 relative rounded-lg w-full ${applicationContext.applicationState.identityVerification.resultImages[2] ? '' : 'hidden'}`}>
          <img alt="" className="mx-auto" src={applicationContext.applicationState.identityVerification.resultImages[2] ? applicationContext.applicationState.identityVerification.resultImages[2] : ''} />
        </div>

        <button id="upload-button" className={`btn btn--primary lg:w-auto w-full`} onClick={uploadDocuments} type="button">Submit</button>
        <button id="restart-button" className={`btn btn--outline lg:w-auto w-full`} onClick={restartVerification} type="button">Restart identification process</button>
      </div>
    </>
  )
}
