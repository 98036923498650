import { toast } from "react-toastify"

export const toastSuccess = (message, { position = "top-right", autoClose = 2000, theme = "colored" } = {}) => {

  toast.success(message, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
  })
}

export const toastError = (message, { position = "top-right", autoClose = 2000, theme = "colored" } = {}) => {

  toast.error(message, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
  })
}

export const toastInfo = (message, { position = "top-right", autoClose = 2000, theme = "colored" } = {}) => {

  toast.info(message, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
  })
}