import React from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Label from './Label'
import ErrorMessage from './ErrorMessage'

export default function PhoneField({
  error,
  label,
  name,
  onBlur,
  onChange = () => {},
  optional,
  tooltip,
  value,
  disabled,
}) {

  const onValueChange = (value) => onChange(value || '')

  return (
    <>
      <Label label={label} tooltip={tooltip} />

      <div className={`relative ${error && 'error'}`}>
        <PhoneInput
          name={name}
          onChange={onValueChange}
          onBlur={onBlur}
          value={value}
          numberInputProps={{ name }}
          disabled={disabled}
        />

        {optional && <p className="optional-text">Optional</p>}
      </div>

      <ErrorMessage text={error} />
    </>
  )
}