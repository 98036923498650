import React from 'react'

import iconProgress from 'images/icons/identify-verification-progress.svg'
import iconSuccess from 'images/icons/identify-verification-success.svg'
import iconError from 'images/icons/verification-error.svg'
import iconPending from 'images/icons/verification-pending.svg'

export default function Step3({textsToDisplay}) {
  if (!textsToDisplay) {
    textsToDisplay = {
      'title': 'Verification pending',
      'topText': 'Your documents have been sent for verification. The results will be available on this screen shortly.',
      'icon': 'pending',
      'bottomText': ''
    }
  }

  const getIcon = () => {
    if (textsToDisplay.icon == 'success') {
      return iconSuccess
    }
    else if (textsToDisplay.icon == 'error') {
      return iconError
    }
    else {
      return iconPending
    }
  }

  return (
    <>
      <h2 className="form-title mb-40">{textsToDisplay.title}</h2>

      <p className="mb-40">{textsToDisplay.topText}</p>

      <img alt="" className="mb-40 mx-auto" src={getIcon()} />

      <p className="mb-40">{textsToDisplay.bottomText}</p>
    </>
  )
}
