import React, { useContext } from 'react'
import EmploymentStep1 from './Step1'
import EmploymentStep2 from './Step2'
import EmploymentStep3 from './Step3'
import EmploymentStep4 from './Step4'
import { Route, Switch } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'

export default function Employment() {
  const { applicationState: { type } } = useContext(ApplicationContext)

  return (
    <Switch>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/employment/step1`}>
        <EmploymentStep1 />
      </Route>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/employment/step2`}>
        <EmploymentStep2 />
      </Route>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/employment/step3`}>
        <EmploymentStep3 />
      </Route>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/employment/step4`}>
        <EmploymentStep4 />
      </Route>
    </Switch>
  )
}
