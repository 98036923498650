import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PhoneField from '../../Form/PhoneField'

import iconText from 'images/icons/identify-text.svg'
import iconQr from 'images/icons/identify-qr.svg'
import iconQrImage from 'images/icons/identify-qr-image.svg'
import iconProgress from 'images/icons/identify-verification-progress.svg'

import axiosService from '../../../utils/AxiosService'
import QRCode from "react-qr-code"
import consumer from "../../../channels/consumer"

export default function Step2() {
  const { application_id, applicant_id } = useParams()
  const [phoneFieldError, setPhoneFieldError] = useState()
  const [state, setState] = useState({
    title: 'Choose a Method',
    topText: 'You can choose to receive a text message with the link or simply scan the QR code below.',
    imageBlock: 'qr', // string 'qr' or 'in_progress'
    idVerificationToken: null
  })

  let history = useHistory()
  let phoneNumber = '+353'

  useEffect(() => {
    axiosService.get('/api/borrower/id_verification_token/' + application_id + '/' + applicant_id)
    .then(response => {
      console.log(response)
      setState({
        title: 'Choose a Method',
        topText: 'You can choose to receive a text message with the link or simply scan the QR code below.',
        imageBlock: 'qr', // string 'qr' or 'in_progress'
        idVerificationToken: response.id_verification_token
      })
    })
    .catch(error => console.log(error))

    consumer.subscriptions.create(
      {
        channel: 'IdVerificationChannel',
        applicant_id: applicant_id
      },
      {
        connected() {
          console.log('connected')
        },
        disconnected() {
          console.log('disconnected')
        },
        received(data) {
          console.log(data)
          if (data?.message == 'mobile_flow_started') {
            showInProgressScreen()
          }
          else if (data?.message == 'mobile_flow_ended_queued_to_agent') {
            navigateToStep('3')
          }
          else if (data?.message == 'mobile_flow_browser_not_supported') {
            navigateToStep('1')
          }
          else {
            showQrScreen()
          }
        }
      }
    )

    return () => {}
  }, [])

  const showInProgressScreen = () => {
    // alert('mobile_flow_started') // Change html to in progress when mobile_flow_started. Rdirect to step3 when mobile_flow_ended.
    setState({
      title: 'Verification in progress',
      topText: 'The Identity Verification process is in progress. Follow the steps on your mobile to continue.',
      imageBlock: 'in_progress' // string 'qr' or 'in_progress'
    })
  }

  const showQrScreen = () => {
    setState({
      title: 'Choose a Method',
      topText: 'You can choose to receive a text message with the link or simply scan the QR code below.',
      imageBlock: 'qr',
      idVerificationToken: state.idVerificationToken
    })
  }

  const navigateToStep = (stepNumber) => {
    history.push(`/borrower/applications/${application_id}/applicants/${applicant_id}/identity-verification/step${stepNumber}`)
  }

  const sendSms = (buttonId) => {
    if (!phoneNumber || phoneNumber == '' || phoneNumber.length < 8) {
      setPhoneFieldError('Please provide your phone number.')
      return
    }

    setPhoneFieldError(null)
    const triggerButton = document.querySelector(buttonId)
    triggerButton.innerHTML = 'Sending...'

    document.querySelectorAll('.send-sms-button').forEach((button) => {
      button.setAttribute('disabled', 'disabled')
    })

    axiosService.post('/api/borrower/identity_verification_sms', { id: applicant_id, application_id: application_id, phone: phoneNumber })
      .then(response => {
        console.log(response)
        enableSmsButtons(triggerButton)
        if(!response.success) alert('Could not send sms. Please try again later or scan the QR code instead.')
      })
      .catch(error => {
        console.log(error)
        enableSmsButtons(triggerButton)
        alert('Could not send sms. Please try again later or scan the QR code instead.')
      })
  }

  const enableSmsButtons = (triggerButton) => {
    triggerButton.innerHTML = triggerButton.id == 'sms1' ? 'Send link to my phone' : 'Send link again'
    document.querySelectorAll('.send-sms-button').forEach((button) => {
      button.removeAttribute('disabled')
    })
  }


  // Ensure url begins with https:// or http://
  let url = location.host
  if (!url.match(/^http(s)?:\/\//)) url = `${location.protocol}//${url}`

  return (
    <>
      <h2 className="form-title mb-40">{state.title}</h2>

      <p className="mb-40">{state.topText}</p>

      { state.imageBlock == 'qr' &&
      <div className="flex flex-wrap mb-40 -mx-4 text-center">
        <div className="flex px-4 w-1/2">
          <div className="border border-turquoise box-shadow p-6 rounded-lg w-full">
            <img alt="" className="mb-3 mx-auto" src={iconText} style={{ height: 200 }} />

            <p className="font-bold mb-8">TEXT MESSAGE</p>

            <div className="mb-3">
              <PhoneField error={phoneFieldError} value={phoneNumber} onChange={value => { phoneNumber = value; if(value.length >= 8) setPhoneFieldError(null) }} />
            </div>

            <button id="sms1" className="btn btn--primary mb-4 w-full send-sms-button" type="button" onClick={() => sendSms('#sms1')}>Send link to my phone</button>

            <button id="sms2" className="btn py-0 send-sms-button" type="button" onClick={() => sendSms('#sms2')}>Send link again</button>
          </div>
        </div>

        <div className="flex px-4 w-1/2">
          <div className="border border-turquoise box-shadow p-6 rounded-lg w-full">
            <img alt="" className="mb-3 mx-auto" src={iconQr} style={{ height: 200 }} />

            <p className="font-bold mb-8">QR CODE</p>

            { state.idVerificationToken &&
            <div className="id-verification-qr-container">
              <QRCode size={160} value={`${url}/borrower/applications/${application_id}/applicants/${state.idVerificationToken}/identity-verification-mobile/id-verification`} />
            </div>
            }
          </div>
        </div>
      </div>
      }

      { state.imageBlock == 'in_progress' &&
      <img alt="" className="mb-40 mx-auto" src={iconProgress} />
      }
    </>
  )
}
