import React from 'react'

export default function BorrowerManageAccount() {


  return (
    <div>
      Borrower Manage Account
    </div>
  )
}