import * as Yup from 'yup'

const businessTypeIsSoleTrader = () => {
  return [
    'business_type',
    {
      is: '1',
      then: Yup.number('This field must contain numbers.').required('This field is required.')
    }
  ]
}

const businessTypeIsPartnerShipOrLLPOrLimitedCompany = () => {
  return [
    'business_type',
    {
      is: business_type => ['2', '3', '4'].includes(business_type),
      then: Yup.number('This field must contain numbers.').required('This field is required.')
    }
  ]
}

export const EmploymentStep4SelfEmployedSchema = Yup.object().shape({
  salary1: Yup.mixed().when(...businessTypeIsPartnerShipOrLLPOrLimitedCompany()),
  salary2: Yup.mixed().when(...businessTypeIsPartnerShipOrLLPOrLimitedCompany()),
  salary3: Yup.mixed().when(...businessTypeIsPartnerShipOrLLPOrLimitedCompany()),
  year1: Yup.mixed().when(...businessTypeIsSoleTrader()),
  year2: Yup.mixed().when(...businessTypeIsSoleTrader()),
  year3: Yup.mixed().when(...businessTypeIsSoleTrader()),
});