import { makeAutoObservable } from "mobx"

export default class CreditCardCommitment {
  constructor(credit_card_commitments={}) {
    this.outstanding_balance = credit_card_commitments.outstanding_balance || 0
    this.credit_card_repayment_type = credit_card_commitments.credit_card_repayment_type || ''
    this.loan_paid_on_or_before = !!credit_card_commitments.loan_paid_on_or_before
    this.monthly_payment = credit_card_commitments.monthly_payment || 0
    this.lender_name = credit_card_commitments.lender_name || ''
    this.credit_account_number = credit_card_commitments.credit_account_number || ''
    this.credit_limit = credit_card_commitments.credit_limit || 0
    this.payments_missed = !!credit_card_commitments.payments_missed
    this.payments_missed_details = credit_card_commitments.payments_missed_details || ''
    makeAutoObservable(this)
  }
}