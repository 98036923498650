import { makeAutoObservable } from "mobx"
import Assets from "./Assets"
import Employment from "./Employment"
import General from "./General"
import Income from "./Income"
import Pension from "./Pension"
import LoanCommitment from "./LoanCommitment"
import CreditCardCommitment from "./CreditCardCommitment"
import OtherIncome from "./OtherIncome"
import ApplicantSteps from "./ApplicantSteps"
import Document from "./Document"

export default class Applicant {
  constructor(applicant={}) {
    this.id = applicant.id
    this.uuid = applicant.uuid
    this.title = applicant.title || ''
    this.first_name = applicant.first_name || ''
    this.middle_name = applicant.middle_name || ''
    this.last_name = applicant.last_name || ''
    this.known_by_other_name = applicant.known_by_other_name || false
    this.other_title = applicant.other_title || ''
    this.other_first_name = applicant.other_first_name || ''
    this.other_middle_name = applicant.other_middle_name || ''
    this.other_last_name = applicant.other_last_name || ''
    this.date_of_birth = applicant.date_of_birth || ''
    this.marital_status = applicant.marital_status || ''
    this.nationality = applicant.nationality || '47'
    this.has_diplomatic_immunity = !!applicant.has_diplomatic_immunity
    this.ppsn = applicant.NINumber || '' // ppsn == NINumber
    this.email = applicant.email || ''
    this.mobile_number = applicant.mobile_number || '+353'
    this.phone_number = applicant.phone_number || '+353'
    this.right_to_live_in_roi = applicant.right_to_live_in_roi || false
    this.country_of_residence = applicant.country_of_residence || 'IRL'
    this.date_moved_to_roi = applicant.date_moved_to_roi || ''
    this.first_time_buyer = applicant.first_time_buyer || false
    this.employment_class = applicant.employment_class || ''
    this.retirement_age = applicant.retirement_age || ''
    this.relation_with_applicant1 = applicant.relation_with_applicant1 || ''
    this.relation_with_applicant2 = applicant.relation_with_applicant2 || ''
    this.relation_with_applicant3 = applicant.relation_with_applicant3 || ''
    this.relation_with_applicant4 = applicant.relation_with_applicant4 || ''
    this.relation_description_with_applicant1 = applicant.relation_description_with_applicant1 || ''
    this.relation_description_with_applicant2 = applicant.relation_description_with_applicant2 || ''
    this.relation_description_with_applicant3 = applicant.relation_description_with_applicant3 || ''
    this.relation_description_with_applicant4 = applicant.relation_description_with_applicant4 || ''
    this.number_of_dependants = applicant.number_of_dependants || 0
    this.dependant1_age = applicant.dependant1_age || ''
    this.dependant2_age = applicant.dependant2_age || ''
    this.dependant3_age = applicant.dependant3_age || ''
    this.dependant4_age = applicant.dependant4_age || ''
    this.dependant5_age = applicant.dependant5_age || ''
    this.dependant6_age = applicant.dependant6_age || ''
    this.dependant7_age = applicant.dependant7_age || ''
    this.dependant8_age = applicant.dependant8_age || ''
    this.dependant9_age = applicant.dependant9_age || ''
    this.dependant10_age = applicant.dependant10_age || ''
    this.address_post_code = applicant.address_post_code || ''
    this.address_line1 = applicant.address_line1 || ''
    this.address_line2 = applicant.address_line2 || ''
    this.address_line3 = applicant.address_line3 || ''
    this.address_city = applicant.address_city || ''
    this.address_county = applicant.address_county || ''
    this.address_country = applicant.address_country || 'IRL'
    this.address_moved_date = applicant.address_moved_date || ''
    this.residential_status = applicant.residential_status || ''
    this.monthly_rent_amount = applicant.monthly_rent_amount || 0
    this.is_correspondence = applicant.is_correspondence || ''
    this.previous1_address_post_code = applicant.previous1_address_post_code || ''
    this.previous1_address_line1 = applicant.previous1_address_line1 || ''
    this.previous1_address_line2 = applicant.previous1_address_line2 || ''
    this.previous1_address_line3 = applicant.previous1_address_line3 || ''
    this.previous1_address_city = applicant.previous1_address_city || ''
    this.previous1_address_county = applicant.previous1_address_county || ''
    this.previous1_address_country = applicant.previous1_address_country || 'IRL'
    this.previous1_address_moved_date = applicant.previous1_address_moved_date || ''
    this.previous1_residential_status = applicant.previous1_residential_status || ''
    this.previous2_address_post_code = applicant.previous2_address_post_code || ''
    this.previous2_address_line1 = applicant.previous2_address_line1 || ''
    this.previous2_address_line2 = applicant.previous2_address_line2 || ''
    this.previous2_address_line3 = applicant.previous2_address_line3 || ''
    this.previous2_address_city = applicant.previous2_address_city || ''
    this.previous2_address_county = applicant.previous2_address_county || ''
    this.previous2_address_country = applicant.previous2_address_country || 'IRL'
    this.previous2_address_moved_date = applicant.previous2_address_moved_date || ''
    this.previous2_residential_status = applicant.previous2_residential_status || ''
    this.previous3_address_post_code = applicant.previous3_address_post_code || ''
    this.previous3_address_line1 = applicant.previous3_address_line1 || ''
    this.previous3_address_line2 = applicant.previous3_address_line2 || ''
    this.previous3_address_line3 = applicant.previous3_address_line3 || ''
    this.previous3_address_city = applicant.previous3_address_city || ''
    this.previous3_address_county = applicant.previous3_address_county || ''
    this.previous3_address_country = applicant.previous3_address_country || 'IRL'
    this.previous3_address_moved_date = applicant.previous3_address_moved_date || ''
    this.previous3_residential_status = applicant.previous3_residential_status || ''
    this.previous4_address_post_code = applicant.previous4_address_post_code || ''
    this.previous4_address_line1 = applicant.previous4_address_line1 || ''
    this.previous4_address_line2 = applicant.previous4_address_line2 || ''
    this.previous4_address_line3 = applicant.previous4_address_line3 || ''
    this.previous4_address_city = applicant.previous4_address_city || ''
    this.previous4_address_county = applicant.previous4_address_county || ''
    this.previous4_address_country = applicant.previous4_address_country || 'IRL'
    this.previous4_address_moved_date = applicant.previous4_address_moved_date || ''
    this.previous4_residential_status = applicant.previous4_residential_status || ''
    this.previous5_address_post_code = applicant.previous5_address_post_code || ''
    this.previous5_address_line1 = applicant.previous5_address_line1 || ''
    this.previous5_address_line2 = applicant.previous5_address_line2 || ''
    this.previous5_address_line3 = applicant.previous5_address_line3 || ''
    this.previous5_address_city = applicant.previous5_address_city || ''
    this.previous5_address_county = applicant.previous5_address_county || ''
    this.previous5_address_country = applicant.previous5_address_country || 'IRL'
    this.previous5_address_moved_date = applicant.previous5_address_moved_date || ''
    this.previous5_residential_status = applicant.previous5_residential_status || ''
    this.roi_correspondence_address_post_code = applicant.roi_correspondence_address_post_code || ''
    this.roi_correspondence_address_line1 = applicant.roi_correspondence_address_line1 || ''
    this.roi_correspondence_address_line2 = applicant.roi_correspondence_address_line2 || ''
    this.roi_correspondence_address_line3 = applicant.roi_correspondence_address_line3 || ''
    this.roi_correspondence_address_city = applicant.roi_correspondence_address_city || ''
    this.roi_correspondence_address_country = applicant.roi_correspondence_address_country || 'IRL'
    this.identity_verification_backend = applicant.identity_verification_backend || {}
    this.email_verified = applicant.email_verified || false
    this.current_applicant_number = undefined
    this.identity_verification_consent = applicant.identity_verification_consent
    this.open_banking_details = applicant.open_banking_details
    this.open_banking_consent = applicant.open_banking_consent

    this.assets = new Assets(applicant.assets)
    this.general = new General(applicant.general)
    this.pension = new Pension(applicant.pension)
    this.employment = new Employment(applicant.employment)
    this.income = new Income(applicant.income)
    this.loan_commitments = applicant.loan_commitments?.map(loan_commitment => new LoanCommitment(loan_commitment.Commitment)) || []
    this.credit_card_commitments = applicant.credit_card_commitments?.map(credit_card_commitment => new CreditCardCommitment(credit_card_commitment.Commitment)) || []
    this.other_income = new OtherIncome(applicant.other_income)
    this.steps = new ApplicantSteps(applicant.steps)
    this.documents = applicant.documents?.map(document => new Document(document)) || []

    makeAutoObservable(this)
  }
}
