import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'
import Document from '../../../models/Document'
import Dropzone from 'react-dropzone'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import _ from 'lodash'
import { toastError } from '../../Form/Toasts'

export default observer(function DocumentUploader({ documentType, currentApplicant, onBeginUploadingDocument, onFinishUploadingDocument }) {
  const { applicant_id, application_id } = useParams()
  const { applicationState: { disabled, backend_url_part } } = useContext(ApplicationContext)

  const acceptedFileTypes = [
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'application/vnd.ms-excel',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'application/vnd.oasis.opendocument.text',
    // 'text/csv',
    // 'application/csv',
    'image/png',
  ]

  const notifyTypeError = () => toastError('Incorrect file type, accepted types are doc, docx, pdf, jpg, jpeg, odt and png')

  const uploadFile = file => {
    const tempDocumentId = _.uniqueId('tmpDocId_')

    onBeginUploadingDocument({ id: tempDocumentId, fileName: file.name })

    const formData = new FormData()
    formData.append('document[file]', file)
    formData.append('document[document_type]', documentType)

    axiosService.post(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}/documents`, formData, { headers: { "Content-type": "multipart/form-data" } })
    .then(document => {
      currentApplicant.documents.push(new Document(document))
      onFinishUploadingDocument(true, tempDocumentId)
    })
    .catch(({ data: { error }}) => {
      // Eg network error, or failed antivirus check
      toastError(error)
      onFinishUploadingDocument(false, tempDocumentId)
    })
  }

  return (
    <Dropzone
      accept={acceptedFileTypes}
      maxFiles={1}
      onDropAccepted={acceptedFiles => uploadFile(acceptedFiles[0])}
      onDropRejected={notifyTypeError}
      disabled={disabled}
    >
      {({getRootProps, getInputProps}) => (
        <div className="border border-dashed border-timber-green cursor-pointer p-2 rounded-lg text-center">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p className="font-bold text-turquoise">
              <i className="far fa-file-upload mr-2" />
              Upload <span className="text-timber-green">or drag item</span>
            </p>
            <p className="text-timber-green-o-40 text-xs">Only JPEG, PNG, PDF, DOCX and XLSV files</p>
          </div>
        </div>
      )}
    </Dropzone>
  )
})