import { observer } from "mobx-react-lite";
import React from "react";
import Modal from '../../../Modal'

export default observer(function EmploymentTypeChangeModal({ modalState, confirm }) {
  if (!modalState.show) return null

  return (
    <Modal>
      <div className="flex justify-right pt-30">
        <button
          className="p-1 ml-auto border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none"
          onClick={() => modalState.show = false}
        >
          <span className="absolute mr-4 right-0 text-black h-6 w-6 text-lg block outline-none focus:outline-none">
            <i className="fa fa-times" />
          </span>
        </button>
      </div>
      <div className="mb-6 px-16 text-center">
        <i className="fal fa-exclamation-triangle mb-4 text-punch text-5xl" />
        <h3 className="font-bold mb-6">
          Do you want to switch to {modalState.next_are_you}?
        </h3>
        <p>
          If you switch to {modalState.next_are_you}, your {modalState.are_you} data that you have filled out so far will be lost!
        </p>
      </div>
      <div className="pb-30 px-50">
        <div className="flex justify-between -mx-4">
          <div className="px-4 w-1/2">
            <button
              className="btn btn--secondary w-full"
              type="button"
              onClick={() => modalState.show = false}
            >
              Back
            </button>
          </div>

          <div className="flex items-center px-4 w-1/2">
            <button
              className="w-full"
              type="button"
              onClick={confirm}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
})