import React, { Fragment } from 'react'
import CurrencyTextField from '../../CurrencyTextField'
import TitleRow from '../../TitleRow'
import ApplicantRow from './ApplicantRow'

export default function AssetsPart({ applicants }) {

  const getMaxAssetAmount = () => {
    return applicants.reduce((maximum_asset, { assets: { assets_amount }}) => assets_amount > maximum_asset ? assets_amount : maximum_asset, 0)
  }

  return (
    <Fragment>
      <TitleRow label="Assets" />
      <ApplicantRow name="Cash Deposits" tdContent={applicant => <CurrencyTextField value={applicant.assets.cash_deposits}/>} />
      <ApplicantRow name="Cash Deposits jointly owned?" tdContent={applicant => applicant.assets.cash_deposits_jointly_owned ? 'Yes' : 'No'} />
      <ApplicantRow name="Shares?" tdContent={applicant => <CurrencyTextField value={applicant.assets.stocks_shares}/>} />
      <ApplicantRow name="Shares jointly owned?" tdContent={applicant => applicant.assets.stock_share_jointly_owned ? 'Yes' : 'No'} />
      {[...Array(+getMaxAssetAmount())].map((_, amount) => (
        <Fragment key={amount}>
          <ApplicantRow name={`Other Asset ${amount + 1}`} tdContent={applicant => <CurrencyTextField value={applicant.assets[`other_assets${amount + 1}`]}/>} />
          <ApplicantRow name={`Specify Asset ${amount + 1}`} tdContent={applicant => applicant.assets[`asset_type${amount + 1}`]} />
        </Fragment>
      ))
      }
    </Fragment>
  )
}