import { makeAutoObservable } from "mobx"

export default class DirectDebit {
  constructor(directDebit={}) {
    this.id = directDebit.id
    this.name_of_account_holder = directDebit.name_of_account_holder || ''
    this.account_number = directDebit.account_number || ''
    this.sort_code = directDebit.sort_code || ''
    this.institution = directDebit.institution || ''
    this.branch = directDebit.branch || ''
    this.contact_postcode = directDebit.contact_postcode || ''
    this.address_line1 = directDebit.address_line1 || ''
    this.address_line2 = directDebit.address_line2 || ''
    this.city = directDebit.city || ''
    this.county = directDebit.county || ''
    this.paymentday = directDebit.paymentday || null
    this.is_confirm_declaration = true
    makeAutoObservable(this)
  }
}