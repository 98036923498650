import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import ApplicationProvider from '../../contexts/ApplicationContext'
import ApplicationErrorsProvider from '../../contexts/ApplicationErrorsContext'
import Applicants from './Applicants'
import Assets from './Assets'
import Commitments from './Commitments'
import DirectDebit from './DirectDebit'
import Employment from './Employment'
import GeneralQuestions from './GeneralQuestions'
import IdentityVerification from './IdentityVerification'
import OpenBanking from './OpenBanking'
import LoanRequirements from './LoanRequirements'
import MainPropertyDetails from './MainPropertyDetails'
import OtherIncome from './OtherIncome'
import Pension from './Pension'
import PersonalDetails from './PersonalDetails'
import Portfolio from './Portfolio'
import Sidebar from './Sidebar'
import Solicitor from './Solicitor'
import { observer } from 'mobx-react-lite'
import BrokerSidebar from '../Broker/Application/BrokerSidebar'
import ApplicantDocumentUpload from './ApplicantDocumentUpload'
import RequestValuation from "../Broker/RequestValuation";

export default observer(function FormContainer({ type = 'borrower' }) {
  const location = useLocation()

  return (
    <ApplicationProvider type={type}>
      <ApplicationErrorsProvider>
        <section>
          <div className={`container ${location.pathname.includes('document-upload') ? 'container--document' : 'container--form'} py-16`}>
            {type === 'borrower' ? <Sidebar /> : <BrokerSidebar />}
            <Switch>
              <Route exact path={`/${type}/applications/:application_id/applicants`}>
                <Applicants />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/identity-verification`}>
                <IdentityVerification />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/open-banking`}>
                <OpenBanking />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/personal-details`}>
                <PersonalDetails />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/employment`}>
                <Employment />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/other-income`}>
                <OtherIncome />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/pension`}>
                <Pension />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/assets`}>
                <Assets />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/commitments`}>
                <Commitments />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/general-questions`}>
                <GeneralQuestions />
              </Route>
              <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/document-upload`}>
                <ApplicantDocumentUpload />
              </Route>
              <Route path={`/${type}/applications/:application_id/portfolio`}>
                <Portfolio />
              </Route>
              <Route path={`/${type}/applications/:application_id/loan-requirements`}>
                <LoanRequirements />
              </Route>
              <Route path={`/${type}/applications/:application_id/main-property-details`}>
                <MainPropertyDetails />
              </Route>
              <Route path={`/${type}/applications/:application_id/direct-debit`}>
                <DirectDebit />
              </Route>
              <Route path={`/${type}/applications/:application_id/solicitor`}>
                <Solicitor />
              </Route>
              <Route path={`/${type}/applications/:application_id/request-valuation`}>
                <RequestValuation />
              </Route>
            </Switch>
          </div>
        </section>
      </ApplicationErrorsProvider>
    </ApplicationProvider>
  )
})
