import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import NextStepButton from '../../Form/NextStepButton'
import ProgressIndicator from '../ProgressIndicator'
import { ApplicationContext } from '../../../contexts/ApplicationContext'

import Step1 from './Step1'
import Step2 from './Step2'

export default observer(function OpenBanking() {
  const { application_id, applicant_id } = useParams()
  const { applicationState: { type }} = useContext(ApplicationContext)
  const url = `/${type}/applications/${application_id}/applicants/${applicant_id}/open-banking`

  const save = () => {}

  return (
    <>
      {type != 'borrower' &&
        <>
          <Step1 baseUrl={url} userType={type} />

          <div className="flex justify-center mt-16">
            <NextStepButton nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/employment/step1`} save={save}/>
          </div>
        </>
      }

      {type == 'borrower' &&
        <Switch>
          <Route path='/borrower/applications/:application_id/applicants/:applicant_id/open-banking/step1'>
            <Step1 baseUrl={url} userType={type} />
          </Route>

          <Route path='/borrower/applications/:application_id/applicants/:applicant_id/open-banking/step2'>
            <Step2 />
          </Route>
        </Switch>
      }
    </>
  )
})
