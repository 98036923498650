import React, { Fragment } from 'react'
import Divider from '../../../../Divider'
import BusinessSectorSelector from '../../../../Form/BusinessSectorSelector'
import CurrencyField from '../../../../Form/CurrencyField'
import DatePicker from '../../../../Form/DatePicker'
import Input from '../../../../Form/Input'
import PhoneField from '../../../../Form/PhoneField'
import ReactSelect from '../../../../Form/ReactSelect'
import YesNoBtnGroup from '../../../../Form/YesNoBtnGroup'
import EmploymentSelectorPart from './EmploymentSelectorPart'

export default function SelfEmployedPart({ applicant }) {

  const businessTypeOptions = [
    { label: 'Sole Trader', value: '1' },
    { label: 'Partnership', value: '2' },
    { label: 'LLP', value: '3' },
    { label: 'Limited Company', value: '4' },
  ]

  return (
    <div>
      <div className="flex flex-wrap -mx-12">
        <div className="px-12 w-1/2">
          <EmploymentSelectorPart applicant={applicant} />

          <div className="mb-40">
            <ReactSelect
              label="Business type"
              options={businessTypeOptions}
              value={applicant.income.business_type}
              disabled
            />
          </div>

          <div className="mb-40">
            <BusinessSectorSelector value={applicant.income.nature_of_business} disabled />
          </div>

          <div className="mb-40">
            <Input label="Name of Business" value={applicant.income.name_of_business} disabled />
          </div>
        </div>

        <div className="px-12 w-1/2">
          { applicant.income.business_type !== '1' &&
            <Fragment>
              <div className="mb-40">
                <Input addon="%" addonPosition="end" label="Percentage Shareholding" value={applicant.income.percentage_of_shareholding} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date established" value={applicant.income.date_established} disabled />
              </div>

              <div className="mb-40">
                <DatePicker label="Date owned or part owned the business" value={applicant.income.date_owned_or_part_owned} disabled />
              </div>
            </Fragment>
          }

          { applicant.income.business_type !== '4' &&
            <div className="mb-40">
              <DatePicker label="Date Self-employment commenced" value={applicant.income.self_employed_date} disabled />
            </div>
          }


          { applicant.income.business_type !== '1' &&
            <div className="mb-40">
              <DatePicker label="Current Year End date" value={applicant.income.current_year_end} disabled />
            </div>
          }
        </div>
      </div>

      <Divider text="Registered address" />

      <div className="flex flex-wrap -mx-12">
        <div className="px-12 w-1/2">
          <div className="mb-40">
            <Input label="Eircode" value={applicant.income.registered_post_code} disabled />
          </div>

          <div className="mb-40">
            <Input label="Address Line 1" value={applicant.income.registered_address_line1} disabled />
          </div>

          <div className="mb-40">
            <Input label="Address Line 2" optional value={applicant.income.registered_address_line2} disabled />
          </div>

          <div className="mb-40">
            <Input label="Address Line 3" optional value={applicant.income.registered_address_line3} disabled />
          </div>
        </div>

        <div className="px-12 w-1/2">
          <div className="mb-40">
            <Input label="City" optional value={applicant.income.registered_city} disabled />
          </div>

          <div className="mb-40">
            <Input label="County" value={applicant.income.registered_county} disabled />
          </div>

          <div className="mb-40">
            <ReactSelect countrySelect label="Country" value={applicant.income.registered_country} disabled />
          </div>

          { applicant.income.business_type === '4' &&
            <div className="mb-40">
              <Input label="Company Registration Number" value={applicant.income.self_employed_company_number} disabled />
            </div>
          }

          <div className="mb-40">
            <YesNoBtnGroup label="Trading address (if different)" value={applicant.income.is_business_address_different} disabled />
          </div>
        </div>
      </div>

      { applicant.income.is_business_address_different &&
        <Fragment>
          <Divider text="Trading address" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="Eircode" value={applicant.income.business_post_code} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 1" value={applicant.income.business_address_line1} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 2" optional value={applicant.income.business_address_line2} disabled />
              </div>

              <div className="mb-40">
                <Input label="Address Line 3" optional value={applicant.income.business_address_line3} disabled />
              </div>
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <Input label="City" optional value={applicant.income.business_city} disabled />
              </div>

              <div className="mb-40">
                <Input label="County" value={applicant.income.business_county} disabled />
              </div>

              <div className="mb-40">
                <ReactSelect countrySelect label="Country" value={applicant.income.business_country} disabled />
              </div>
            </div>
          </div>
        </Fragment>
      }

      <div className="flex flex-wrap -mx-12">
        <div className="px-12 w-1/2">
          <div className="mb-40">
            <YesNoBtnGroup label="Do you have an Accountant?" value={applicant.income.do_you_have_accountant} disabled />
          </div>

          { applicant.income.do_you_have_accountant &&
            <Fragment>
              <div className="mb-40">
                <Input label="Name of Accountant Firm" value={applicant.income.accountants_firm} disabled />
              </div>

              <div className="mb-40">
                <Input label="Email" value={applicant.income.accountant_email} disabled />
              </div>

              <div className="mb-40">
                <PhoneField label="Phone Number" value={applicant.income.accountants_telephone_number} disabled />
              </div>

              <div className="mb-40">
                <Input label="Contact Name" value={applicant.income.contact_name} disabled />
              </div>

              <div className="mb-40">
                <Input label="Eircode" value={applicant.income.accountants_post_code} disabled />
              </div>
            </Fragment>
          }
        </div>

        { applicant.income.do_you_have_accountant &&
          <div className="px-12 w-1/2">
            <div className="mb-40">
              <Input label="Address Line 1" value={applicant.income.accountants_address_line1} disabled />
            </div>

            <div className="mb-40">
              <Input label="Address Line 2" optional value={applicant.income.accountants_address_line2} disabled />
            </div>

            <div className="mb-40">
              <Input label="City" optional value={applicant.income.accountants_city} disabled />
            </div>

            <div className="mb-40">
              <Input label="County" value={applicant.income.accountants_county} disabled />
            </div>

            <div className="mb-40">
              <ReactSelect countrySelect label="Country" value={applicant.income.accountants_country} disabled />
            </div>
          </div>
        }
      </div>

      { applicant.income.business_type !== '1' &&
        <Fragment>
          <Divider text="Financials - End of last year" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField addon="€" addonPosition="start" label="Salary" value={applicant.income.salary1} disabled />
              </div>

              { applicant.income.business_type === '4' &&
                <div className="mb-40">
                  <CurrencyField addon="€" addonPosition="start" label="Dividend" value={applicant.income.dividend1} disabled />
                </div>
              }
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField addon="€" addonPosition="start" label="Your share of Net profit" value={applicant.income.net_assets1} disabled />
              </div>
            </div>
          </div>

          <Divider text="Financials - End of year before" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField addon="€" addonPosition="start" label="Salary" value={applicant.income.salary2} disabled />
              </div>

              { applicant.income.business_type === '4' &&
                <div className="mb-40">
                  <CurrencyField addon="€" addonPosition="start" label="Dividend" value={applicant.income.dividend2} disabled />
                </div>
              }
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField addon="€" addonPosition="start" label="Your share of Net profit" value={applicant.income.net_assets2} disabled />
              </div>
            </div>
          </div>

          <Divider text="Financials - End of year before" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField addon="€" addonPosition="start" label="Salary" value={applicant.income.salary3} disabled />
              </div>

              { applicant.income.business_type === '4' &&
                <div className="mb-40">
                  <CurrencyField addon="€" addonPosition="start" label="Dividend" value={applicant.income.dividend3} disabled />
                </div>
              }
            </div>

            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField addon="€" addonPosition="start" label="Your share of Net profit" value={applicant.income.net_assets3} disabled />
              </div>
            </div>
          </div>
        </Fragment>
      }
      { applicant.income.business_type === '1' &&
        <Fragment>
          <Divider text="Financials - Latest Tax Year" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField addon="€" addonPosition="start" label="Net profit" value={applicant.income.year1} disabled />
              </div>
            </div>
          </div>

          <Divider text="Financials - Previous Tax Year" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField addon="€" addonPosition="start" label="Net profit" value={applicant.income.year2} disabled />
              </div>
            </div>
          </div>

          <Divider text="Financials - Previous Tax Year" />

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <div className="mb-40">
                <CurrencyField addon="€" addonPosition="start" label="Net profit" value={applicant.income.year3} disabled />
              </div>
            </div>
          </div>
        </Fragment>
      }

    </div>
  )

}