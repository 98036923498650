import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import axiosService from '../../../utils/AxiosService'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../models/Applicant'
import YapilyConnectScreen from './YapilyConnectScreen'
import BankSelectScreen from './BankSelectScreen'
import DefaultScreen from './DefaultScreen'
import ResultScreen from './ResultScreen'
import NextStepButton from '../../Form/NextStepButton'
import { toastError } from '../../Form/Toasts'

export default observer(function Step1({ baseUrl, userType }) {
  const { application_id, applicant_id } = useParams()
  const { applicationState: { applicants } } = useContext(ApplicationContext)
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)
  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants])

  const [state, setState] = useState({
    currentStep: 1,
    openBankingAccounts: {},
    openBankingStatus: null,
    openBankingUrl: null,
    openBankingConsent: null,
    selectedBank: {
      icon: null,
      label: null,
      value: null
    },
  })

  useEffect(() => {
    const namespace = {
      borrower: 'borrower',
      broker: 'apprivo_broker'
    }[userType]

    axiosService.get(`/api/${namespace}/applications/${application_id}/applicants/${applicant_id}/ob_accounts`)
      .then((results) => {
        if (Object.keys(results).length > 0) {
          setState({
            ...state,
            currentStep: 4,
            openBankingAccounts: results,
          })
        } else {
          setState({
            ...state,
            currentStep: 1,
            openBankingAccounts: results,
          })
        }
      })
      .catch(({ data: { error } }) => console.log('err', error))
  }, [application_id, applicant_id]);

  const toggleConsent = (value) => {
    axiosService.post('/api/borrower/open_banking/consent', { applicant_id, consent: value })
      .then(() => setState({ ...state, openBankingConsent: value }))
      .catch(error => console.log(error))
  }

  const save = () => {}

  return (
    <>
      {userType != 'borrower'
        && (
          <>
            <div className="mb-4 mx-auto w-1/2">
              <YesNoBtnGroup
                disabled={true}
                label="Do you want to use Open Banking?"
                name="open_banking_consent"
                value={currentApplicant.open_banking_consent}
              />
            </div>

            <ResultScreen
              openBankingAccounts={state.openBankingAccounts}
              setState={setState}
              state={state}
              userType={userType}
            />
          </>
        )}

      {userType == 'borrower'
        && (
          <>
            {state.currentStep !== 3 && <h2 className="form-title mb-40">Open Banking</h2>}

            {state.currentStep === 1
              && (
                <DefaultScreen
                  baseUrl={baseUrl}
                  save={save}
                  setState={setState}
                  state={state}
                  toggleConsent={toggleConsent}
                />
              )}

            {state.currentStep === 2
              && (
                <BankSelectScreen
                  setState={setState}
                  state={state}
                />
              )}

            {state.currentStep === 3
              && (
                <YapilyConnectScreen
                  setState={setState}
                  state={state}
                />
              )}

            {state.currentStep === 4
              && (
                <ResultScreen
                  openBankingAccounts={state.openBankingAccounts}
                  setState={setState}
                  state={state}
                  userType={userType}
                />
              )}

            {(state.currentStep === 1 || state.currentStep === 4) && (
              <div className="flex justify-center mt-16">
                {(
                  state.openBankingConsent !== null
                    ? state.openBankingConsent && state.currentStep === 1
                    : currentApplicant.open_banking_consent && state.currentStep === 1
                )
                  ? (
                    <button
                      className="btn btn--primary lg:w-auto w-full"
                      disabled={!(state.openBankingConsent !== null || currentApplicant.open_banking_consent)}
                      id="open-banking-start-button"
                      onClick={(e) => {
                        e.preventDefault()

                        if (applicantErrors[applicant_id]?.personalDetailsStep1Errors > 0 || applicantErrors[applicant_id]?.personalDetailsStep2Errors > 0) {
                          toastError('Please complete Personal Details before continuing', { autoClose: 4000 })
                          return
                        }

                        setState({
                          ...state,
                          currentStep: 2,
                        })
                      }}
                      type="button"
                    >
                      Start Open Banking process
                    </button>
                  )
                  : (
                    <NextStepButton
                      nextUrl={`/borrower/applications/${application_id}/applicants/${applicant_id}/employment/step1`}
                      url={baseUrl}
                      save={save}
                    />
                  )}
              </div>
            )}
          </>
        )}
    </>
  )
})
