import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import Input from '../../Form/Input'
import ReactSelect from '../../Form/ReactSelect'
import DatePicker from '../../Form/DatePicker'
import Divider from '../../Divider'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Applicant from '../../../models/Applicant'
import { observer } from 'mobx-react-lite'
import PersonalDetailsActions from './PersonalDetailsActions'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'
import { PersonalDetailsStep4Schema } from '../../../schemas/PersonalDetailsSchemas/PersonalDetailsStep4Schema'
import moment from 'moment'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import { notifyProgressSaved } from '../../Form/Notifications'
import LoqateWrapper from '../../Form/LoqateWrapper'

export default observer(function PersonalDetailsStep4() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const residentialStatusOptions = [
    { label: 'Owner with Mortgage', value: '1' },
    { label: 'Owner no Mortgage', value: '2' },
    { label: 'Tenant', value: '3' },
    { label: 'With Family or Friend', value: '4' },
  ]

  const fields = [
    "address_post_code",
    "retirement_age",
    "address_line1",
    "address_line2",
    "address_line3",
    "address_city",
    "address_county",
    "address_country",
    "address_moved_date",
    "residential_status",
    "monthly_rent_amount",
    "previous1_address_post_code",
    "previous1_address_line1",
    "previous1_address_line2",
    "previous1_address_line3",
    "previous1_address_city",
    "previous1_address_county",
    "previous1_address_country",
    "previous1_address_moved_date",
    "previous1_residential_status",
    "previous2_address_post_code",
    "previous2_address_line1",
    "previous2_address_line2",
    "previous2_address_line3",
    "previous2_address_city",
    "previous2_address_county",
    "previous2_address_country",
    "previous2_address_moved_date",
    "previous2_residential_status",
    "previous3_address_post_code",
    "previous3_address_line1",
    "previous3_address_line2",
    "previous3_address_line3",
    "previous3_address_city",
    "previous3_address_county",
    "previous3_address_country",
    "previous3_address_moved_date",
    "previous3_residential_status",
    "previous4_address_post_code",
    "previous4_address_line1",
    "previous4_address_line2",
    "previous4_address_line3",
    "previous4_address_city",
    "previous4_address_county",
    "previous4_address_country",
    "previous4_address_moved_date",
    "previous4_residential_status",
    "previous5_address_post_code",
    "previous5_address_line1",
    "previous5_address_line2",
    "previous5_address_line3",
    "previous5_address_city",
    "previous5_address_county",
    "previous5_address_country",
    "previous5_address_moved_date",
    "previous5_residential_status",
  ]

  const save = applicant => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant })
    .then(() => {
      notifyProgressSaved()
      Object.keys(applicant).forEach(key => currentApplicant[key] = applicant[key])
      applicationErrorsContext.validatePersonalDetailsStep4(currentApplicant, applicant_id)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  const currentApplicantFields = useMemo(() => {
    return fields.reduce((result, field) => {
      result[field] = currentApplicant[field]
      return result
    }, {})
  }, [currentApplicant])

  useEffect(() => {
    if(currentApplicant.steps.personal_details_step4_visited) touchFields()
    applicationErrorsContext.validatePersonalDetailsStep4(currentApplicant, applicant_id)
  }, [loading])

  return (
    <div>
      <h2 className="form-title mb-40">Personal details</h2>
      { !disabled && <ApplicantStepUpdater step="personal_details_step4_visited" /> }

      <Formik
        initialValues={currentApplicantFields}
        validationSchema={PersonalDetailsStep4Schema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <Divider text="Current Address" />

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <LoqateWrapper
                    label="Search Address"
                    tooltip="Type address or Eircode to lookup address"
                    onSelect={address => {
                      setFieldValue('address_post_code', address.PostalCode, true)
                      setFieldValue('address_line1', address.Line1, true)
                      setFieldValue('address_line2', address.Line2, true)
                      setFieldValue('address_line3', address.Line3, true)
                      setFieldValue('address_city', address.City, true)
                      address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                        ? setFieldValue('address_county', 'Dublin', true)
                        : setFieldValue('address_county', address.Province, true)
                      setFieldValue('address_country', address.CountryIso3, true)
                    }}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.address_post_code && touched.address_post_code && errors.address_post_code}
                    label="Eircode"
                    name="address_post_code"
                    type="text"
                    onChange={(e) => {
                      const postCodeValue = e.target.value
                      if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('address_county', 'Dublin', true)
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    value={values.address_post_code}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.address_line1 && touched.address_line1 && errors.address_line1}
                    label="Address Line 1"
                    name="address_line1"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_line1}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.address_line2 && touched.address_line2 && errors.address_line2}
                    label="Address Line 2"
                    name="address_line2"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_line2}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.address_line3 && touched.address_line3 && errors.address_line3}
                    label="Address Line 3"
                    name="address_line3"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_line3}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    error={errors.address_city && touched.address_city && errors.address_city}
                    label="City"
                    name="address_city"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_city}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.address_county && touched.address_county && errors.address_county}
                    label="County"
                    name="address_county"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_county}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <ReactSelect
                    countrySelect
                    error={errors.address_country && touched.address_country && errors.address_country}
                    label="Country"
                    name="address_country"
                    placeholder="Select Country"
                    onChange={value => setFieldValue('address_country', value?.value || '', true)}
                    onBlur={handleBlur}
                    value={values.address_country}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <DatePicker
                    error={errors.address_moved_date && touched.address_moved_date && errors.address_moved_date}
                    label="Date Moved to Address"
                    name="address_moved_date"
                    onChange={value => setFieldValue('address_moved_date', value, true)}
                    value={values.address_moved_date}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <ReactSelect
                    error={errors.residential_status && touched.residential_status && errors.residential_status}
                    label="Residential Status"
                    name="residential_status"
                    options={residentialStatusOptions}
                    placeholder="Select your status"
                    onChange={value => setFieldValue('residential_status', value?.value || '', true)}
                    onBlur={handleBlur}
                    value={values.residential_status}
                    disabled={disabled}
                  />
                </div>

                {values.residential_status === '3' && (
                  <div className="mb-40">
                    <Input
                      addon="€"
                      addonPosition="start"
                      error={errors.monthly_rent_amount && touched.monthly_rent_amount && errors.monthly_rent_amount}
                      label="Monthly Rent Amount"
                      name="monthly_rent_amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.monthly_rent_amount}
                      disabled={disabled}
                    />
                  </div>
                )}
              </div>
            </div>

            { values.address_moved_date && moment(values.address_moved_date) > moment().subtract(3, 'year') &&
              <Fragment>
                <Divider text="Previous Address" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('previous1_address_post_code', address.PostalCode, true)
                          setFieldValue('previous1_address_line1', address.Line1, true)
                          setFieldValue('previous1_address_line2', address.Line2, true)
                          setFieldValue('previous1_address_line3', address.Line3, true)
                          setFieldValue('previous1_address_city', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('previous1_address_county', 'Dublin', true)
                            : setFieldValue('previous1_address_county', address.Province, true)
                          setFieldValue('previous1_address_country', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous1_address_post_code && touched.previous1_address_post_code && errors.previous1_address_post_code}
                        label="Eircode"
                        name="previous1_address_post_code"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous1_address_county', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.previous1_address_post_code}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous1_address_line1 && touched.previous1_address_line1 && errors.previous1_address_line1}
                        label="Address Line 1"
                        name="previous1_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous1_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous1_address_line2 && touched.previous1_address_line2 && errors.previous1_address_line2}
                        label="Address Line 2"
                        name="previous1_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous1_address_line2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous1_address_line3 && touched.previous1_address_line3 && errors.previous1_address_line3}
                        label="Address Line 3"
                        name="previous1_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous1_address_line3}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous1_address_city && touched.previous1_address_city && errors.previous1_address_city}
                        label="City"
                        name="previous1_address_city"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous1_address_city}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous1_address_county && touched.previous1_address_county && errors.previous1_address_county}
                        label="County"
                        name="previous1_address_county"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous1_address_county}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        error={errors.previous1_address_country && touched.previous1_address_country && errors.previous1_address_country}
                        label="Country"
                        name="previous1_address_country"
                        placeholder="Select Country"
                        onChange={value => setFieldValue('previous1_address_country', value?.value || null, true)}
                        onBlur={handleBlur}
                        value={values.previous1_address_country}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous1_address_moved_date && touched.previous1_address_moved_date && errors.previous1_address_moved_date}
                        label="Date Moved to Address"
                        name="previous1_address_moved_date"
                        onChange={value => setFieldValue('previous1_address_moved_date', value, true)}
                        value={values.previous1_address_moved_date}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        error={errors.previous1_residential_status && touched.previous1_residential_status && errors.previous1_residential_status}
                        label="Residential Status"
                        name="previous1_residential_status"
                        options={residentialStatusOptions}
                        placeholder="Select your status"
                        onChange={value => setFieldValue('previous1_residential_status', value?.value || '', true)}
                        onBlur={handleBlur}
                        value={values.previous1_residential_status}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }

            { values.previous1_address_moved_date && moment(values.previous1_address_moved_date) > moment().subtract(3, 'year') &&
              <Fragment>
                <Divider text="Previous Address" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('previous2_address_post_code', address.PostalCode, true)
                          setFieldValue('previous2_address_line1', address.Line1, true)
                          setFieldValue('previous2_address_line2', address.Line2, true)
                          setFieldValue('previous2_address_line3', address.Line3, true)
                          setFieldValue('previous2_address_city', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('previous2_address_county', 'Dublin', true)
                            : setFieldValue('previous2_address_county', address.Province, true)
                          setFieldValue('previous2_address_country', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous2_address_post_code && touched.previous2_address_post_code && errors.previous2_address_post_code}
                        label="Eircode"
                        name="previous2_address_post_code"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous2_address_county', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.previous2_address_post_code}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous2_address_line1 && touched.previous2_address_line1 && errors.previous2_address_line1}
                        label="Address Line 1"
                        name="previous2_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous2_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous2_address_line2 && touched.previous2_address_line2 && errors.previous2_address_line2}
                        label="Address Line 2"
                        name="previous2_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous2_address_line2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous2_address_line3 && touched.previous2_address_line3 && errors.previous2_address_line3}
                        label="Address Line 3"
                        name="previous2_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous2_address_line3}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous2_address_city && touched.previous2_address_city && errors.previous2_address_city}
                        label="City"
                        name="previous2_address_city"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous2_address_city}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous2_address_county && touched.previous2_address_county && errors.previous2_address_county}
                        label="County"
                        name="previous2_address_county"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous2_address_county}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        error={errors.previous2_address_country && touched.previous2_address_country && errors.previous2_address_country}
                        label="Country"
                        name="previous2_address_country"
                        placeholder="Select Country"
                        onChange={value => setFieldValue('previous2_address_country', value?.value || null, true)}
                        onBlur={handleBlur}
                        value={values.previous2_address_country}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous2_address_moved_date && touched.previous2_address_moved_date && errors.previous2_address_moved_date}
                        label="Date Moved to Address"
                        name="previous2_address_moved_date"
                        onChange={value => setFieldValue('previous2_address_moved_date', value, true)}
                        value={values.previous2_address_moved_date}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        error={errors.previous2_residential_status && touched.previous2_residential_status && errors.previous2_residential_status}
                        label="Residential Status"
                        name="previous2_residential_status"
                        options={residentialStatusOptions}
                        placeholder="Select your status"
                        onChange={value => setFieldValue('previous2_residential_status', value?.value || '', true)}
                        onBlur={handleBlur}
                        value={values.previous2_residential_status}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }

            { values.previous2_address_moved_date && moment(values.previous2_address_moved_date) > moment().subtract(3, 'year') &&
              <Fragment>
                <Divider text="Previous Address" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('previous3_address_post_code', address.PostalCode, true)
                          setFieldValue('previous3_address_line1', address.Line1, true)
                          setFieldValue('previous3_address_line2', address.Line2, true)
                          setFieldValue('previous3_address_line3', address.Line3, true)
                          setFieldValue('previous3_address_city', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('previous3_address_county', 'Dublin', true)
                            : setFieldValue('previous3_address_county', address.Province, true)
                          setFieldValue('previous3_address_country', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous3_address_post_code && touched.previous3_address_post_code && errors.previous3_address_post_code}
                        label="Eircode"
                        name="previous3_address_post_code"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous3_address_county', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.previous3_address_post_code}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous3_address_line1 && touched.previous3_address_line1 && errors.previous3_address_line1}
                        label="Address Line 1"
                        name="previous3_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous3_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous3_address_line2 && touched.previous3_address_line2 && errors.previous3_address_line2}
                        label="Address Line 2"
                        name="previous3_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous3_address_line2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous3_address_line3 && touched.previous3_address_line3 && errors.previous3_address_line3}
                        label="Address Line 3"
                        name="previous3_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous3_address_line3}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous3_address_city && touched.previous3_address_city && errors.previous3_address_city}
                        label="City"
                        name="previous3_address_city"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous3_address_city}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous3_address_county && touched.previous3_address_county && errors.previous3_address_county}
                        label="County"
                        name="previous3_address_county"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous3_address_county}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        error={errors.previous3_address_country && touched.previous3_address_country && errors.previous3_address_country}
                        label="Country"
                        name="previous3_address_country"
                        placeholder="Select Country"
                        onChange={value => setFieldValue('previous3_address_country', value?.value || null, true)}
                        onBlur={handleBlur}
                        value={values.previous3_address_country}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous3_address_moved_date && touched.previous3_address_moved_date && errors.previous3_address_moved_date}
                        label="Date Moved to Address"
                        name="previous3_address_moved_date"
                        onChange={value => setFieldValue('previous3_address_moved_date', value, true)}
                        value={values.previous3_address_moved_date}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        error={errors.previous3_residential_status && touched.previous3_residential_status && errors.previous3_residential_status}
                        label="Residential Status"
                        name="previous3_residential_status"
                        options={residentialStatusOptions}
                        placeholder="Select your status"
                        onChange={value => setFieldValue('previous3_residential_status', value?.value || '', true)}
                        onBlur={handleBlur}
                        value={values.previous3_residential_status}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }

            { values.previous3_address_moved_date && moment(values.previous3_address_moved_date) > moment().subtract(3, 'year') &&
              <Fragment>

                <Divider text="Previous Address" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('previous4_address_post_code', address.PostalCode, true)
                          setFieldValue('previous4_address_line1', address.Line1, true)
                          setFieldValue('previous4_address_line2', address.Line2, true)
                          setFieldValue('previous4_address_line3', address.Line3, true)
                          setFieldValue('previous4_address_city', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('previous4_address_county', 'Dublin', true)
                            : setFieldValue('previous4_address_county', address.Province, true)
                          setFieldValue('previous4_address_country', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous4_address_post_code && touched.previous4_address_post_code && errors.previous4_address_post_code}
                        label="Eircode"
                        name="previous4_address_post_code"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous4_address_county', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.previous4_address_post_code}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous4_address_line1 && touched.previous4_address_line1 && errors.previous4_address_line1}
                        label="Address Line 1"
                        name="previous4_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous4_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous4_address_line2 && touched.previous4_address_line2 && errors.previous4_address_line2}
                        label="Address Line 2"
                        name="previous4_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous4_address_line2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous4_address_line3 && touched.previous4_address_line3 && errors.previous4_address_line3}
                        label="Address Line 3"
                        name="previous4_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous4_address_line3}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous4_address_city && touched.previous4_address_city && errors.previous4_address_city}
                        label="City"
                        name="previous4_address_city"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous4_address_city}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous4_address_county && touched.previous4_address_county && errors.previous4_address_county}
                        label="County"
                        name="previous4_address_county"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous4_address_county}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        error={errors.previous4_address_country && touched.previous4_address_country && errors.previous4_address_country}
                        label="Country"
                        name="previous4_address_country"
                        placeholder="Select Country"
                        onChange={value => setFieldValue('previous4_address_country', value?.value || null, true)}
                        onBlur={handleBlur}
                        value={values.previous4_address_country}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous4_address_moved_date && touched.previous4_address_moved_date && errors.previous4_address_moved_date}
                        label="Date Moved to Address"
                        name="previous4_address_moved_date"
                        onChange={value => setFieldValue('previous4_address_moved_date', value, true)}
                        value={values.previous4_address_moved_date}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        error={errors.previous4_residential_status && touched.previous4_residential_status && errors.previous4_residential_status}
                        label="Residential Status"
                        name="previous4_residential_status"
                        options={residentialStatusOptions}
                        placeholder="Select your status"
                        onChange={value => setFieldValue('previous4_residential_status', value?.value || '', true)}
                        onBlur={handleBlur}
                        value={values.previous4_residential_status}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }

            { values.previous4_address_moved_date && moment(values.previous4_address_moved_date) > moment().subtract(3, 'year') &&
              <Fragment>
                <Divider text="Previous Address" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('previous5_address_post_code', address.PostalCode, true)
                          setFieldValue('previous5_address_line1', address.Line1, true)
                          setFieldValue('previous5_address_line2', address.Line2, true)
                          setFieldValue('previous5_address_line3', address.Line3, true)
                          setFieldValue('previous5_address_city', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('previous5_address_county', 'Dublin', true)
                            : setFieldValue('previous5_address_county', address.Province, true)
                          setFieldValue('previous5_address_country', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous5_address_post_code && touched.previous5_address_post_code && errors.previous5_address_post_code}
                        label="Eircode"
                        name="previous5_address_post_code"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('previous5_address_county', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.previous5_address_post_code}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous5_address_line1 && touched.previous5_address_line1 && errors.previous5_address_line1}
                        label="Address Line 1"
                        name="previous5_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous5_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous5_address_line2 && touched.previous5_address_line2 && errors.previous5_address_line2}
                        label="Address Line 2"
                        name="previous5_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous5_address_line2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous5_address_line3 && touched.previous5_address_line3 && errors.previous5_address_line3}
                        label="Address Line 3"
                        name="previous5_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous5_address_line3}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={errors.previous5_address_city && touched.previous5_address_city && errors.previous5_address_city}
                        label="City"
                        name="previous5_address_city"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous5_address_city}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.previous5_address_county && touched.previous5_address_county && errors.previous5_address_county}
                        label="County"
                        name="previous5_address_county"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.previous5_address_county}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        error={errors.previous5_address_country && touched.previous5_address_country && errors.previous5_address_country}
                        label="Country"
                        name="previous5_address_country"
                        placeholder="Select Country"
                        onChange={value => setFieldValue('previous5_address_country', value?.value || null, true)}
                        onBlur={handleBlur}
                        value={values.previous5_address_country}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.previous5_address_moved_date && touched.previous5_address_moved_date && errors.previous5_address_moved_date}
                        label="Date Moved to Address"
                        name="previous5_address_moved_date"
                        onChange={value => setFieldValue('previous5_address_moved_date', value, true)}
                        value={values.previous5_address_moved_date}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        error={errors.previous5_residential_status && touched.previous5_residential_status && errors.previous5_residential_status}
                        label="Residential Status"
                        name="previous5_residential_status"
                        options={residentialStatusOptions}
                        placeholder="Select your status"
                        onChange={value => setFieldValue('previous5_residential_status', value?.value || '', true)}
                        onBlur={handleBlur}
                        value={values.previous5_residential_status}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }
            <PersonalDetailsActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})