import { makeAutoObservable } from "mobx"

export default class Property {
  constructor(property={}) {
    this.id = property.id
    this.post_code = property.post_code || ''
    this.address_line1 = property.address_line1 || ''
    this.address_line2 = property.address_line2 || ''
    this.address_line3 = property.address_line3 || ''
    this.city = property.city || ''
    this.county = property.county || ''
    this.country = property.country || 'IRL'
    this.house_hold_property_type = property.house_hold_property_type || ''
    this.estimated_current_value = property.estimated_current_value || 0
    this.monthly_rental = property.monthly_rental === '' || property.monthly_rental === null ? '' : !!property.monthly_rental ? property.monthly_rental : 0
    this.arrears = property.arrears || ''
    this.market_value = property.market_value || 0
    this.is_property_mortgaged = !!property.is_property_mortgaged
    this.existing_lender_name = property.existing_lender_name || ''
    this.outstanding_mortgage_amount = property.outstanding_mortgage_amount || 0
    this.interest_rate = property.interest_rate || 0
    this.monthly_repayment = property.monthly_repayment || 0
    this.term_remaining = property.term_remaining || ''
    this.repayments_restructured = !!property.repayments_restructured
    this.restructure_details = property.restructure_details || ''
    this.description = property.description || ''
    this.associated_loan_parties = property.associated_loan_parties || ''
    this.other_parties_switch = !!property.other_parties_switch
    this.other_ownership_name = property.other_ownership_name || ''
    this.other_ownership_percentage = property.other_ownership_percentage || 0
    this._destroy = property._destroy || false
    makeAutoObservable(this)
  }
}