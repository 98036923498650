import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import CurrencyTextField from '../CurrencyTextField'
import TitleRow from '../TitleRow'

export default observer(function PortfolioPart() {
  const { applicationState: { properties }} = useContext(ApplicationContext)

  const houseHoldPropertyTypeOptions = [
    { label: 'Second home',  value: '1' },
    { label: 'Holiday Let',  value: '2' },
    { label: 'Let Property', value: '3' },
  ]

  const propertyUsage = house_hold_property_type => {
    return houseHoldPropertyTypeOptions.find(option => option.value === house_hold_property_type)?.label
  }

  return (
    <Fragment>
      <TitleRow label="Property Portfolio" />
      { properties.map((property, index) => (
        <Fragment key={property.id}>
          <tr>
            <td className="font-bold w-1/4">Property {index + 1}</td>
            <td colSpan="2"></td>
          </tr>
          <tr>
            <td className="w-1/4">Property Eircode</td>
            <td className="text-center" colSpan="2">{property.post_code}</td>
          </tr>
          <tr>
            <td className="w-1/4">Property Usage</td>
            <td className="text-center" colSpan="2">{propertyUsage(property.house_hold_property_type)}</td>
          </tr>
          <tr>
            <td className="w-1/4">Monthly Rental Income</td>
            <td className="text-center" colSpan="2"><CurrencyTextField value={property.monthly_rental}/></td>
          </tr>
          <tr>
            <td className="w-1/4">Market Value</td>
            <td className="text-center" colSpan="2"><CurrencyTextField value={property.estimated_current_value}/></td>
          </tr>
          <tr>
            <td className="w-1/4">Property Mortgaged</td>
            <td className="text-center" colSpan="2">{property.is_property_mortgaged ? 'Yes' : 'No'}</td>
          </tr>
          { property.is_property_mortgaged &&
            <Fragment>
              <tr>
                <td className="w-1/4">Lender Name</td>
                <td className="text-center" colSpan="2">{property.existing_lender_name}</td>
              </tr>
              <tr>
                <td className="w-1/4">Mortgage Balance Outstanding</td>
                <td className="text-center" colSpan="2"><CurrencyTextField value={property.outstanding_mortgage_amount}/></td>
              </tr>
              <tr>
                <td className="w-1/4">Current Interest Rate</td>
                <td className="text-center" colSpan="2">{property.interest_rate}</td>
              </tr>
              <tr>
                <td className="w-1/4">Monthly Payment</td>
                <td className="text-center" colSpan="2"><CurrencyTextField value={property.monthly_repayment}/></td>
              </tr>
              <tr>
                <td className="w-1/4">Previous Arrears</td>
                <td className="text-center" colSpan="2">{property.repayments_restructured ? 'Yes' : 'No'}</td>
              </tr>
            </Fragment>
          }
          <tr>
            <td className="w-1/4">Other Parties Named On Title</td>
            <td className="text-center" colSpan="2">{property.other_parties_switch ? 'Yes' : 'No'}</td>
          </tr>
          { property.other_parties_switch &&
            <Fragment>
              <tr>
                <td className="w-1/4">Owners</td>
                <td className="text-center" colSpan="2">{property.other_ownership_name}</td>
              </tr>
              <tr>
                <td className="w-1/4">Ownership %</td>
                <td className="text-center" colSpan="2">{property.other_ownership_percentage}</td>
              </tr>

            </Fragment>
          }
        </Fragment>
        ))
      }
    </Fragment>
  )

})