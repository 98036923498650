import React from 'react'
import Input from '../../Form/Input'
import CurrencyField from '../../Form/CurrencyField'

export default function AprcCalculatorForm({
  calculateLtv,
  dirty,
  errors,
  handleBlur,
  handleChange,
  isValid,
  setFieldValue,
  setState,
  state,
  touched,
  values,
}) {
  return (
    <form
      className="flex flex-col"
      onSubmit={e => {
        e.preventDefault()
        setState({ ...state, submitted: true })
      }}
    >
      <div className="flex-auto">
        <h3 className="font-bold mb-6 text-2xl">Loan Details</h3>

        <div className="mb-6">
          <CurrencyField
            addon="€"
            addonPosition="start"
            error={errors.property_value && touched.property_value && errors.property_value}
            label="Estimated Property Value"
            name="property_value"
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            value={values.property_value}
          />
        </div>

        <div className="calculator-mortgage-container mb-6">
          <CurrencyField
            addon="€"
            addonPosition="start"
            error={errors.mortgage_amount && touched.mortgage_amount && errors.mortgage_amount}
            label="Mortgage Amount / LTV"
            name="mortgage_amount"
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            value={values.mortgage_amount}
          />

          <p className="calculator-mortgage-percent">{dirty && isValid ? calculateLtv(values) : '0'} %</p>
        </div>

        <div className="mb-8">
          <Input
            addon="years"
            addonPosition="end"
            error={errors.mortgage_term && touched.mortgage_term && errors.mortgage_term}
            label="Mortgage Term"
            max={35}
            min={10}
            name="mortgage_term"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mortgage_term}
          />
        </div>
      </div>

      <div>
        {(!dirty || !isValid) && <p className="mb-8 text-center text-turquoise">Enter your loan details above</p>}

        <button
          className={`
            btn btn--primary w-full
            ${(!dirty || !isValid) ? 'disabled' : ''}
          `}
          disabled={!dirty || !isValid}
          type="submit"
        >
          Get Rates
        </button>
      </div>
    </form>
  )
}
