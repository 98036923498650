import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import Input from '../../Form/Input'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import Divider from '../../Divider'
import OtherIncomeActions from './OtherIncomeActions'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Applicant from '../../../models/Applicant'
import { OtherIncomeStep3Schema } from '../../../schemas/OtherIncomeSchemas/OtherIncomeStep3Schema'
import axiosService from '../../../utils/AxiosService'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import OtherIncome from '../../../models/OtherIncome'
import PayFrequencySelector from '../../Form/PayFrequencySelector'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'

export default observer(function OtherIncomeStep3() {
  const { applicationState: { applicants, loading, disabled, backend_url_part } } = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ["do_you_have_income_5", "amount5", "pay_frequency5", "guaranteed5", "source_details5"]

  const save = other_income => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { other_income } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.other_income = new OtherIncome(other_income)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  useEffect(() => {
    if(currentApplicant.steps.other_income_step3_visited) touchFields()
    applicationErrorsContext.validateOtherIncomeStep3(currentApplicant.other_income, applicant_id)
  }, [loading, currentApplicant.other_income])

  return (
    <div>
      <h2 className="form-title mb-40">Other Income</h2>
      { !disabled && <ApplicantStepUpdater step="other_income_step3_visited" /> }

      <Formik
        initialValues={currentApplicant.other_income}
        validationSchema={OtherIncomeStep3Schema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <Divider text="Others" />

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <YesNoBtnGroup
                    error={errors.do_you_have_income_5 && touched.do_you_have_income_5 && errors.do_you_have_income_5}
                    label="Do you have any other source of income?"
                    name="do_you_have_income_5"
                    onChange={(name, value) => {
                      setFieldValue(name, value, true)
                      setFieldValue('source_details5', '', true)
                      setFieldValue('amount5', 0, true)
                      setFieldValue('pay_frequency5', null, true)
                      setFieldValue('guaranteed5', false, true)
                    }}
                    value={values.do_you_have_income_5}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="px-12 w-1/2" />
              <div className="px-12 w-1/2">
                { values.do_you_have_income_5 &&
                  <Fragment>
                    <div className="mb-40">
                      <Input
                        error={errors.source_details5 && touched.source_details5 && errors.source_details5}
                        label="Description"
                        name="source_details5"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.source_details5}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={errors.amount5 && touched.amount5 && errors.amount5}
                        label="Amount"
                        name="amount5"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.amount5}
                        disabled={disabled}
                      />
                    </div>
                  </Fragment>
                }
              </div>

              <div className="px-12 w-1/2">
                { values.do_you_have_income_5 &&
                  <Fragment>

                    <div className="mb-40">
                      <PayFrequencySelector
                        error={errors.pay_frequency5 && touched.pay_frequency5 && errors.pay_frequency5}
                        name="pay_frequency5"
                        onChange={value => setFieldValue('pay_frequency5', (value?.value || ''), true)}
                        onBlur={handleBlur}
                        value={values.pay_frequency5}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <YesNoBtnGroup
                        error={errors.guaranteed5 && touched.guaranteed5 && errors.guaranteed5}
                        label="Guaranteed"
                        name="guaranteed5"
                        onChange={(name, value) => setFieldValue(name, value, true)}
                        value={values.guaranteed5}
                        disabled={disabled}
                      />
                    </div>
                  </Fragment>
                }
              </div>
            </div>

            <OtherIncomeActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})
