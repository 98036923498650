import React from 'react'
import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'
import Label from './Label'

export default function RcSlider({
  handleChange,
  label,
  max,
  min,
  tooltip,
  value,
}) {
  return (
    <>
      <Label label={label} tooltip={tooltip} />

      <Slider
        min={min}
        max={max}
        onChange={handleChange}
        value={value}
      />

      <div className="flex font-bold fs-13 justify-between mt-3">
        <p>{min}</p>
        <p>{max}</p>
      </div>
    </>
  )
}
