import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import iconPensionIncomeGray from 'images/icons/pension-income-gray.svg'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'

export default observer(function PensionIncomeIcon({ applicant }) {
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const errorClass = useMemo(() => {
    if(!applicant.steps.pension_visited) return 'bg-white'
    if(applicantErrors[applicant.id]?.pensionErrors) return 'bg-red-500'
    return 'bg-green-500'
  }, [applicant, applicantErrors[applicant.id]?.pensionErrors])

  return (
    <div className={`applicant-image ${errorClass}`}>
      <img alt="Pension Income" src={iconPensionIncomeGray} />
    </div>
  )
})