import React, { useEffect, useContext, useState } from 'react'

import iconAdjust from 'images/icons/adjust.svg'
import iconCropAlt from 'images/icons/crop-alt.svg'
import iconIdCardGreenFront from 'images/icons/id-card-green-front.svg'
import iconIdCardGreenBack from 'images/icons/id-card-green-back.svg'
import iconPassport from 'images/icons/passport.svg'
import iconSelfie from 'images/icons/id-selfie.svg'
import iconVerificationError from 'images/icons/verification-error.svg'
import iconLightbuld from 'images/icons/lightbulb.svg'
import iconMask from 'images/icons/mask.svg'
import iconUserCircle from 'images/icons/user-circle.svg'
import ProgressIndicator from '../ProgressIndicator'
import axiosService from '../../../utils/AxiosService'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { useHistory, useLocation, useParams } from 'react-router-dom'

export default function MiSnap({url}) {
  let attemptTimer = null
  let cameraStartTimer = null
  let recentHint = null

  let history = useHistory()
  const location = useLocation()
  const { application_id, id_verification_token } = useParams()
  const idVerifyMobileBaseUrl = `/borrower/applications/${application_id}/applicants/${id_verification_token}/identity-verification-mobile`
  const [browserNotSupported, setBrowserNotSupported] = useState()

  const applicationContext = useContext(ApplicationContext)
  const mitekSDKPath = '/mitek/mitekSDK/'
  const autoCaptureHints = require('./MiSnapAutoCaptureHints')
  const generalHints = require('./MiSnapGeneralHints')
  const customMessages = require('./MiSnapCustomMessages')

  let documentType = applicationContext.applicationState.identityVerification.documentType
  const documentTypeText = {
    DL_FRONT: 'Driving License Front',
    DL_BACK: 'Driving License Back',
    PASSPORT: 'Passport',
    SELFIE: 'Selfie'
  }
  const documentTypeImages = {
    DL_FRONT: iconIdCardGreenFront,
    DL_BACK: iconIdCardGreenBack,
    PASSPORT: iconPassport,
    SELFIE: iconSelfie
  }

  const steps = documentType == 'DL_FRONT' || documentType == 'DL_BACK' || location.pathname.match('step3') ? 3 : 2

  useEffect(() => {
    if (documentType != 'DL_FRONT' && documentType != 'DL_BACK' && documentType != 'PASSPORT' && documentType != 'SELFIE') {
      history.push(idVerifyMobileBaseUrl + '/document-type')
    }

    window.scrollTo(0, 0)
    window.captureButton = document.querySelector('#capture-button')

    const script = document.createElement('script');
    script.src = '/mitek/mitek-science-sdk.js';
    document.body.appendChild(script);

    return () => {
      console.log('useEffect return')
      document.querySelector('#mitekMediaContainer')?.remove()
      document.body.removeChild(script)
    }
  }, [])

  // Prevent zoom changes in Safari as it ignores the viewport meta setting for zoom
  document.addEventListener('gesturestart', function (e) { e.preventDefault() })

  const disableButton = () => {
    window.captureButton.setAttribute('disabled', 'disabled')
    window.captureButton.innerHTML = 'Starting camera...'
  }

  const enableButton = () => {
    window.captureButton.removeAttribute('disabled')
    window.captureButton.innerHTML = 'Capture ' + documentTypeText[documentType]
  }

  const startCapture = (evt) => {
    // May be needed to address new browser standards (explicit video play)
    document.querySelector('video[autoplay="true"]').play().then(function(err) { return null }).catch(function(err) { return null })
    disableButton()
    recentHint = null
    attemptTimer = null
    startAutoCapture()
  }

  const startAutoCapture = () => {
    if (applicationContext.applicationState.identityVerification.failedAutoCaptureCount >= 3 || applicationContext.applicationState.identityVerification.disableAutoCapture) {
      startManualCapture()
      return
    }

    cameraStartTimer = setTimeout(function () {
      if (typeof screenfull != "undefined" && screenfull.isEnabled) screenfull.exit()
      mitekScienceSDK.cmd('SDK_STOP')
      enableButton()
      applicationContext.applicationState.identityVerification.failedAutoCaptureCount++
      window.scrollTo(0, 0)
      axiosService.post('/api/borrower/identity_verification/mobile_flow_browser_not_supported', { id_verification_token: id_verification_token, application_id: application_id })
        .then(response => { console.log(response) })
        .catch(error => { console.log(error) })

      setBrowserNotSupported(true)
    }, 15000)

    mitekScienceSDK.on('SDK_ERROR', function(err) {
      console.log('MiSnap auto capture error, falling back to manual capture: ', err);
      clearTimeout(cameraStartTimer)
      clearTimeout(attemptTimer)
      enableButton()
      applicationContext.applicationState.identityVerification.disableAutoCapture = true
      updateIconAndText('Could not launch camera, please tap button again.')
      window.scrollTo(0, 0)
    })

    mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', function (result) {
      if (mitekMediaStreamSource.paused) { mitekMediaStreamSource.play() }
      if (typeof screenfull != "undefined" && screenfull.isEnabled) screenfull.request()
      mitekScienceSDK.cmd('SHOW_HINT', customMessages[documentType == 'DL_BACK' ? 'DL_FRONT' : documentType].firstMessage) // Show initial hint message
    })

    mitekScienceSDK.on('FRAME_PROCESSING_STARTED', function (result) {
      console.log('FRAME_PROCESSING_STARTED')
      clearTimeout(cameraStartTimer)
      attemptTimer = setTimeout(function () {
        clearTimeout(attemptTimer)
        if (typeof screenfull != "undefined" && screenfull.isEnabled) screenfull.exit()
        mitekScienceSDK.cmd('SDK_STOP')
        enableButton()
        applicationContext.applicationState.identityVerification.failedAutoCaptureCount++
        window.scrollTo(0, 0)
      }, 30000)
    })

    mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', function (result) {
      console.log('FRAME_PROCESSING_FEEDBACK result: ', result)
      recentHint = result.key;

      if (documentType == 'SELFIE') {
        // Turn guideing frame green if face is inside
        if (result.key == 'MISNAP_SMILE' || result.key == 'MISNAP_STOP_SMILING' || result.key == 'MISNAP_READY_POSE') {
          document.getElementsByClassName('integrator SELFIE')[0].classList.add('FACE_IN_GUIDE');
        }
        else document.getElementsByClassName('integrator SELFIE')[0].classList.remove('FACE_IN_GUIDE');
      }

      if (recentHint) {
        let hintMsg = autoCaptureHints[recentHint];
        if (recentHint == 'MITEK_ERROR_FOUR_CORNER') hintMsg = customMessages[documentType == 'DL_BACK' ? 'DL_FRONT' : documentType].fourCornerMessage;
        mitekScienceSDK.cmd('SHOW_HINT', hintMsg);
        updateIconAndText(hintMsg + '. Please try again.')
      }
    })

    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', function (result) {
      console.log('FRAME_CAPTURE_RESULT: ', result)
      clearTimeout(attemptTimer)
      if (typeof screenfull != "undefined" && screenfull.isEnabled) screenfull.exit()
      updateIconAndText()
      enableButton()

      applicationContext.applicationState.identityVerification.resultImages.push(result.response.imageData)
      navigateToNextStep()
    })

    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
      mode: 'AUTO_CAPTURE',
      documentType: documentType == 'DL_BACK' ? 'DL_FRONT' : documentType,
      mitekSDKPath: mitekSDKPath,
      options: {
        qualityPercent: 80,
        hintFrequencyMS: 1200,
        hintAriaLive: 2,
        guidePaddingLevel: 1,
        hintMessageSize: 2,
        disableSmileDetection: false,
        videoContainerId: null,
        videoRecordingEnabled: false
      }
    })
  }

  const startManualCapture = () => {
    applicationContext.applicationState.identityVerification.manualCaptureCount++

    mitekScienceSDK.on('SDK_ERROR', function (err) {
      console.log(err)
      enableButton()
      updateIconAndText('Unexpected camera error.')
      window.scrollTo(0, 0)
    });

    mitekScienceSDK.on('IMAGE_CAPTURED', function () {
      console.log('MANUAL IMAGE_CAPTURED')
      updateIconAndText('Processing image, please wait...')
      window.scrollTo(0, 0)
    });

    mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', function (result) {
      console.log('MANUAL FRAME_PROCESSING_FEEDBACK: ', generalHints[result.key])
      if (result.key == "IMAGE_SMALLER_THAN_MIN_SIZE" || result.key == "CORRUPT_IMAGE") enableButton()
      updateIconAndText(generalHints[result.key] + '. Please try again.')
      window.scrollTo(0, 0)
    });

    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', function (result) {
      console.log('Manual capture result', result)
      const warning = result.response?.warnings?.key
      if (warning) {
        let hintMsg = generalHints[warning];
        if (warning == 'MITEK_ERROR_FOUR_CORNER') hintMsg = customMessages[documentType].fourCornerMessage;
        updateIconAndText(hintMsg + '. Please try again.')
        enableButton()
        window.scrollTo(0, 0)
      }
      else {
        updateIconAndText()
        applicationContext.applicationState.identityVerification.resultImages.push(result.response.imageData)
        navigateToNextStep()
      }
    });

    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
      mode: 'MANUAL_CAPTURE',
      documentType: documentType == 'DL_BACK' ? 'DL_FRONT' : documentType,
      mitekSDKPath: mitekSDKPath,
      options: { qualityPercent: 80 }
    })
  }

  const updateIconAndText = (hintMsg = null) => {
    document.querySelector('#verification-icon').src = !hintMsg || (hintMsg && hintMsg == 'Processing image, please wait...') ? iconIdCardGreenFront : iconVerificationError
    document.querySelector('#capture-summary').innerHTML = hintMsg ? hintMsg : captureSummaryText()
  }

  const captureSummaryText = () => {
    if (documentType == 'DL_FRONT') {
      return 'Use your camera to take a picture of the front of your drivers license.'
    }
    else if (documentType == 'DL_BACK') {
      return 'Use your camera to take a picture of the back of your drivers license.'
    }
    else if (documentType == 'PASSPORT') {
      return 'Use your camera to take a picture of the photo page in your passport.'
    }
    else if (documentType == 'SELFIE') {
      return 'Use your front camera to take a selfie.'
    }
  }

  const navigateToNextStep = () => {
    let nextType
    let nextStep

    if (documentType == 'DL_FRONT') {
      nextType = 'DL_BACK'
      nextStep = '/capture/step2'
    }
    else if (documentType == 'DL_BACK') {
      nextType = 'SELFIE'
      nextStep = '/capture/step3'
    }
    else if (documentType == 'SELFIE') {
      nextType = null
      nextStep = '/document-summary'
    }
    else if (documentType == 'PASSPORT') {
      nextType = 'SELFIE'
      nextStep = '/capture/step2'
    }

    applicationContext.applicationState.identityVerification.documentType = nextType
    history.push(idVerifyMobileBaseUrl + nextStep)
  }

  const save = () => {}

  return (
    <>
    { browserNotSupported &&
      <>
        <h2 className="form-title mb-40">Your mobile browser is not supported</h2>
        <p id="capture-summary" className="mb-40">Please use one of the supported browsers to verify your identity: Chrome (<a className="underline text-turquoise" href="https://play.google.com/store/apps/details?id=com.android.chrome">Android</a> / <a className="underline text-turquoise" href="https://apps.apple.com/us/app/google-chrome/id535886823">iOS</a>), Firefox (<a className="underline text-turquoise" href="https://play.google.com/store/apps/details?id=org.mozilla.firefox">Android</a> / <a className="underline text-turquoise" href="https://apps.apple.com/us/app/firefox-private-safe-browser/id989804926">iOS</a>), Safari (<a className="underline text-turquoise" href="https://apps.apple.com/us/app/safari/id1146562112">iOS</a>), Samsung Internet Browser (<a className="underline text-turquoise" href="https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser">Android</a>)</p>
      </>
    }

    { !browserNotSupported &&
      <>
        <h2 className="form-title mb-40">Capture { documentTypeText[documentType] }</h2>

        <p id="capture-summary" className="mb-40">{captureSummaryText()}</p>

        <div className="border-dashed-white bg-black-o-4 border-2 border-turquoise flex items-center justify-center mb-40 rounded-lg" style={{ height: 212 }}>
          <div className="text-center">
            <img id="verification-icon" alt="" className="mb-2 mx-auto" src={documentTypeImages[documentType]} />
          </div>
        </div>

        <div className="flex flex-wrap mb-40 -mx-2 text-center text-xs">
          <div className="flex px-2 w-1/3">
            <div className="border border-timber-green-o-16 p-4 rounded-lg w-full">
              <div className="p-4">
                <img alt="" className="mb-2 mx-auto" src={documentType == 'SELFIE' ? iconMask : iconAdjust} />
              </div>

              <p>
                {documentType == 'SELFIE' ? 'No masks,' : 'Use dark'} <br />
                {documentType == 'SELFIE' ? 'hats, scarfs' : 'background!'} <br />
                {documentType == 'SELFIE' ? 'etc.' : ''}
              </p>
            </div>
          </div>

          <div className="flex px-2 w-1/3">
            <div className="border border-timber-green-o-16 p-4 rounded-lg w-full">
              <div className="p-4">
                <img alt="" className="mb-2 mx-auto" src={documentType == 'SELFIE' ? iconUserCircle : iconCropAlt} />
              </div>

              <p>
                {documentType == 'SELFIE' ? 'Center your' : 'Get all 4'} <br />
                {documentType == 'SELFIE' ? 'face in the' : 'corners in the'} <br />frame!
              </p>
            </div>
          </div>

          <div className="flex px-2 w-1/3">
            <div className="border border-timber-green-o-16 p-4 rounded-lg w-full">
              <div className="p-4">
                <img alt="" className="mb-2 mx-auto" src={iconLightbuld} />
              </div>

              <p>Make sure<br /> lighting is<br /> good!</p>
            </div>
          </div>
        </div>

        <div className="mb-40">
          <ProgressIndicator url={url} steps={steps} save={save} disableNavigation={true} />
        </div>

        <div className="flex justify-center">
          <button id="capture-button" className={`btn btn--primary lg:w-auto w-full`} onClick={startCapture} type="button">Capture {documentTypeText[documentType]}</button>
        </div>
      </>
    }
    </>
  )
}
