import { makeAutoObservable } from "mobx"

export default class ApplicantErrors {

  constructor() {
    this.openBankingErrors = 0
    this.idVerificationErrors = 0
    this.personalDetailsStep1Errors = 0
    this.personalDetailsStep2Errors = 0
    this.personalDetailsStep3Errors = 0
    this.personalDetailsStep4Errors = 0
    this.employmentStep1Errors = 0
    this.employmentStep2Errors = 0
    this.employmentStep3Errors = 0
    this.employmentStep4Errors = 0
    this.otherIncomeStep1Errors = 0
    this.otherIncomeStep2Errors = 0
    this.otherIncomeStep3Errors = 0
    this.pensionErrors = 0
    this.assetsErrors = 0
    this.commitmentsStep1Errors = 0
    this.commitmentsStep2Errors = 0
    this.generalQuestionsErrors = 0
    this.documentUploadErrors = 0

    makeAutoObservable(this)
  }
}