const customMessages = {
  DL_FRONT: {
    firstMessage: 'Center document on a dark background',
    fourCornerMessage: 'Center document on a dark background'
  },
  PDF417_BARCODE: {
    firstMessage: 'Center document on a dark background',
    fourCornerMessage: 'Scanning for barcode'
  },
  PASSPORT: {
    firstMessage: 'Center photo page on a dark background',
    fourCornerMessage: 'Center photo page on a dark background'
  },
  SELFIE: {
    firstMessage: 'Place face in oval',
    fourCornerMessage: 'Face not found'
  },
  CHECK_FRONT: {
    firstMessage: 'Center Check Front on a dark background',
    fourCornerMessage: 'Scanning for Check Front'
  },
  CHECK_BACK: {
    firstMessage: 'Center Check Back on a dark background',
    fourCornerMessage: 'Scanning for Check Back'
  },
  DOCUMENT: {
    firstMessage: 'Center document on a dark background',
    fourCornerMessage: 'Center document on a dark background'
  }
}

module.exports = customMessages
