import React, { useEffect, useState, useContext } from 'react'

import iconMainPropertyDetailsWhite from 'images/icons/main-property-details-white.svg'
import iconSolicitorWhite from 'images/icons/solicitor-white.svg'
import { useHistory, useLocation } from 'react-router-dom'
import RootStoreContext from '../../contexts/RootStoreContext'
import { CurrentBorrowerContext } from '../../contexts/CurrentBorrowerContext'
import axiosService from '../../utils/AxiosService'

export default function BorrowerSidebar() {
  const rootStore = useContext(RootStoreContext)
  const history = useHistory()
  const location = useLocation()
  const [selected, setSelected] = useState()
  const currentBorrowerContext = useContext(CurrentBorrowerContext)

  const paths = {
    'applications': 'applications',
    'manage-account': 'manageAccount',
  }

  const navigateTo = route => () => history.push(route)

  useEffect(() => {
    const currentPath = Object.keys(paths).find(path => location.pathname.includes(path))
    setSelected(paths[currentPath])
  }, [location.pathname])

  useEffect(() => getCurrentBorrower(), [])

  const getCurrentBorrower = () => {
    axiosService.get('/api/borrower/profile/current')
    .then(borrower => currentBorrowerContext.setCurrentBorrower(borrower))
    .catch(() => {})
  }

  return (
    <aside className="data-capture-sidebar">
      <div className="bg-black-o-8 px-6">
        <span className="flex font-semibold items-center justify-between px-4 py-20 text-white" href="">{currentBorrowerContext?.currentBorrower?.name}</span>
      </div>

      <div className="bg-black-o-8 px-6">
        <span className="flex items-center justify-between px-4 py-20 text-white" href="">{currentBorrowerContext?.currentBorrower?.email}</span>
      </div>

      <div className="border-b border-white-o-15 px-6 py-4">
        <button
          className={`data-capture-sidebar-link ${selected === 'applications' ? 'active' : ''}`}
          onClick={navigateTo('/borrower/applications')}
        >
          <img
            alt="Applications"
            className="mr-4"
            src={iconMainPropertyDetailsWhite}
          />
          Application(s)
        </button>

        {/* <button
          className={`data-capture-sidebar-link ${selected === 'manageAccount' ? 'active' : ''}`}
          onClick={navigateTo('/borrower/manage-account')}
        >
          <img
            alt="Manage Account"
            className="mr-4"
            src={iconDirectDebitWhite}
          />
          Manage Account
        </button> */}

        <button
          className='data-capture-sidebar-link'
          onClick={() => rootStore.deauthenticateUser()}
        >
          <img
            alt="Signout"
            className="mr-4"
            src={iconSolicitorWhite}
          />
          Sign out
        </button>
      </div>
    </aside>
  )
}
