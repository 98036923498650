import React, { useContext, useEffect } from 'react'
import NextStepButton from '../../Form/NextStepButton'

import iconDrivingLicense from 'images/icons/driving-license.svg'
import iconPassport from 'images/icons/passport.svg'
import { useParams } from 'react-router-dom'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { ApplicationContext } from '../../../contexts/ApplicationContext'

export default observer (function DocumentType() {
  const applicationContext = useContext(ApplicationContext)
  console.log('documentType.jsx', applicationContext);
  const { application_id } = useParams()
  const { id_verification_token } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {}
  }, [])

  const save = documentType => () => {
    applicationContext.applicationState.identityVerification.documentType = documentType
  }

  return (
    <>
      <h2 className="form-title mb-40">Select Document Type</h2>

      <p className="mb-40">Please select your ID type from the following options: </p>

      <div className="flex flex-wrap mb-40 -mx-4 text-center">
        <div className="flex lg:mb-0 lg:w-1/2 mb-4 px-4 w-full">
          <div className="border border-turquoise box-shadow p-6 rounded-lg w-full">
            <img alt="" className="mb-4 mx-auto" src={iconDrivingLicense} style={{ height: 110 }} />

            <NextStepButton className="w-full" nextUrl={`/borrower/applications/${application_id}/applicants/${id_verification_token}/identity-verification-mobile/capture/step1`} text="Use Driving License" save={save('DL_FRONT')} />
          </div>
        </div>

        <div className="flex lg:mb-0 lg:w-1/2 mb-4 px-4 w-full">
          <div className="border border-turquoise box-shadow p-6 rounded-lg w-full">
            <img alt="" className="mb-4 mx-auto" src={iconPassport} style={{ height: 110 }} />

            <NextStepButton className="w-full" nextUrl={`/borrower/applications/${application_id}/applicants/${id_verification_token}/identity-verification-mobile/capture/step1`} text="Use Passport" save={save('PASSPORT')} />
          </div>
        </div>
      </div>
    </>
  )
}
)
