import { observer } from 'mobx-react-lite'
import React from 'react'
import DocumentViewer from './DocumentsParts/DocumentViewer'

export default observer(function DocumentsPart({ applicant, index }) {

  return (
    <div>
      <h2 className="form-title mb-40">Applicant {index + 1}'s Documents</h2>
      <DocumentViewer documentTypeName="Proof of ID" documentType="Proof of ID" applicant={applicant} />
      <DocumentViewer documentTypeName="Current Account Statement" documentType="Current Account Statement" applicant={applicant} />
      <DocumentViewer documentTypeName="Savings Account Statement" documentType="Savings Account Statement" applicant={applicant} />
      <DocumentViewer documentTypeName="Credit Card Statement" documentType="Credit Card Statement" applicant={applicant} />
      {/* <DocumentViewer documentTypeName="Other Borrowing Statement" documentType="Other borrowing Statement" applicant={applicant} /> */}
      <DocumentViewer documentTypeName="Irish Residence Permit" documentType="Irish Residence Permit (IRP)" applicant={applicant} />
      <DocumentViewer documentTypeName="Salary Certificate" documentType="Salary Certificate" applicant={applicant} />
      <DocumentViewer documentTypeName="Payslip" documentType="Payslip" applicant={applicant} />
      <DocumentViewer documentTypeName="Employment Detail Summary" documentType="Employment Detail Summary" applicant={applicant} />
      <DocumentViewer documentTypeName="Proof of Deposit" documentType="Proof of Deposit" applicant={applicant} />
    </div>
  )

})