import React from 'react'
import NumberFormat from 'react-number-format'

export default function CurrencyTextField({ className, value }) {
  return (
    <NumberFormat
      className={className}
      thousandsGroupStyle="thousand"
      value={value}
      decimalSeparator="."
      displayType="text"
      type="text"
      thousandSeparator=","
    />
  )
}