import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Applications from './Applications/Applications'
import NewApplication from './Applications/NewApplication'
import Borrowers from './Borrowers/Borrowers'
import BrokerSidebar from './BrokerSidebar'
import ManageAccount from './ManageAccount/ManageAccount'
import AffordabilityCalculator from '../Landing/AffordabilityCalculator'

export default function BrokerDashboard() {
  const location = useLocation()
  return (
    <section>
      <div className={`${location.pathname === '/broker/applications' ? 'container--application-dashboard' : 'container container--document'} py-16`}>
        <BrokerSidebar />
        <Switch>
          <Route exact path="/broker/new-application">
            <NewApplication />
          </Route>
          <Route exact path="/broker/applications">
            <Applications />
          </Route>
          <Route path="/broker/borrowers">
            <Borrowers />
          </Route>
          <Route path="/broker/manage-account">
            <ManageAccount />
          </Route>
          <Route path="/broker/affordability-calculator">
            <AffordabilityCalculator />
          </Route>
        </Switch>
      </div>
    </section>
  )
}