import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Applicant from '../../../models/Applicant'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import NextStepButton from '../../Form/NextStepButton'
import DocumentUploadSection from './DocumentUploadSection'
import ProofOfIdSection from './ProofOfIdSection'
import OpenBankingPdf from './OpenBankingPdf'

export default observer(function ApplicantDocumentUpload() {
  const { application_id, applicant_id } = useParams()
  const { applicationState: { applicants, disabled, type, backend_url_part } } = useContext(ApplicationContext)

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const getNextUrl = () => {
    if(+applicant_id === +applicants[applicants.length - 1]?.id) return `/${type}/applications/${application_id}/loan-requirements/step1`
    const applicantIndex = applicants.findIndex(applicant => +applicant.id == applicant_id)
    const nextApplicantId = applicants[applicantIndex + 1]?.id
    return `/${type}/applications/${application_id}/applicants/${nextApplicantId}/identity-verification/step1`
  }

  return (
    <div>
      <h2 className="form-title mb-40">Document Upload</h2>
      { !disabled && <ApplicantStepUpdater step="document_upload_visited" /> }

      <ProofOfIdSection type={type} backendUrlPart={backend_url_part} currentApplicant={currentApplicant} disabled={disabled} />
      <OpenBankingPdf tooltip="Your bank statements are being prepared and will appear in the respective statement section below when completed" type={type} />
      <DocumentUploadSection tooltip="Please provide statements for any accounts not shared using open banking" documentTypeName="Current Account Statement" documentType="Current Account Statement" currentApplicant={currentApplicant} />
      <DocumentUploadSection tooltip="Please provide statements for any accounts not shared using open banking" documentTypeName="Savings Account Statement" documentType="Savings Account Statement" currentApplicant={currentApplicant} optional />
      <DocumentUploadSection tooltip="Please provide statements for any accounts not shared using open banking" documentTypeName="Credit Card Statement" documentType="Credit Card Statement" currentApplicant={currentApplicant} optional />
      <DocumentUploadSection tooltip="Tooltip Missing" documentTypeName="Other Borrowing Statement" documentType="Other borrowing Statement" currentApplicant={currentApplicant} optional />
      <DocumentUploadSection tooltip={"Applicants must have the permanent right to reside in the Republic of Ireland. Non EEA citizens need to demonstrate rights to work and reside in the Republic of Ireland.\n (A) A visa stamp in a passport (for this purpose only, the passport may be expired)\n (B) A Republic of Ireland Residence Permit\n (C) GNIB (Garda National Immigration Bureau) Card"} documentTypeName="Irish Residence Permit" documentType="Irish Residence Permit (IRP)" currentApplicant={currentApplicant} optional />
      <DocumentUploadSection tooltip="Salary Certificate to be completed and stamped by your employer confirming employment status and basic salary. Certificate must be dated within the last three months." documentTypeName="Salary Certificate" documentType="Salary Certificate" currentApplicant={currentApplicant} />
      <DocumentUploadSection tooltip={`Please upload, depending on your salary frequency:
        -3 most recent monthly  payslips, or
        -5 most recent forthnightly  payslips, or
        -9 most recent weekly  payslips,
        where the last dated pay slip must be within 3 months of the mortgage application date`} documentTypeName="Payslip" documentType="Payslip" currentApplicant={currentApplicant} />
      <DocumentUploadSection documentTypeName="Employment Detail Summary" documentType="Employment Detail Summary" currentApplicant={currentApplicant} tooltip={`Please upload your Employment Detail Summary for the previous year. To find out how to access your Employment Detail Summary click <a href="https://www.revenue.ie/en/jobs-and-pensions/end-of-year-process/employment-detail-summary.aspx" target="_blank">here</a>`} />
      { currentApplicant.employment.are_you == 'Self Employed' &&
        <Fragment>
          <DocumentUploadSection tooltip="Tooltip Missing" documentTypeName="Revenue acknowledged Forms 11" documentType="Revenue acknowledged Forms 11" currentApplicant={currentApplicant} />
          <DocumentUploadSection tooltip="Tooltip Missing" documentTypeName="Business Current Account Statement" documentType="Business Current Account Statement" currentApplicant={currentApplicant} />
          <DocumentUploadSection tooltip="Tooltip Missing" documentTypeName="Business Loan Account Statement" documentType="Business Loan Account Statement" currentApplicant={currentApplicant} />
          <DocumentUploadSection tooltip="Tooltip Missing" documentTypeName="Audited Accounts" documentType="Audited Accounts" currentApplicant={currentApplicant} />
          <DocumentUploadSection tooltip="Tooltip Missing" documentTypeName="Accountant Tax affairs confirmation" documentType="Accountant Tax affairs confirmation" currentApplicant={currentApplicant} />
        </Fragment>
      }
      <DocumentUploadSection tooltip="If your deposit is coming from savings and you have already uploaded savings account statements, there is no requirement to upload again." documentTypeName="Proof of Deposit" documentType="Proof of Deposit" currentApplicant={currentApplicant} optional />
      {currentApplicant.documents.find(item => item.document_type === 'Other borrowing Statement') && <DocumentUploadSection documentTypeName="Other Account Statements" documentType="Other borrowing Statement" currentApplicant={currentApplicant} optional hideUpload />}
      <DocumentUploadSection tooltip="Tooltip Missing" documentTypeName="Other Documents" documentType="Other Documents" currentApplicant={currentApplicant} />
      {type === "broker" && <DocumentUploadSection tooltip="Tooltip Missing" documentTypeName="Broker Documents" documentType="Broker Documents" currentApplicant={currentApplicant} optional />}

      <div className="flex justify-between mt-16">
        <NextStepButton nextUrl={getNextUrl()} />
      </div>
    </div>
  )

})
