import React, { Fragment, useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import BtnGroup from '../../Form/BtnGroup'
import Input from '../../Form/Input'
import NextStepButton from '../../Form/NextStepButton'
import ReactSelect from '../../Form/ReactSelect'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import axiosService from '../../../utils/AxiosService'
import { observer } from 'mobx-react-lite'
import { Formik } from 'formik'
import { mainPropertyDetailsSchema } from '../../../schemas/MainPropertyDetailsSchema'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import ApplicationStepUpdater from '../../Form/ApplicationStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'
import LoqateWrapper from '../../Form/LoqateWrapper'

export default observer(function MainPropertyDetails() {
  const { applicationState } = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id } = useParams()
  const formikRef = useRef()

  const propertyTentureOptions = [
    { label: 'Freehold', value: 'FH' },
    { label: 'Leasehold', value: 'LH' },
  ]

  const propertyTypeOptions = [
    { label: 'Apartment', value: 'PURBFA' },
    { label: 'Bungalow', value: 'DTBLW' },
    { label: 'Detached', value: 'DTDH' },
    { label: 'Semi-Detached', value: 'SEMDTDH' },
    { label: 'Terraced', value: 'TCDH' },
  ]

  const berRatingOptions = [
    { label: 'A1', value: 'A1' },
    { label: 'A2', value: 'A2' },
    { label: 'A3', value: 'A3' },
    { label: 'B1', value: 'B1' },
    { label: 'B2', value: 'B2' },
    { label: 'B3', value: 'B3' },
    { label: 'C1', value: 'C1' },
    { label: 'C2', value: 'C2' },
    { label: 'C3', value: 'C3' },
    { label: 'D1', value: 'D1' },
    { label: 'D2', value: 'D2' },
    { label: 'E1', value: 'E1' },
    { label: 'E2', value: 'E2' },
    { label: 'F', value: 'F' },
    { label: 'G', value: 'G' },
  ]

  const save = security => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${applicationState.backend_url_part}/applications/${application_id}`, { application: { security_attributes: security }})
    .then(() => {
      notifyProgressSaved()
      applicationState.security = security
    })
  }

  const touchFields = () => formikRef.current.setTouched(Object.keys(applicationState.security).reduce((result, key) => { result[key] = true; return result },{}))

  useEffect(() => {
    if(applicationState.application.steps.main_property_details_visited) touchFields()
    applicationErrorsContext.validateMainPropertyDetails()
  }, [applicationState.loading, applicationState.security])

  return (
    <>
      <h2 className="form-title mb-40">Main Property Details</h2>
      {!applicationState.disabled && <ApplicationStepUpdater step="main_property_details_visited" />}

      <Formik
        initialValues={applicationState.security}
        validationSchema={mainPropertyDetailsSchema}
        enableReinitialize={applicationState.loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <YesNoBtnGroup
                    label="Specific property in mind?"
                    name="specific_property_in_mind"
                    onChange={(name, value) => {
                      setFieldValue(name, value, true)
                      setFieldValue('property_address_post_code', '', true)
                      setFieldValue('property_address_line1', '', true)
                      setFieldValue('property_address_line2', '', true)
                      setFieldValue('property_address_line3', '', true)
                      setFieldValue('property_address_city', '', true)
                      setFieldValue('property_address_county', '', true)
                      setFieldValue('property_address_country', 'IRL', true)
                      setFieldValue('property_type', '', true)
                      setFieldValue('energy_performance_certificate', '', true)
                      setFieldValue('around_commercial_premises', false, true)
                      setFieldValue('property_tenure', '', true)
                      setFieldValue('service_charge', '', true)
                      setFieldValue('ground_rent', '', true)
                      setFieldValue('unexpired_remaining_lease', '', true)
                    }}
                    tooltip="Have you identified a property you would like to purchase? If switching your mortgage please provide details on the mortgaged property"
                    value={values.specific_property_in_mind}
                    disabled={applicationState.disabled}
                  />
                </div>

                {values.specific_property_in_mind && (
                  <>
                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('property_address_post_code', address.PostalCode, true)
                          setFieldValue('property_address_line1', address.Line1, true)
                          setFieldValue('property_address_line2', address.Line2, true)
                          setFieldValue('property_address_line3', address.Line3, true)
                          setFieldValue('property_address_city', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('property_address_county', 'Dublin', true)
                            : setFieldValue('property_address_county', address.Province, true)
                          setFieldValue('property_address_country', address.CountryIso3, true)
                        }}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        autoFocus={true}
                        error={errors.property_address_post_code && touched.property_address_post_code && errors.property_address_post_code}
                        label="Eircode"
                        name="property_address_post_code"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('property_address_county', 'Dublin', true)
                          handleChange(e)
                        }}
                        type="text"
                        value={values.property_address_post_code}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.property_address_line1 && touched.property_address_line1 && errors.property_address_line1}
                        label="Address line 1"
                        name="property_address_line1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.property_address_line1}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.property_address_line2 && touched.property_address_line2 && errors.property_address_line2}
                        label="Address line 2"
                        name="property_address_line2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        optional
                        type="text"
                        value={values.property_address_line2}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.property_address_line3 && touched.property_address_line3 && errors.property_address_line3}
                        label="Address line 3"
                        name="property_address_line3"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        optional
                        type="text"
                        value={values.property_address_line3}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.property_address_city && touched.property_address_city && errors.property_address_city}
                        label="City"
                        name="property_address_city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.property_address_city}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        error={errors.property_address_country && touched.property_address_country && errors.property_address_country}
                        label="Country"
                        name="property_address_country"
                        onChange={property_address_country => setFieldValue('property_address_country', property_address_country.value, true)}
                        value={values.property_address_country}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        error={errors.energy_performance_certificate && touched.energy_performance_certificate && errors.energy_performance_certificate}
                        label="BER Rating"
                        name="energy_performance_certificate"
                        onChange={energy_performance_certificate => setFieldValue('energy_performance_certificate', energy_performance_certificate.value, true)}
                        options={berRatingOptions}
                        value={values.energy_performance_certificate}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <YesNoBtnGroup
                        error={errors.around_commercial_premises && touched.around_commercial_premises && errors.around_commercial_premises}
                        label="Property over a commerical unit?"
                        name="around_commercial_premises"
                        tooltip="Is the property located over commercial premises?"
                        onChange={(name, value) => setFieldValue(name, value, true)}
                        value={values.around_commercial_premises}
                        disabled={applicationState.disabled}
                        tooltip="Properties above or adjacent to commercial units are not permitted."
                      />
                    </div>
                  </>
                )}

                <div className="mb-40">
                  <ReactSelect
                      error={errors.property_type && touched.property_type && errors.property_type}
                      label="Property Type"
                      name="property_type"
                      onChange={property_type => setFieldValue('property_type', property_type.value, true)}
                      options={propertyTypeOptions}
                      value={values.property_type}
                      disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                      error={errors.property_address_county && touched.property_address_county && errors.property_address_county}
                      label="County"
                      name="property_address_county"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.property_address_county}
                      disabled={applicationState.disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                { values.specific_property_in_mind &&
                  <Fragment>
                    <div className="mb-40">
                      <BtnGroup
                        error={errors.property_tenure && touched.property_tenure && errors.property_tenure}
                        label="What is the property tenure?"
                        name="property_tenure"
                        onChange={(name, value) => {
                          setFieldValue(name, value, true)
                          setFieldValue('service_charge', '', true)
                          setFieldValue('ground_rent', '', true)
                          setFieldValue('unexpired_remaining_lease', '', true)
                        }}
                        options={propertyTentureOptions}
                        tooltip="Leasehold properties require a minimum of 25 years unexpired term on the lease at the end of the mortgage term."
                        value={values.property_tenure}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    { values.property_tenure === 'LH' && (
                      <>
                        <div className="mb-40">
                          <CurrencyField
                            addon="€"
                            addonPosition="start"
                            error={errors.service_charge && touched.service_charge && errors.service_charge}
                            label="Service Charge"
                            name="service_charge"
                            onBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            value={values.service_charge}
                            disabled={applicationState.disabled}
                          />
                        </div>

                        <div className="mb-40">
                          <CurrencyField
                            addon="€"
                            addonPosition="start"
                            error={errors.ground_rent && touched.ground_rent && errors.ground_rent}
                            label="Ground Rent"
                            name="ground_rent"
                            onBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            value={values.ground_rent}
                            disabled={applicationState.disabled}
                          />
                        </div>

                        <div className="mb-40">
                          <CurrencyField
                            error={errors.unexpired_remaining_lease && touched.unexpired_remaining_lease && errors.unexpired_remaining_lease}
                            label="Remaining Lease in Years"
                            name="unexpired_remaining_lease"
                            onBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            value={values.unexpired_remaining_lease}
                            disabled={applicationState.disabled}
                          />
                        </div>
                      </>
                    )}
                  </Fragment>
                }
              </div>
            </div>

            <div className="flex justify-between mt-16">
              {!applicationState.disabled && <button className="btn btn--secondary" type="submit">Save Progress</button>}
              <NextStepButton nextUrl={`/${applicationState.type}/applications/${application_id}/portfolio`} save={applicationState.disabled ? () => {} : save(values)}/>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
})
