import React from 'react'

export default function DocumentFailedUploadRow({ fileName }) {

  return (
    <div className="flex items-center mb-3">

      <div className="w-full">
        <p>{fileName}</p>
        <p className="text-punch ">
          <span className="font-bold">Upload failed:</span> Please check internet connection, the file is valid and try again
        </p>
      </div>
    </div>
  )
}
