import { makeAutoObservable } from "mobx"

export default class Assets {
  constructor(assets={}) {
    this.cash_deposits = assets.cash_deposits || 0
    this.cash_deposits_jointly_owned = assets.cash_deposits_jointly_owned || false
    this.stocks_shares = assets.stocks_shares || 0
    this.stock_share_jointly_owned = assets.stock_share_jointly_owned || false
    // this.investments_amount = assets.investments_amount || ''
    // this.other_investments1 = assets.other_investments1 || ''
    // this.investment_type1 = assets.investment_type1 || ''
    this.assets_amount = assets.assets_amount || 0
    this.other_assets1 = assets.other_assets1 || ''
    this.asset_type1 = assets.asset_type1 || ''
    this.other_assets2 = assets.other_assets2 || ''
    this.asset_type2 = assets.asset_type2 || ''
    this.other_assets3 = assets.other_assets3 || ''
    this.asset_type3 = assets.asset_type3 || ''
    this.other_assets4 = assets.other_assets4 || ''
    this.asset_type4 = assets.asset_type4 || ''
    this.other_assets5 = assets.other_assets5 || ''
    this.asset_type5 = assets.asset_type5 || ''
    this.other_assets6 = assets.other_assets6 || ''
    this.asset_type6 = assets.asset_type6 || ''
    this.other_assets7 = assets.other_assets7 || ''
    this.asset_type7 = assets.asset_type7 || ''
    this.other_assets8 = assets.other_assets8 || ''
    this.asset_type8 = assets.asset_type8 || ''
    this.other_assets9 = assets.other_assets9 || ''
    this.asset_type9 = assets.asset_type9 || ''
    this.other_assets10 = assets.other_assets10 || ''
    this.asset_type10 = assets.asset_type10 || ''
    makeAutoObservable(this)
  }
}
