import React, { useContext, useEffect, useMemo, useRef } from 'react'
import Input from '../../Form/Input'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import NextStepButton from '../../Form/NextStepButton'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Applicant from '../../../models/Applicant'
import Pension from '../../../models/Pension'
import { Formik } from 'formik'
import { pensionSchema } from '../../../schemas/PensionSchema'
import axiosService from '../../../utils/AxiosService'
import { observer } from 'mobx-react-lite'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'

export default observer(function PensionPage() {
  const { applicationState: { applicants, loading, disabled, type, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const save = pension => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { pension }})
    .then(() => {
      notifyProgressSaved()
      currentApplicant.pension = new Pension(pension)
    })
  }

  const touchFields = () => formikRef.current.setTouched(Object.keys(currentApplicant.pension).reduce((result, field) => { result[field] = true; return result },{}))

  useEffect(() => {
    if(currentApplicant.steps.pension_visited) touchFields()
    applicationErrorsContext.validatePension(currentApplicant.pension, applicant_id)
  }, [loading, currentApplicant.pension])

  return (
    <>
      <h2 className="form-title mb-40">Pension Income</h2>
      { !disabled && <ApplicantStepUpdater step="pension_visited" /> }

      <Formik
        initialValues={currentApplicant.pension}
        validationSchema={pensionSchema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >

        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <YesNoBtnGroup
                    error={errors.do_you_have_pension_income && touched.do_you_have_pension_income && errors.do_you_have_pension_income}
                    label="Do you have pension income?"
                    name="do_you_have_pension_income"
                    onChange={(name, value) => {
                      setFieldValue(name, value, true)
                      setFieldValue('gross_pension_income', 0, true)
                      setFieldValue('annual_state_pension_income', 0, true)
                    }}
                    value={values.do_you_have_pension_income}
                    disabled={disabled}
                  />
                </div>

                { values.do_you_have_pension_income &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={errors.gross_pension_income && touched.gross_pension_income && errors.gross_pension_income}
                      label="Annual Private Workplace Pension Income"
                      name="gross_pension_income"
                      noRightPadding
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      value={values.gross_pension_income}
                      disabled={disabled}
                    />
                  </div>
                }
              </div>

              <div className="px-12 w-1/2">
                {values.do_you_have_pension_income &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={errors.annual_state_pension_income && touched.annual_state_pension_income && errors.annual_state_pension_income}
                      label="Annual State Pension Income"
                      name="annual_state_pension_income"
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      value={values.annual_state_pension_income}
                      disabled={disabled}
                    />
                  </div>
                }
              </div>
            </div>
            <div className="flex justify-between mt-16">
              {!disabled && <button className="btn btn--secondary" type="button" onClick={save(values)}>Save Progress</button>}
              <NextStepButton nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/assets`} save={disabled ? () => {} : save(values)}/>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
})
