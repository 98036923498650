import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../../models/Applicant'
import Income from '../../../../models/Income'
import { EmploymentStep1SelfEmployedSchema } from '../../../../schemas/EmploymentSchemas/EmploymentStep1SelfEmployedSchema'
import axiosService from '../../../../utils/AxiosService'
import BusinessSectorSelector from '../../../Form/BusinessSectorSelector'
import DatePicker from '../../../Form/DatePicker'
import Input from '../../../Form/Input'
import { notifyProgressSaved } from '../../../Form/Notifications'
import ReactSelect from '../../../Form/ReactSelect'
import EmploymentActions from '../EmploymentActions'
import EmploymentSelector from './EmploymentSelector'

export default observer(function SelfEmployedFormPart() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ['self_employed_company_number', 'business_type', 'nature_of_business', 'name_of_business', 'percentage_of_shareholding', 'date_established', 'date_owned_or_part_owned', 'current_year_end', 'self_employed_date']

  const businessTypeOptions = [
    { label: 'Sole Trader', value: '1' },
    { label: 'Partnership', value: '2' },
    { label: 'LLP', value: '3' },
    { label: 'Limited Company', value: '4' },
  ]

  const save = income => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { income, employment: currentApplicant.employment, steps: {...currentApplicant.steps, employment_step1_visited: true } } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.income = new Income(income)
      currentApplicant.steps.employment_step1_visited = true
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  useEffect(() => {
    if(currentApplicant.steps.employment_step1_visited) touchFields()
    applicationErrorsContext.validateEmploymentStep1SelfEmployed(currentApplicant.income, applicant_id)
  }, [loading, currentApplicant.income])

  return (
    <Formik
      initialValues={{...currentApplicant.income, are_you: currentApplicant.employment.are_you }}
      validationSchema={EmploymentStep1SelfEmployedSchema}
      enableReinitialize={loading}
      innerRef={formikRef}
    >
      {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
        <form onSubmit={save(values)}>

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <EmploymentSelector disabled={disabled} applicant={currentApplicant} setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} handleBlur={handleBlur} />

              <div className="mb-40">
                <ReactSelect
                  error={errors.business_type && touched.business_type && errors.business_type}
                  label="Business type"
                  options={businessTypeOptions}
                  placeholder="Select Business type"
                  name="business_type"
                  onChange={business_type => {
                    setFieldValue('business_type', business_type.value, true)
                    if(business_type.value !== '4') setFieldValue('self_employed_company_number', '', true)
                    setFieldValue('percentage_of_shareholding', '', true)
                    setFieldValue('date_established', '', true)
                    setFieldValue('date_owned_or_part_owned', '', true)
                    setFieldValue('self_employed_date', '', true)
                    setFieldValue('current_year_end', '', true)
                    setFieldValue('limited_company_salary1', 0, true)
                    setFieldValue('limited_company_salary2', 0, true)
                    setFieldValue('limited_company_salary3', 0, true)
                    setFieldValue('salary1', 0, true)
                    setFieldValue('salary2', 0, true)
                    setFieldValue('salary3', 0, true)
                    setFieldValue('year1', 0, true)
                    setFieldValue('year2', 0, true)
                    setFieldValue('year3', 0, true)
                  }}
                  value={values.business_type}
                  disabled={disabled}
                />
              </div>

              <div className="mb-40">
                <BusinessSectorSelector
                  error={errors.nature_of_business && touched.nature_of_business && errors.nature_of_business}
                  name="nature_of_business"
                  onChange={nature_of_business => setFieldValue('nature_of_business', nature_of_business.value, true)}
                  value={values.nature_of_business}
                  disabled={disabled}
                />
              </div>

              <div className="mb-40">
                <Input
                  error={errors.name_of_business && touched.name_of_business && errors.name_of_business}
                  label="Name of Business"
                  name="name_of_business"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name_of_business}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="px-12 w-1/2">
              { values.business_type !== '1' &&
                <Fragment>
                  <div className="mb-40">
                    <Input
                      addon="%"
                      addonPosition="end"
                      error={errors.percentage_of_shareholding && touched.percentage_of_shareholding && errors.percentage_of_shareholding}
                      label="Percentage Shareholding"
                      name="percentage_of_shareholding"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.percentage_of_shareholding}
                      disabled={disabled}
                    />
                  </div>

                  <div className="mb-40">
                    <DatePicker
                      error={errors.date_established && touched.date_established && errors.date_established}
                      label="Date established"
                      name="date_established"
                      onChange={value => setFieldValue('date_established', value, true)}
                      value={values.date_established}
                      disabled={disabled}
                    />
                  </div>
                  <div className="mb-40">
                    <DatePicker
                      error={errors.date_owned_or_part_owned && touched.date_owned_or_part_owned && errors.date_owned_or_part_owned}
                      label="Date owned or part owned the business"
                      name="date_owned_or_part_owned"
                      onChange={value => setFieldValue('date_owned_or_part_owned', value, true)}
                      value={values.date_owned_or_part_owned}
                      disabled={disabled}
                    />
                  </div>
                </Fragment>
              }

              { values.business_type !== '4' &&
                <div className="mb-40">
                  <DatePicker
                    error={errors.self_employed_date && touched.self_employed_date && errors.self_employed_date}
                    label="Date Self-employment commenced"
                    name="self_employed_date"
                    onChange={value => setFieldValue('self_employed_date', value, true)}
                    value={values.self_employed_date}
                    disabled={disabled}
                  />
                </div>
              }


              { values.business_type !== '1' &&
                <div className="mb-40">
                  <DatePicker
                    error={errors.current_year_end && touched.current_year_end && errors.current_year_end}
                    label="Current Year End date"
                    name="current_year_end"
                    onChange={value => setFieldValue('current_year_end', value, true)}
                    value={values.current_year_end}
                    disabled={disabled}
                  />
                </div>
              }
            </div>
          </div>
          <EmploymentActions save={save(values)} />
        </form>
      )}
    </Formik>
  )
})