import React from 'react'

export default function Profile() {

  return (
    <div>
      <h2 className="form-title mb-40">Profile</h2>
    </div>
  )

}