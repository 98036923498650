import * as Yup from 'yup'
import moment from 'moment'

const startingDateIsSoonerThan3Years = () => {
  return [
    ['date_joined', 'date_contract_started', 'employment_type'],
    {
      is: (date_joined, date_contract_started, employment_type) => {
        const currentDate = employment_type === '2' ? date_joined : date_contract_started
        return currentDate && moment(currentDate) > moment().subtract(3, "year")
      },
      then: Yup.string().nullable().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
    }
  ]
}


const startingDateIsSoonerThan3YearsForDate = () => {
  return [
    ['date_joined', 'date_contract_started', 'employment_type'],
    {
      is: (date_joined, date_contract_started, employment_type) => {
        const currentDate = employment_type === '2' ? date_joined : date_contract_started
        return currentDate && moment(currentDate) > moment().subtract(3, "year")
      },
      then: Yup.string().required('This field is required.'),
    }
  ]
}

const lastDateStartIsSoonerThan3Years = previous_employment_start_date => {
  return [
    previous_employment_start_date,
    {
      is: previous_employment_start_date => previous_employment_start_date && moment(previous_employment_start_date) > moment().subtract(3, "year"),
      then: Yup.string().nullable().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
    }
  ]
}


const lastDateStartIsSoonerThan3YearsForDate = previous_employment_start_date => {
  return [
    previous_employment_start_date,
    {
      is: previous_employment_start_date => previous_employment_start_date && moment(previous_employment_start_date) > moment().subtract(3, "year"),
      then: Yup.string().required('This field is required.'),
    }
  ]
}

export const EmploymentStep4EmployedSchema = Yup.object().shape({
  previous_job_title: Yup.string().when(...startingDateIsSoonerThan3Years()),
  previous_employer_name: Yup.string().when(...startingDateIsSoonerThan3Years()),
  previous_address_post_code: Yup.string().when(...startingDateIsSoonerThan3Years()),
  previous_address_line1: Yup.string().when(...startingDateIsSoonerThan3Years()),
  previous_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous_address_city: Yup.string().when(...startingDateIsSoonerThan3Years()),
  previous_address_country: Yup.mixed().nullable().when(...startingDateIsSoonerThan3Years()),
  previous_nature_of_business: Yup.mixed().nullable().when(...startingDateIsSoonerThan3Years()),
  previous_date_joined: Yup.string().when(...startingDateIsSoonerThan3YearsForDate()),
  previous_date_left: Yup.string().when(...startingDateIsSoonerThan3YearsForDate()),
  previous_job_title2: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined')),
  previous_employer_name2: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined')),
  previous_address_post_code2: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined')),
  previous2_address_line1: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined')),
  previous2_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous2_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous_address_city2: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined')),
  previous_address_country2: Yup.mixed().nullable().when(...lastDateStartIsSoonerThan3Years('previous_date_joined')),
  previous_nature_of_business2: Yup.mixed().nullable().when(...lastDateStartIsSoonerThan3Years('previous_date_joined')),
  previous_date_joined2: Yup.string().when(...lastDateStartIsSoonerThan3YearsForDate('previous_date_joined')),
  previous_date_left2: Yup.string().when(...lastDateStartIsSoonerThan3YearsForDate('previous_date_joined')),
  previous_job_title3: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined2')),
  previous_employer_name3: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined2')),
  previous_address_post_code3: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined2')),
  previous3_address_line1: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined2')),
  previous3_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous3_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous_address_city3: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined2')),
  previous_address_country3: Yup.mixed().nullable().when(...lastDateStartIsSoonerThan3Years('previous_date_joined2')),
  previous_nature_of_business3: Yup.mixed().nullable().when(...lastDateStartIsSoonerThan3Years('previous_date_joined2')),
  previous_date_joined3: Yup.string().when(...lastDateStartIsSoonerThan3YearsForDate('previous_date_joined2')),
  previous_date_left3: Yup.string().when(...lastDateStartIsSoonerThan3YearsForDate('previous_date_joined2')),
  previous_job_title4: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined3')),
  previous_employer_name4: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined3')),
  previous_address_post_code4: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined3')),
  previous4_address_line1: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined3')),
  previous4_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous4_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous_address_city4: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined3')),
  previous_address_country4: Yup.mixed().nullable().when(...lastDateStartIsSoonerThan3Years('previous_date_joined3')),
  previous_nature_of_business4: Yup.mixed().nullable().when(...lastDateStartIsSoonerThan3Years('previous_date_joined3')),
  previous_date_joined4: Yup.string().when(...lastDateStartIsSoonerThan3YearsForDate('previous_date_joined3')),
  previous_date_left4: Yup.string().when(...lastDateStartIsSoonerThan3YearsForDate('previous_date_joined3')),
  previous_job_title5: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined4')),
  previous_employer_name5: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined4')),
  previous_address_post_code5: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined4')),
  previous5_address_line1: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined4')),
  previous5_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous5_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous_address_city5: Yup.string().when(...lastDateStartIsSoonerThan3Years('previous_date_joined4')),
  previous_address_country5: Yup.mixed().nullable().when(...lastDateStartIsSoonerThan3Years('previous_date_joined4')),
  previous_nature_of_business5: Yup.mixed().nullable().when(...lastDateStartIsSoonerThan3Years('previous_date_joined4')),
  previous_date_joined5: Yup.string().when(...lastDateStartIsSoonerThan3YearsForDate('previous_date_joined4')),
  previous_date_left5: Yup.string().when(...lastDateStartIsSoonerThan3YearsForDate('previous_date_joined4')),
});