import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../../models/Applicant'
import Employment from '../../../../models/Employment'
import { EmploymentStep1EmployedSchema } from '../../../../schemas/EmploymentSchemas/EmploymentStep1EmployedSchema'
import axiosService from '../../../../utils/AxiosService'
import BtnGroup from '../../../Form/BtnGroup'
import BusinessSectorSelector from '../../../Form/BusinessSectorSelector'
import DatePicker from '../../../Form/DatePicker'
import Input from '../../../Form/Input'
import { notifyProgressSaved } from '../../../Form/Notifications'
import PhoneField from '../../../Form/PhoneField'
import ReactSelect from '../../../Form/ReactSelect'
import YesNoBtnGroup from '../../../Form/YesNoBtnGroup'
import EmploymentActions from '../EmploymentActions'
import EmploymentSelector from './EmploymentSelector'

export default observer(function EmployedFormPart() {

  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ["are_you", "job_title", 'employer_telephone', "employer_name", "business_sector", "nature_of_business", "employment_type", "date_joined", 'date_contract_started', "date_contract_ends", "on_probation", "probation_end_date", "redundancy_notice", "redundancy_remaining_details"]

  const employmentSectorOptions = [
    { label: 'Private', value: '2' },
    { label: 'Public', value: '1' },
  ]

  const employmentTypeOptions = [
    { label: 'Temporary', value: '1' },
    { label: 'Permanent', value: '2' },
    { label: 'Fixed Term', value: '3' },
  ]

  const save = employment => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { employment, income: currentApplicant.income, steps: {...currentApplicant.steps, employment_step1_visited: true } } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.employment = new Employment(employment)
      currentApplicant.steps.employment_step1_visited = true
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  useEffect(() => {
    if(currentApplicant.steps.employment_step1_visited) touchFields()
    applicationErrorsContext.validateEmploymentStep1Employed(currentApplicant.employment, applicant_id)
  }, [loading, currentApplicant.employment])


  return (
    <Formik
      initialValues={currentApplicant.employment}
      validationSchema={EmploymentStep1EmployedSchema}
      enableReinitialize={loading}
      innerRef={formikRef}
    >
      {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
        <form onSubmit={save(values)}>

          <div className="flex flex-wrap -mx-12">
            <div className="px-12 w-1/2">
              <EmploymentSelector applicant={currentApplicant} disabled={disabled} setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} handleBlur={handleBlur} />
              <div className="mb-40">
                <Input
                  error={errors.job_title && touched.job_title && errors.job_title}
                  label="Job Title"
                  name="job_title"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.job_title}
                  disabled={disabled}
                />
              </div>

              <div className="mb-40">
                <Input
                  error={errors.employer_name && touched.employer_name && errors.employer_name}
                  label="Employer Name"
                  name="employer_name"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.employer_name}
                  disabled={disabled}
                />
              </div>

              <div className="mb-40">
                <PhoneField
                  error={errors.employer_telephone && touched.employer_telephone && errors.employer_telephone}
                  label="Employer Phone Number"
                  name="employer_telephone"
                  onChange={value => setFieldValue('employer_telephone', value, true)}
                  onBlur={handleBlur}
                  value={values.employer_telephone}
                  disabled={disabled}
                />
              </div>

              <div className="mb-40">
                <BtnGroup
                  error={errors.business_sector && touched.business_sector && errors.business_sector}
                  label="Employment Sector"
                  name="business_sector"
                  options={employmentSectorOptions}
                  onChange={(name, value) => setFieldValue(name, value, true)}
                  value={values.business_sector}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="px-12 w-1/2">

              <div className="mb-40">
                <BusinessSectorSelector
                  error={errors.nature_of_business && touched.nature_of_business && errors.nature_of_business}
                  name="nature_of_business"
                  onChange={nature_of_business => setFieldValue('nature_of_business', nature_of_business.value, true)}
                  value={values.nature_of_business}
                  disabled={disabled}
                />
              </div>

              <div className="mb-40">
                <ReactSelect
                  error={errors.employment_type && touched.employment_type && errors.employment_type}
                  label="Employment Type"
                  name="employment_type"
                  options={employmentTypeOptions}
                  placeholder="Select Employment Type"
                  onChange={employment_type => {
                    setFieldValue('employment_type', employment_type.value, true)
                    setFieldValue('date_joined', '', true)
                    setFieldValue('date_contract_started', '', true)
                    setFieldValue('date_contract_ends', '', true)
                  }}
                  value={values.employment_type}
                  disabled={disabled}
                />
              </div>

              { values.employment_type && values.employment_type === '2' &&
                <div className="mb-40">
                  <DatePicker
                    error={errors.date_joined && touched.date_joined && errors.date_joined}
                    label="Start Date"
                    name="date_joined"
                    onChange={value => setFieldValue('date_joined', value, true)}
                    value={values.date_joined}
                    disabled={disabled}
                    tooltip="If current employment start date is less than 3 years ago you will be asked for details of your previous employment in later screens"
                  />
                </div>
              }

              { values.employment_type && values.employment_type !== '2' &&
                <Fragment>
                  <div className="mb-40">
                    <DatePicker
                      error={errors.date_contract_started && touched.date_contract_started && errors.date_contract_started}
                      label="Start Date"
                      name="date_contract_started"
                      onChange={value => setFieldValue('date_contract_started', value, true)}
                      value={values.date_contract_started}
                      disabled={disabled}
                      tooltip="If current employment start date is less than 3 years ago you will be asked for details of your previous employment in later screens"
                    />
                  </div>

                  <div className="mb-40">
                    <DatePicker
                      error={errors.date_contract_ends && touched.date_contract_ends && errors.date_contract_ends}
                      label="End Date"
                      name="date_contract_ends"
                      optional
                      onChange={value => setFieldValue('date_contract_ends', value, true)}
                      value={values.date_contract_ends}
                      disabled={disabled}
                    />
                  </div>
                </Fragment>
              }

              <div className="mb-40">
                <YesNoBtnGroup
                  error={errors.on_probation && touched.on_probation && errors.on_probation}
                  label="Within Probationary period?"
                  name="on_probation"
                  onChange={(name, value) => {
                    setFieldValue(name, value, true)
                    setFieldValue('probation_end_date', '', true)
                  }}
                  value={values.on_probation}
                  disabled={disabled}
                />
              </div>

              { values.on_probation && <div className="mb-40">
                  <DatePicker
                    error={errors.probation_end_date && touched.probation_end_date && errors.probation_end_date}
                    label="Date Probation ends"
                    name="probation_end_date"
                    type="text"
                    onChange={value => setFieldValue('probation_end_date', value, true)}
                    onBlur={handleBlur}
                    value={values.probation_end_date}
                    disabled={disabled}
                  />
                </div>
              }

              <div className="mb-40">
                <YesNoBtnGroup
                  error={errors.redundancy_notice && touched.redundancy_notice && errors.redundancy_notice}
                  label="Under notice of termination or redundancy?"
                  name="redundancy_notice"
                  onChange={(name, value) => {
                    setFieldValue(name, value, true)
                    setFieldValue('redundancy_remaining_details', '', true)
                  }}
                  value={values.redundancy_notice}
                  disabled={disabled}
                />
              </div>

              {values.redundancy_notice &&
                <div className="mb-40">
                  <Input
                    error={errors.redundancy_remaining_details && touched.redundancy_remaining_details && errors.redundancy_remaining_details}
                    label="Please Provide Details"
                    name="redundancy_remaining_details"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.redundancy_remaining_details}
                    disabled={disabled}
                  />
                </div>
              }
            </div>
          </div>
          <EmploymentActions save={save(values)} />
        </form>
      )}
    </Formik>
  )
})