import React, { Fragment, useContext, useEffect, useRef } from 'react'
import Input from '../../Form/Input'
import ReactSelect from '../../Form/ReactSelect'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import NextStepButton from '../../Form/NextStepButton'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { FieldArray, Formik } from 'formik'
import { PropertySchema } from '../../../schemas/PropertySchema'
import Property from '../../../models/Property'
import axiosService from '../../../utils/AxiosService'
import { observer } from 'mobx-react-lite'
import ApplicationStepUpdater from '../../Form/ApplicationStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'
import LoqateWrapper from '../../Form/LoqateWrapper'

export default observer(function Portfolio() {
  const { application_id } = useParams()
  const { applicationState } = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const formikRef = useRef()

  const fields = ['id', 'post_code', 'address_line1', 'address_line2', 'address_line3', 'city', 'county', 'country', 'house_hold_property_type', 'estimated_current_value', 'monthly_rental', 'arrears', 'market_value', 'is_property_mortgaged', 'existing_lender_name', 'outstanding_mortgage_amount', 'interest_rate', 'monthly_repayment', 'term_remaining', 'repayments_restructured', 'restructure_details', 'description', 'other_parties_switch', 'other_ownership_name', 'other_ownership_percentage', '_destroy']

  const houseHoldPropertyTypeOptions = [
    { label: 'Second home',  value: '1' },
    { label: 'Holiday Let',  value: '2' },
    { label: 'Let Property', value: '3' },
  ]

  const save = properties => e => {
    e?.preventDefault()
    touchFields(properties)

    return axiosService.patch(`/api/${applicationState.backend_url_part}/applications/${application_id}`, { application: { properties_attributes: properties } })
    .then(({ properties: newProperties }) => {
      notifyProgressSaved()
      applicationState.properties = newProperties.map(property => new Property(property))
    })
  }

  const touchFields = properties => {
    formikRef.current.setTouched(properties.reduce((result, _, index) => {
      result.properties[index] ||= {}
      fields.forEach(field => result.properties[index][field] = true)
      return result
    },{ properties: [] }))
  }

  useEffect(() => {
    if(applicationState.application.steps.portfolio_visited) touchFields(applicationState.properties)
    applicationErrorsContext.validatePortfolio()
  }, [applicationState.loading, applicationState.properties])

  return (
    <>
      <h2 className="form-title mb-40">Property Portfolio</h2>
      {!applicationState.disabled && <ApplicationStepUpdater step="portfolio_visited" /> }

      <p className="mb-40">Please provide details of any other properties you own and will retain after drawdown of this new mortgage.</p>

      <p className="mb-40">If you are a mover and do not plan on selling your current home, please add the details of the property below. If you are selling the property before drawdown of this new mortgage, please exclude this property.</p>


      <Formik
        initialValues={{ properties: applicationState.properties }}
        validationSchema={PropertySchema}
        enableReinitialize={applicationState.loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values.properties)}>
            <FieldArray name="properties">
              {({ insert, remove, push }) => (
                <div>
                  <div className="flex flex-wrap -mx-12">
                    <div className="px-12 w-1/2">
                      <div className="btn-group-container flex items-center justify-between mb-40">
                        <button
                          className="btn btn--group btn--primary w-2/3"
                          type="button"
                          onClick={() => push(new Property())}
                          disabled={applicationState.disabled}
                        >
                          Add Property
                          <i className="far fa-plus ml-10" />
                        </button>

                        <span className="pr-8">{values.properties.filter(property => !property._destroy).length}</span>
                      </div>
                    </div>
                  </div>
                  { values.properties.map((property, index) => (
                    <div key={index}>
                      {!property._destroy &&

                        <Fragment>
                          <div className="flex items-center mb-40">
                            <p className="mr-6">
                              <i className="fa fa-chevron-down mr-2" />
                              Property {index + 1 - values.properties.filter(property => property._destroy).length} details
                            </p>

                            <div className="bg-timber-green-o-16 flex-auto mr-6" style={{ height: 2 }} />

                            <button className="bg-punch circle fs-9 text-white" style={{ height: 20, width: 20 }} type="button"
                              disabled={applicationState.disabled}
                              onClick={() => setFieldValue(`properties.${index}._destroy`, true, true)}>
                              <i className="fa fa-trash-alt" />
                            </button>
                          </div>
                          <div className="flex flex-wrap -mx-12">
                            <div className="px-12 w-1/2">
                              <div className="mb-40">
                                <LoqateWrapper
                                  label="Search Address"
                                  tooltip="Type address or Eircode to lookup address"
                                  onSelect={address => {
                                    setFieldValue(`properties.${index}.post_code`, address.PostalCode, true)
                                    setFieldValue(`properties.${index}.address_line1`, address.Line1, true)
                                    setFieldValue(`properties.${index}.address_line2`, address.Line2, true)
                                    setFieldValue(`properties.${index}.address_line3`, address.Line3, true)
                                    setFieldValue(`properties.${index}.city`, address.City, true)
                                    setFieldValue(`properties.${index}.county`, address.Province, true)
                                    setFieldValue(`properties.${index}.country`, address.CountryIso3, true)
                                  }}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <Input
                                  error={errors?.properties?.[index]?.post_code && touched?.properties?.[index]?.post_code && errors?.properties?.[index]?.post_code}
                                  label="Eircode"
                                  name={`properties.${index}.post_code`}
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={property.post_code}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <Input
                                  error={errors?.properties?.[index]?.address_line1 && touched?.properties?.[index]?.address_line1 && errors?.properties?.[index]?.address_line1}
                                  label="Address Line 1"
                                  name={`properties.${index}.address_line1`}
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={property.address_line1}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <Input
                                  error={errors?.properties?.[index]?.address_line2 && touched?.properties?.[index]?.address_line2 && errors?.properties?.[index]?.address_line2}
                                  label="Address Line 2"
                                  name={`properties.${index}.address_line2`}
                                  optional
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={property.address_line2}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <Input
                                  error={errors?.properties?.[index]?.address_line3 && touched?.properties?.[index]?.address_line3 && errors?.properties?.[index]?.address_line3}
                                  label="Address Line 3"
                                  name={`properties.${index}.address_line3`}
                                  optional
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={property.address_line3}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <Input
                                  error={errors?.properties?.[index]?.city && touched?.properties?.[index]?.city && errors?.properties?.[index]?.city}
                                  label="City"
                                  name={`properties.${index}.city`}
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={property.city}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <Input
                                  error={errors?.properties?.[index]?.county && touched?.properties?.[index]?.county && errors?.properties?.[index]?.county}
                                  label="County"
                                  name={`properties.${index}.county`}
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={property.county}
                                  disabled={applicationState.disabled}
                                />
                              </div>
                            </div>

                            <div className="px-12 w-1/2">
                              <div className="mb-40">
                                <ReactSelect
                                  countrySelect
                                  error={errors?.properties?.[index]?.country && touched?.properties?.[index]?.country && errors?.properties?.[index]?.country}
                                  label="Country"
                                  name={`properties.${index}.country`}
                                  onChange={value => setFieldValue(`properties.${index}.country`, (value?.value || null), true)}
                                  onBlur={handleBlur}
                                  value={property.country}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <ReactSelect
                                  options={houseHoldPropertyTypeOptions}
                                  error={errors?.properties?.[index]?.house_hold_property_type && touched?.properties?.[index]?.house_hold_property_type && errors?.properties?.[index]?.house_hold_property_type}
                                  label="Property Usage"
                                  name={`properties.${index}.house_hold_property_type`}
                                  onChange={value => setFieldValue(`properties.${index}.house_hold_property_type`, (value?.value || null), true)}
                                  onBlur={handleBlur}
                                  value={property.house_hold_property_type}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <CurrencyField
                                  addon="€"
                                  addonPosition="start"
                                  error={errors?.properties?.[index]?.monthly_rental && touched?.properties?.[index]?.monthly_rental && errors?.properties?.[index]?.monthly_rental}
                                  label="Monthly Rental Income"
                                  name={`properties.${index}.monthly_rental`}
                                  setFieldValue={setFieldValue}
                                  onBlur={handleBlur}
                                  value={property.monthly_rental}
                                  disabled={applicationState.disabled}
                                  tooltip="If you are retaining your current home as a let property, you can include projected rental income here."
                                />
                              </div>

                              <div className="mb-40">
                                <CurrencyField
                                  addon="€"
                                  addonPosition="start"
                                  error={errors?.properties?.[index]?.estimated_current_value && touched?.properties?.[index]?.estimated_current_value && errors?.properties?.[index]?.estimated_current_value}
                                  label="Market Value"
                                  name={`properties.${index}.estimated_current_value`}
                                  setFieldValue={setFieldValue}
                                  onBlur={handleBlur}
                                  value={property.estimated_current_value}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <YesNoBtnGroup
                                  error={errors?.properties?.[index]?.is_property_mortgaged && touched?.properties?.[index]?.is_property_mortgaged && errors?.properties?.[index]?.is_property_mortgaged}
                                  label="Is the property mortgaged?"
                                  name={`properties.${index}.is_property_mortgaged`}
                                  onChange={(name, value) => {
                                    setFieldValue(name, value, true)
                                    setFieldValue(`properties.${index}.existing_lender_name`, '', true)
                                    setFieldValue(`properties.${index}.outstanding_mortgage_amount`, '', true)
                                    setFieldValue(`properties.${index}.interest_rate`, '', true)
                                    setFieldValue(`properties.${index}.monthly_repayment`, '', true)
                                    setFieldValue(`properties.${index}.repayments_restructured`, false, true)
                                  }}
                                  onBlur={handleBlur}
                                  value={property.is_property_mortgaged}
                                  disabled={applicationState.disabled}
                                />
                              </div>

                              <div className="mb-40">
                                <YesNoBtnGroup
                                  error={errors?.properties?.[index]?.other_parties_switch && touched?.properties?.[index]?.other_parties_switch && errors?.properties?.[index]?.other_parties_switch}
                                  label="Other parties who are not named?"
                                  name={`properties.${index}.other_parties_switch`}
                                  onChange={(name, value) => {
                                    setFieldValue(name, value, true)
                                    setFieldValue('other_ownership_name', '', true)
                                    setFieldValue('other_ownership_percentage', '', true)
                                  }}
                                  onBlur={handleBlur}
                                  value={property.other_parties_switch}
                                  disabled={applicationState.disabled}
                                  tooltip="Are there other parties who also own this property and are not part of this new mortgage?"
                                />
                              </div>
                            </div>
                          </div>

                          { property.is_property_mortgaged &&
                            <Fragment>

                              <div className="flex justify-end">
                                <div className="flex items-center mb-40 w-4/5">
                                  <p className="mr-6">Mortgaged property</p>

                                  <div className="bg-timber-green-o-16 flex-auto mr-6" style={{ height: 2 }} />

                                  <i className="fa fa-chevron-down" />
                                </div>
                              </div>

                              <div className="flex flex-wrap -mx-12">
                                <div className="px-12 w-1/2">
                                  <div className="mb-40">
                                    <Input
                                      error={errors?.properties?.[index]?.existing_lender_name && touched?.properties?.[index]?.existing_lender_name && errors?.properties?.[index]?.existing_lender_name}
                                      label="Lender Name"
                                      name={`properties.${index}.existing_lender_name`}
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={property.existing_lender_name}
                                      disabled={applicationState.disabled}
                                    />
                                  </div>

                                  <div className="mb-40">
                                    <CurrencyField
                                      addon="€"
                                      addonPosition="start"
                                      error={errors?.properties?.[index]?.outstanding_mortgage_amount && touched?.properties?.[index]?.outstanding_mortgage_amount && errors?.properties?.[index]?.outstanding_mortgage_amount}
                                      label="Mortgage balance outstanding"
                                      name={`properties.${index}.outstanding_mortgage_amount`}
                                      setFieldValue={setFieldValue}
                                      onBlur={handleBlur}
                                      value={property.outstanding_mortgage_amount}
                                      disabled={applicationState.disabled}
                                    />
                                  </div>

                                  <div className="mb-40">
                                    <YesNoBtnGroup
                                      error={errors?.properties?.[index]?.repayments_restructured && touched?.properties?.[index]?.repayments_restructured && errors?.properties?.[index]?.repayments_restructured}
                                      label="Has this mortgage ever been in arrears or restructured?"
                                      name={`properties.${index}.repayments_restructured`}
                                      onChange={(name, value) => setFieldValue(name, value, true)}
                                      onBlur={handleBlur}
                                      value={property.repayments_restructured}
                                      disabled={applicationState.disabled}
                                    />
                                  </div>
                                </div>

                                <div className="px-12 w-1/2">
                                  <div className="mb-40">
                                    <Input
                                      error={errors?.properties?.[index]?.interest_rate && touched?.properties?.[index]?.interest_rate && errors?.properties?.[index]?.interest_rate}
                                      label="Current Interest Rate"
                                      name={`properties.${index}.interest_rate`}
                                      addon="%"
                                      addonPosition="start"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      tooltip="This can be found on your mortgage account statements or by contacting your mortgage provider."
                                      value={property.interest_rate}
                                      disabled={applicationState.disabled}
                                      min="0"
                                      max="99"
                                    />
                                  </div>

                                  <div className="mb-40">
                                    <CurrencyField
                                      addon="€"
                                      addonPosition="start"
                                      error={errors?.properties?.[index]?.monthly_repayment && touched?.properties?.[index]?.monthly_repayment && errors?.properties?.[index]?.monthly_repayment}
                                      label="Monthly payment"
                                      name={`properties.${index}.monthly_repayment`}
                                      setFieldValue={setFieldValue}
                                      onBlur={handleBlur}
                                      value={property.monthly_repayment}
                                      disabled={applicationState.disabled}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          }
                          { property.other_parties_switch &&
                            <Fragment>
                              <div className="flex justify-end">
                                <div className="flex items-center mb-40 w-4/5">
                                  <p className="mr-6">Associated parties</p>

                                  <div className="bg-timber-green-o-16 flex-auto mr-6" style={{ height: 2 }} />

                                  <i className="fa fa-chevron-down" />
                                </div>
                              </div>
                              <div className="flex flex-wrap -mx-12">
                                <div className="px-12 w-1/2">
                                  <div className="mb-40">
                                    <Input
                                      error={errors?.properties?.[index]?.other_ownership_name && touched?.properties?.[index]?.other_ownership_name && errors?.properties?.[index]?.other_ownership_name}
                                      label="Owners"
                                      name={`properties.${index}.other_ownership_name`}
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={property.other_ownership_name}
                                      disabled={applicationState.disabled}
                                    />
                                  </div>
                                </div>

                                <div className="px-12 w-1/2">
                                  <div className="mb-40">
                                    <Input
                                      addon="%"
                                      addonPosition="end"
                                      error={errors?.properties?.[index]?.other_ownership_percentage && touched?.properties?.[index]?.other_ownership_percentage && errors?.properties?.[index]?.other_ownership_percentage}
                                      label="Ownerships"
                                      name={`properties.${index}.other_ownership_percentage`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={property.other_ownership_percentage}
                                      disabled={applicationState.disabled}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          }
                        </Fragment>
                      }
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
            <div className="flex justify-between mt-16">
              {!applicationState.disabled && <button className="btn btn--secondary" type="button" onClick={save(values.properties)}>Save Progress</button>}
              <NextStepButton nextUrl={`/${applicationState.type}/applications/${application_id}/direct-debit`} save={applicationState.disabled ? () => {} : save(values.properties)}/>
            </div>
          </form>
        )}
      </Formik>

    </>
  )
})
