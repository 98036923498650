import React from 'react'
import { observable, transaction } from "mobx"
import axios from 'axios'
import _ from 'lodash'
import axiosService from '../utils/AxiosService'

/**
 * RootStore
 * This store holds frontend/application wide logic, common to both Broker and Borrower users.
 * It's main responsibilities are:
 * - Handling when user authenticates/decauthenticates (either manually or through bad API request)
 * - Checking and handling when session times out (see doc/session-timeout.md)
 * 
 * The instance of this can be access through the React provider/context,
 * or you can just use the instance variable (as declared below).
 */
class RootStoreClass {
  // If to log for debugging
  loggingEnabled = false 
  // If the store is bootstrapped (aka is setup and checked if current user is authenticated)
  isBootstrapped = observable.box(false) 
  // Either 'borrower' or 'broker', set based on URL path in constructor
  userType = observable.box(undefined) 
  // If the user is authenticated, managed by methods below
  userIsAuthenticated = observable.box(false) 
  // How often in milliseconds to check/poll the session expiration
  sessionTimeoutCheckFrequency = 5000
  // The interval responsible for checking the session timeout (when authenticated)
  sessionTimeoutInterval = undefined
  // Remaining seconds in session, per the check, which is observed by things like 
  // SessionTimeoutDialog (so will show and user can extend session), uses `.box` as is primitive
  sessionTimeoutSecondsRemaining = observable.box(undefined) 

  /**
   * Constructor
   */
  constructor() {
    this.bootstrap()
  }

  /**
   * Bootstraps the store, by determine the user type (eg 'borrower' or 'broker')
   * and checking if the current user is authenticated
   */
  bootstrap() {
    // Run in transaction so only 1 render for any changes to `observable`s (eg `isBootstrapped`, `userIsAuthenticated`)
    transaction(async () => {
      // Determine the user type from URL (eg 'borrower' or 'broker')
      this.determineUserType()

      // Based on if current user is authenticated, set up the store for authenticated user
      // - If user is not authenticated, do nothing as they will login and that will call `authenticateUser` accordingly
      // - We use the plain axios client, as axiosService has logic for when not authenticated
      const currentUserUrl = (this.userType.get() === 'borrower') ? '/api/borrower/profile/current' : '/api/apprivo_broker/profile/current'
      try {
        // If doesn't throw an error, user is authenticated
        const result = await axios.get(currentUserUrl)
        this.log('bootstrap', result) 
        this.authenticateUser()
      }
      catch (error) {
        // We let RouterComponent take care of any redirect to login pages...
        this.log('bootstrap', `User not authenticated`, error) 
      }
      finally { 
        // Finished checking if the user is authenticated or not
        this.log('bootstrap', 'Finished!') 
        this.isBootstrapped.set(true)
      }
    })
  }

  // MARK: Authentication

  /**
   * Authenticates user, but performing appropriate setup actions
   * Should be called either if user is authenticated on initial load (ie in constructor),
   * or if user signs in (like in Borrower2fa component)
   */
  authenticateUser() {
    this.log(`authenticateUser`, `called`)
    // Set as authenticated
    this.userIsAuthenticated.set(true)

    // Begin checking session timeout
    this.sessionTimeoutStartInterval()
  }

  /**
   * Deauthenticates the user, performing the appropriate
   * actions based on if they're a borrower or broker. 
   * Consider using the throttled version of this if could be hit 
   * serveral times (eg in API error response hanndling)
   * 
   * Called when either:
   * - User hits an authentication related API error (eg not authenticated or session timed out, see AxiosService)
   * - User signs out
   * 
   * It will:
   * - Stop checking for session time out 
   * - Attempt to destroy session/sign out on backend
   * - Hard redirect to login page (which also will clear all JS vars)
   * 
   * Reason (if any) should be 'not-authenticated' or 'session-timed-out', similar to API response codes,
   * so login page can show appropriate message.
   */
  async deauthenticateUser(reason = undefined) {
    this.log(`deauthenticateUser`, `reason:`, reason)

    // Stop session timeout
    this.sessionTimeoutClearInterval()

    // Borrower
    if (this.userType.get() === 'borrower') {
      // We don't care if this is successful or not, eg if user 
      // session was already timed out, they'll be signed out
      // - We use plain Axios client as don't want response handling/interceptions
      try {
        await axios.delete('/api/borrower/sign_out')
      }
      catch(e) {}

      // Redirect to login page with param to show appropriate message (if any)
      let redirectUrl = `/borrower/login`
      if (reason === 'not-authenticated') redirectUrl += '?n=ltc' // 'Login to continue'
      else if (reason === 'session-timed-out') redirectUrl += '?n=to' // 'Timed out'
      window.location.href = redirectUrl
    }
    // Broker
    else if (this.userType.get() === 'broker') {
      // We don't care if this is successful or not, eg if user 
      // session was already timed out, they'll be signed out
      // - We use plain Axios client as don't want response handling/interceptions
      try {
        await axios.delete('/api/apprivo_broker/sessions/sign_out')
      }
      catch(e) {}

      // Redirect to login page with param to show appropriate message (if any)
      let redirectUrl = `/broker/login`
      if (reason === 'not-authenticated') redirectUrl += '?n=ltc' // 'Login to continue'
      else if (reason === 'session-timed-out') redirectUrl += '?n=to' // 'Timed out'
      window.location.href = redirectUrl
    }

    // Finally set as not authenticated
    this.userIsAuthenticated = false
  }
  deauthenticateUserThrottled = _.throttle(this.deauthenticateUser, 3000)

  /**
   * Determines and sets the user type (eg `broker` or `borrower`) from the 
   * URL route prefix (eg `/borrower/applications` = 'borrower'). 
   * Should be called when user signs in or first authenticates.
   * 
   * Note: some path won't always have this prefix, such as for email verification, 
   * so we fallback to borrower if thats the case, look at RouterComponent for reference.
   */
  determineUserType() {
    const pathParts = window.location.pathname.split('/')
    if (pathParts.length > 1 && pathParts[1] === 'borrower') this.userType.set('borrower')
    else if (pathParts.length > 1 && pathParts[1] === 'broker') this.userType.set('broker')
    else { 
      this.log('determineUserType', "Unknown user type, so falling back to 'borrower'"); 
      this.userType.set('borrower');
    }
    this.log('determineUserType', `Setting user type as: ${this.userType.get()}`)
  }

  // MARK: Session Timeout

  /**
   * Starts the recurring check of session timeout (eg every 10 seconds)
   */
  sessionTimeoutStartInterval() {
    this.log(`sessionTimeoutStartInterval`, `called`)
    if (!this.sessionTimeoutInterval) {
      this.sessionTimeoutInterval = setInterval(() => this.sessionTimeoutCheckRemaining(), this.sessionTimeoutCheckFrequency)
    }
  }

  /**
   * Stops the recurring check of session out
   */
  sessionTimeoutClearInterval() {
    this.log(`sessionTimeoutClearInterval`, `called`)
    if (this.sessionTimeoutInterval) {
      clearInterval(this.sessionTimeoutInterval)
      this.sessionTimeoutInterval = undefined
    }
  }

  /**
   * Checks how long is remaining in the session and sets it. This is likely 
   * called every 10 seconds (or whatever is set), and based on 'seconds_remaining',
   * observing components like 'SessionTimeoutDialog' may show (so user can extend session).
   * 
   * When the seconds remaining hits 0, the user will have been signed out on backend,
   * so we sign them out here also.
   */
  async sessionTimeoutCheckRemaining() {
    try {
      const response = await axiosService.get(`/api/${this.userType.get() === 'broker' ? 'apprivo_broker' : 'borrower'}/session_timeout/remaining`)
      this.log(`sessionTimeoutCheckRemaining`, response)
      this.sessionTimeoutSecondsRemaining.set(response.seconds_remaining)
      this.log(`sessionTimeoutCheckRemaining`, `seconds remaining`, response.seconds_remaining)

      // If it hit zero, then session has timed out and user will be signed out by backend, so just 
      // - This will also stop any looping interval calling this
      if (response.seconds_remaining <= 0) {
        this.deauthenticateUser('session-timed-out')
      }
    }
    catch(error) {
      // Do nothing if fails, could be a network error or user already unauthenticated...
      this.log(`sessionTimeoutCheckRemaining`, `error occurred`, error)
    }
  }

  /**
   * Extends the session timeout by calling the API and setting the new seconds remaining
   */
  async sessionTimeoutExtend() {
    try {
      const response = await axiosService.post(`/api/${this.userType.get() === 'broker' ? 'apprivo_broker' : 'borrower'}/session_timeout/extend`)
      this.sessionTimeoutSecondsRemaining.set(response.seconds_remaining)
      this.log(`sessionTimeoutExtend`, `success`, response)
    }
    catch(error) {
      // Do nothing if fails, could be a network error or user already unauthenticated...
      this.log(`sessionTimeoutExtend`, `error`, error)
    }
  }

  // MARK: Utility

  /**
   * Logs if enabled
   */
  log(method, ...args){
    if (this.loggingEnabled) console.log(`[RootStore][${method}]`, ...args)
  }
}
export const rootStore = new RootStoreClass()


// MARK: Context

export const RootStoreContext = React.createContext(null)
export const RootStoreProvider = ({ children }) => {
  return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>
}
export default RootStoreContext
