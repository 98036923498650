import React from 'react'

import iconOverpayment from 'images/landing/ftb-overpayment.svg'

export default function MortgageFeaturesBox4() {
  return (
    <div className="landing-feature-column">
      <div className="landing-feature-box">
        <div className="landing-feature-icon-container">
          <img alt="Overpayment" className="landing-feature-icon" src={iconOverpayment} />
        </div>

        <h3 className="landing-feature-title">Overpayment</h3>

        <p>Save on your rate by choosing not to overpay</p>
      </div>
    </div>
  )
}
