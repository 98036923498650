import React from 'react';

export default function Modal({ children }) {
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-70 outline-none focus:outline-none"
      >
        <div className="relative w-auto my-6 mx-auto max-w-8xl">
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
            style={{ maxWidth: 640 }}
          >
            {children}
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-60 bg-timber-green-o-40" />
    </>
  );
};
