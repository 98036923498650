import React from 'react'
import ReactSelect from './ReactSelect'

export default function PayFrequencySelector({ error, label = 'Frequency', name, placeholder = 'Select Frequency', onChange, onBlur, value, disabled }) {

  const frequencyOptions = [
    { label: 'Weekly', value: 52 },
    { label: '2 Weekly', value: 26 },
    { label: '4 Weekly', value: 13 },
    { label: 'Monthly ', value: 12 },
    { label: 'Annually', value: 1 },
  ]

  return (
    <ReactSelect
      error={error}
      label={label}
      name={name}
      options={frequencyOptions}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
    />
  )
}