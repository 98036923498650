import { observer } from 'mobx-react-lite'
import React from 'react'
import SolicitorPart from './Parts/SolicitorPart'
import DirectDebitPart from './Parts/DirectDebitPart'
import PortfolioPart from './Parts/PortfolioPart'
import MainPropertyDetailsPart from './Parts/MainPropertyDetailsPart'
import LoanRequirementsPart from './Parts/LoanRequirementsPart'
import ApplicantsPart from './Parts/ApplicantsPart'

export default observer(function Summary() {

  const closeSummary = () => window.close()

  return (
    <div className="mx-20">
      <div className="table-outer-container">
        <div className="table-container">
          <table className="table fs-13" style={{ width: '50%' }}>
            <tbody className="table--border">
              <ApplicantsPart />
              <tr><td></td><td></td><td></td></tr>
              <LoanRequirementsPart />
              <tr><td></td><td colSpan="2"></td></tr>
              <MainPropertyDetailsPart />
              <tr><td></td><td colSpan="2"></td></tr>
              <PortfolioPart />
              <tr><td></td><td colSpan="2"></td></tr>
              <DirectDebitPart />
              <tr><td></td><td colSpan="2"></td></tr>
              <SolicitorPart />
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-wrap -mx-12">
        <div className="px-12 w-1/2 my-8">
          <button className="btn btn--primary text-center w-full" onClick={closeSummary}>Close Summary</button>
        </div>
      </div>
    </div>
  )

})
