import * as Yup from 'yup'

const tradingAddressIsDifferent = () => {
  return [
    'is_business_address_different',
    {
      is: true,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ]
}

export const EmploymentStep2SelfEmployedSchema = Yup.object().shape({
  registered_post_code: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  registered_address_line1: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  registered_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  registered_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  registered_city: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  registered_county: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  registered_country: Yup.string().required('This field is required.'),
  self_employed_company_number: Yup.string().when('business_type', {
    is: '4',
    then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
  }),
  is_business_address_different: Yup.boolean().required('This field is required.'),
  business_post_code: Yup.string().when(...tradingAddressIsDifferent()),
  business_address_line1: Yup.string().when(...tradingAddressIsDifferent()),
  business_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  business_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  business_city: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  business_country: Yup.mixed().nullable().when(...tradingAddressIsDifferent()),
  business_county: Yup.string().when(...tradingAddressIsDifferent()),
});