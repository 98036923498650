import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import Input from '../../Form/Input'
import NextStepButton from '../../Form/NextStepButton'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Applicant from '../../../models/Applicant'
import General from '../../../models/General'
import { observer } from 'mobx-react-lite'
import { Formik } from 'formik'
import { generalSchema } from '../../../schemas/GeneralSchema'
import axiosService from '../../../utils/AxiosService'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import { notifyProgressSaved } from '../../Form/Notifications'

export default observer(function GeneralQuestions() {
  const { application_id, applicant_id } = useParams()
  const { applicationState: { applicants, loading, type, disabled, backend_url_part } } = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const save = general => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { general }})
    .then(() => {
      notifyProgressSaved()
      currentApplicant.general = new General(general)
    })
  }

  const touchFields = () => formikRef.current.setTouched(Object.keys(currentApplicant.general).reduce((result, key) => { result[key] = true; return result },{}))

  useEffect(() => {
    if(currentApplicant.steps.general_questions_visited) touchFields()
    applicationErrorsContext.validateGeneralQuestions(currentApplicant.general, applicant_id)
  }, [loading, currentApplicant.general])

  return (
    <>
      <h2 className="form-title mb-40">General Questions</h2>
      { !disabled && <ApplicantStepUpdater step="general_questions_visited" /> }

      <Formik
        initialValues={currentApplicant.general}
        validationSchema={generalSchema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <div className="flex flex-wrap -mx-12">

              <div className="mb-40 flex w-full justify-between">
                <div className="px-12 w-1/2">
                  <YesNoBtnGroup
                    error={errors.have_payday_loan && touched.have_payday_loan && errors.have_payday_loan}
                    label="Payday loan in the past 3 years?"
                    name="have_payday_loan"
                    tooltip="Short term money lent at a high rate of interest on the agreement that it will be repaid when you receive your next wage."
                    onChange={(name, value) => {
                      setFieldValue(name, value, true)
                      setFieldValue('payday_loan_details', '', true)
                    }}
                    value={values.have_payday_loan}
                    disabled={disabled}
                  />
                </div>

                <div className="px-12 w-1/2">
                  {values.have_payday_loan &&
                    <Fragment>
                      <Input
                        error={errors.payday_loan_details && touched.payday_loan_details && errors.payday_loan_details}
                        label="Please provide details"
                        name="payday_loan_details"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.payday_loan_details}
                        disabled={disabled}
                      />
                    </Fragment>
                  }
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-16">
              {!disabled && <button className="btn btn--secondary" type="submit">Save Progress</button>}
              <NextStepButton nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/document-upload`} save={disabled ? () => {} : save(values)}/>
            </div>
          </form>
        )}
      </Formik>

    </>
  )
})