import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'
import TitleRow from '../../TitleRow'
import ApplicantRow from './ApplicantRow'

export default observer(function PensionPart({ applicants }) {
  return (
    <Fragment>
      { (applicants[0]?.pension?.do_you_have_pension_income || applicants[1]?.pension?.do_you_have_pension_income) &&
        <Fragment>
          <TitleRow label="Pension Income" />
          <ApplicantRow name="Annual Private Workplace Pension" tdContent={applicant => applicant.pension.gross_pension_income} />
          <ApplicantRow name="Annual State Pension" tdContent={applicant => applicant.pension.annual_state_pension_income} />
        </Fragment>
      }
    </Fragment>
  )
})