import * as Yup from 'yup'

const doYouHavePensionIncome = () => {
  return [
    'do_you_have_pension_income',
    {
      is: true,
      then: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.')
    }
  ]
}

export const pensionSchema = Yup.object().shape({
  do_you_have_pension_income: Yup.boolean().required('This field is required.'),
  gross_pension_income: Yup.mixed().when(...doYouHavePensionIncome()),
  annual_state_pension_income: Yup.mixed().when(...doYouHavePensionIncome()),
});