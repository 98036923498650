import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../../models/Applicant'
import Income from '../../../../models/Income'
import { EmploymentStep3SelfEmployedSchema } from '../../../../schemas/EmploymentSchemas/EmploymentStep3SelfEmployedSchema'
import axiosService from '../../../../utils/AxiosService'
import Input from '../../../Form/Input'
import ReactSelect from '../../../Form/ReactSelect'
import YesNoBtnGroup from '../../../Form/YesNoBtnGroup'
import EmploymentActions from '../EmploymentActions'
import PhoneField from '../../../Form/PhoneField'
import { notifyProgressSaved } from '../../../Form/Notifications'
import LoqateWrapper from '../../../Form/LoqateWrapper'

export default observer(function SelfEmployedFormPart() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ["do_you_have_accountant", "accountants_firm", "accountant_email", 'accountants_telephone_number', "contact_name", "accountants_post_code", "accountants_address_line1", "accountants_address_line2", "accountants_city", "accountants_county", "accountants_country"]

  const save = income => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { income } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.income = new Income(income)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  useEffect(() => {
    if(currentApplicant.steps.employment_step3_visited) touchFields()
    applicationErrorsContext.validateEmploymentStep3SelfEmployed(currentApplicant.income, applicant_id)
  }, [loading, currentApplicant.income])

  return (
    <div>
      <Formik
        initialValues={currentApplicant.income}
        validationSchema={EmploymentStep3SelfEmployedSchema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <YesNoBtnGroup
                    error={errors.do_you_have_accountant && touched.do_you_have_accountant && errors.do_you_have_accountant}
                    label="Do you have an Accountant?"
                    name="do_you_have_accountant"
                    onChange={(name, value) => {
                      setFieldValue(name, value, true)
                      setFieldValue('business_post_code', '', true)
                      setFieldValue('accountants_firm', '', true)
                      setFieldValue('accountant_email', '', true)
                      setFieldValue('accountants_telephone_number', '+353', true)
                      setFieldValue('contact_name', '', true)
                      setFieldValue('accountants_post_code', '', true)
                      setFieldValue('accountants_address_line1', '', true)
                      setFieldValue('accountants_address_line2', '', true)
                      setFieldValue('accountants_city', '', true)
                      setFieldValue('accountants_county', '', true)
                      setFieldValue('accountants_country', 'IRL', true)
                    }}
                    value={values.do_you_have_accountant}
                    disabled={disabled}
                  />
                </div>

                { values.do_you_have_accountant &&
                  <Fragment>
                    <div className="mb-40">
                      <Input
                        error={errors.accountants_firm && touched.accountants_firm && errors.accountants_firm}
                        label="Name of Accountant Firm"
                        name="accountants_firm"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.accountants_firm}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.accountant_email && touched.accountant_email && errors.accountant_email}
                        label="Email"
                        name="accountant_email"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.accountant_email}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <PhoneField
                        error={errors.accountants_telephone_number && touched.accountants_telephone_number && errors.accountants_telephone_number}
                        label="Phone Number"
                        name="accountants_telephone_number"
                        onChange={value => setFieldValue('accountants_telephone_number', value, true)}
                        onBlur={handleBlur}
                        value={values.accountants_telephone_number}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.contact_name && touched.contact_name && errors.contact_name}
                        label="Contact Name"
                        name="contact_name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.contact_name}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.accountants_post_code && touched.accountants_post_code && errors.accountants_post_code}
                        label="Eircode"
                        name="accountants_post_code"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('accountants_county', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.accountants_post_code}
                        disabled={disabled}
                      />
                    </div>
                  </Fragment>
                }
              </div>

              { values.do_you_have_accountant &&
                <div className="px-12 w-1/2">
                  <div className="mb-40">
                    <LoqateWrapper
                      label="Search Address"
                      tooltip="Type address or Eircode to lookup address"
                      onSelect={address => {
                        setFieldValue('accountants_post_code', address.PostalCode, true)
                        setFieldValue('accountants_address_line1', address.Line1, true)
                        setFieldValue('accountants_address_line2', address.Line2, true)
                        setFieldValue('accountants_city', address.City, true)
                        address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                          ? setFieldValue('accountants_county', 'Dublin', true)
                          : setFieldValue('accountants_county', address.Province, true)
                        setFieldValue('accountants_country', address.CountryIso3, true)
                      }}
                      disabled={disabled}
                    />
                  </div>

                  <div className="mb-40">
                    <Input
                      error={errors.accountants_address_line1 && touched.accountants_address_line1 && errors.accountants_address_line1}
                      label="Address Line 1"
                      name="accountants_address_line1"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.accountants_address_line1}
                      disabled={disabled}
                    />
                  </div>

                  <div className="mb-40">
                    <Input
                      error={errors.accountants_address_line2 && touched.accountants_address_line2 && errors.accountants_address_line2}
                      label="Address Line 2"
                      name="accountants_address_line2"
                      optional
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.accountants_address_line2}
                      disabled={disabled}
                    />
                  </div>

                  <div className="mb-40">
                    <Input
                      error={errors.accountants_city && touched.accountants_city && errors.accountants_city}
                      label="City"
                      name="accountants_city"
                      optional
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.accountants_city}
                      disabled={disabled}
                    />
                  </div>

                  <div className="mb-40">
                    <Input
                      error={errors.accountants_county && touched.accountants_county && errors.accountants_county}
                      label="County"
                      name="accountants_county"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.accountants_county}
                      disabled={disabled}
                    />
                  </div>

                  <div className="mb-40">
                    <ReactSelect
                      countrySelect
                      error={errors.accountants_country && touched.accountants_country && errors.accountants_country}
                      label="Country"
                      name="accountants_country"
                      placeholder="Select country"
                      onChange={accountants_country => setFieldValue('accountants_country', accountants_country.value, true)}
                      value={values.accountants_country}
                      disabled={disabled}
                    />
                  </div>
                </div>
              }
            </div>
            <EmploymentActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})