import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../models/Applicant'
import NextStepButton from '../../Form/NextStepButton'
import ProgressIndicator from '../ProgressIndicator'

export default observer(function CommitmentsActions({ save }) {
  const { application_id, applicant_id } = useParams()
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)
  const { applicationState: { applicants, type }} = useContext(ApplicationContext)

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const url = `/${type}/applications/${application_id}/applicants/${applicant_id}/commitments`

  const commitmentsStepsErrors = useMemo(() => {
    return [
      { errors: applicantErrors[currentApplicant.id]?.commitmentsStep1Errors, visited: currentApplicant.steps.commitments_step1_visited },
      { errors: applicantErrors[currentApplicant.id]?.commitmentsStep2Errors, visited: currentApplicant.steps.commitments_step2_visited },
  ]}, [
    currentApplicant,
    applicantErrors[currentApplicant.id]?.commitmentsStep1Errors,
    applicantErrors[currentApplicant.id]?.commitmentsStep2Errors,
    currentApplicant.steps.commitments_step1_visited,
    currentApplicant.steps.commitments_step2_visited,
  ])

  return (
    <Fragment>
      <ProgressIndicator url={url} steps={2} save={save} errors={commitmentsStepsErrors} />

      <div className="flex justify-between mt-16">
        <button className="btn btn--secondary" type="submit">Save Progress</button>
        <NextStepButton steps={2} url={url} nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/general-questions`} save={save}/>
      </div>
    </Fragment>
  )
})