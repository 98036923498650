import React, { useContext, useEffect, useRef, useState } from 'react'
import Input from "../../Form/Input";
import { Formik } from 'formik'
import ReactSelect from "../../Form/ReactSelect";
import axiosService from "../../../utils/AxiosService";
import { useHistory, useParams } from "react-router-dom";
import CurrencyField from "../../Form/CurrencyField";
import Modal from "../../Modal";
import { observer } from "mobx-react-lite";
import { toastError, toastSuccess } from '../../Form/Toasts';
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { RequestValuationSchema } from '../../../schemas/RequestValuationSchema'

export default observer(function RequestValuation() {
  const history = useHistory()
  const formikRef = useRef()
  const { applicationState: { applicants, application, loading, security, mortgage } } = useContext(ApplicationContext)
  const { application_id } = useParams()
  const [submitRequestModalOpen, setSubmitRequestModalOpen] = useState(false)

  const mortgageTypeOptions = [
    { label: 'Purchase', value: 'Purchase' },
    { label: 'Switcher', value: 'Remortgage' },
  ]

  const requestValuation = values => {

    axiosService.post(`/api/apprivo_broker/applications/${application_id}/request_valuation`, values)
    .then((response) => {
      console.log(response)
      toastSuccess('Valuation Requested Successfully')
      history.push('/broker/applications')
    })
    .catch((response) => {
      console.log(response)
      // toastError(full_messages)
    })
  }

  useEffect(() => getAddress(security?.property_address_post_code),[security])

  const getAddress = eircode => {
    if(!eircode) return
    axiosService.get(`/api/apprivo_broker/address/${eircode}`)
    .then((response) => {
      formikRef.current.setFieldValue('property_address', response?.Label?.replaceAll('\n', ' '), true)
    })
  }

  return (
    <>
      <h2 className="form-title mb-40">Request a Property Valuation</h2>

      <p className="mb-40">
        Please ensure the customer is ready to proceed with the property purchase or refinance and has a valid loan offer from MoCo.
      </p>

      <Formik
        innerRef={formikRef}
        initialValues={{
          applicant_name: `${applicants[0]?.first_name} ${applicants[0]?.middle_name} ${applicants[0]?.last_name}`.replaceAll(/\s+/g, ' '),
          applicant_email_address: applicants[0]?.email,
          purchase_type: application.purchase_type,
          application_number: application.application_number,
          property_eircode: security.property_address_post_code,
          property_address: '',
          purchase_price: null,
          loan_amount: null
        }}
        validationSchema={RequestValuationSchema}
        enableReinitialize={loading}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    label="Applicant Name"
                    name="applicant_name"
                    value={values.applicant_name}
                    type="text"
                    disabled
                  />
                </div>

                <div className="mb-40">
                  <Input
                    label="Email Address"
                    name="applicant_email_address"
                    value={values.applicant_email_address}
                    type="text"
                    disabled
                  />
                </div>

                <div className="mb-40">
                  <ReactSelect
                    label="Mortgage Type"
                    name="purchase_type"
                    value={values.purchase_type}
                    options={mortgageTypeOptions}
                    disabled
                  />
                </div>

                <div className="mb-40">
                  <Input
                    label="Application ID"
                    name="application_number"
                    value={values.application_number}
                    type="text"
                    disabled
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    error={errors.property_eircode && touched.property_eircode && errors.property_eircode}
                    label="Property Eircode"
                    name="property_eircode"
                    value={values.property_eircode}
                    onChange={({ target: { value: eircode }}) => {
                      setFieldValue('property_eircode', eircode, true)
                      getAddress(eircode)
                    }}
                    onBlur={handleBlur}
                    type="text"
                    disabled={application.application_evaluation.created_at}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    label="Property Address"
                    name="property_address"
                    value={values.property_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    disabled
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    error={errors.purchase_price && touched.purchase_price && errors.purchase_price}
                    addon="€"
                    addonPosition="start"
                    label="Purchase Price/Property Value"
                    name="purchase_price"
                    setFieldValue={setFieldValue}
                    value={values.purchase_price}
                    onBlur={handleBlur}
                    disabled={application.application_evaluation.created_at}
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    error={errors.loan_amount && touched.loan_amount && errors.loan_amount}
                    addon="€"
                    addonPosition="start"
                    label="Loan Amount"
                    name="loan_amount"
                    setFieldValue={setFieldValue}
                    value={values.loan_amount}
                    onBlur={handleBlur}
                    disabled={application.application_evaluation.created_at}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end items-center">
              {application.application_evaluation.created_at &&
                <p className="pr-2">Valuation requested at {application.application_evaluation.created_at}</p>
              }
              <button className='btn btn--primary' disabled={errors.loan_amount || errors.purchase_price || errors.property_eircode || application.application_evaluation.created_at} onClick={() => setSubmitRequestModalOpen(true)} type="button">
                Submit
              </button>
            </div>
            { submitRequestModalOpen &&
              <Modal>
                <div className="p-8">
                  <p className="mb-8">
                    A valuation instruction will be sent to the borrower by email. Are you sure you wish to continue?
                  </p>

                  <div className="flex justify-center -mx-4">
                    <button className="btn btn--primary mx-4" onClick={() => setSubmitRequestModalOpen(false)} type="button">
                      Cancel
                    </button>

                    <button
                      className="btn btn--primary mx-4"
                      onClick={() => {
                        requestValuation(values)
                        setSubmitRequestModalOpen(false)
                      }}
                      type="button"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </Modal>
            }
          </form>
        )}
      </Formik>
    </>
  )
})
