import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import AssetsPart from './ApplicantParts/AssetsPart'
import CreditCardCommitmentsPart from './ApplicantParts/CreditCardCommitmentsPart'
import DocumentsPart from './ApplicantParts/DocumentsPart'
import EmploymentPart from './ApplicantParts/EmploymentPart'
import GeneralQuestionsPart from './ApplicantParts/GeneralQuestionsPart'
import LoanCommitmentsPart from './ApplicantParts/LoanCommitmentsPart'
import OtherIncomePart from './ApplicantParts/OtherIncomePart'
import PensionPart from './ApplicantParts/PensionPart'
import PersonalDetailsPart from './ApplicantParts/PersonalDetailsPart'

export default observer(function ApplicantsPart() {
  const { applicationState: { applicants }} = useContext(ApplicationContext)

  return (
    <Fragment>
      <tr>
        <td></td>
        { applicants.map((applicant, index) => <td key={applicant.id} className="font-bold text-center">Applicant {index + 1}</td>)}
      </tr>
      <PersonalDetailsPart applicants={applicants} />
      <tr><td></td><td></td><td></td></tr>
      <EmploymentPart applicants={applicants} />
      <tr><td></td><td></td><td></td></tr>
      <OtherIncomePart applicants={applicants} />
      <tr><td></td><td></td><td></td></tr>
      <PensionPart applicants={applicants} />
      <tr><td></td><td></td><td></td></tr>
      <AssetsPart applicants={applicants} />
      <tr><td></td><td></td><td></td></tr>
      <LoanCommitmentsPart applicants={applicants} />
      <tr><td></td><td></td><td></td></tr>
      <CreditCardCommitmentsPart applicants={applicants} />
      <tr><td></td><td></td><td></td></tr>
      <GeneralQuestionsPart applicants={applicants} />
    </Fragment>
  )
})