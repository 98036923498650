import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axiosService from '../../utils/AxiosService'

export default function Freshdesk() {

  const location = useLocation()
  const [broker, setBroker] = useState({})
  const brokerPage = useMemo(() => location.pathname.includes('/broker/'), [location.pathname])
  const brokerLoginPage = useMemo(() => location.pathname.includes('/broker/login'), [location.pathname])

  useEffect(() => brokerPage && window.FreshworksWidget('hide', 'launcher'), [])

  useEffect(() => {
    if(brokerPage && !brokerLoginPage && !broker.id) getCurrentBroker()
    if(brokerLoginPage) {
      setBroker({})
      window.FreshworksWidget('identify', 'ticketForm', { name: '', email: '' })
    }
  }, [location.pathname])

  const getCurrentBroker = () => {
    axiosService.get('/api/apprivo_broker/sessions/current')
    .then(broker => {
      setBroker(broker)
      window.FreshworksWidget('identify', 'ticketForm', { name: broker.name, email: broker.email })
    })
    .catch(() => {})
  }

  const openWidget = () => window.FreshworksWidget('open', 'ticketForm')

  return (
    <Fragment>
      { brokerPage &&
        <div className="fixed" style={{ zIndex: 60, right: '25px', top: '11px' }}>
          <button className="btn btn--primary" style={{ padding: '0.5rem'}} onClick={openWidget}>Contact Us</button>
        </div>
      }
    </Fragment>
  )
}