import * as Yup from 'yup'

const specificPropertyInMind = () => {
  return [
    'specific_property_in_mind',
    {
      is: true,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ]
}

export const mainPropertyDetailsSchema = Yup.object().shape({
  specific_property_in_mind: Yup.boolean().required('This field is required.'),
  property_address_post_code: Yup.string().when(...specificPropertyInMind()),
  property_address_line1: Yup.string().when(...specificPropertyInMind()),
  property_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  property_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  property_address_city: Yup.string().when(...specificPropertyInMind()),
  property_address_county: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  property_address_country: Yup.string().when(...specificPropertyInMind()),
  property_type: Yup.string().required('This field is required.'),
  property_tenure: Yup.string().when(...specificPropertyInMind()),
  service_charge: Yup.mixed().when(
    ['property_tenure', 'specific_property_in_mind'],
    {
      is: (property_tenure, specific_property_in_mind) => property_tenure === 'LH' && specific_property_in_mind,
      then: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
    }
  ),
  ground_rent: Yup.mixed().when(
    ['property_tenure', 'specific_property_in_mind'],
    {
      is: (property_tenure, specific_property_in_mind) => property_tenure === 'LH' && specific_property_in_mind,
      then: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
    }
  ),
  unexpired_remaining_lease: Yup.mixed().when(
    ['property_tenure', 'specific_property_in_mind'],
    {
      is: (property_tenure, specific_property_in_mind) => property_tenure === 'LH' && specific_property_in_mind,
      then: Yup.lazy(value => value === '' ? Yup.string() : Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.')),
    }
  ),
  around_commercial_premises: Yup.boolean().required('This field is required.'),
  energy_performance_certificate: Yup.string().when(...specificPropertyInMind()),
});
