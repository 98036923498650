import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'
import { CurrentBrokerContext } from '../../../contexts/CurrentBrokerContext';
import { toastInfo, toastSuccess } from '../../Form/Toasts';
import { useState } from 'react';

export default function SendToApprivoButton() {
  const { application_id } = useParams()
  const history = useHistory()
  const currentBrokerContext = useContext(CurrentBrokerContext)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const sendToApprivo = () => {
    setButtonDisabled(true)
    toastInfo('Submitting')
    axiosService.post(`/api/apprivo_broker/applications/${application_id}/send_to_apprivo`)
    .then(() => {
      toastSuccess('Submitted for Product Selection')
      history.push('/broker/applications')
    })
    .catch(({ data: { errors: { full_messages }}}) => {
      toastError(full_messages)
      setButtonDisabled(false)
    })
  }

  return (
    <div className="px-40 py-4">
      { !currentBrokerContext.currentBroker?.brokerage_admin && <button disabled={buttonDisabled} className={`btn btn--primary text-center w-full`} onClick={sendToApprivo}>Submit for Product Selection</button> }
    </div>
  )
}