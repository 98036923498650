import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'

/**
 * Shown when an applicant verifies their email address, eg
 * /email_verification/5/9/99b18615e1c3e41f6a81aa5695966fa810b82f1d4b8598a7411825f6fab2069f
 * 
 * Because the applicant may not be the borrower, this is shown publicly
 * with no authentication and uses the signature validate
 */
export default function EmailVerification() {
  const { application_id, applicant_id, signature } = useParams()
  const [verified, setVerified] = useState({ state: "not_verified", message: '' })

  useEffect(() => {
    axiosService.post(`/api/applicants/verify_email_address/${application_id}/${applicant_id}/${signature}`)
    .then(() => setVerified({ state: "verified" }))
    .catch(({ data: { error }}) => setVerified({ state: "failed", message: error }))
  }, [])


  return (
    <div>
      { verified.state === "not_verified" &&
        <div className="w-full h-72 text-center flex justify-center flex-col">
          <h2 >Please wait a moment</h2>
          <p>You will be notified when your email address is verified</p>
        </div>
      }

      { verified.state === "verified" &&
        <div className="w-full h-72 text-center flex justify-center flex-col">
          <h2>Thank you, your email has been successfully verified.</h2>
          <p>You can now close this tab</p>
        </div>
      }
      { verified.state === "failed" &&
        <div className="w-full h-72 text-center flex justify-center flex-col">
          <h2>Something went wrong</h2>
          <p>{verified.message}</p>
        </div>
      }
    </div>
  )

}