import React, { Fragment } from 'react'
import CurrencyTextField from '../../CurrencyTextField'
import TitleRow from '../../TitleRow'
import ApplicantRow from './ApplicantRow'

export default function OtherIncomePart({ applicants }) {
  const frequencyOptions = [
    { label: 'Weekly', value: 52 },
    { label: '2 Weekly', value: 26 },
    { label: '4 Weekly', value: 13 },
    { label: 'Monthly ', value: 12 },
    { label: 'Annually', value: 1 },
  ]

  const getFrequency = frequency => frequencyOptions.find(option => option.value === frequency)?.label

  return (
    <Fragment>
      <TitleRow label="Other Income" />
      <ApplicantRow name="Permanent State Benefits" tdContent={applicant => applicant.other_income.do_you_have_income_1 ? 'Yes' : 'No'} />
      {(applicants[0]?.other_income?.do_you_have_income_1 || applicants[1]?.other_income?.do_you_have_income_1) &&
        <Fragment>
          <ApplicantRow name="Permanent State Benefits Amount" tdContent={applicant => <CurrencyTextField value={applicant.other_income.amount1} />} />
          <ApplicantRow name="Permanent State Benefits Frequency" tdContent={applicant => getFrequency(applicant.other_income.pay_frequency1)} />
          <ApplicantRow name="Permanent State Benefits Guaranteed" tdContent={applicant => applicant.other_income.guaranteed1 ? 'Yes' : 'No'} />
        </Fragment>
      }
      <ApplicantRow name="Annual Stock Vesting" tdContent={applicant => applicant.other_income.do_you_have_income_2 ? 'Yes' : 'No'} />
      {(applicants[0]?.other_income?.do_you_have_income_2 || applicants[1]?.other_income?.do_you_have_income_2) &&
        <Fragment>
          <ApplicantRow name="Annual Stock Vesting Amount" tdContent={applicant => <CurrencyTextField value={applicant.other_income.amount2} />} />
          <ApplicantRow name="Annual Stock Vesting Frequency" tdContent={applicant => getFrequency(applicant.other_income.pay_frequency2)} />
          <ApplicantRow name="Annual Stock Vesting Guaranteed" tdContent={applicant => applicant.other_income.guaranteed2 ? 'Yes' : 'No'} />
        </Fragment>
      }
      <ApplicantRow name="Maintenance (Court Order)" tdContent={applicant => applicant.other_income.do_you_have_income_3 ? 'Yes' : 'No'} />
      {(applicants[0]?.other_income?.do_you_have_income_3 || applicants[1]?.other_income?.do_you_have_income_3) &&
        <Fragment>
          <ApplicantRow name="Maintenance (Court Order) Amount" tdContent={applicant => <CurrencyTextField value={applicant.other_income.amount3} />} />
          <ApplicantRow name="Maintenance (Court Order) Frequency" tdContent={applicant => getFrequency(applicant.other_income.pay_frequency3)} />
          <ApplicantRow name="Maintenance (Court Order) Guaranteed" tdContent={applicant => applicant.other_income.guaranteed3 ? 'Yes' : 'No'} />
        </Fragment>
      }
      <ApplicantRow name="Maintenance (Voluntary)" tdContent={applicant => applicant.other_income.do_you_have_income_4 ? 'Yes' : 'No'} />
      {(applicants[0]?.other_income?.do_you_have_income_4 || applicants[1]?.other_income?.do_you_have_income_4) &&
        <Fragment>
          <ApplicantRow name="Maintenance (Voluntary) Amount" tdContent={applicant => <CurrencyTextField value={applicant.other_income.amount4} />} />
          <ApplicantRow name="Maintenance (Voluntary) Frequency" tdContent={applicant => getFrequency(applicant.other_income.pay_frequency4)} />
          <ApplicantRow name="Maintenance (Voluntary) Guaranteed" tdContent={applicant => applicant.other_income.guaranteed4 ? 'Yes' : 'No'} />
        </Fragment>
      }
      <ApplicantRow name="Others" tdContent={applicant => applicant.other_income.do_you_have_income_5 ? 'Yes' : 'No'} />
      {(applicants[0]?.other_income?.do_you_have_income_5 || applicants[1]?.other_income?.do_you_have_income_5) &&
        <Fragment>
          <ApplicantRow name="Others Description" tdContent={applicant => applicant.other_income.source_details5} />
          <ApplicantRow name="Others Amount" tdContent={applicant => <CurrencyTextField value={applicant.other_income.amount5} />} />
          <ApplicantRow name="Others Frequency" tdContent={applicant => getFrequency(applicant.other_income.pay_frequency5)} />
          <ApplicantRow name="Others Guaranteed" tdContent={applicant => applicant.other_income.guaranteed5 ? 'Yes' : 'No'} />
        </Fragment>
      }
    </Fragment>
  )
}