import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../../models/Applicant'
import Income from '../../../../models/Income'
import { EmploymentStep2SelfEmployedSchema } from '../../../../schemas/EmploymentSchemas/EmploymentStep2SelfEmployedSchema'
import axiosService from '../../../../utils/AxiosService'
import Divider from '../../../Divider'
import Input from '../../../Form/Input'
import LoqateWrapper from '../../../Form/LoqateWrapper'
import { notifyProgressSaved } from '../../../Form/Notifications'
import ReactSelect from '../../../Form/ReactSelect'
import YesNoBtnGroup from '../../../Form/YesNoBtnGroup'
import EmploymentActions from '../EmploymentActions'

export default observer(function SelfEmployedFormPart() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ['business_type', "registered_post_code", "registered_address_line1", "registered_address_line2", "registered_address_line3", "registered_city", "registered_county", "registered_country", "self_employed_company_number", "is_business_address_different", "business_post_code", "business_address_line1", "business_address_line2", "business_address_line3", "business_city", "business_country", "business_county"]

  const save = income => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { income } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.income = new Income(income)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  useEffect(() => {
    if(currentApplicant.steps.employment_step2_visited) touchFields()
    applicationErrorsContext.validateEmploymentStep2SelfEmployed(currentApplicant.income, applicant_id)
  }, [loading, currentApplicant.income])

  return (
    <div>
      <Formik
        initialValues={currentApplicant.income}
        validationSchema={EmploymentStep2SelfEmployedSchema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <Divider text="Registered address" />

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <LoqateWrapper
                    label="Search Address"
                    tooltip="Type address or Eircode to lookup address"
                    onSelect={address => {
                      setFieldValue('registered_post_code', address.PostalCode, true)
                      setFieldValue('registered_address_line1', address.Line1, true)
                      setFieldValue('registered_address_line2', address.Line2, true)
                      setFieldValue('registered_address_line3', address.Line3, true)
                      setFieldValue('registered_city', address.City, true)
                      address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                        ? setFieldValue('registered_county', 'Dublin', true)
                        : setFieldValue('registered_county', address.Province, true)
                      setFieldValue('registered_country', address.CountryIso3, true)
                    }}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.registered_post_code && errors.registered_post_code}
                    label="Eircode"
                    name="registered_post_code"
                    type="text"
                    onChange={(e) => {
                      const postCodeValue = e.target.value
                      if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('registered_county', 'Dublin', true)
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    value={values.registered_post_code}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.registered_address_line1 && errors.registered_address_line1}
                    label="Address Line 1"
                    name="registered_address_line1"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.registered_address_line1}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.registered_address_line2 && errors.registered_address_line2}
                    label="Address Line 2"
                    name="registered_address_line2"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.registered_address_line2}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.registered_address_line3 && errors.registered_address_line3}
                    label="Address Line 3"
                    name="registered_address_line3"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.registered_address_line3}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    error={touched.registered_city && errors.registered_city}
                    label="City"
                    name="registered_city"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.registered_city}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={touched.registered_county && errors.registered_county}
                    label="County"
                    name="registered_county"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.registered_county}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <ReactSelect
                    countrySelect
                    error={touched.registered_country && errors.registered_country}
                    label="Country"
                    name="registered_country"
                    placeholder="Select country"
                    onChange={registered_country => setFieldValue('registered_country', registered_country.value, true)}
                    value={values.registered_country}
                    disabled={disabled}
                  />
                </div>

                { values.business_type === '4' &&
                  <div className="mb-40">
                    <Input
                      error={touched.self_employed_company_number && errors.self_employed_company_number}
                      label="Company Registration Number"
                      name="self_employed_company_number"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.self_employed_company_number}
                      disabled={disabled}
                    />
                  </div>
                }

                <div className="mb-40">
                  <YesNoBtnGroup
                    error={touched.is_business_address_different && errors.is_business_address_different}
                    label="Trading address (if different)"
                    name="is_business_address_different"
                    onChange={(name, value) => {
                      setFieldValue(name, value, true)
                      setFieldValue('business_post_code', '', true)
                      setFieldValue('business_address_line1', '', true)
                      setFieldValue('business_address_line2', '', true)
                      setFieldValue('business_address_line3', '', true)
                      setFieldValue('business_city', '', true)
                      setFieldValue('business_county', '', true)
                      setFieldValue('business_country', '', true)
                    }}
                    value={values.is_business_address_different}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>

            { values.is_business_address_different &&
              <Fragment>
                <Divider text="Trading address" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <LoqateWrapper
                        label="Search Address"
                        tooltip="Type address or Eircode to lookup address"
                        onSelect={address => {
                          setFieldValue('business_post_code', address.PostalCode, true)
                          setFieldValue('business_address_line1', address.Line1, true)
                          setFieldValue('business_address_line2', address.Line2, true)
                          setFieldValue('business_address_line3', address.Line3, true)
                          setFieldValue('business_city', address.City, true)
                          address.PostalCode.charAt(0).toLocaleLowerCase() === 'd'
                            ? setFieldValue('business_county', 'Dublin', true)
                            : setFieldValue('business_county', address.Province, true)
                          setFieldValue('business_country', address.CountryIso3, true)
                        }}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={touched.business_post_code && errors.business_post_code}
                        label="Eircode"
                        name="business_post_code"
                        type="text"
                        onChange={(e) => {
                          const postCodeValue = e.target.value
                          if (postCodeValue.charAt(0).toLocaleLowerCase() === 'd') setFieldValue('business_county', 'Dublin', true)
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        value={values.business_post_code}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={touched.business_address_line1 && errors.business_address_line1}
                        label="Address Line 1"
                        name="business_address_line1"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.business_address_line1}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={touched.business_address_line2 && errors.business_address_line2}
                        label="Address Line 2"
                        name="business_address_line2"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.business_address_line2}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={touched.business_address_line3 && errors.business_address_line3}
                        label="Address Line 3"
                        name="business_address_line3"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.business_address_line3}
                        disabled={disabled}
                      />
                    </div>
                  </div>

                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <Input
                        error={touched.business_city && errors.business_city}
                        label="City"
                        name="business_city"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.business_city}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={touched.business_county && errors.business_county}
                        label="County"
                        name="business_county"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.business_county}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <ReactSelect
                        countrySelect
                        error={touched.business_country && errors.business_country}
                        label="Country"
                        name="business_country"
                        placeholder="Select country"
                        onChange={business_country => setFieldValue('business_country', business_country.value, true)}
                        value={values.business_country}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            }
            <EmploymentActions save={save(values)} />
          </form>
        )}
    </Formik>
  </div>
  )
})