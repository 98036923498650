import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

export default observer(function ApplicantValidator({ applicant, validateGeneralQuestions, validateAssets, validatePension, validateCommitmentsStep1, validateCommitmentsStep2, validateOtherIncomeStep1, validateOtherIncomeStep2, validateOtherIncomeStep3, validatePersonalDetailsStep1, validatePersonalDetailsStep2, validatePersonalDetailsStep3, validatePersonalDetailsStep4, validateEmploymentStep1Employed, validateEmploymentStep2Employed, validateEmploymentStep3Employed, validateEmploymentStep4Employed, validateEmploymentStep1SelfEmployed, validateEmploymentStep2SelfEmployed, validateEmploymentStep3SelfEmployed, validateEmploymentStep4SelfEmployed, validateOpenBanking, validateIdVerification, applicantEmails, firstApplicantId }) {

  useEffect(() => { if(applicant.steps.general_questions_visited) validateGeneralQuestions(applicant.general, applicant.id) }, [applicant.general])
  useEffect(() => { if(applicant.steps.assets_visited) validateAssets(applicant.assets, applicant.id) }, [applicant.assets])
  useEffect(() => { if(applicant.steps.pension_visited) validatePension(applicant.pension, applicant.id) }, [applicant.pension])
  useEffect(() => { if(applicant.steps.commitments_step1_visited) validateCommitmentsStep1(applicant.loan_commitments, applicant.id) }, [applicant.loan_commitments])
  useEffect(() => { if(applicant.steps.commitments_step2_visited) validateCommitmentsStep2(applicant.credit_card_commitments, applicant.id) }, [applicant.credit_card_commitments])

  useEffect(() => {
    if(applicant.steps.other_income_step1_visited) validateOtherIncomeStep1(applicant.other_income, applicant.id)
    if(applicant.steps.other_income_step2_visited) validateOtherIncomeStep2(applicant.other_income, applicant.id)
    if(applicant.steps.other_income_step3_visited) validateOtherIncomeStep3(applicant.other_income, applicant.id)
  }, [applicant.other_income])

  useEffect(() => {
    if(applicant.steps.personal_details_step1_visited) validatePersonalDetailsStep1(applicant, applicant.id)
    if(applicant.steps.personal_details_step2_visited) validatePersonalDetailsStep2({ ...applicant, applicantEmails, firstApplicantId }, applicant.id)
    if(applicant.steps.personal_details_step3_visited) validatePersonalDetailsStep3(applicant, applicant.id)
    if(applicant.steps.personal_details_step4_visited) validatePersonalDetailsStep4(applicant, applicant.id)
  }, [applicant, firstApplicantId])

  useEffect(() => validateOpenBanking(applicant), [applicant.open_banking_consent, applicant.open_banking_details?.status])
  useEffect(() => validateIdVerification(applicant), [applicant.identity_verification_consent, applicant.identity_verification_backend?.status])

  useEffect(() => {
    if(['Retired', 'Not Employed', 'None of the above'].includes(applicant.employment.are_you)) return

    if(applicant.steps.employment_step1_visited) {
      applicant.employment.are_you === 'Self Employed' ? validateEmploymentStep1SelfEmployed(applicant.income, applicant.id) : validateEmploymentStep1Employed(applicant.employment, applicant.id)
    }
    if(applicant.steps.employment_step2_visited) {
      applicant.employment.are_you === 'Self Employed' ? validateEmploymentStep2SelfEmployed(applicant.income, applicant.id) : validateEmploymentStep2Employed(applicant.employment, applicant.id)
    }
    if(applicant.steps.employment_step3_visited) {
      applicant.employment.are_you === 'Self Employed' ? validateEmploymentStep3SelfEmployed(applicant.income, applicant.id) : validateEmploymentStep3Employed(applicant.employment, applicant.id)
    }
    if(applicant.steps.employment_step4_visited) {
      applicant.employment.are_you === 'Self Employed' ? validateEmploymentStep4SelfEmployed(applicant.income, applicant.id) : validateEmploymentStep4Employed(applicant.employment, applicant.id)
    }

  }, [applicant.employment, applicant.income])

  return (
    <div>

    </div>
  )
})