import * as Yup from 'yup'

export const PersonalDetailsStep1Schema = Yup.object().shape({
  title: Yup.string().required('This field is required.'),
  first_name: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  middle_name: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  last_name: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  known_by_other_name: Yup.boolean().required('This field is required.'),
  other_title: Yup.string().when(
    "known_by_other_name", {
      is: true,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
  other_first_name: Yup.string().when(
    "known_by_other_name", {
      is: true,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
  other_middle_name: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_last_name: Yup.string().when(
    "known_by_other_name", {
      is: true,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
});