import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function ProgressIndicator({
  steps,
  url,
  save = () => {},
  disableNavigation = false,
  errors = []
}) {
  const [currentStep, setCurrentStep] = useState(1)

  let history = useHistory()
  const location = useLocation()

  const navigate = i => () => {
    if (disableNavigation) return

    const savePromise = save()
    if(savePromise?.then) {
      savePromise.then(() => {
        history.push(`${url}/step${i + 1}`)
      })
    }
    else {
      history.push(`${url}/step${i + 1}`)
    }
  }

  useEffect(() => {
    setCurrentStep(+/step(\d+)/.exec(location.pathname)?.[1])
  }, [location])

  const getErrorClass = index => {
    if(!(errors[index] && errors[index].visited)) return ''
    return errors[index].errors ? 'invalid' : 'valid'
  }

  return (
    <div className={`progress-indicator-container ${disableNavigation ? 'disable-navigation' : ''}`}>
      <div className="progress-indicator-line">
        {
          [...Array(steps)].map((e, i) => (
            <button
              className={`progress-circle ${getErrorClass(i)} ${currentStep === i + 1 ? 'active' : ''}`}
              key={i}
              onClick={navigate(i)}
              type="button"
            >
              {i + 1}
            </button>
          ))
        }
      </div>
    </div>
  )
}
