import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactSelect from '../../Form/ReactSelect'
import axiosService from '../../../utils/AxiosService'
import Modal from '../../Modal'

export default function BankSelect({
  setState,
  state,
}) {
  const { application_id, applicant_id } = useParams()
  const [bankOptions, setBankOptions] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    axiosService.get(`/api/borrower/applications/${application_id}/applicants/${applicant_id}/ob_institutions`)
      .then((results) => {
        setBankOptions(results.map(item => {
          return {
            icon: item.media[0].source,
            label: item.name,
            value: item.id
          }
        }))
      })
      .catch(({ data: { error } }) => console.log('err', error))
  }, []);

  const CustomSelectOption = ({
    data,
    innerProps,
    isFocused,
    isSelected,
  }) => (
    <div
      className={`
        react-select__option flex items-center
        ${isSelected && 'react-select__option--is-selected'}
        ${isFocused && 'react-select__option--is-focused'}
      `}
      {...innerProps}
    >
      <div
        className="bg-center bg-contain bg-no-repeat mr-2"
        style={{
          backgroundImage: `url(${data.icon})`,
          height: 30,
          width: 30,
        }}
      />

      {data.label}
    </div>
  )

  return (
    <>
      {modalOpen && (
        <Modal>
          <div className="p-8">
            <p className="mb-8">
              Please note that Permanent TSB only provide transaction information for the last 3 months through Open Banking.  In line with market practice, MoCo require a minimum of 6 months transaction information to provide mortgage approval. To provide the previous 3 months’ transaction information, please upload your Permanent TSB statements in the Document Upload section of your application. 
            </p>

            <div className="flex justify-center -mx-4">
              <button
                className="btn btn--primary mx-4"
                onClick={(e) => {
                  e.prevenDefault
                  setModalOpen(false)
                }}
                type="button"
              >
                Ok
              </button>
            </div>
          </div>
        </Modal>
      )}

      <div className="flex justify-between">
        <div className="w-1/2">
          <ReactSelect
            components={{ Option: CustomSelectOption }}
            placeholder="Select your bank"
            onChange={item => {
              setState({
                ...state,
                selectedBank: item,
              })
              if (item.value === 'permanent-tsb') setModalOpen(true)
            }}
            options={bankOptions}
            value={state.selectedBank.value}
          />
        </div>

        <button
          className={`btn btn--primary ${state.selectedBank.value === null && 'disabled'}`}
          disabled={state.selectedBank.value === null}
          onClick={(e) => {
            e.preventDefault();
            setState({
              ...state,
              currentStep: 3,
            });
          }}
          type="button"
        >
          Connect
        </button>
      </div>
    </>
  )
}
