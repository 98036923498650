import * as Yup from 'yup'
import moment from 'moment'

const checkCharacterCalculation = value => {
  if(!value) return false
  let total = 0
  for (let index = 0; index < 7; index++) {
    total += +value[index] * (8-index)
  }
  if(value.length == 9) total += ("ABCDEFGHIJKLMNOPQRSTU".indexOf(value[8])+1)*9
  const remainder = total % 23
  const character = "ABCDEFGHIJKLMNOPQRSTUV"[remainder-1]
  return character === value[7]

}

export const PersonalDetailsStep2Schema = Yup.object().shape({
  date_of_birth: Yup.date()
    .required('This field is required.')
    .test(
      'date_of_birth',
      'Age should be over 18.',
      (date) => moment().diff(moment(date), 'years') >= 18
    ),
  marital_status: Yup.string().required('This field is required.'),
  nationality: Yup.string().required('This field is required.'),
  ppsn: Yup.string()
    .required('This field is required.')
    .min(8, 'PPSN number must be at least 8 characters')
    .max(9, 'PPSN number must be maximum 9 characters')
    .test({
      name: 'ppsndigit',
      exclusive: true,
      message: 'PPSN number first 7 characters must be digits',
      test: value => value && +value.substring(0,7) == value.substring(0,7),
    }).test({
      name: 'ppsn8',
      exclusive: true,
      message: 'PPSN number 8th and 9th characters must be uppercase letters',
      test: value => value && /^[A-Z]{1,2}$/.test(`${value[7]}${value[8] ? value[8] : ''}`),
    }),
    // .test({
    //   name: 'ppsn8',
    //   exclusive: true,
    //   message: 'PPSN number 8th character must be an uppercase letter between A and V',
    //   test: value => value && /[A-V]/.test(value[7]),
    // }).test({
    //   name: 'ppsn9',
    //   exclusive: true,
    //   message: 'PPSN number 9th character must be an uppercase letter from W, H or A',
    //   test: value => value && value.length === 9 ? /[AHW]/.test(value[8]) : true,
    // }).test({
    //   name: 'ppsnCheck',
    //   exclusive: true,
    //   message: 'PPSN invalid',
    //   test: value => checkCharacterCalculation(value),
    // }),
  email: Yup.string().required('This field is required.').email('Enter a valid email address.')
    .test({
      name: 'emailuniqueness',
      exclusive: true,
      message: 'Email address must be unique',
      test: (value, { parent }) => parent.id === parent.firstApplicantId || Object.keys(parent.applicantEmails).reduce((result, applicant_id) => parent.applicantEmails[applicant_id] === value ? result + 1 : result, 0) <= 1
    }),
  email_verified: Yup.boolean().test({
    name: 'emailmustbeverified',
    exclusive: true,
    message: 'Email address must be verified',
    test: value => value === true
  }).required('This field is required.'),
  mobile_number: Yup.string().required('This field is required.'),
  phone_number: Yup.string(),
  work_phone_number: Yup.string(),
  right_to_live_in_roi: Yup.boolean().when(
    'nationality',
    {
      is: nationality => nationality !== "47",
      then: Yup.boolean()
      .test({
        name: 'mustliveinroi',
        exclusive: true,
        message: 'We are unable to accept applications from applicants who do no have the right to live and work in ROI',
        test: value => value === true
      })
    }
  ),
  date_moved_to_roi: Yup.mixed()
    .when(
      "nationality", {
        is: '47',
        then: Yup.string(),
        otherwise: Yup.date().required('This field is required.').test(
          'date_moved_to_roi',
          'Date can not be within the last 6 months.',
          (date) => moment().diff(moment(date), 'months') >= 6
        )
      }
    ),
  has_diplomatic_immunity: Yup.boolean().when(
    'nationality',
    {
      is: nationality => nationality !== "47",
      then: Yup.boolean()
      .test({
        name: 'mustnotbediplomaticallyimmune',
        exclusive: true,
        message: 'We do not accept applications from for applicants holding diplomatic immunity',
        test: value => value === false
      }),
    }
  )
});