import * as Yup from 'yup'

export const CommitmentsStep2Schema = Yup.object().shape({
  credit_card_commitments: Yup.array()
    .of(
      Yup.object().shape({
        outstanding_balance: Yup.number('This field must contain numbers.').typeError('It must be a number').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
        credit_card_repayment_type: Yup.string().required('This field is required.'),
        loan_paid_on_or_before: Yup.boolean().required('This field is required.'),
        monthly_payment: Yup.number('This field must contain numbers.').typeError('It must be a number').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
        lender_name: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
        credit_account_number: Yup.number('This field must contain numbers.').typeError('It must be a number').min(0, 'This field must be greater than or equal 0.').max(9999, 'This field should contain max 4 digits').required('This field is required.'),
        credit_limit: Yup.number('This field must contain numbers.').typeError('It must be a number').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
        payments_missed: Yup.boolean().required('This field is required.'),
        payments_missed_details: Yup.string().when(
          'payments_missed',
          {
            is: true,
            then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
          }
        )
      })
    )
});