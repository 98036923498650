import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import iconVerificationError from 'images/icons/verification-error.svg'
import iconVerificationOk from 'images/icons/verification-ok.svg'
import iconVerificationPending from 'images/icons/verification-pending.svg'
import iconAdjust from 'images/icons/adjust.svg'
import iconCropAlt from 'images/icons/crop-alt.svg'
import iconLightbuld from 'images/icons/lightbulb.svg'

import { ApplicationContext } from '../../../contexts/ApplicationContext'
import axiosService from '../../../utils/AxiosService'

export default function Status() {
  const applicationContext = useContext(ApplicationContext)
  const { application_id, id_verification_token } = useParams()

  useEffect(() => {
    console.log('status useEffect')

    axiosService.post('/api/borrower/identity_verification/mobile_flow_ended', { queued_to_agent: applicationContext.applicationState.identityVerification.resultStatus.topText.includes('Your documents have been sent for verification') ? true : false, id_verification_token: id_verification_token, application_id: application_id })
      .then(response => {
        console.log(response)
      })
      .catch(error => { console.log(error) })

    window.scrollTo(0, 0)

    console.log('status useEffect after')

    return () => {}
  }, [])

  const retry = () => {
    window.location.replace(window.location.href.replace(/status/g, 'id-verification'))
  }

  const save = () => {

  }

  return (
    <>
      <h2 className="form-title mb-40">{ applicationContext.applicationState.identityVerification.resultStatus.title }</h2>

      <p>{ applicationContext.applicationState.identityVerification.resultStatus.topText }</p>

      {(function() {
        if (applicationContext.applicationState.identityVerification.resultStatus.icon == 'iconVerificationOk') {
          return <img alt="" className="mb-4" src={iconVerificationOk} />
        }
        else if (applicationContext.applicationState.identityVerification.resultStatus.icon == 'iconVerificationPending') {
          return <img alt="" className="mb-4" src={iconVerificationPending} />
        }
        else {
          return <img alt="" className="mb-4" src={iconVerificationError} />
        }
      })()}

      { applicationContext.applicationState.identityVerification.resultStatus.hints &&
      <div className="flex flex-wrap mb-40 -mx-2 text-center text-xs">
        <div className="flex px-2 w-1/3">
          <div className="border border-tulip-tree p-4 rounded-lg w-full">
            <div className="p-4">
              <img alt="" className="mb-2 mx-auto" src={iconAdjust} />
            </div>

            <p>Use dark<br /> background!</p>
          </div>
        </div>

        <div className="flex px-2 w-1/3">
          <div className="border border-tulip-tree p-4 rounded-lg w-full">
            <div className="p-4">
              <img alt="" className="mb-2 mx-auto" src={iconCropAlt} />
            </div>

            <p>Get all 4<br /> corners in the<br /> frame!</p>
          </div>
        </div>

        <div className="flex px-2 w-1/3">
          <div className="border border-tulip-tree p-4 rounded-lg w-full">
            <div className="p-4">
              <img alt="" className="mb-2 mx-auto" src={iconLightbuld} />
            </div>

            <p>Make sure<br /> lightning is<br /> good</p>
          </div>
        </div>
      </div>
      }

      {(function() {
        if (applicationContext.applicationState.identityVerification.resultStatus.bottomText != '') {
          return <p className="mb-40">{ applicationContext.applicationState.identityVerification.resultStatus.bottomText }</p>
        }
        else {
          return ''
        }
      })()}

      {(function() {
        if (applicationContext.applicationState.identityVerification.resultStatus.buttonText != '') {
          return <div className="flex justify-center"><button className="btn btn--primary lg:w-auto w-full" type="button" onClick={retry}>{applicationContext.applicationState.identityVerification.resultStatus.buttonText}</button></div>
        }
        else {
          return ''
        }
      })()}
    </>
  )
}
