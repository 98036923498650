import * as Yup from 'yup'
import iban from 'iban'

export const directDebitSchema = Yup.object().shape({
  name_of_account_holder: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  account_number: Yup.string().required('This field is required.')
  .test({
    name: 'iban',
    exclusive: true,
    message: 'IBAN number is incorrect',
    test: value => value && iban.isValid(value),
  })
  .test({
    name: 'uppercase',
    exclusive: true,
    message: 'IBAN number should be uppercase',
    test: value => value && value[0]?.toUpperCase() === value[0] && value[1]?.toUpperCase() === value[1],
  }),
  sort_code: Yup.string().required('This field is required.').test({
    name: 'BIC',
    exclusive: true,
    message: 'BIC number should contain minimum 8 uppercase letters or numbers.',
    test: value => value && /^[A-Z0-9]{8,}$/.test(value),
  }),
  institution: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  branch: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  contact_postcode: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_line1: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  city: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  county: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  paymentday: Yup.number().typeError('This field must contain numbers.').required('This field is required.')
});