import React, { Fragment } from 'react'
import TitleRow from '../../TitleRow'
import ApplicantRow from './ApplicantRow'

export default function GeneralQuestionsPart({ applicants }) {

  return (
    <Fragment>
      <TitleRow label="General Questions" />
      <ApplicantRow name="Payday loan in past 3 years" tdContent={applicant => applicant.general.have_payday_loan ? 'Yes' : 'No'} />
      {(applicants[0]?.general?.have_payday_loan || applicants[1]?.general?.have_payday_loan) &&
        <ApplicantRow name="Details" tdContent={applicant => applicant.general.payday_loan_details} />
      }
    </Fragment>
  )
}