import React, { Fragment, useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Input from '../../Form/Input'
import ReactSelect from '../../Form/ReactSelect'
import ReactMultiSelect from '../../Form/ReactMultiSelect'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import axiosService from '../../../utils/AxiosService'
import { observer } from 'mobx-react-lite'
import { Formik } from 'formik'
import ApplicationStepUpdater from '../../Form/ApplicationStepUpdater'
import LoanRequirementsActions from './LoanRequirementsActions'
import { loanRequirementsStep1Schema } from '../../../schemas/LoanRequirementsSchemas/LoanRequirementsStep1Schema'
import Mortgage from '../../../models/Mortgage'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'

export default observer(function LoanRequirementsStep1() {
  const mortgageTypeOptions = [
    { label: 'Purchase', value: 'Purchase' },
    { label: 'Switcher', value: 'Remortgage' },
  ]

  const depositTypeOptions = [
    { label: 'Gift', value: 'Gift' },
    { label: 'Inheritance', value: 'Inheritance' },
    { label: 'Property Sale', value: 'Property Sale' },
    { label: 'Help to Buy Scheme', value: 'Help to Buy Scheme?' },
    { label: 'Savings', value: 'Savings' },
    { label: 'Other', value: 'Other' },
  ]

  const { applicationState } = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id } = useParams()
  const formikRef = useRef()

  const save = data => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(
      `/api/${applicationState.backend_url_part}/applications/${application_id}`,
      { application:
        {
          mortgage_attributes: data,
          purchase_type: data.purchase_type
        }
      }
    )
    .then(() => {
      notifyProgressSaved()
      applicationState.mortgage = new Mortgage(data)
      applicationState.application.purchase_type = data.purchase_type
    })
  }

  const touchFields = () => {
    formikRef.current.setTouched(Object.keys(applicationState.mortgage).reduce((result, key) => {
      result[key] = true
      return result
    },{ purchase_type: true }))
  }

  const onChangeDepositComesFrom = setFieldValue => value => {
    setFieldValue('source_other', value.includes('Other'), true)
    if(!value.includes('Other')) setFieldValue('source_other_amount', '', true)

    setFieldValue('source_gift', value.includes('Gift'), true)
    if(!value.includes('Gift')) setFieldValue('source_gift_amount', '', true)

    setFieldValue('source_inheritance', value.includes('Inheritance'), true)
    if(!value.includes('Inheritance')) setFieldValue('source_inheritance_amount', '', true)

    setFieldValue('source_property', value.includes('Property Sale'), true)
    if(!value.includes('Property Sale')) setFieldValue('source_property_amount', '', true)

    setFieldValue('source_help_to_buy', value.includes('Help to Buy Scheme?'), true)
    if(!value.includes('Help to Buy Scheme?')) setFieldValue('source_help_to_buy_amount', '', true)

    setFieldValue('source_savings', value.includes('Savings'), true)
    if(!value.includes('Savings')) setFieldValue('source_savings_amount', '', true)

    setFieldValue('deposit_comes_from', value, true)
  }

  const setSpecificPropertyInMindToTrue = () => {
    applicationState.security.specific_property_in_mind = true
    applicationErrorsContext.validateMainPropertyDetails()
  }

  useEffect(() => {
    if(applicationState.application.steps.loan_requirements_step1_visited) touchFields()
    applicationErrorsContext.validateLoanRequirementsStep1()
  }, [applicationState.loading, applicationState.mortgage])

  return (
    <div>
      <h2 className="form-title mb-40">Loan Requirements</h2>
      <p className="mb-40">Specify the loan type and amount of the mortgage required here.</p>
      {!applicationState.disabled && <ApplicationStepUpdater step="loan_requirements_step1_visited" />}

      <Formik
        initialValues={{
          ...applicationState.mortgage,
          purchase_type: applicationState.application.purchase_type
        }}
        validationSchema={loanRequirementsStep1Schema}
        enableReinitialize={applicationState.loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue, setFieldTouched }) => (
          <form onSubmit={save(values)}>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">

                <div className="mb-40">
                  <ReactSelect
                    error={touched.purchase_type && errors.purchase_type}
                    label="Mortgage Type"
                    name="purchase_type"
                    onChange={value => {
                      setFieldValue('purchase_type', (value?.value || ''), true)
                      if(value?.value !== 'Purchase') {
                        setFieldValue('purchase_price', 0, true)
                        setSpecificPropertyInMindToTrue()

                        if (value?.value === 'Remortgage') {
                          setFieldValue('source_other', true, true)
                          setFieldValue('source_other_amount', 0, true)
                          setFieldValue('if_other_details', 'Switcher', true)
                          setFieldValue('deposit_comes_from', 'Other', true)
                        }
                      } else {
                        setFieldValue('source_other', false, true)
                        setFieldValue('source_other_amount', 0, true)
                        setFieldValue('if_other_details', '', true)
                        setFieldValue('deposit_comes_from', '', true)
                        setFieldValue('existing_mortgage_lender', '', true)
                        setFieldValue('purchase_date', '', true)
                        setFieldValue('purchase_price', 0, true)
                        setFieldValue('estimated_value', '', true)
                        setFieldValue('original_amount_borrowed', '', true)
                        setFieldValue('outstanding_balance', '', true)
                        setFieldValue('purpose_of_mortgage', '', true)
                      }
                    }}
                    options={mortgageTypeOptions}
                    value={values.purchase_type}
                    disabled={applicationState.disabled}
                  />
                </div>

                { values.purchase_type === 'Purchase' &&
                  <div className="mb-40">
                    <CurrencyField
                      error={touched.purchase_price && errors.purchase_price}
                      addon="€"
                      addonPosition="start"
                      error={touched.purchase_price && errors.purchase_price}
                      label="Purchase price/value of property"
                      name="purchase_price"
                      setFieldValue={setFieldValue}
                      onChange={handleChange}
                      tooltip="If no specific property identified, please provide estimate value of the property being sought"
                      value={values.purchase_price}
                      disabled={applicationState.disabled}
                    />
                  </div>
                }

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={touched.loan_required && errors.loan_required}
                    label="Loan required"
                    name="loan_required"
                    onBlur={handleBlur}
                    additionalAction={value => {
                      if(+value === +values.outstanding_balance) {
                        setFieldValue('purpose_of_mortgage', null, true)
                      }
                    }}
                    setFieldValue={setFieldValue}
                    value={values.loan_required}
                    disabled={applicationState.disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                {values.purchase_type !== 'Remortgage' &&
                  <div className="mb-40">
                    <ReactMultiSelect
                      error={touched.deposit_comes_from && errors.deposit_comes_from}
                      label="Where will the deposit come from?"
                      name="deposit_comes_from"
                      onChange={onChangeDepositComesFrom(setFieldValue)}
                      options={depositTypeOptions}
                      value={values.deposit_comes_from}
                      disabled={applicationState.disabled}
                      tooltip="If switching your mortgage and no deposit required, please select 'Other' and input €0 as deposit amount"
                    />
                  </div>
                }

                { values.source_gift &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={touched.source_gift_amount && errors.source_gift_amount}
                      label="Gift Amount"
                      name="source_gift_amount"
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.source_gift_amount}
                      disabled={applicationState.disabled}
                    />
                  </div>
                }

                { values.source_inheritance &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={touched.source_inheritance_amount && errors.source_inheritance_amount}
                      label="Inheritance Amount"
                      name="source_inheritance_amount"
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.source_inheritance_amount}
                      disabled={applicationState.disabled}
                    />
                  </div>
                }

                { values.source_property &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={touched.source_property_amount && errors.source_property_amount}
                      label="Property Sale Amount"
                      name="source_property_amount"
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.source_property_amount}
                      disabled={applicationState.disabled}
                    />
                  </div>
                }

                { values.source_help_to_buy &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={touched.source_help_to_buy_amount && errors.source_help_to_buy_amount}
                      label="Help to Buy Amount"
                      name="source_help_to_buy_amount"
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.source_help_to_buy_amount}
                      disabled={applicationState.disabled}
                    />
                  </div>
                }

                { values.source_savings &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={touched.source_savings_amount && errors.source_savings_amount}
                      label="Savings Amount"
                      name="source_savings_amount"
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.source_savings_amount}
                      disabled={applicationState.disabled}
                    />
                  </div>
                }


                { values.source_other && values.purchase_type !== 'Remortgage' &&
                  <Fragment>
                    <div className="mb-40">
                      <Input
                        error={touched.if_other_details && errors.if_other_details}
                        label="Please describe source of funds"
                        name="if_other_details"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.if_other_details}
                        disabled={applicationState.disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.source_other_amount && errors.source_other_amount}
                        label="Other Amount"
                        name="source_other_amount"
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        value={values.source_other_amount}
                        disabled={applicationState.disabled}
                      />
                    </div>
                  </Fragment>
                }
              </div>
            </div>
            <LoanRequirementsActions save={save(values)} purchase_type={values.purchase_type} />
          </form>
        )}
      </Formik>
    </div>
  )
})
