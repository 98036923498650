import React from 'react'

import iconIdentifySms from 'images/icons/identify-sms.svg'
import iconEncryption from 'images/icons/identify-encryption.svg'
import iconPhoto from 'images/icons/identify-photo.svg'
import iconConfirmed from 'images/icons/identify-confirmed.svg'

export default function Step1({textsToDisplay}) {
  if (!textsToDisplay) {
    textsToDisplay = {
      'topText': 'When applying for a mortgage, all applicants are required to verify their identity. We’ve partnered with Mitek Systems to make verifying your identity quick and easy. Click <a href="#">here</a> to find out more on how it works.'
    }
  }

  return (
    <>
      <h2 className="form-title mb-40">Identity verification</h2>

      <p className="mb-40"><b dangerouslySetInnerHTML={{__html: textsToDisplay.topText}}></b></p>

      <div className="flex flex-wrap mb-40 -mx-4 text-center text-sm">
        <div className="px-4 w-1/4">
          <div className="bg-white border border-timber-green-o-16 mb-4 p-4 rounded-lg" style={{ minHeight: 150 }}>
            <img alt="" className="mb-2 mx-auto" src={iconIdentifySms} />
          </div>

          <p>Get the link by text or from QR code.</p>
        </div>

        <div className="px-4 w-1/4">
          <div className="bg-white border border-timber-green-o-16 mb-4 p-4 rounded-lg" style={{ minHeight: 150 }}>
            <img alt="" className="mb-2 mx-auto" src={iconEncryption} />
          </div>

          <p>Open the link on your smart phone and start the process.</p>
        </div>

        <div className="px-4 w-1/4">
          <div className="bg-white border border-timber-green-o-16 mb-4 p-4 rounded-lg" style={{ minHeight: 150 }}>
            <img alt="" className="mb-2 mx-auto" src={iconPhoto} />
          </div>

          <p>Follow the steps to take and send photos.</p>
        </div>

        <div className="px-4 w-1/4">
          <div className="bg-white border border-timber-green-o-16 mb-4 p-4 rounded-lg" style={{ minHeight: 150 }}>
            <img alt="" className="mb-2 mx-auto" src={iconConfirmed} />
          </div>

          <p>Complete the steps on your mobile and view results here.</p>
        </div>
      </div>

      <p className="mb-5 text-center">In order to assess your mortgage application, MoCo will need to verify your identity. MoCo offer an automated process, whereby we will request a photo of you and a photo of your chosen form of identification during the application process. These images will be shared with our third-party partner, MiTek, who will use them to verify your identity. The information and images provided by you for this purpose will be used for this purpose only.</p>
      <p className="mb-5 text-center">We require your consent in order to perform this processing. If you prefer not to give this consent please contact your broker who will inform you how to complete the identity verification process and your application.</p>
      <p className="mb-40 text-center">Further information about the processing of personal data, including information about rights available, is set out in the <a className="link" href="https://staging.moco-dev.com/policies#customer-data-privacy" target="_blank">Customer Data Privacy Notice</a> and <a className="link" href="https://staging.moco-dev.com/policies#data-protection" target="_blank">MoCo’s Data Protection Policy</a>. Consent can be withdrawn at any time, using the contact information set out in the <a className="link" href="https://staging.moco-dev.com/policies#customer-data-privacy" target="_blank">Customer Data Privacy Notice.</a></p>
    </>
  )
}
