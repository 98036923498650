import React from 'react'
import Slider from 'react-slick'

import imageFtb from 'images/landing/hero-first-time-buyer.svg'
import imageMover from 'images/landing/hero-mover.svg'
import imageSwitcher from 'images/landing/hero-switcher.svg'

export default function MortgageFeatures() {
  const settings = {
    arrows: false,
    autoplaySpeed: 5000,
    autoplay: true,
    dots: false,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <div className="slick-flex">
        <Slider {...settings}>
          <div className="slick-item-wrapper">
            <img alt="First time buyer" src={imageFtb} />
          </div>

          <div className="slick-item-wrapper">
            <img alt="Mover" src={imageMover} />
          </div>

          <div className="slick-item-wrapper">
            <img alt="Switcher" src={imageSwitcher} />
          </div>
        </Slider>
      </div>
    </>
  )
}