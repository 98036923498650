import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import iconOtherIncomeGray from 'images/icons/other-income-gray.svg'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'

export default observer(function OtherIncomeIcon({ applicant }) {
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const errorClass = useMemo(() => {
    if(applicant.steps.other_income_step1_visited && !applicantErrors[applicant.id]?.otherIncomeStep1Errors && applicant.steps.other_income_step2_visited && !applicantErrors[applicant.id]?.otherIncomeStep2Errors && applicant.steps.other_income_step3_visited && !applicantErrors[applicant.id]?.otherIncomeStep3Errors) return 'bg-green-500'
    if  ((!applicant.steps.other_income_step1_visited || !applicantErrors[applicant.id]?.otherIncomeStep1Errors)
      && (!applicant.steps.other_income_step2_visited || !applicantErrors[applicant.id]?.otherIncomeStep2Errors)
      && (!applicant.steps.other_income_step3_visited || !applicantErrors[applicant.id]?.otherIncomeStep3Errors))
    {
      return 'bg-white'
    } else {
      return 'bg-red-500'
    }
  }, [applicant, applicantErrors[applicant.id]?.otherIncomeStep1Errors, applicantErrors[applicant.id]?.otherIncomeStep2Errors, applicantErrors[applicant.id]?.otherIncomeStep3Errors])

  return (
    <div className={`applicant-image ${errorClass}`}>
      <img alt="Other Income" src={iconOtherIncomeGray} />
    </div>
  )
})