import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axiosService from '../../utils/AxiosService'
import Input from '../Form/Input'
import PhoneField from '../Form/PhoneField'
import { toastError, toastSuccess } from '../Form/Toasts'
import BorrowerConsentModal from './BorrowerConsentModal'
import { observer, useLocalObservable } from 'mobx-react-lite'
import BorrowerSendCodeModal from './BorrowerSendCodeModal'

const registrationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(10, 'The password has to be at least 10 characters long.')
    .test({
      test: password => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&_])[^\s]{10,}$/.test(password),
      message: "Password should contains at least one lowercase letter, one uppercase letter, one number and one special character"
    }),
  password_confirmation: Yup.string()
    .required('Required')
    .when('password', (password, schema) => {
      return schema.test({
        test: password_confirmation => !!password && password_confirmation === password,
        message: "Should be equal to password"
      })
    }),
  phone: Yup.string().required('Required')
      .min(8, 'The phone number has to be at least 8 characters long.')
      .max(15, 'The phone number cannot contain more than 15 digits.'),
  two_factor_authentication_code: Yup.string().required('Required')
});

export default observer(function BorrowerRegistrationPage() {

  const [open, setOpen] = useState(false)
  const borrowerState = useLocalObservable(() => ({
    open: false,
    borrower: {
      phone: '+353',
      password: '',
      password_confirmation: '',
      two_factor_authentication_code: '',
      consent_1_accepted: false,
      consent_1_date: '',
      consent_2_accepted: false
    }
  }))
  const openConsentModal = borrower => {
    borrowerState.borrower = borrower
    borrowerState.open = true
  }
  const openSendCodeModal = () => setOpen(true)


  return (
    <div className="bg-registration items-center flex w-full" style={{ height: 'calc(100vh - 62px)'}}>
      <div className="container container--main">
        <div className="container--devise">
          <h2 className="font-bold text-2xl">Welcome to MoCo</h2>
          <h2 className="font-bold mb-40 text-2xl">Please register your account</h2>
          <Formik
              initialValues={borrowerState.borrower}
              validationSchema={registrationSchema}
              onSubmit={openConsentModal}
            >
              {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
                <Form>
                  <div className="form-item-container">
                    <Input error={touched.password && errors.password} label="Create Password" type="password" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                  </div>
                  <div className="form-item-container">
                    <Input error={touched.password_confirmation && errors.password_confirmation} label="Confirm Password" type="password" name="password_confirmation" onChange={handleChange} onBlur={handleBlur} value={values.password_confirmation} />
                  </div>
                  <div className="form-item-container">
                    <PhoneField error={touched.phone && errors.phone} label="Mobile Phone Number" name="phone" onChange={phone => setFieldValue('phone', phone, true)} onBlur={handleBlur} value={values.phone} />
                  </div>

                  <p className="text-sm mb-4">In order to authenticate your account, we will send a code by SMS to your mobile. Please enter the code below</p>

                  <div className="form-item-container">
                    <button type="button"
                            className="btn btn--primary text-left w-full"
                            onClick={openSendCodeModal}
                            disabled={values.password === '' || values.password_confirmation === '' || values.phone === ''}>
                      Send code
                    </button>

                    {open && <BorrowerSendCodeModal setOpen={setOpen} values={values} />}
                  </div>

                  <div className="form-item-container">
                    <Input error={touched.two_factor_authentication_code && errors.two_factor_authentication_code} label="Two Factor Authentication Code" maxLength="6" name="two_factor_authentication_code" onChange={handleChange} onBlur={handleBlur} value={values.two_factor_authentication_code} />
                  </div>

                  <button
                      type="submit"
                      className="submit btn btn--primary text-left w-full"
                      disabled={values.two_factor_authentication_code === ''}>
                    Complete your registration
                  </button>
                </Form>
              )}
            </Formik>
            {borrowerState.open && (
              <BorrowerConsentModal
                borrowerState={borrowerState}
              />
            )}
            <p className="text-center w-full mt-4">
              Already Registered? Click <a href="/borrower/login" className="text-turquoise">here</a> to log in
            </p>
        </div>
      </div>
    </div>
  )
})
