import { makeAutoObservable } from "mobx"

export default class Document {

  constructor(document={}) {
    this.id = document.id
    this.applicant_id = document.applicant_id
    this.url = document.url || ''
    this.document_type = document.document_type || ''
    this.file_name = document.file_name || ''
    this.updated_at = document.updated_at || ''
    this.from_api = document.from_api || false

    makeAutoObservable(this)
  }
}
