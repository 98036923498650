import React, { useContext, useEffect, useState } from 'react'

import iconApplicantsWhite from 'images/icons/applicants-white.svg'
import iconDirectDebitWhite from 'images/icons/direct-debit-white.svg'
import iconMainPropertyDetailsWhite from 'images/icons/main-property-details-white.svg'
import iconProductWhite from 'images/icons/product-white.svg'
import iconSolicitorWhite from 'images/icons/solicitor-white.svg'
import iconLoanRequirementsWhite from 'images/icons/loan-requirements-white.svg'
import iconPortfolioWhite from 'images/icons/portfolio-white.svg'
import iconUploadGray from 'images/icons/doc-upload-gray.svg'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import axiosService from '../../utils/AxiosService'
import { CurrentBorrowerContext } from '../../contexts/CurrentBorrowerContext'
import { ApplicationContext } from '../../contexts/ApplicationContext'
import Application from '../../models/Application'
import Property from '../../models/Property'
import Applicant from '../../models/Applicant'
import Security from '../../models/Security'
import Mortgage from '../../models/Mortgage'
import Solicitor from '../../models/Solicitor'
import DirectDebit from '../../models/DirectDebit'
import { observer, useLocalObservable } from 'mobx-react-lite'
import SidebarDropdown from './SidebarDropdown'
import { ApplicationErrorsContext } from '../../contexts/ApplicationErrorsContext'
import SidebarStatusIcon from './SidebarStatusIcon'
import ApplyForMortgageButton from './ApplyForMortgageButton'
import { toastError } from '../Form/Toasts'

export default observer(function Sidebar() {
  const history = useHistory()
  const location = useLocation()
  const [selected, setSelected] = useState()
  const { application_id } = useParams()
  const currentBorrowerContext = useContext(CurrentBorrowerContext)
  const applicationContext = useContext(ApplicationContext)
  const { applicationErrorsState: { solicitorErrors, directDebitErrors, mainPropertyDetailsErrors, portfolioErrors, loanRequirementsStep1Errors, loanRequirementsStep2Errors, documentUploadErrors } } = useContext(ApplicationErrorsContext)

  const pathsState = useLocalObservable(() => ({
    paths: {
      'applicants':            'applicants',
      'direct-debit':          'directDebit',
      'loan-requirements':     'loanRequirements',
      'main-property-details': 'mainPropertyDetails',
      // 'product':               'product',
      'solicitor':             'solicitor',
      'portfolio':             'portfolio',
      // [`applications/${application_id}/document-upload`]: 'documentUpload',
    }
  }))

  const applicantPaths = {
    'assets':                'assets',
    'commitments':           'commitments',
    'employment':            'employment',
    'general-questions':     'generalQuestions',
    'identity-verification': 'identityVerification',
    'other-income':          'otherIncome',
    'pension':               'pension',
    'personal-details':      'personalDetails',
    'open-banking':          'openBanking',
    'document-upload':       'documentUpload',
  }

  const navigateTo = route => () => history.push(route)

  useEffect(() => {
    applicationContext.applicationState.applicants.forEach(applicant => {
      Object.keys(applicantPaths).forEach(applicantPath => {
        pathsState.paths[`applicants/${applicant.id}/${applicantPath}`] = `${applicantPaths[applicantPath]}${applicant.id}`
      })
    })
    updateSelectedPath()
  }, [applicationContext.applicationState.applicants.length])

  useEffect(() => updateSelectedPath(), [location.pathname])

  const updateSelectedPath = () => {
    const currentPaths = Object.keys(pathsState.paths).filter(path => location.pathname.includes(path))
    const currentPath = currentPaths.length === 1 ? currentPaths[0] : currentPaths[1]
    setSelected(pathsState.paths[currentPath])
  }

  useEffect(() => {
    getCurrentBorrower()
    getApplication()
  }, [])

  const getCurrentBorrower = () => {
    axiosService.get('/api/borrower/profile/current')
    .then(borrower => currentBorrowerContext.setCurrentBorrower(borrower))
    .catch(() => {})
  }

  const goBackToMainMenu = () => history.push('/borrower/applications')

  const getApplication = () => {
    applicationContext.applicationState.loading = true
    axiosService.get(`/api/borrower/applications/${application_id}`)
    .then(application => {
      applicationContext.applicationState.application = new Application(application)
      applicationContext.applicationState.applicants = application.applicants.map(applicant => new Applicant(applicant))
      applicationContext.applicationState.properties = application.properties.map(property => new Property(property))
      applicationContext.applicationState.security = new Security(application.security || {})
      applicationContext.applicationState.mortgage = new Mortgage(application.mortgage || {})
      applicationContext.applicationState.solicitor = new Solicitor(application.solicitor || {})
      applicationContext.applicationState.directDebit = new DirectDebit(application.direct_debit || {})
      setTimeout(() => applicationContext.applicationState.loading = false, 1000)
    })
    .catch(({ data: { error }}) => {
      toastError(error)
      history.push('/borrower/applications')
    })
  }

  return (
    <aside className="data-capture-sidebar">
      <div className="bg-black-o-8 px-6">
        <a className="flex font-semibold items-center justify-between px-4 py-20 text-white" href="">Application Process</a>
      </div>

      <div className="border-b border-white-o-15 px-6 py-4">
        <button
          className={`data-capture-sidebar-link ${selected === 'applicants' ? 'active' : ''}`}
          onClick={navigateTo(`/borrower/applications/${application_id}/applicants`)}
        >
          <img
            alt="Applicants"
            className="mr-4"
            src={iconApplicantsWhite}
          />
          Applicants
        </button>
      </div>

      {
        applicationContext.applicationState.applicants.map((applicant, index) => (
          <SidebarDropdown key={applicant.id} applicant={applicant} index={index} navigateTo={navigateTo} selected={selected} setSelected={setSelected} open={location.pathname.includes(`/applicants/${applicant.id}`)} />
        ))
      }

      <div className="border-b border-white-o-15 px-6 py-4">
        <button
          className={`data-capture-sidebar-link ${selected === 'loanRequirements' ? 'active' : ''}`}
          onClick={navigateTo(`/borrower/applications/${application_id}/loan-requirements/step1`)}
        >
          <img
            alt="Loan Requirements"
            className="mr-4"
            src={iconLoanRequirementsWhite}
          />
          Loan Requirements
          <SidebarStatusIcon errors={applicationContext.applicationState.application.purchase_type === 'Purchase' ? [
              { errors: loanRequirementsStep1Errors, visited: applicationContext.applicationState.application.steps.loan_requirements_step1_visited },
            ] : [
              { errors: loanRequirementsStep1Errors, visited: applicationContext.applicationState.application.steps.loan_requirements_step1_visited },
              { errors: loanRequirementsStep2Errors, visited: applicationContext.applicationState.application.steps.loan_requirements_step2_visited }
            ]}
          />
        </button>

        <button
          className={`data-capture-sidebar-link ${selected === 'mainPropertyDetails' ? 'active' : ''}`}
          onClick={navigateTo(`/borrower/applications/${application_id}/main-property-details`)}
        >
          <img
            alt="Main Property Details"
            className="mr-4"
            src={iconMainPropertyDetailsWhite}
          />
          Main Property Details
          <SidebarStatusIcon errors={[{ errors: mainPropertyDetailsErrors, visited: applicationContext.applicationState.application.steps.main_property_details_visited }]} />
        </button>

        <button
          className={`data-capture-sidebar-link ${selected === 'portfolio' ? 'active' : ''}`}
          onClick={navigateTo(`/borrower/applications/${application_id}/portfolio`)}
        >
          <img
            alt="portfolio"
            className="mr-4"
            src={iconPortfolioWhite}
          />
          Property Portfolio
          <SidebarStatusIcon errors={[{ errors: portfolioErrors, visited:  applicationContext.applicationState.application.steps.portfolio_visited}]} />
        </button>

        <button
          className={`data-capture-sidebar-link ${selected === 'directDebit' ? 'active' : ''}`}
          onClick={navigateTo(`/borrower/applications/${application_id}/direct-debit`)}
        >
          <img
            alt="Bank Details"
            className="mr-4"
            src={iconDirectDebitWhite}
          />
          Bank Details
          <SidebarStatusIcon errors={[{ errors: directDebitErrors, visited: applicationContext.applicationState.application.steps.direct_debit_visited }]} />
        </button>

        <button
          className={`data-capture-sidebar-link ${selected === 'solicitor' ? 'active' : ''}`}
          onClick={navigateTo(`/borrower/applications/${application_id}/solicitor`)}
        >
          <img
            alt="Solicitor"
            className="mr-4"
            src={iconSolicitorWhite}
          />
          Solicitor
          <SidebarStatusIcon errors={[{ errors: solicitorErrors, visited: applicationContext.applicationState.application.steps.solicitor_visited }]} />
        </button>

        {/* <button
          className={`data-capture-sidebar-link ${selected === 'documentUpload' ? 'active' : ''}`}
          onClick={navigateTo(`/borrower/applications/${application_id}/document-upload`)}
        >
          <img
            alt="Document Upload"
            className="mr-4"
            src={iconUploadGray}
          />
          Document Upload
          <SidebarStatusIcon errors={[{ errors: documentUploadErrors, visited: applicationContext.applicationState.application.steps.document_upload_visited }]} />
        </button> */}
      </div>

      <div className="px-40 py-4">
        <a className="btn btn--primary text-center w-full" href={`/borrower/applications/${application_id}/summary`} target="_blank" rel="noopener noreferrer">Summary</a>
      </div>

      { applicationContext.applicationState.application.state === 'draft' && <ApplyForMortgageButton /> }

      <div className="px-40 py-8">
        <button className="btn btn--primary text-center w-full" onClick={goBackToMainMenu}>Back to Main Menu</button>
      </div>
    </aside>
  )
})
