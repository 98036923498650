import { observer, useLocalObservable } from "mobx-react-lite";
import React, { useContext, useEffect } from 'react'
import axiosService from '../../../utils/AxiosService'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { CurrentBrokerContext } from '../../../contexts/CurrentBrokerContext'
import ReactSelect from '../../Form/ReactSelect'

export default observer(function Applications() {
  let history = useHistory()
  const currentBrokerContext = useContext(CurrentBrokerContext)
  const applicationsState = useLocalObservable(() => (
    {
      applications: [],
      apprivoBrokers: [],
      currentPage: 1,
      pages: 1,
      selectedBroker: {},
    }
  ))

  const getApplications = () => {
    axiosService.get('/api/apprivo_broker/applications', {
      params: {
        current_page: applicationsState.currentPage,
        apprivo_broker_id: applicationsState.selectedBroker.value,
      }
    })
    .then(({ applications, pages }) => {
      applicationsState.applications = applications
      applicationsState.pages = pages
    })
  }

  const getApprivoBrokers = () => {
    axiosService.get('/api/apprivo_broker/apprivo_brokers').then(({ apprivo_brokers }) => {
      applicationsState.apprivoBrokers = apprivo_brokers
    })
  }

  useEffect(() => {
    getApplications()
    getApprivoBrokers()
    document.documentElement.classList.add('bg-alabaster')
    return () => document.documentElement.classList.remove('bg-alabaster')
  }, [])

  const openApplication = application_id => () => history.push(`/broker/applications/${application_id}/applicants`)

  const getState = state => {
    return {
      draft: 'In progress',
      for_review: 'For Review',
      submitted: 'Submitted',
      failed: 'Incomplete',
      valuation_requested: 'Valuation Requested',
    }[state]
  }

  const getStateClass = state => {
    return {
      draft: 'text-bright-sun',
      for_review: '',
      submitted: 'text-turquoise',
      failed: 'text-punch',
      valuation_requested: '',
    }[state]
  }

  const getLastUploadedDocumentDate = application => {
    let lastUpdatedTime = moment('1970-01-01')
    application.applicants.forEach(({ documents }) => {
      documents.forEach(document => {
        if(lastUpdatedTime < moment(document.updated_at)) lastUpdatedTime = moment(document.updated_at)
      })
    })
    return lastUpdatedTime.format('DD/MM/YYYY') === '01/01/1970' ? 'No document uploaded' : lastUpdatedTime.format('DD/MM/YYYY hh:mm:ss A')
  }

  const setPage = page => () => {
    applicationsState.currentPage = page
    getApplications()
    window.scrollTo(0, 0)
  }

  const filterApplications = value => {
    applicationsState.selectedBroker = value
    getApplications()
  }

  const filterApplicationsOptions = [
    { label: 'Show all applications', value: null },
    ...applicationsState.apprivoBrokers.map( apprivoBroker => ({label: apprivoBroker.name, value: apprivoBroker.apprivo_broker_id}) )
  ]

  return (
    <div>
      <h2 className="form-title mb-40">Applications</h2>

      {currentBrokerContext?.currentBroker?.brokerage_admin && (
        <div className="flex items-center justify-end mb-4">
          <p className="mr-2">Filter by broker:</p>

          <div style={{ minWidth: 250 }}>
            <ReactSelect
              label=""
              onChange={filterApplications}
              options={filterApplicationsOptions}
              value={applicationsState.selectedBroker.value}
            />
          </div>
        </div>
      )}

      <div className="table-outer-container table-outer-container--application-dashboard">
        <div className="table-container table-container--application-dashboard">
          <table className="table fs-13">
            <thead>
              <tr>
                <th className="w-1/7 text-center">View</th>
                <th className="w-1/7 text-center">Application Number</th>
                <th className="w-1/7 text-center">Borrower Name</th>
                <th className="w-1/7 text-center">Borrower Mobile</th>
                <th className="w-1/7 text-center">Borrower Email</th>
                <th className="w-1/7 text-center">Status</th>
                <th className="w-1/7 text-center">Date Created</th>
                <th className="w-1/7 text-center">Document Uploaded</th>
                <th className="w-1/7 text-center">Last Updated</th>
              </tr>
              <tr>
                <td colSpan="8" style={{ height: 24 }} />
              </tr>
            </thead>
            <tbody className="table--border">
              {applicationsState.applications.map(application => (
                <tr key={application.id}>
                  <td className="w-1/7">
                    <button className="btn btn--primary whitespace-nowrap" onClick={openApplication(application.id)}>Open application</button>
                  </td>
                  <td className="font-bold w-1/7">{application.application_number}</td>
                  <td className="w-1/7">{`${application.applicants.length && application.applicants?.[0]?.first_name || ''} ${application.applicants.length && application.applicants?.[0]?.middle_name || ''} ${application.applicants.length && application.applicants?.[0]?.last_name || ''}`}</td>
                  <td className="w-1/7">{application?.borrower?.phone}</td>
                  <td className="w-1/7">{application?.borrower?.email}</td>
                  <td className="w-1/7"><p className={`text-center ${getStateClass(application.state)}`}>{getState(application.state)}</p></td>
                  <td className="w-1/7">{moment(application.created_at).format('DD/MM/YYYY')}</td>
                  <td className="w-1/7">{getLastUploadedDocumentDate(application)}</td>
                  <td className="w-1/7">{moment(application.updated_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      { applicationsState.pages > 1 &&
        <div className="flex justify-center mt-6">
          <button className="mx-4" disabled={applicationsState.currentPage === 1} onClick={setPage(applicationsState.currentPage - 1)}><i className="fa fa-caret-left" /></button>

          {[...Array(+applicationsState.pages)].map((_, amount) => (
            <button key={amount} className={`mx-4 ${amount + 1 === applicationsState.currentPage && 'font-bold text-turquoise'}`} onClick={setPage(amount + 1)}>{amount + 1}</button>
          ))}
          <button className="mx-4" disabled={applicationsState.currentPage === applicationsState.pages} onClick={setPage(applicationsState.currentPage + 1)}><i className="fa fa-caret-right" /></button>
        </div>
      }
    </div>
  )
})
