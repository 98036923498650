import * as Yup from 'yup'

const notEmptyString = value => value === '' ? Yup.string() : Yup.number('This field must contain numbers.').typeError('It must be a number').min(0, 'This field must be greater than or equal 0.')

export const assetsSchema = Yup.object().shape({
  cash_deposits: Yup.lazy(notEmptyString),
  cash_deposits_jointly_owned: Yup.boolean(),
  stocks_shares: Yup.lazy(notEmptyString),
  stock_share_jointly_owned: Yup.boolean(),
  assets_amount: Yup.number('This field must contain numbers.').integer().max(10).min(0, 'This field must be greater than or equal 0.'),
  other_assets1: Yup.lazy(notEmptyString),
  asset_type1: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_assets2: Yup.lazy(notEmptyString),
  asset_type2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_assets3: Yup.lazy(notEmptyString),
  asset_type3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_assets4: Yup.lazy(notEmptyString),
  asset_type4: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_assets5: Yup.lazy(notEmptyString),
  asset_type5: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_assets6: Yup.lazy(notEmptyString),
  asset_type6: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_assets7: Yup.lazy(notEmptyString),
  asset_type7: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_assets8: Yup.lazy(notEmptyString),
  asset_type8: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_assets9: Yup.lazy(notEmptyString),
  asset_type9: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  other_assets10: Yup.lazy(notEmptyString),
  asset_type10: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
});