import * as Yup from 'yup'

export const EmploymentStep3EmployedSchema = Yup.object().shape({
  basic_salary: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  guaranteed_overtime: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  guaranteed_bonus: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  regular_overtime: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  commission: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  car_allowance: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  allowance: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  dividends: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  other_income: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  others_name: Yup.string().max(35, 'This field must be maximum 35 characters long.')
});