import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import Input from '../../Form/Input'
import ReactSelect from '../../Form/ReactSelect'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import { Formik, FieldArray } from 'formik'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import CommitmentsActions from './CommitmentsActions'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { CommitmentsStep2Schema } from '../../../schemas/CommitmentsSchema/CommitmentsStep2Schema'
import axiosService from '../../../utils/AxiosService'
import Applicant from '../../../models/Applicant'
import CreditCardCommitment from '../../../models/CreditCardCommitment'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'

export default observer(function CommitmentsStep2() {
  const { applicationState: { applicants, loading, backend_url_part, type, disabled }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicant_id, applicants])

  const fields = ["outstanding_balance","credit_card_repayment_type","loan_paid_on_or_before","monthly_payment","lender_name","credit_account_number","credit_limit","payments_missed","payments_missed_details"]

  const save = credit_card_commitments => e => {
    e?.preventDefault()
    touchFields(credit_card_commitments)

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { credit_card_commitments } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.credit_card_commitments = credit_card_commitments
    })
  }

  const touchFields = credit_card_commitments => {
    formikRef.current.setTouched(credit_card_commitments.reduce((result, _, index) => {
      result.credit_card_commitments[index] ||= {}
      fields.forEach(field => result.credit_card_commitments[index][field] = true)
      return result
    },{ credit_card_commitments: [] }))
  }

  useEffect(() => {
    if(currentApplicant.steps.commitments_step2_visited) touchFields(currentApplicant.credit_card_commitments)
    applicationErrorsContext.validateCommitmentsStep2(currentApplicant.credit_card_commitments, applicant_id)
  }, [loading, currentApplicant.credit_card_commitments])

  const creditCardOptions = [
    { label: 'Repay in Full each Month', value: '3' },
    { label: 'Monthly Payment (Greater than minimum Payment)', value: '2' },
    { label: 'Monthly Payment (At least minimum Payment)', value: '1' },
  ]

  return (
    <Fragment>
      { !disabled && <ApplicantStepUpdater step="commitments_step2_visited" /> }

      <p className="mb-40">Please add details of any credit cards you are named on. Please do not include credit cards you are named on as an authorised user only.</p>

      <Formik
        initialValues={{ credit_card_commitments: currentApplicant.credit_card_commitments }}
        validationSchema={CommitmentsStep2Schema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values.credit_card_commitments)}>
            <FieldArray name="credit_card_commitments">
              {({ insert, remove, push }) => (
                <div>
                  <div className="flex flex-wrap -mx-12">
                    <div className="px-12 w-1/2">
                      <div className="btn-group-container flex items-center justify-between mb-40">
                        <button
                          className="btn btn--group btn--primary w-2/3"
                          onClick={() => push(new CreditCardCommitment())}
                          type="button"
                          disabled={disabled}
                        >
                          Add Card
                          <i className="far fa-plus ml-10" />
                        </button>

                        {values.credit_card_commitments.length > 0 && <span className="pr-8">{values.credit_card_commitments.length}</span>}
                      </div>
                    </div>
                  </div>

                  { values.credit_card_commitments.map((credit_card_commitment, index) => (
                      <div key={index}>
                        <div className="flex items-center mb-40">
                          <p className="mr-6">
                            <i className="fa fa-chevron-down mr-2" />
                            Credit Card {index + 1} Details
                          </p>

                          <div className="bg-timber-green-o-16 flex-auto mr-6" style={{ height: 2 }} />

                          <button
                            className="bg-punch circle fs-9 text-white"
                            onClick={() => remove(index)}
                            style={{ height: 20, width: 20 }}
                            type="button"
                            disabled={disabled}
                          >
                            <i className="fa fa-trash-alt" />
                          </button>
                        </div>

                        <div className="flex flex-wrap -mx-12">
                          <div className="px-12 w-1/2">
                            <div className="mb-40">
                              <CurrencyField
                                addon="€"
                                addonPosition="start"
                                error={errors?.credit_card_commitments?.[index]?.outstanding_balance && touched?.credit_card_commitments?.[index]?.outstanding_balance && errors?.credit_card_commitments?.[index]?.outstanding_balance}
                                label="Outstanding balance"
                                name={`credit_card_commitments.${index}.outstanding_balance`}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                value={credit_card_commitment.outstanding_balance}
                                disabled={disabled}
                              />
                            </div>

                            <div className="mb-40">
                              <ReactSelect
                                error={errors?.credit_card_commitments?.[index]?.credit_card_repayment_type && touched?.credit_card_commitments?.[index]?.credit_card_repayment_type && errors?.credit_card_commitments?.[index]?.credit_card_repayment_type}
                                label="How do you pay your credit card?"
                                name={`credit_card_commitments.${index}.credit_card_repayment_type`}
                                options={creditCardOptions}
                                onChange={value => setFieldValue(`credit_card_commitments.${index}.credit_card_repayment_type`, (value?.value || ''), true)}
                                onBlur={handleBlur}
                                value={credit_card_commitment.credit_card_repayment_type}
                                disabled={disabled}
                              />
                            </div>

                            <div className="mb-40">
                              <YesNoBtnGroup
                                error={errors?.credit_card_commitments?.[index]?.loan_paid_on_or_before && touched?.credit_card_commitments?.[index]?.loan_paid_on_or_before && errors?.credit_card_commitments?.[index]?.loan_paid_on_or_before}
                                label="To be repaid prior to drawdown?"
                                name={`credit_card_commitments.${index}.loan_paid_on_or_before`}
                                onChange={(name, value) => setFieldValue(name, value, true)}
                                value={credit_card_commitment.loan_paid_on_or_before}
                                disabled={disabled}
                                tooltip="Will the balance be repaid on or before commencement of this new mortgage?"
                              />
                            </div>

                            <div className="mb-40">
                              <CurrencyField
                                addon="€"
                                addonPosition="start"
                                error={errors?.credit_card_commitments?.[index]?.monthly_payment && touched?.credit_card_commitments?.[index]?.monthly_payment && errors?.credit_card_commitments?.[index]?.monthly_payment}
                                label="Monthly payment"
                                name={`credit_card_commitments.${index}.monthly_payment`}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                value={credit_card_commitment.monthly_payment}
                                disabled={disabled}
                              />
                            </div>
                          </div>

                          <div className="px-12 w-1/2">
                            <div className="mb-40">
                              <Input
                                error={errors?.credit_card_commitments?.[index]?.lender_name && touched?.credit_card_commitments?.[index]?.lender_name && errors?.credit_card_commitments?.[index]?.lender_name}
                                label="Card issuer"
                                name={`credit_card_commitments.${index}.lender_name`}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={credit_card_commitment.lender_name}
                                disabled={disabled}
                              />
                            </div>

                            <div className="mb-40">
                              <Input
                                error={errors?.credit_card_commitments?.[index]?.credit_account_number && touched?.credit_card_commitments?.[index]?.credit_account_number && errors?.credit_card_commitments?.[index]?.credit_account_number}
                                label="Last 4 digits of card number"
                                name={`credit_card_commitments.${index}.credit_account_number`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={credit_card_commitment.credit_account_number}
                                disabled={disabled}
                              />
                            </div>

                            <div className="mb-40">
                              <CurrencyField
                                addon="€"
                                addonPosition="start"
                                error={errors?.credit_card_commitments?.[index]?.credit_limit && touched?.credit_card_commitments?.[index]?.credit_limit && errors?.credit_card_commitments?.[index]?.credit_limit}
                                label="Credit Limit"
                                name={`credit_card_commitments.${index}.credit_limit`}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                value={credit_card_commitment.credit_limit}
                                disabled={disabled}
                              />
                            </div>

                            <div className="mb-40">
                              <YesNoBtnGroup
                                error={errors?.credit_card_commitments?.[index]?.payments_missed && touched?.credit_card_commitments?.[index]?.payments_missed && errors?.credit_card_commitments?.[index]?.payments_missed}
                                label="Missed payment in past 2 years?"
                                name={`credit_card_commitments.${index}.payments_missed`}
                                onChange={(name, value) => {
                                  setFieldValue(name, value, true)
                                  setFieldValue('[0].payments_missed_details', '', true)
                                }}
                                value={credit_card_commitment.payments_missed}
                                disabled={disabled}
                              />
                            </div>

                            {credit_card_commitment.payments_missed && (
                              <div className="mb-40">
                                <Input
                                  error={errors?.credit_card_commitments?.[index]?.payments_missed_details && touched?.credit_card_commitments?.[index]?.payments_missed_details && errors?.credit_card_commitments?.[index]?.payments_missed_details}
                                  label="Please provide full details"
                                  name={`credit_card_commitments.${index}.payments_missed_details`}
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={credit_card_commitment.payments_missed_details}
                                  disabled={disabled}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
              )}
            </FieldArray>

            <CommitmentsActions save={save(values.credit_card_commitments)} />
          </form>
        )}
      </Formik>
    </Fragment>
  )
})