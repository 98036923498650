import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import NextStepButton from '../../Form/NextStepButton'
import CaptureStep1 from './CaptureStep1'
import CaptureStep2 from './CaptureStep2'
import CaptureStep3 from './CaptureStep3'

export default function Capture() {
  const { application_id, id_verification_token } = useParams()

  const save = () => {
  }

  const url = `/borrower/applications/${application_id}/applicants/${id_verification_token}/identity-verification-mobile/capture`

  const currentStep = +/step(\d+)/.exec(location.pathname)?.[1]

  const setText = {
    1: 'Capture ID Front',
    2: 'Capture ID Back',
    3: 'Capture Selfie'
  }[currentStep]

  return (
    <>
      <Switch>
        <Route path='/borrower/applications/:application_id/applicants/:id_verification_token/identity-verification-mobile/capture/step1'>
          <CaptureStep1 url={url} />
        </Route>
        <Route path='/borrower/applications/:application_id/applicants/:id_verification_token/identity-verification-mobile/capture/step2'>
          <CaptureStep2 url={url} />
        </Route>
        <Route path='/borrower/applications/:application_id/applicants/:id_verification_token/identity-verification-mobile/capture/step3'>
          <CaptureStep3 url={url} />
        </Route>
      </Switch>
    </>
  )
}
