import React from 'react'
import Label from './Label'
import ErrorMessage from './ErrorMessage'

export default function Textarea({
  error,
  label,
  name,
  onBlur,
  onChange,
  optional,
  tooltip,
  value,
  className,
}) {
  return (
    <>
      <Label label={label} tooltip={tooltip} />

      <div className="relative">
        <textarea
          className={`textarea ${className} ${error && 'error'}`}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />

        {optional && <p className="optional-text">Optional</p>}
      </div>

      <ErrorMessage text={error} />
    </>
  )
}
