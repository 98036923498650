import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import TitleRow from '../TitleRow'

export default observer(function DirectDebitPart() {
  const { applicationState: { directDebit }} = useContext(ApplicationContext)

  return (
    <Fragment>
      <TitleRow label="Bank Details" />
      <tr>
        <td className="w-1/4">Name(s) of account holder(s)</td>
        <td className="text-center" colSpan="2">{directDebit.name_of_account_holder}</td>
      </tr>
      <tr>
        <td className="w-1/4">IBAN</td>
        <td className="text-center" colSpan="2">{directDebit.account_number}</td>
      </tr>
      <tr>
        <td className="w-1/4">BIC</td>
        <td className="text-center" colSpan="2">{directDebit.sort_code}</td>
      </tr>
      <tr>
        <td className="w-1/4">Institution</td>
        <td className="text-center" colSpan="2">{directDebit.institution}</td>
      </tr>
      <tr>
        <td className="w-1/4">Branch</td>
        <td className="text-center" colSpan="2">{directDebit.branch}</td>
      </tr>
      <tr>
        <td className="w-1/4">Branch Eircode</td>
        <td className="text-center" colSpan="2">{directDebit.contact_postcode}</td>
      </tr>
      <tr>
        <td className="w-1/4">Preferred payment day</td>
        <td className="text-center" colSpan="2">{directDebit.payment_day}</td>
      </tr>
      <tr>
        <td className="w-1/4">Direct Debit confirmation</td>
        <td className="text-center" colSpan="2">{directDebit.is_confirm_declaration ? 'Yes' : 'No'}</td>
      </tr>
    </Fragment>
  )
})