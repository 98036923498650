import React, { useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Input from '../../Form/Input'
import ReactSelect from '../../Form/ReactSelect'
import Divider from '../../Divider'
import DatePicker from '../../Form/DatePicker'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import axiosService from '../../../utils/AxiosService'
import { observer } from 'mobx-react-lite'
import { Formik } from 'formik'
import ApplicationStepUpdater from '../../Form/ApplicationStepUpdater'
import LoanRequirementsActions from './LoanRequirementsActions'
import Mortgage from '../../../models/Mortgage'
import { loanRequirementsStep2Schema } from '../../../schemas/LoanRequirementsSchemas/LoanRequirementsStep2Schema'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import CurrencyField from '../../Form/CurrencyField'
import { notifyProgressSaved } from '../../Form/Notifications'

export default observer(function LoanRequirementsStep2() {
  const remortgagePurposeOptions = [
    { label: 'Education', value: '9' },
    // { label: 'Equity of Release', value: '8' },
    { label: 'Debt Consolidation', value: '2' },
    { label: 'Home improvements', value: '3' },
    { label: 'Gift to Family', value: '11' },
    { label: 'Medical', value: '10' },
    { label: 'Purchase of Investment Property', value: '7' },
    { label: 'No extra money raised', value: '5' },
    { label: 'Other', value: '6' },
  ]

  const { applicationState } = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id } = useParams()
  const formikRef = useRef()

  const save = mortgage => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${applicationState.backend_url_part}/applications/${application_id}`, { application: { mortgage_attributes: mortgage }})
    .then(() => {
      notifyProgressSaved()
      applicationState.mortgage = new Mortgage(mortgage)
    })
  }

  const touchFields = () => formikRef.current.setTouched(Object.keys(applicationState.mortgage).reduce((result, key) => { result[key] = true; return result },{}))

  useEffect(() => {
    if(applicationState.application.steps.loan_requirements_step2_visited) touchFields()
    applicationErrorsContext.validateLoanRequirementsStep2()
  }, [applicationState.loading, applicationState.mortgage])

  return (
    <div>
      <h2 className="form-title mb-40">Loan Requirements</h2>
      {!applicationState.disabled && <ApplicationStepUpdater step="loan_requirements_step2_visited" />}

      <Divider text="Existing Mortgage Details" />

      <Formik
        initialValues={{
          ...applicationState.mortgage,
          purchase_type: applicationState.application.purchase_type
        }}
        validationSchema={loanRequirementsStep2Schema}
        enableReinitialize={applicationState.loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>
            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <Input
                    autoFocus={true}
                    error={errors.existing_mortgage_lender && touched.existing_mortgage_lender && errors.existing_mortgage_lender}
                    label="Existing Mortgage Lender"
                    name="existing_mortgage_lender"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.existing_mortgage_lender}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <DatePicker
                    error={errors.purchase_date && touched.purchase_date && errors.purchase_date}
                    label="Purchase Date"
                    name="purchase_date"
                    onChange={value => setFieldValue('purchase_date', value, true)}
                    value={values.purchase_date}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.purchase_price && touched.purchase_price && errors.purchase_price}
                    label="Original Purchase Price"
                    name="purchase_price"
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    value={values.purchase_price}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.estimated_value && touched.estimated_value && errors.estimated_value}
                    label="Estimated Value of Property"
                    name="estimated_value"
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    value={values.estimated_value}
                    disabled={applicationState.disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.original_amount_borrowed && touched.original_amount_borrowed && errors.original_amount_borrowed}
                    label="Amount Originally Borrowed"
                    name="original_amount_borrowed"
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    tooltip="How much did you originally borrow to purchase the property?"
                    value={values.original_amount_borrowed}
                    disabled={applicationState.disabled}
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={errors.outstanding_balance && touched.outstanding_balance && errors.outstanding_balance}
                    label="Outstanding Balance"
                    name="outstanding_balance"
                    onBlur={handleBlur}
                    additionalAction={(value) => {
                      if(+value === +values.loan_required) {
                        setFieldValue('purpose_of_mortgage', '5', true)
                      } else {
                        setFieldValue('purpose_of_mortgage', null, true)
                      }
                    }}
                    setFieldValue={setFieldValue}
                    value={values.outstanding_balance}
                    disabled={applicationState.disabled}
                  />
                </div>

                {+values.outstanding_balance !== +values.loan_required &&
                  <div className="mb-40">
                    <ReactSelect
                      error={errors.purpose_of_mortgage && touched.purpose_of_mortgage && errors.purpose_of_mortgage}
                      label="Purpose of additional funds"
                      name="purpose_of_mortgage"
                      onChange={value => setFieldValue('purpose_of_mortgage', (value?.value || ''), true)}
                      options={remortgagePurposeOptions}
                      tooltip="Additional funds cannot be used for speculative investment or for commercial use"
                      value={values.purpose_of_mortgage}
                      disabled={applicationState.disabled}
                    />
                  </div>
                }
              </div>
            </div>

            <LoanRequirementsActions save={save(values)} purchase_type={applicationState.application.purchase_type} />
          </form>
        )}
      </Formik>
    </div>
  )
})
