const generalHints = {
  MITEK_ERROR_FOUR_CORNER: 'We can\'t find the 4 corners of your document.',
  MITEK_ERROR_TOO_DARK: 'There is not enough light on your document.',
  MITEK_ERROR_FOCUS: 'The image is too blurry.',
  MITEK_ERROR_GLARE: 'The image has glare.',
  MITEK_ERROR_MIN_PADDING: 'Move the camera further away from your document.',
  MITEK_ERROR_HORIZONTAL_FILL: 'Move the camera closer to your document.',
  MITEK_ERROR_SKEW_ANGLE: 'Document is skewed.  Hold camera directly over your document.',
  MITEK_ERROR_LOW_CONTRAST: 'Center document on a dark background.',
  MITEK_ERROR_BUSY_BACKGROUND: 'The background is too busy.  Please use a solid background.',
  MITEK_ERROR_MRZ_MISSING: 'No MRZ found',
  CV_NO_BARCODE_FOUND: 'We were unable to detect the barcode from the back of your license.',
  IMAGE_SMALLER_THAN_MIN_SIZE: 'The image you provided is too small.',
  CORRUPT_IMAGE: 'The image you provided is unreadable.',
  MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
  MISNAP_HEAD_TOO_CLOSE: 'Move Farther Away',
  MISNAP_HEAD_TOO_FAR: 'Get Closer',
  NO_FACE_FOUND: 'No Face Detected',
}

module.exports = generalHints
