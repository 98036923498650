import * as Yup from 'yup'

const purchaseTypeIsRemortgageItMustBeAPositiveNumber = () => {
  return [
    'purchase_type',
    {
      is: "Remortgage",
      then: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.')
    }
  ]
}

export const loanRequirementsStep2Schema = Yup.object().shape({
  existing_mortgage_lender: Yup.mixed().when(
    'purchase_type',
    {
      is: "Remortgage",
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
  purchase_date: Yup.mixed().when(
    'purchase_type',
    {
      is: "Remortgage",
      then: Yup.date().required('This field is required.')
    }
  ),
  purchase_price: Yup.mixed().when(...purchaseTypeIsRemortgageItMustBeAPositiveNumber()),
  estimated_value: Yup.mixed().when(...purchaseTypeIsRemortgageItMustBeAPositiveNumber()),
  original_amount_borrowed: Yup.mixed().when(...purchaseTypeIsRemortgageItMustBeAPositiveNumber()),
  outstanding_balance: Yup.mixed().when(...purchaseTypeIsRemortgageItMustBeAPositiveNumber()),
  purpose_of_mortgage: Yup.mixed().when(
    ['purchase_type', 'outstanding_balance', 'loan_required'],
    {
      is: (purchase_type, outstanding_balance, loan_required) => +outstanding_balance !== +loan_required && purchase_type === 'Remortgage',
      then: Yup.string().nullable().required('This field is required.')
    }
  ),
});