import * as Yup from 'yup'

export const EmploymentStep1EmployedSchema = Yup.object().shape({
  are_you: Yup.string().required('This field is required.'),
  job_title: Yup.string().when(
    'are_you',
    {
      is: 'Employed',
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
  employer_name: Yup.string().when(
    'are_you',
    {
      is: 'Employed',
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
  employer_telephone: Yup.string().when(
    'are_you',
    {
      is: 'Employed',
      then: Yup.string().required('This field is required.')
          .min(8, 'The phone number has to be at least 8 characters long.')
          .max(15, 'The phone number cannot contain more than 15 digits.'),
    }
  ),
  business_sector: Yup.mixed().nullable().when(
    'are_you',
    {
      is: 'Employed',
      then: Yup.string().nullable().required('This field is required.')
    }
  ),
  nature_of_business: Yup.mixed().nullable().when(
    'are_you',
    {
      is: 'Employed',
      then: Yup.string().nullable().required('This field is required.')
    }
  ),
  employment_type: Yup.string().nullable().when(
    'are_you',
    {
      is: 'Employed',
      then: Yup.string().nullable().required('This field is required.')
    }
  ),
  date_joined: Yup.string().when(
    ['are_you', 'employment_type'],
    {
      is: (are_you, employment_type) => are_you === 'Employed' && employment_type === '2',
      then: Yup.string().required('This field is required.')
    }
  ),
  date_contract_started: Yup.string().when(
    ['are_you', 'employment_type'],
    {
      is: (are_you, employment_type) => are_you === 'Employed' && employment_type !== '2',
      then: Yup.string().required('This field is required.')
    }
  ),
  date_contract_ends: Yup.string(),
  on_probation: Yup.boolean().when(
    'are_you',
    {
      is: 'Employed',
      then: Yup.boolean().required('This field is required.')
    }
  ),
  probation_end_date: Yup.string().when(
    ['are_you', 'on_probation'],
    {
      is: (are_you, on_probation) => are_you === 'Employed' && on_probation,
      then: Yup.string().required('This field is required.')
    }
  ),
  redundancy_notice: Yup.boolean().when(
    'are_you',
    {
      is: 'Employed',
      then: Yup.boolean().required('This field is required.')
    }
  ),
  redundancy_remaining_details: Yup.string().when(
    ['are_you', 'redundancy_notice'],
    {
      is: (are_you, redundancy_notice) => are_you === 'Employed' && redundancy_notice,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ),
});
