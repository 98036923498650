import React from 'react'
import { MultiSelect } from 'react-multi-select-component'
import Label from './Label'
import ErrorMessage from './ErrorMessage'

export default function ReactMultiSelect({
  error,
  label,
  onChange = () => {},
  options,
  placeholder = 'Select',
  tooltip,
  value,
  disabled
}) {
  const convertArrayToString = (array) => array.map(e => e.value).join(',')
  const convertStringToArray = (string) => {
    const arr = string.split(',')
    return arr.map(i => ({ label: options.find(option => option.value === i).label, value: i }))
  }

  return (
    <>
      <Label label={label} tooltip={tooltip} />

      <div className={error ? 'error' : ''}>
        <MultiSelect
          labelledBy={placeholder}
          onChange={(value) => onChange(convertArrayToString(value))}
          options={options}
          value={value && value.length > 0 ? convertStringToArray(value) : []}
          disabled={disabled}
        />
      </div>

      <ErrorMessage text={error} />
    </>
  );
}
