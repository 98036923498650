import React from 'react'
import { Route, Switch } from 'react-router-dom'
import BorrowerApplications from './BorrowerApplications'
import BorrowerManageAccount from './BorrowerManageAccount'
import BorrowerSidebar from './BorrowerSidebar'

export default function BorrowerDashboard() {
  return (
  <section>
    <div className="container container--document py-16">
      <BorrowerSidebar />
      <Switch>
        <Route exact path="/borrower/applications">
          <BorrowerApplications />
        </Route>
        <Route path="/borrower/manage-account">
          <BorrowerManageAccount />
        </Route>
      </Switch>
    </div>
  </section>
  )
}