import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import Applicant from '../../../models/Applicant'
import NextStepButton from '../../Form/NextStepButton'
import ProgressIndicator from '../ProgressIndicator'
import moment from 'moment'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'

export default observer(function EmploymentActions({ save }) {
  const { application_id, applicant_id } = useParams()
  const { applicationState: { applicants, disabled, type }} = useContext(ApplicationContext)
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const url = `/${type}/applications/${application_id}/applicants/${applicant_id}/employment`

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicant_id, applicants])

  const steps = useMemo(() => {
    if(currentApplicant.employment.are_you === 'Self Employed') return 4
    if(currentApplicant.employment.are_you !== 'Employed') return 1
    if(currentApplicant.employment.are_you === 'Employed') {
      const jobStartDate = currentApplicant.employment.employment_type === '2' ? currentApplicant.employment.date_joined : currentApplicant.employment.date_contract_started
      if(moment(jobStartDate) > moment().subtract(3, 'years')) return 4
      return 3
    }
  }, [currentApplicant.employment.are_you, currentApplicant.employment.employment_type, currentApplicant.employment.date_joined, currentApplicant.employment.date_contract_started])

  const employmentStepsErrors = useMemo(() => {
    return [
    { errors: applicantErrors[currentApplicant.id]?.employmentStep1Errors, visited: currentApplicant.steps.employment_step1_visited },
    { errors: applicantErrors[currentApplicant.id]?.employmentStep2Errors, visited: currentApplicant.steps.employment_step2_visited },
    { errors: applicantErrors[currentApplicant.id]?.employmentStep3Errors, visited: currentApplicant.steps.employment_step3_visited },
    { errors: applicantErrors[currentApplicant.id]?.employmentStep4Errors, visited: currentApplicant.steps.employment_step4_visited },
  ]}, [
    currentApplicant,
    applicantErrors[currentApplicant.id]?.employmentStep1Errors,
    applicantErrors[currentApplicant.id]?.employmentStep2Errors,
    applicantErrors[currentApplicant.id]?.employmentStep3Errors,
    applicantErrors[currentApplicant.id]?.employmentStep4Errors,
    currentApplicant.steps.employment_step1_visited,
    currentApplicant.steps.employment_step2_visited,
    currentApplicant.steps.employment_step3_visited,
    currentApplicant.steps.employment_step4_visited,
  ])

  return (
    <Fragment>
      { [3,4].includes(steps) && <ProgressIndicator url={url} steps={steps} save={disabled ? () => {} : save} errors={employmentStepsErrors} />}

      <div className="flex justify-between mt-16">
        {!disabled && <button className="btn btn--secondary" type="submit">Save Progress</button>}
        <NextStepButton steps={steps} url={url} nextUrl={`/${type}/applications/${application_id}/applicants/${applicant_id}/other-income/step1`} save={disabled ? () => {} : save}/>
      </div>
    </Fragment>
  )
})