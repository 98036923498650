import React from 'react'

import logo from 'images/logo.svg'
import iconSuccess from 'images/icons/open-banking-success.svg'
import iconError from 'images/icons/open-banking-error.svg'

export default function Step2() {
  let textsToDisplay

  if (window.location.href.match('success')) {
    textsToDisplay = {
      text: 'You have successfully shared transaction details from your selected accounts. You can now close this window and return to your application',
      icon: 'success',
      title: 'Successfully connected'
    }
  } else {
    textsToDisplay = {
      text: 'Sorry we could not connect to your bank. Please return to your application to retry or contact your broker if the problem persists.',
      icon: 'error',
      title: 'Something went wrong'
    }
  }

  const getIcon = () => {
    if (textsToDisplay.icon === 'success') return iconSuccess
    return iconError
  }

  const getColorClass = () => {
    if (textsToDisplay.icon === 'success') return 'text-turquoise'
    return 'text-punch'
  }

  return (
    <div className="bg-white flex fixed inset-0 items-center justify-center z-50">
      <div>
        <img alt="MoCo" className="mb-8 mx-auto" src={logo} />

        <img alt="" className="mb-8 mx-auto" src={getIcon()} />

        <p className={`mb-8 flex justify-center font-bold ${getColorClass()} text-2xl`}>{textsToDisplay.title}</p>

        <p className="mb-8 mx-auto text-center text-sm" style={{ maxWidth: 500 }}>{textsToDisplay.text}</p>

        <div className="flex justify-center mt-16">
          <button
            className="btn btn--primary"
            onClick={(e) => {
              e.preventDefault()
              window.close()
            }}
            type="button"
          >
            Back to Application
          </button>
        </div>
      </div>
    </div>
  )
}
