import * as Yup from 'yup'
import moment from 'moment'

const addressMovedDateIsLessThan3Years = addressMovedDate => {
  return [
    addressMovedDate,
    {
      is: date => date && moment(date) > moment().subtract(3, "year"),
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
    }
  ]
}

const addressMovedDateIsLessThan3YearsDate = addressMovedDate => {
  return [
    addressMovedDate,
    {
      is: date => date && moment(date) > moment().subtract(3, "year"),
      then: Yup.string().required('This field is required.'),
    }
  ]
}

export const PersonalDetailsStep4Schema = Yup.object().shape({
  address_post_code: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_line1: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  address_city: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_county: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.'),
  address_country: Yup.string().required('This field is required.'),
  address_moved_date: Yup.string().required('This field is required.'),
  residential_status: Yup.string().required('This field is required.'),
  monthly_rent_amount: Yup.number('This field must contain numbers.').min(0, 'This field must be greater than or equal 0.').required('This field is required.'),
  previous1_address_post_code: Yup.string().when(...addressMovedDateIsLessThan3Years("address_moved_date")),
  previous1_address_line1: Yup.string().when(...addressMovedDateIsLessThan3Years("address_moved_date")),
  previous1_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous1_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous1_address_city: Yup.string().when(...addressMovedDateIsLessThan3Years("address_moved_date")),
  previous1_address_county: Yup.string().when(...addressMovedDateIsLessThan3Years("address_moved_date")),
  previous1_address_country: Yup.mixed().nullable().when(...addressMovedDateIsLessThan3Years("address_moved_date")),
  previous1_address_moved_date: Yup.string().when(...addressMovedDateIsLessThan3YearsDate("address_moved_date")),
  previous1_residential_status: Yup.string().when(...addressMovedDateIsLessThan3Years("address_moved_date")),
  previous2_address_post_code: Yup.string().when(...addressMovedDateIsLessThan3Years("previous1_address_moved_date")),
  previous2_address_line1: Yup.string().when(...addressMovedDateIsLessThan3Years("previous1_address_moved_date")),
  previous2_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous2_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous2_address_city: Yup.string().when(...addressMovedDateIsLessThan3Years("previous1_address_moved_date")),
  previous2_address_county: Yup.string().when(...addressMovedDateIsLessThan3Years("previous1_address_moved_date")),
  previous2_address_country: Yup.mixed().nullable().when(...addressMovedDateIsLessThan3Years("previous1_address_moved_date")),
  previous2_address_moved_date: Yup.string().when(...addressMovedDateIsLessThan3YearsDate("previous1_address_moved_date")),
  previous2_residential_status: Yup.string().when(...addressMovedDateIsLessThan3Years("previous1_address_moved_date")),
  previous3_address_post_code: Yup.string().when(...addressMovedDateIsLessThan3Years("previous2_address_moved_date")),
  previous3_address_line1: Yup.string().when(...addressMovedDateIsLessThan3Years("previous2_address_moved_date")),
  previous3_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous3_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous3_address_city: Yup.string().when(...addressMovedDateIsLessThan3Years("previous2_address_moved_date")),
  previous3_address_county: Yup.string().when(...addressMovedDateIsLessThan3Years("previous2_address_moved_date")),
  previous3_address_country: Yup.mixed().nullable().when(...addressMovedDateIsLessThan3Years("previous2_address_moved_date")),
  previous3_address_moved_date: Yup.string().when(...addressMovedDateIsLessThan3YearsDate("previous2_address_moved_date")),
  previous3_residential_status: Yup.string().when(...addressMovedDateIsLessThan3Years("previous2_address_moved_date")),
  previous4_address_post_code: Yup.string().when(...addressMovedDateIsLessThan3Years("previous3_address_moved_date")),
  previous4_address_line1: Yup.string().when(...addressMovedDateIsLessThan3Years("previous3_address_moved_date")),
  previous4_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous4_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous4_address_city: Yup.string().when(...addressMovedDateIsLessThan3Years("previous3_address_moved_date")),
  previous4_address_county: Yup.string().when(...addressMovedDateIsLessThan3Years("previous3_address_moved_date")),
  previous4_address_country: Yup.mixed().nullable().when(...addressMovedDateIsLessThan3Years("previous3_address_moved_date")),
  previous4_address_moved_date: Yup.string().when(...addressMovedDateIsLessThan3YearsDate("previous3_address_moved_date")),
  previous4_residential_status: Yup.string().when(...addressMovedDateIsLessThan3Years("previous3_address_moved_date")),
  previous5_address_post_code: Yup.string().when(...addressMovedDateIsLessThan3Years("previous4_address_moved_date")),
  previous5_address_line1: Yup.string().when(...addressMovedDateIsLessThan3Years("previous4_address_moved_date")),
  previous5_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous5_address_line3: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  previous5_address_city: Yup.string().when(...addressMovedDateIsLessThan3Years("previous4_address_moved_date")),
  previous5_address_county: Yup.string().when(...addressMovedDateIsLessThan3Years("previous4_address_moved_date")),
  previous5_address_country: Yup.mixed().nullable().when(...addressMovedDateIsLessThan3Years("previous4_address_moved_date")),
  previous5_address_moved_date: Yup.string().when(...addressMovedDateIsLessThan3YearsDate("previous4_address_moved_date")),
  previous5_residential_status: Yup.string().when(...addressMovedDateIsLessThan3Years("previous4_address_moved_date")),
});