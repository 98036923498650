import React, { useContext } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { observer } from 'mobx-react-lite'
import Applicant from '../../../models/Applicant'
import PersonalDetailsIcon from './parts/PersonalDetailsIcon'
import EmploymentIcon from './parts/EmploymentIcon'
import OtherIncomeIcon from './parts/OtherIncomeIcon'
import PensionIncomeIcon from './parts/PensionIncomeIcon'
import AssetsIcon from './parts/AssetsIcon'
import GeneralQuestionsIcon from './parts/GeneralQuestionsIcon'
import CommitmentsIcon from './parts/CommitmentsIcon'
import DocumentUploadIcon from './parts/DocumentUploadIcon'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'

export default observer(function Applicants() {
  const { application_id } = useParams()
  let history = useHistory()
  const { applicationState: { applicants, type, disabled, backend_url_part }} = useContext(ApplicationContext)
  const { applicationErrorsState: { applicantErrors }} = useContext(ApplicationErrorsContext)

  const addApplicant = () => {
    axiosService.post(`/api/${backend_url_part}/applications/${application_id}/applicants`, { applicant: { application_id }})
    .then(applicant => {
      applicants.push(new Applicant(applicant))
    })
    .catch(error => {
      console.log(error)
    })
  }

  const getNextUrl = () => {
    if(applicants.length) return `/${type}/applications/${application_id}/applicants/${applicants[0].id}/identity-verification/step1`
    return `/${type}/applications/${application_id}/loan-requirements/step1`
  }

  const deleteApplicant = (applicantId, index) => () => {
    axiosService.delete(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicantId}`)
    .then(() => {
      applicants.splice(index, 1)
      delete applicantErrors[applicantId]
    }).catch(error => {
      console.log(error)
    })
  }

  const navigateToNextStep = () => {
    history.push(getNextUrl())
  }

  return (
    <div>
      <h2 className="form-title mb-40">Applicants</h2>

      <p className="mb-16 text-sm">
        Add applicants to this mortgage here. This dashboard will show progress made by each applicant.
      </p>

      <div className="flex flex-wrap -mx-12">
        <div className="px-12 w-1/2">
          <div className="btn-group-container flex items-center justify-between mb-16">
            <button
              className={`btn btn--group btn--primary ${(applicants.length >= 2 || disabled) && 'opacity-50'} w-2/3`}
              type="button"
              onClick={addApplicant}
              disabled={applicants.length >= 2 || disabled}
            >
              Add Applicant
              <i className="far fa-plus ml-10" />
            </button>
            <span className="pr-8">{applicants.length}</span>
          </div>
        </div>
      </div>

      { applicants.map((applicant, index) => (
        <div key={applicant.id} className="border border-timber-green-o-16 box-shadow flex items-center mb-8 px-8 py-6 rounded-lg">
          <div className="w-1/4">
          <p className="font-bold">Applicant {index + 1}</p>
            <p>{applicant.first_name} {applicant.middle_name} {applicant.last_name}</p>
          </div>

          <div className="applicant-image-container">
            <PersonalDetailsIcon applicant={applicant} />
            <EmploymentIcon applicant={applicant} />
            <OtherIncomeIcon applicant={applicant} />
            <PensionIncomeIcon applicant={applicant} />
            <AssetsIcon applicant={applicant} />
            <CommitmentsIcon applicant={applicant} />
            <GeneralQuestionsIcon applicant={applicant} />
            <DocumentUploadIcon applicant={applicant} />
          </div>

          <div className="flex flex-auto justify-end">
            <button
              className="bg-punch circle flex items-center justify-center text-white"
              style={{ height: 40, width: 40 }}
              type="button"
              disabled={disabled}
              onClick={deleteApplicant(applicant.id, index)}
            >
              <i className="far fa-trash" />
            </button>
          </div>
        </div>
      ))}

      <div className="flex justify-end mt-16">
        <button className="btn btn--primary" onClick={navigateToNextStep} type="button">Next step</button>
      </div>
    </div>
  )

})