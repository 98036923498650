import { makeAutoObservable } from "mobx"

export default class Mortgage {

  static sourceKeyValues = {
    'Gift': 'source_gift',
    'Inheritance': 'source_inheritance',
    'Property Sale': 'source_property',
    'Help to Buy Scheme?': 'source_help_to_buy',
    'Savings': 'source_savings',
    'Other': 'source_other',
  }

  constructor(mortgage={}) {
    this.id = mortgage.id
    this.purchase_price = mortgage.purchase_price || 0
    this.loan_required = mortgage.loan_required || 0
    this.if_other_details = mortgage.if_other_details || ''
    this.source_gift = !!mortgage.source_gift
    this.source_gift_amount = mortgage.source_gift_amount || 0
    this.source_inheritance = !!mortgage.source_inheritance
    this.source_inheritance_amount = mortgage.source_inheritance_amount || 0
    this.source_property = !!mortgage.source_property
    this.source_property_amount = mortgage.source_property_amount || 0
    this.source_help_to_buy = !!mortgage.source_help_to_buy
    this.source_help_to_buy_amount = mortgage.source_help_to_buy_amount || 0
    this.source_savings = !!mortgage.source_savings
    this.source_savings_amount = mortgage.source_savings_amount || 0
    this.source_other = !!mortgage.source_other
    this.source_other_amount = mortgage.source_other_amount || 0
    this.existing_mortgage_lender = mortgage.existing_mortgage_lender || ''
    this.purchase_date = mortgage.purchase_date || ''
    // this.original_purchase_price = mortgage.original_purchase_price || 0
    this.estimated_value = mortgage.estimated_value || 0
    this.original_amount_borrowed = mortgage.original_amount_borrowed || 0
    this.outstanding_balance = mortgage.outstanding_balance || 0
    this.purpose_of_mortgage = (+mortgage.outstanding_balance === +mortgage.loan_required ? '5' : mortgage.purpose_of_mortgage) || null
    this.deposit_comes_from = ['Gift', 'Inheritance', 'Property Sale', 'Help to Buy Scheme?', 'Savings', 'Other'].reduce((result, value) => {
      if(this[Mortgage.sourceKeyValues[value]]) {
        result === '' ? result = value : result += `,${value}`
      }
      return result
    }, '')

    makeAutoObservable(this)
  }
}