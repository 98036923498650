import React, { Fragment } from 'react'
import AddressSearch from 'react-loqate'
import Input from './Input';
import Label from './Label'

export default function LoqateWrapper({ onSelect, label, tooltip, disabled }) {

  return (
    <Fragment>
      {!disabled &&
        <Fragment>
          <Label label={label} tooltip={tooltip} />

          <AddressSearch
            apiKey="FU38-DF65-XJ75-JK68"
            locale="en_IE"
            countries={["IE, GB"]}
            onSelect={onSelect}
            limit={10}
            components={{ Input: AddressSearchInput }}
            className="relative"
            inputClassname="input"
            listItemClassname="input overflow-hidden"
          />
        </Fragment>
        ||
        <Input
          label={label}
          tooltip={tooltip}
          disabled
        />
      }
    </Fragment>
  )
}

const AddressSearchInput = props => {
  return (
    <input
      autoComplete="chrome-off"
      {...props}
    />
  );
};