import { makeAutoObservable } from "mobx"

export default class ApplicantSteps {

  constructor(steps={}) {
    this.personal_details_step1_visited = !!steps.personal_details_step1_visited
    this.personal_details_step2_visited = !!steps.personal_details_step2_visited
    this.personal_details_step3_visited = !!steps.personal_details_step3_visited
    this.personal_details_step4_visited = !!steps.personal_details_step4_visited
    this.employment_step1_visited = !!steps.employment_step1_visited
    this.employment_step2_visited = !!steps.employment_step2_visited
    this.employment_step3_visited = !!steps.employment_step3_visited
    this.employment_step4_visited = !!steps.employment_step4_visited
    this.other_income_step1_visited = !!steps.other_income_step1_visited
    this.other_income_step2_visited = !!steps.other_income_step2_visited
    this.other_income_step3_visited = !!steps.other_income_step3_visited
    this.pension_visited = !!steps.pension_visited
    this.assets_visited = !!steps.assets_visited
    this.commitments_step1_visited = !!steps.commitments_step1_visited
    this.commitments_step2_visited = !!steps.commitments_step2_visited
    this.general_questions_visited = !!steps.general_questions_visited
    this.document_upload_visited = !!steps.document_upload_visited

    makeAutoObservable(this)
  }
}