import React, { useContext, useMemo } from 'react'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { useParams } from 'react-router-dom'
import Applicant from '../../../models/Applicant'
import EmployedFormPart from './Step4Parts/EmployedFormPart'
import { observer } from 'mobx-react-lite'
import SelfEmployedFormPart from './Step4Parts/SelfEmployedFormPart'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'

export default observer(function EmploymentStep4() {
  const { applicationState: { applicants, disabled }} = useContext(ApplicationContext)
  const { applicant_id } = useParams()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  return (
    <div>
      <h2 className="form-title mb-40">Employment</h2>
      { !disabled && <ApplicantStepUpdater step="employment_step4_visited" /> }

      { currentApplicant.employment.are_you === 'Employed' && <EmployedFormPart />}
      { currentApplicant.employment.are_you === 'Self Employed' && <SelfEmployedFormPart />}
    </div>
  )
})
