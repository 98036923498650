import { makeAutoObservable } from "mobx"

export default class Solicitor {
  constructor(solicitor={}) {
    this.id = solicitor.id
    this.name_of_firm = solicitor.name_of_firm || ''
    this.solicitor_acting = solicitor.solicitor_acting || ''
    this.telephone = solicitor.telephone || '+353'
    this.email = solicitor.email || ''
    this.address_post_code = solicitor.address_post_code || ''
    this.address_line1 = solicitor.address_line1 || ''
    this.address_line2 = solicitor.address_line2 || ''
    this.address_line3 = solicitor.address_line3 || ''
    this.address_city = solicitor.address_city || ''
    this.address_county = solicitor.address_county || ''
    this.address_country = solicitor.address_country || 'IRL'
    this.do_you_have_a_solicitor = !!solicitor.do_you_have_a_solicitor
    makeAutoObservable(this)
  }
}