import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CurrentBorrowerContext } from '../../contexts/CurrentBorrowerContext'
import Switch from '../Form/Switch'

import moment from 'moment'
import axiosService from '../../utils/AxiosService'
import { toastError } from '../Form/Toasts'

export default observer(function BorrowerConsentModal({ borrowerState }) {
  const { token } = useParams()
  const history = useHistory()
  const currentBorrowerContext = useContext(CurrentBorrowerContext)

  const register = () => {
    axiosService.post(`/api/borrower/passwords/${token}`, { borrower: borrowerState.borrower })
    .then(({ borrower }) => {
      currentBorrowerContext.setCurrentBorrower(borrower)
      history.push('/borrower/applications')
    })
    .catch(({ data: { error }}) => toastError(error, { autoClose: 4000 }))
  }

  return (
    <Fragment>
      <div className="fixed inset-0 z-60 outline-none focus:outline-none overflow-y-auto">
        <div className="flex flex-auto justify-center items-center min-h-screen">
          <div className="border-0 m-6 sm:m-8 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style={{ maxWidth: 970 }}>
            <div className="p-6 sm:px-24 sm:py-10">
              {/* Close Button */}
              <div style={{ position: 'absolute', right: '5px', top: '5px'}}>
                <button
                  type="button"
                  className="inline-flex items-center h-7 w-7 border rounded-md border-transparent hover:bg-gray-50 focus:outline-none"
                  onClick={() => borrowerState.open = false}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ color: '#9ca3af' }}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <h3 className="font-bold mb-4 text-2xl">Welcome to MoCo’s Customer Mortgage Application Portal.</h3>

              <div className="mb-16">
                <p className="mb-4">If you continue to use this portal to submit your mortgage application, you are agreeing to comply with the following terms and conditions of use:</p>
                <ul className="list-disc pl-6">
                  <li className="mb-2">Any information you provide is correct and accurate at the time of submission and MoCo will act on the basis that all information is correct and accurate.</li>
                  <li className="mb-2">If, for any reason, the information you provide in the application becomes inaccurate or out-of-date prior to the drawdown of any related mortgage, you will inform MoCo without undue delay.</li>
                  <li className="mb-2">All applicants intend to enter into a mortgage agreement and have sought and followed any advice provided by their mortgage broker or intermediary.</li>
                  <li className="mb-2">All applicants are over the age of 18 at the time of submission.</li>
                </ul>
              </div>
              <div className="flex mb-16">
                <p className="font-bold">I/We agree to the Terms and Conditions above, and acknowledge that I/we have read and understood the <a className="underline" href="/policies#customer-data-privacy" target="_blank">Customer Data Privacy Notice</a> and <a className="underline" href="/policies#data-protection" target="_blank">Data Protection Policy</a>.<span className="text-punch">*</span></p>
                <div className="pl-12">
                  <Switch
                    name="consent_1_accepted"
                    onChange={(e) => {
                      borrowerState.borrower[e.target.name] = e.target.checked
                      borrowerState.borrower.consent_1_date = e.target.checked ? moment().format('DD/MM/YYYY') : ''
                    }}
                    value={borrowerState.borrower.consent_1_accepted}
                  />
                </div>
              </div>
              <div className="mb-4">
                <p>MoCo may, in the future, partner with third-parties in order to offer products and services that MoCo determine to be beneficial to you.</p>
              </div>
              <div className="flex mb-16">
                <p className="font-bold">I/We consent to MoCo sharing my/our personal data with its partners to keep me informed and to offer me/us new products and benefits relating to my/our MoCo mortgage application, which MoCo believes would interest me/us. I/we understand that I/we can withdraw consent at any time, using the contact information set out in the <a className="underline" href="/policies#customer-data-privacy" target="_blank">Customer Data Privacy Notice</a>.</p>
                <div className="pl-12">
                  <Switch
                    name="consent_2_accepted"
                    onChange={(e) => borrowerState.borrower[e.target.name] = e.target.checked}
                    value={borrowerState.borrower.consent_2_accepted}
                  />
                </div>
              </div>

              <div className="flex justify-center">
                <div className="relative">
                  <button
                    className={`w-full btn btn--primary ${!borrowerState.borrower.consent_1_accepted && 'opacity-50'}`}
                    style={{ paddingRight: "5rem"}}
                    onClick={register}
                    disabled={!borrowerState.borrower.consent_1_accepted}
                  >
                    Continue to the site
                  </button>
                  <i className="fa fa-arrow-right absolute left-56 text-white top-1/2 -translate-y-1/2 transform" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-50 bg-timber-green-o-40" />
    </Fragment>
  );
})