import React, { useContext } from 'react'
import PersonalDetailsStep1 from './Step1'
import PersonalDetailsStep2 from './Step2'
import PersonalDetailsStep3 from './Step3'
import PersonalDetailsStep4 from './Step4'
import { Route, Switch } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'

export default function PersonalDetails() {
  const { applicationState: { type } } = useContext(ApplicationContext)

  return (
    <Switch>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/personal-details/step1`}>
        <PersonalDetailsStep1 />
      </Route>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/personal-details/step2`}>
        <PersonalDetailsStep2 />
      </Route>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/personal-details/step3`}>
        <PersonalDetailsStep3 />
      </Route>
      <Route path={`/${type}/applications/:application_id/applicants/:applicant_id/personal-details/step4`}>
        <PersonalDetailsStep4 />
      </Route>
    </Switch>
  )
}
