import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import Input from '../../Form/Input'
import ReactSelect from '../../Form/ReactSelect'
import PhoneField from '../../Form/PhoneField'
import DatePicker from '../../Form/DatePicker'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { PersonalDetailsStep2Schema } from '../../../schemas/PersonalDetailsSchemas/PersonalDetailsStep2Schema'
import axiosService from '../../../utils/AxiosService'
import PersonalDetailsActions from './PersonalDetailsActions'
import { Formik } from 'formik'
import Applicant from '../../../models/Applicant'
import { observer } from 'mobx-react-lite'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import NationalitySelector from '../../Form/NationalitySelector'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import { notifyProgressSaved } from '../../Form/Notifications'
import { toastError, toastSuccess } from '../../Form/Toasts'
import consumer from '../../../channels/consumer'
import ErrorMessage from '../../Form/ErrorMessage'
import Label from '../../Form/Label'

export default observer(function PersonalDetailsStep2() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ["date_of_birth", "marital_status", "nationality", "ppsn", "email", "email_verified", "mobile_number", "phone_number", "right_to_live_in_roi", "date_moved_to_roi", "has_diplomatic_immunity", "country_of_residence"]

  const maritalOptions = [
    { label: 'Single', value: '5' },
    { label: 'Married / Civil Partnership / Living with Partner', value: '1' },
    { label: 'Divorced / Civil Partnership has been dissolved', value: '2' },
    { label: 'Widowed / Surviving Civil Partner', value: '3' },
    { label: 'Separated', value: '4' },
  ]

  const save = applicant => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant })
    .then(() => {
      notifyProgressSaved()
      Object.keys(applicant).forEach(key => currentApplicant[key] = applicant[key])
      applicationErrorsContext.validatePersonalDetailsStep2({ ...currentApplicant, applicantEmails: applicationErrorsContext.applicationErrorsState.applicantEmails, firstApplicantId: applicationErrorsContext.applicationErrorsState.firstApplicantId }, applicant_id)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  const currentApplicantFields = useMemo(() => {
    return fields.reduce((result, field) => {
      result[field] = currentApplicant[field]
      return result
    }, {})
  }, [currentApplicant])

  useEffect(() => {
    if(currentApplicant.steps.personal_details_step2_visited) touchFields()
    applicationErrorsContext.validatePersonalDetailsStep2({ ...currentApplicant, applicantEmails: applicationErrorsContext.applicationErrorsState.applicantEmails, firstApplicantId: applicationErrorsContext.applicationErrorsState.firstApplicantId }, applicant_id)
  }, [loading])

  useEffect(() => {

    const channel = consumer.subscriptions.create(
      {
        channel: 'EmailVerificationChannel',
        applicant_id
      },
      {
        connected() {
          console.log('connected')
        },
        disconnected() {
          console.log('disconnected')
        },
        received({ message }) {
          toastSuccess(message)
          formikRef.current.setFieldValue('email_verified', true, true)
        }
      }
    )

    axiosService.get(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}/email_verified`)
    .then(({ email_verified }) => {
      formikRef.current.setFieldValue('email_verified', email_verified, true)
    })
    .catch(({ data: { error }}) => toastError(error))
    return () => {
      channel.unsubscribe()
    }
  }, [])

  const sendEmailVerificationEmail = ({ email }) => () => {
    axiosService.post(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}/send_verification_email`, { email })
    .then((response) => {
      // Could be unsuccessful due to invalid email address
      if (response.success === true) {
        toastSuccess('Email sent with instructions')
      } else {
        toastError(response.message ?? 'An error occurred')
      }
    })
    .catch(error => console.log(error))
  }

  return (
    <div>
      <h2 className="form-title mb-40">Personal details</h2>
      { !disabled && <ApplicantStepUpdater step="personal_details_step2_visited" /> }

      <Formik
        initialValues={{ ...currentApplicantFields, applicantEmails: applicationErrorsContext.applicationErrorsState.applicantEmails, id: currentApplicant.id, firstApplicantId: applicationErrorsContext.applicationErrorsState.firstApplicantId }}
        validationSchema={PersonalDetailsStep2Schema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <DatePicker
                    error={errors.date_of_birth && touched.date_of_birth && errors.date_of_birth}
                    label="Date of Birth"
                    name="date_of_birth"
                    onChange={value => setFieldValue('date_of_birth', value, true)}
                    value={values.date_of_birth}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <ReactSelect
                    error={errors.marital_status && touched.marital_status && errors.marital_status}
                    label="Marital Satus"
                    name="marital_status"
                    options={maritalOptions}
                    placeholder="Select your status"
                    onChange={value => setFieldValue('marital_status', value?.value || '', true)}
                    onBlur={handleBlur}
                    value={values.marital_status}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <NationalitySelector
                    error={errors.nationality && touched.nationality && errors.nationality}
                    handleBlur={handleBlur}
                    handleChange={value => {
                      setFieldValue('nationality', value?.value || '', true)
                      if(value?.value === '47') {
                        setFieldValue('right_to_live_in_roi', false, true)
                        setFieldValue('date_moved_to_roi', '', true)
                        setFieldValue('has_diplomatic_immunity', false, true)
                      }
                    }}
                    nationality={values.nationality}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.ppsn && touched.ppsn && errors.ppsn}
                    label="PPSN"
                    name="ppsn"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ppsn}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-6">
                  <Input
                    error={errors.email && touched.email && errors.email}
                    label="Email Address"
                    name="email"
                    type="text"
                    onChange={({ target: { name, value }}) => {
                      setFieldValue(name, value, true)
                      setFieldValue('email_verified', false, true)
                      setFieldValue('applicantEmails', { ...values.applicantEmails, [applicant_id]: value })
                      applicationErrorsContext.applicationErrorsState.applicantEmails[applicant_id] = value
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-4">
                  <div className="flex items-center text-white relative" style={{  }}>
                    <div className="mt-3">
                      <Label label="Email Verified" />
                    </div>
                    { values.email_verified
                      ? <span className={`data-capture-sidebar-icon data-capture-sidebar-icon--success`}>
                          <i className="fa fa-check" />
                        </span>
                      : <span className={`data-capture-sidebar-icon data-capture-sidebar-icon--error`}>x</span>
                    }
                  </div>
                  <ErrorMessage text={touched.email_verified && errors.email_verified} />
                </div>
                { !values.email_verified &&
                  <div className="mb-40">
                    <button className="btn btn--primary w-full" type="button" onClick={sendEmailVerificationEmail(values)}>Send verification email</button>
                  </div>
                }

                <div className="mb-40">
                  <ReactSelect
                    countrySelect
                    error={errors.country_of_residence && touched.country_of_residence && errors.country_of_residence}
                    label="Country of residence"
                    name="country_of_residence"
                    placeholder="Select Country"
                    onChange={value => setFieldValue('country_of_residence', value?.value || '', true)}
                    onBlur={handleBlur}
                    value={values.country_of_residence}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <PhoneField
                    error={errors.mobile_number && touched.mobile_number && errors.mobile_number}
                    label="Mobile Phone Number"
                    name="mobile_number"
                    onChange={value => setFieldValue('mobile_number', value, true)}
                    onBlur={handleBlur}
                    value={values.mobile_number}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <PhoneField
                    error={errors.phone_number && touched.phone_number && errors.phone_number}
                    label="Alternative Phone Number"
                    name="phone_number"
                    optional
                    onChange={value => setFieldValue('phone_number', value, true)}
                    onBlur={handleBlur}
                    value={values.phone_number}
                    disabled={disabled}
                  />
                </div>

                {values.nationality !== '47' &&
                  <Fragment>
                    <div className="mb-40">
                      <YesNoBtnGroup
                        error={errors.right_to_live_in_roi && touched.right_to_live_in_roi && errors.right_to_live_in_roi}
                        label="Right to live in the ROI"
                        name="right_to_live_in_roi"
                        tooltip="Do you have the right to live in the ROI?"
                        onChange={(name, value) => setFieldValue(name, value, true)}
                        onBlur={handleBlur}
                        value={values.right_to_live_in_roi}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <DatePicker
                        error={errors.date_moved_to_roi && touched.date_moved_to_roi && errors.date_moved_to_roi}
                        label="Date moved to Ireland"
                        name="date_moved_to_roi"
                        onChange={value => setFieldValue('date_moved_to_roi', value, true)}
                        value={values.date_moved_to_roi}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <YesNoBtnGroup
                        error={errors.has_diplomatic_immunity && touched.has_diplomatic_immunity && errors.has_diplomatic_immunity}
                        label="Do you have diplomatic immunity?"
                        name="has_diplomatic_immunity"
                        onChange={(name, value) => setFieldValue(name, value, true)}
                        onBlur={handleBlur}
                        value={values.has_diplomatic_immunity}
                        disabled={disabled}
                      />
                    </div>
                  </Fragment>
                }
              </div>
            </div>
            <PersonalDetailsActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})