import { makeAutoObservable } from "mobx"
import ApplicationSteps from "./ApplicationSteps"
import Document from "./Document"
import ApplicationEvaluation from './ApplicationEvaluation'
// import Product from './Product'

export default class Application {
  constructor(application={}) {
    this.id = application.id
    this.application_number = application.application_number
    this.purchase_type = application.purchase_type || ''
    this.state = application.state || ''
    // this.product = new Product(application.product || undefined)
    this.steps = new ApplicationSteps(application.steps)
    this.documents = application.documents?.map(document => new Document(document)) || []
    this.application_evaluation = new ApplicationEvaluation(application.application_evaluation)
    makeAutoObservable(this)
  }
}
