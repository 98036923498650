import React from 'react'
import Label from './Label'
import ErrorMessage from './ErrorMessage'

export default function BtnGroup({
  error,
  label,
  name,
  onChange,
  options,
  showAsterisk,
  tooltip,
  value,
  disabled
}) {
  return (
    <>
      <Label
        label={label}
        showAsterisk={showAsterisk}
        tooltip={tooltip}
      />

      <div className={`btn-group-container ${error ? 'error' : ''}`}>
        <div className="flex -mx-3">
          {options.map(button => (
            <div className="px-3 w-1/2" key={button.label}>
              <button
                className={`
                  btn btn--group w-full
                  ${value === button.value ? 'btn--primary' : 'btn--outlined-primary'}
                `}
                disabled={disabled}
                onClick={() => { onChange(name, button.value) }}
                type="button"
              >
                {button.label}
              </button>
            </div>
          ))}
        </div>
      </div>

      <ErrorMessage text={error} />
    </>
  )
}
