import { observer } from 'mobx-react-lite'
import { autorun } from 'mobx'
import React, { useContext, useState, useRef, useEffect } from 'react'
import RootStoreContext from '../contexts/RootStoreContext'

/**
 * Dialog/model that shows user (Borrower or Broker) when session is soon to expire (eg within 90 seconds),
 * and allows them to extend the session. It works by observing RootStore.sessionTimeoutSecondsRemaining,
 * and showing when within a threshold (eg 90 seconds).
 * 
 * If 2 or more tabs/windows are open, because of the polling with RootStore.sessionTimeoutCheckRemaining,
 * the dialog in other tab/window will disappear when session is extended.
 */
const SessionTimeoutDialog = observer(() => {
  const secondsThresholdToShowDialog = 90 // In seconds
  const rootStore = useContext(RootStoreContext)
  const [secondsRemaining, setSecondsRemaining] = useState(undefined) // Set this initially so doesn't show
  const secondsRemainingRef = useRef(secondsRemaining)
  const intervalRef = useRef()

  // Because we use an interval and useEffect, we need a ref to access secondsRemaining
  useEffect(() => {
    secondsRemainingRef.current = secondsRemaining
  }, [secondsRemaining])


  // Using Mobx, we observe changes to rootStore.sessionTimeoutSecondsRemaining and 
  // update `secondsRemaining` remaining accordingly. Only when seconds remaining
  // is within the threshold (eg 90 seconds), so we actually set 'secondsRemaining'
  // and start the interval to countdown to when the session expires
  useEffect(
    () => {
        autorun(() => {
          const sessionTimeoutSecondsRemaining = rootStore.sessionTimeoutSecondsRemaining.get()

          // 'sessionTimeoutSecondsRemaining' is initially undefined in the RootStore. If it changes
          // and is not within threshold (ie first check or was extended), we clear any interval and 
          // nullify 'secondsRemaining' so it doesn't render the dialog
          if (sessionTimeoutSecondsRemaining === undefined || sessionTimeoutSecondsRemaining > secondsThresholdToShowDialog) {
            if (intervalRef.current) {
              clearInterval(intervalRef.current)
              intervalRef.current = undefined
            }
            setSecondsRemaining(undefined)
          }
          // Otherwise, seconds remaining is within the threshold, so we create the interval 
          // (if none yet, as 'sessionTimeoutSecondsRemaining' will also change on subsequent checks)
          else if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
              if (secondsRemainingRef.current === undefined) {
                setSecondsRemaining(rootStore.sessionTimeoutSecondsRemaining.get())
              }
              else {
                setSecondsRemaining(secondsRemainingRef.current - 1)
              }
            }, 1000)
          }
        })
    }, []
  )

  if (secondsRemaining === undefined) return null
  return (
    <>
      {/* Modal */}
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4"
            style={{ maxWidth: 540 }}
          >
            {/* Heading */}
            <div className="mb-6 px-16 text-center">
              <h2 className="font-bold mb-1 text-2xl">
                Extend Session
              </h2>
              <p>
                Your session is due to expire in {secondsRemaining > 0 ? secondsRemaining : 0} seconds, do you wish to extend the session?
              </p>
            </div>

            {/* Submit */}
            <button 
              type="submit" 
              className="submit btn btn--primary text-center" 
              onClick={() => rootStore.sessionTimeoutExtend()}
            >
              Extend Session
            </button>
          </div>
        </div>
      </div>

      {/* Backdrop */}
      <div className="fixed inset-0 z-40 bg-timber-green-o-40" />
    </>
  )
})
export default SessionTimeoutDialog