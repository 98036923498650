import React from 'react'
import { calculateAprc } from './AprcCalculator/AprcCalculation'

export default function OurRates({
  cash_backs,
  ltvs,
  overpayments,
  terms,
}) {
  const defaultOverpayment = false

  const mortgage_term = 20
  const mortgage_amount = 100000
  const property_value = 200000

  const calculateOverpayment = (values, type, rateFixedTerm, min) => {
    if (values.overpayment === null) return

    return overpayments.find(item => values.overpayment ? item.overpayment.toLowerCase() === 'yes' : item.overpayment.toLowerCase() === 'no'
      && item.min == min
      && item.term === rateFixedTerm
    )[type]
  }

  const calculateBasicRate = (rateFixedTerm, type, values, cashback, min) => {
    const termsExtraRate = terms.find(item => item.term === rateFixedTerm
      && item.min == min
      && parseFloat(item.cash_back) === cashback
    ) || 0
    const termsExtraRateLoading = parseFloat(termsExtraRate[type] || 0)
    const overpaymentLoading = parseFloat(calculateOverpayment(values, type, rateFixedTerm, min))
    const ltvLoading = parseFloat(ltvs.find(item => item.min == min
      && item.term === rateFixedTerm
    )[type])

    return ltvLoading + termsExtraRateLoading + overpaymentLoading
  }

  const values = {
    mortgage_amount,
    mortgage_term,
    overpayment: defaultOverpayment,
    property_value
  }

  return (
    <>
      <div className="table-outer-container">
        <div className="table-container">
          <table className="table">
            <tr>
              <th className="w-1/4">Fixed Rate Term</th>
              <th className="w-1/4">LTV</th>
              <th className="w-1/4">Fixed Rate</th>
              <th className="w-1/4">APRC</th>
            </tr>
          </table>

          <table className="table">
            <tbody>
              {cash_backs
                .sort((a, b) => a.term - b.term || a.min - b.min)
                .map(cashback => {
                  const fixedRate = (calculateBasicRate(cashback.term, 'fixed_rate_loading', values, parseInt(cashback.cash_back), cashback.min) + parseFloat(cashback.fixed_rate_loading)).toFixed(2)
                  const variableRate = (calculateBasicRate(cashback.term, 'roll_off_variable_rate_loading', values, parseInt(cashback.cash_back), cashback.min) + parseFloat(cashback.roll_off_variable_rate_loading)).toFixed(2)

                  if (parseInt(cashback.cash_back) !== 0) return

                  return (
                    <tr key={cashback.id}>
                      <td className="w-1/4">{cashback.term} years</td>
                      <td className="w-1/4">{`${cashback.min == 0 ? '' : `>=${Math.ceil(cashback.min)}%`} < ${Math.ceil(cashback.max)}%`}</td>
                      <td className="w-1/4">{fixedRate} %</td>
                      <td className="w-1/4">
                        {
                          calculateAprc(
                            fixedRate,
                            variableRate,
                            cashback.term,
                            values.mortgage_term,
                            values.mortgage_amount,
                          )
                        } %
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
