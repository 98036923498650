import { makeAutoObservable } from "mobx"

export default class Security {
  constructor(security={}) {
    this.id = security.id
    this.specific_property_in_mind = !!security.specific_property_in_mind
    this.property_address_post_code = security.property_address_post_code || ''
    this.property_address_line1 = security.property_address_line1 || ''
    this.property_address_line2 = security.property_address_line2 || ''
    this.property_address_line3 = security.property_address_line3 || ''
    this.property_address_city = security.property_address_city || ''
    this.property_address_county = security.property_address_county || ''
    this.property_address_country = security.property_address_country || 'IRL'
    this.property_type = security.property_type || ''
    this.property_tenure = security.property_tenure || ''
    this.service_charge = security.service_charge || 0
    this.ground_rent = security.ground_rent || 0
    this.unexpired_remaining_lease = security.unexpired_remaining_lease || 0
    this.around_commercial_premises = !!security.around_commercial_premises
    this.energy_performance_certificate = security.energy_performance_certificate || ''
    makeAutoObservable(this)
  }
}