import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../models/Applicant'
import { PersonalDetailsStep1Schema } from '../../../schemas/PersonalDetailsSchemas/PersonalDetailsStep1Schema'
import axiosService from '../../../utils/AxiosService'
import ApplicantStepUpdater from '../../Form/ApplicantStepUpdater'
import Input from '../../Form/Input'
import { notifyProgressSaved } from '../../Form/Notifications'
import ReactSelect from '../../Form/ReactSelect'
import YesNoBtnGroup from '../../Form/YesNoBtnGroup'
import PersonalDetailsActions from './PersonalDetailsActions'

export default observer(function PersonalDetailsStep1() {
  const { applicationState: { applicants, loading, disabled, backend_url_part }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicants, applicant_id])

  const fields = ["title", "first_name", "middle_name", "last_name", "known_by_other_name", "other_title", "other_first_name", "other_middle_name", "other_last_name"]

  const titleOptions = [
    { label: 'Mr', value: '1' },
    { label: 'Mrs', value: '2' },
    { label: 'Miss', value: '3' },
    { label: 'Ms', value: '4' },
    { label: 'Dr', value: '5' },
  ]

  const knownByOtherNameOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  const save = applicant => e => {
    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant })
    .then(() => {
      notifyProgressSaved()
      Object.keys(applicant).forEach(key => currentApplicant[key] = applicant[key])
      applicationErrorsContext.validatePersonalDetailsStep1(currentApplicant, applicant_id)
    })
  }

  const touchFields = () => formikRef.current.setTouched(fields.reduce((result, field) => { result[field] = true; return result }, {}))

  const currentApplicantFields = useMemo(() => {
    return fields.reduce((result, field) => {
      result[field] = currentApplicant[field]
      return result
    }, {})
  }, [currentApplicant])

  useEffect(() => {
    if(currentApplicant.steps.personal_details_step1_visited) touchFields()
    applicationErrorsContext.validatePersonalDetailsStep1(currentApplicant, applicant_id)
  }, [loading])

  return (
    <div>
      <h2 className="form-title mb-40">Personal details</h2>
      { !disabled && <ApplicantStepUpdater step="personal_details_step1_visited" /> }

      <Formik
        innerRef={formikRef}
        initialValues={currentApplicantFields}
        validationSchema={PersonalDetailsStep1Schema}
        enableReinitialize={loading}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <ReactSelect
                    error={errors.title && touched.title && errors.title}
                    label="Title"
                    name="title"
                    options={titleOptions}
                    placeholder="Select your title"
                    onChange={value => setFieldValue('title', (value?.value || ''), true)}
                    onBlur={handleBlur}
                    value={values.title}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.first_name && touched.first_name && errors.first_name}
                    label="First Name"
                    name="first_name"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.middle_name && touched.middle_name && errors.middle_name}
                    label="Middle Name"
                    name="middle_name"
                    optional
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.middle_name}
                    disabled={disabled}
                  />
                </div>

                <div className="mb-40">
                  <Input
                    error={errors.last_name && touched.last_name && errors.last_name}
                    label="Last Name"
                    name="last_name"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className="px-12 w-1/2">
                <div className="mb-40">
                  <YesNoBtnGroup
                    error={errors.known_by_other_name && touched.known_by_other_name && errors.known_by_other_name}
                    label="Other Name?"
                    name="known_by_other_name"
                    tooltip="Details of any other name you have been known by in the past, or if a different name appears on your identiy documentation"
                    onChange={(name, value) => {
                      setFieldValue(name, value, true)
                      setFieldValue('other_title', '', true)
                      setFieldValue('other_first_name', '', true)
                      setFieldValue('other_middle_name', '', true)
                      setFieldValue('other_last_name', '', true)
                    }}
                    value={values.known_by_other_name}
                    options={knownByOtherNameOptions}
                    disabled={disabled}
                  />
                </div>

                { values.known_by_other_name &&
                  <Fragment>
                    <div className="mb-40">
                      <ReactSelect
                        error={errors.other_title && touched.other_title && errors.other_title}
                        label="Other Title"
                        name="other_title"
                        options={titleOptions}
                        placeholder="Select your title"
                        onChange={value => setFieldValue('other_title', (value?.value || ''), true)}
                        onBlur={handleBlur}
                        value={values.other_title}
                        disabled={disabled}
                      />
                    </div>

                    <div className="mb-40">
                      <Input
                        error={errors.other_first_name && touched.other_first_name && errors.other_first_name}
                        label="Other First Name"
                        name="other_first_name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.other_first_name}
                        disabled={disabled}
                      />
                    </div>

                    {/* <div className="mb-40">
                      <Input
                        error={errors.other_middle_name && touched.other_middle_name && errors.other_middle_name}
                        label="Other Middle Name"
                        name="other_middle_name"
                        optional
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.other_middle_name}
                        disabled={disabled}
                      />
                    </div> */}

                    <div className="mb-40">
                      <Input
                        error={errors.other_last_name && touched.other_last_name && errors.other_last_name}
                        label="Other Last Name"
                        name="other_last_name"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.other_last_name}
                        disabled={disabled}
                      />
                    </div>
                  </Fragment>
                }
              </div>
            </div>
            <PersonalDetailsActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})
