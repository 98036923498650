import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Input from '../../Form/Input';
import axiosService from '../../../utils/AxiosService';
import { useHistory } from 'react-router-dom';

const loginSchema = Yup.object().shape({
  email: Yup.string().required('Required').email('Invalid email').test({
    name: 'uppercase',
    exclusive: true,
    message: 'Email address should contain only lowercase characters',
    test: value => value && !/[A-Z]/.test(value),
  }),
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
});

export default function NewApplication() {
  const history = useHistory()

  const sendInvitationEmail = borrower => {
    axiosService.post('/api/apprivo_broker/applications', { borrower })
    .then(() => {
      history.push('/broker/applications')
    })
  }

  return (
    <div>
      <h2 className="form-title mb-40">Create an Application</h2>

      <p className="mb-16 text-sm">
        Send an email to an applicant to invite them to register and apply for a mortgage with MoCo. For joint applications, send an email to the primary applicant only
      </p>

      <Formik initialValues={{ email: '', first_name: '', last_name: '' }} validationSchema={loginSchema} onSubmit={sendInvitationEmail}>
        {({ errors, touched, handleBlur, handleChange, values }) => (
          <Form className="w-1/2">
            <div className="form-item-container">
              <Input error={errors.email && touched.email && errors.email} label="Borrower email address" autoFocus={true} name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
            </div>
            <div className="form-item-container">
              <Input error={errors.first_name && touched.first_name && errors.first_name} label="Borrower first name" name="first_name" onChange={handleChange} onBlur={handleBlur} value={values.first_name} />
            </div>
            <div className="form-item-container">
              <Input error={errors.last_name && touched.last_name && errors.last_name} label="Borrower last name" name="last_name" onChange={handleChange} onBlur={handleBlur} value={values.last_name} />
            </div>
            <button type="submit" className="submit btn btn--primary text-left w-full">Send an invitation email</button>
          </Form>
        )}
      </Formik>
    </div>
  )
}