import React, { useContext, useEffect } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { ApplicationContext } from './ApplicationContext'
import ApplicantErrors from '../models/ApplicantErrors'
import { solicitorSchema } from '../schemas/SolicitorSchema'
import { directDebitSchema } from '../schemas/DirectDebitSchema'
import { mainPropertyDetailsSchema } from '../schemas/MainPropertyDetailsSchema'
import { PropertySchema } from '../schemas/PropertySchema'
import { loanRequirementsStep1Schema } from '../schemas/LoanRequirementsSchemas/LoanRequirementsStep1Schema'
import { loanRequirementsStep2Schema } from '../schemas/LoanRequirementsSchemas/LoanRequirementsStep2Schema'
import ApplicantValidator from '../components/DataCapture/ApplicantValidator'
import { generalSchema } from '../schemas/GeneralSchema'
import { assetsSchema } from '../schemas/AssetsSchema'
import { pensionSchema } from '../schemas/PensionSchema'
import { CommitmentsStep1Schema } from '../schemas/CommitmentsSchema/CommitmentsStep1Schema'
import { CommitmentsStep2Schema } from '../schemas/CommitmentsSchema/CommitmentsStep2Schema'
import { OtherIncomeStep1Schema } from '../schemas/OtherIncomeSchemas/OtherIncomeStep1Schema'
import { OtherIncomeStep2Schema } from '../schemas/OtherIncomeSchemas/OtherIncomeStep2Schema'
import { OtherIncomeStep3Schema } from '../schemas/OtherIncomeSchemas/OtherIncomeStep3Schema'
import { PersonalDetailsStep1Schema } from '../schemas/PersonalDetailsSchemas/PersonalDetailsStep1Schema'
import { PersonalDetailsStep2Schema } from '../schemas/PersonalDetailsSchemas/PersonalDetailsStep2Schema'
import { PersonalDetailsStep3Schema } from '../schemas/PersonalDetailsSchemas/PersonalDetailsStep3Schema'
import { PersonalDetailsStep4Schema } from '../schemas/PersonalDetailsSchemas/PersonalDetailsStep4Schema'
import { EmploymentStep1EmployedSchema } from '../schemas/EmploymentSchemas/EmploymentStep1EmployedSchema'
import { EmploymentStep2EmployedSchema } from '../schemas/EmploymentSchemas/EmploymentStep2EmployedSchema'
import { EmploymentStep3EmployedSchema } from '../schemas/EmploymentSchemas/EmploymentStep3EmployedSchema'
import { EmploymentStep4EmployedSchema } from '../schemas/EmploymentSchemas/EmploymentStep4EmployedSchema'
import { EmploymentStep1SelfEmployedSchema } from '../schemas/EmploymentSchemas/EmploymentStep1SelfEmployedSchema'
import { EmploymentStep4SelfEmployedSchema } from '../schemas/EmploymentSchemas/EmploymentStep4SelfEmployedSchema'
import { EmploymentStep3SelfEmployedSchema } from '../schemas/EmploymentSchemas/EmploymentStep3SelfEmployedSchema'
import { EmploymentStep2SelfEmployedSchema } from '../schemas/EmploymentSchemas/EmploymentStep2SelfEmployedSchema'
export const ApplicationErrorsContext = React.createContext({})

export default observer(function ApplicationErrorsProvider(props) {
  const applicationContext = useContext(ApplicationContext)

  const applicationErrorsState = useLocalObservable(() =>
    ({
      portfolioErrors: 0,
      loanRequirementsStep1Errors: 0,
      loanRequirementsStep2Errors: 0,
      mainPropertyDetailsErrors: 0,
      directDebitErrors: 0,
      solicitorErrors: 0,
      documentUploadErrors: 0,
      applicantErrors: {},
      applicantEmails: {},
      firstApplicantId: 0
    })
  )

  useEffect(() => {
    applicationErrorsState.firstApplicantId = applicationContext.applicationState.applicants[0] ? applicationContext.applicationState.applicants[0].id : 0
    applicationContext.applicationState.applicants.forEach((applicant, index) => {
      if(!applicationErrorsState.applicantErrors[applicant.id]) {
        applicationErrorsState.applicantErrors[applicant.id] = new ApplicantErrors()
      }
      if(!applicationErrorsState.applicantEmails[applicant.id]) {
        applicationErrorsState.applicantEmails[applicant.id] = applicant.email
      }
      if(applicationContext.applicationState.applicants.length > 1) {
        applicant.current_applicant_number = index + 1
      } else {
        applicant.current_applicant_number = undefined
      }
      validatePersonalDetailsStep3(applicant, applicant.id)
    })
  }, [applicationContext.applicationState.applicants.length])

  const validatePortfolio = () => validateApplicationModelBySchema(PropertySchema, { properties: applicationContext.applicationState.properties }, 'portfolioErrors')
  const validateLoanRequirementsStep1 = () => validateApplicationModelBySchema(loanRequirementsStep1Schema, { purchase_type: applicationContext.applicationState.application.purchase_type, ...applicationContext.applicationState.mortgage }, 'loanRequirementsStep1Errors')
  const validateLoanRequirementsStep2 = () => validateApplicationModelBySchema(loanRequirementsStep2Schema, { purchase_type: applicationContext.applicationState.application.purchase_type, ...applicationContext.applicationState.mortgage }, 'loanRequirementsStep2Errors')
  const validateDirectDebit = () => validateApplicationModelBySchema(directDebitSchema, applicationContext.applicationState.directDebit, 'directDebitErrors')
  const validateSolicitor = () => validateApplicationModelBySchema(solicitorSchema, applicationContext.applicationState.solicitor, 'solicitorErrors')
  const validateMainPropertyDetails = () => validateApplicationModelBySchema(mainPropertyDetailsSchema, applicationContext.applicationState.security, 'mainPropertyDetailsErrors')

  const validateApplicationModelBySchema = (schema, model, errorsName) => {
    schema.validate(model, { abortEarly: false })
    .then(() => applicationErrorsState[errorsName] = 0)
    .catch(error => applicationErrorsState[errorsName] = error.errors.length)
  }

  useEffect(() => {
    if(!applicationContext.applicationState.loading) {
      setTimeout(() => {
        applicationContext.applicationState.applicants.forEach(applicant => {
          validatePersonalDetailsStep2({ ...applicant, applicantEmails: applicationErrorsState.applicantEmails, firstApplicantId: applicationErrorsState.firstApplicantId }, applicant.id)
        })
      }, 500)
    }
  }, [applicationContext.applicationState.loading])

  const validateGeneralQuestions = (general, applicant_id) => validateApplicantModelBySchema(generalSchema, general, applicant_id, 'generalQuestionsErrors')
  const validateCommitmentsStep1 = (loan_commitments, applicant_id) => validateApplicantModelBySchema(CommitmentsStep1Schema, { loan_commitments }, applicant_id, 'commitmentsStep1Errors')
  const validateCommitmentsStep2 = (credit_card_commitments, applicant_id) => validateApplicantModelBySchema(CommitmentsStep2Schema, { credit_card_commitments }, applicant_id, 'commitmentsStep2Errors')

  const validateOtherIncomeStep1 = (other_income, applicant_id) => validateApplicantModelBySchema(OtherIncomeStep1Schema, other_income, applicant_id, 'otherIncomeStep1Errors')
  const validateOtherIncomeStep2 = (other_income, applicant_id) => validateApplicantModelBySchema(OtherIncomeStep2Schema, other_income, applicant_id, 'otherIncomeStep2Errors')
  const validateOtherIncomeStep3 = (other_income, applicant_id) => validateApplicantModelBySchema(OtherIncomeStep3Schema, other_income, applicant_id, 'otherIncomeStep3Errors')

  const validatePersonalDetailsStep1 = (applicant, applicant_id) => validateApplicantModelBySchema(PersonalDetailsStep1Schema, applicant, applicant_id, 'personalDetailsStep1Errors')
  const validatePersonalDetailsStep2 = (applicant, applicant_id) => validateApplicantModelBySchema(PersonalDetailsStep2Schema, applicant, applicant_id, 'personalDetailsStep2Errors')
  const validatePersonalDetailsStep3 = (applicant, applicant_id) => validateApplicantModelBySchema(PersonalDetailsStep3Schema, applicant, applicant_id, 'personalDetailsStep3Errors')
  const validatePersonalDetailsStep4 = (applicant, applicant_id) => validateApplicantModelBySchema(PersonalDetailsStep4Schema, applicant, applicant_id, 'personalDetailsStep4Errors')

  const validateEmploymentStep1Employed = (employment, applicant_id) => validateApplicantModelBySchema(EmploymentStep1EmployedSchema, employment, applicant_id, 'employmentStep1Errors')
  const validateEmploymentStep2Employed = (employment, applicant_id) => validateApplicantModelBySchema(EmploymentStep2EmployedSchema, employment, applicant_id, 'employmentStep2Errors')
  const validateEmploymentStep3Employed = (employment, applicant_id) => validateApplicantModelBySchema(EmploymentStep3EmployedSchema, employment, applicant_id, 'employmentStep3Errors')
  const validateEmploymentStep4Employed = (employment, applicant_id) => validateApplicantModelBySchema(EmploymentStep4EmployedSchema, employment, applicant_id, 'employmentStep4Errors')

  const validateEmploymentStep1SelfEmployed = (income, applicant_id) => validateApplicantModelBySchema(EmploymentStep1SelfEmployedSchema, income, applicant_id, 'employmentStep1Errors')
  const validateEmploymentStep2SelfEmployed = (income, applicant_id) => validateApplicantModelBySchema(EmploymentStep2SelfEmployedSchema, income, applicant_id, 'employmentStep2Errors')
  const validateEmploymentStep3SelfEmployed = (income, applicant_id) => validateApplicantModelBySchema(EmploymentStep3SelfEmployedSchema, income, applicant_id, 'employmentStep3Errors')
  const validateEmploymentStep4SelfEmployed = (income, applicant_id) => validateApplicantModelBySchema(EmploymentStep4SelfEmployedSchema, income, applicant_id, 'employmentStep4Errors')

  const validateAssets = (assets, applicant_id) => validateApplicantModelBySchema(assetsSchema, assets, applicant_id, 'assetsErrors')
  const validatePension = (pension, applicant_id) => validateApplicantModelBySchema(pensionSchema, pension, applicant_id, 'pensionErrors')

  const validateApplicantModelBySchema = (schema, model, applicant_id, errorsName) => {
    if(!applicationErrorsState.applicantErrors[applicant_id]) applicationErrorsState.applicantErrors[applicant_id] = new ApplicantErrors()
    schema.validate(model, { abortEarly: false })
    .then(() => applicationErrorsState.applicantErrors[applicant_id][errorsName] = 0)
    .catch(error =>  applicationErrorsState.applicantErrors[applicant_id][errorsName] = error.errors.length)
  }

  const validateOpenBanking = applicant => {
    if(!applicationErrorsState.applicantErrors[applicant.id]) applicationErrorsState.applicantErrors[applicant.id] = new ApplicantErrors()
    if(applicant.open_banking_consent === false || applicant.open_banking_details?.status == 'complete') {
      applicationErrorsState.applicantErrors[applicant.id].openBankingErrors = 0
    } else {
      applicationErrorsState.applicantErrors[applicant.id].openBankingErrors = 1
    }
  }

  const validateIdVerification = applicant => {
    if(!applicationErrorsState.applicantErrors[applicant.id]) applicationErrorsState.applicantErrors[applicant.id] = new ApplicantErrors()
    if(applicant.identity_verification_consent === false || applicant.identity_verification_backend?.status == 'success') {
      applicationErrorsState.applicantErrors[applicant.id].idVerificationErrors = 0
    } else {
      applicationErrorsState.applicantErrors[applicant.id].idVerificationErrors = 1
    }
  }

  useEffect(() => { if(applicationContext.applicationState.application.steps.portfolio_visited) validatePortfolio() }, [applicationContext.applicationState.properties])
  useEffect(() => {
    if(applicationContext.applicationState.application.steps.loan_requirements_step1_visited) validateLoanRequirementsStep1()
    if(applicationContext.applicationState.application.steps.loan_requirements_step2_visited) validateLoanRequirementsStep2()
  }, [applicationContext.applicationState.mortgage])
  useEffect(() => { if(applicationContext.applicationState.application.steps.solicitor_visited) validateSolicitor() }, [applicationContext.applicationState.solicitor])
  useEffect(() => { if(applicationContext.applicationState.application.steps.direct_debit_visited) validateDirectDebit() }, [applicationContext.applicationState.directDebit])
  useEffect(() => { if(applicationContext.applicationState.application.steps.main_property_details_visited) validateMainPropertyDetails() }, [applicationContext.applicationState.security])

  return (
    <ApplicationErrorsContext.Provider value={{ applicationErrorsState, validateDirectDebit, validateSolicitor, validateMainPropertyDetails, validatePortfolio, validateLoanRequirementsStep1, validateLoanRequirementsStep2, validateGeneralQuestions, validateAssets, validatePension, validateCommitmentsStep1, validateCommitmentsStep2, validateOtherIncomeStep1, validateOtherIncomeStep2, validateOtherIncomeStep3, validatePersonalDetailsStep1, validatePersonalDetailsStep2, validatePersonalDetailsStep3, validatePersonalDetailsStep4, validateEmploymentStep1Employed, validateEmploymentStep2Employed, validateEmploymentStep3Employed, validateEmploymentStep4Employed, validateEmploymentStep1SelfEmployed, validateEmploymentStep2SelfEmployed, validateEmploymentStep3SelfEmployed, validateEmploymentStep4SelfEmployed }}>
      {applicationContext.applicationState.applicants.map(applicant => <ApplicantValidator key={applicant.id} applicant={applicant} validateGeneralQuestions={validateGeneralQuestions} validateAssets={validateAssets} validatePension={validatePension} validateCommitmentsStep1={validateCommitmentsStep1} validateCommitmentsStep2={validateCommitmentsStep2} validateOtherIncomeStep1={validateOtherIncomeStep1} validateOtherIncomeStep2={validateOtherIncomeStep2} validateOtherIncomeStep3={validateOtherIncomeStep3}  validatePersonalDetailsStep1={validatePersonalDetailsStep1} validatePersonalDetailsStep2={validatePersonalDetailsStep2} validatePersonalDetailsStep3={validatePersonalDetailsStep3} validatePersonalDetailsStep4={validatePersonalDetailsStep4} validateEmploymentStep1Employed={validateEmploymentStep1Employed} validateEmploymentStep2Employed={validateEmploymentStep2Employed} validateEmploymentStep3Employed={validateEmploymentStep3Employed} validateEmploymentStep4Employed={validateEmploymentStep4Employed} validateEmploymentStep1SelfEmployed={validateEmploymentStep1SelfEmployed} validateEmploymentStep2SelfEmployed={validateEmploymentStep2SelfEmployed} validateEmploymentStep3SelfEmployed={validateEmploymentStep3SelfEmployed} validateEmploymentStep4SelfEmployed={validateEmploymentStep4SelfEmployed} validateOpenBanking={validateOpenBanking} validateIdVerification={validateIdVerification} applicantEmails={applicationErrorsState.applicantEmails} firstApplicantId={applicationErrorsState.firstApplicantId} />)}
      {props.children}
    </ApplicationErrorsContext.Provider>
  )
})
