import { makeAutoObservable } from "mobx"

export default class ApplicationSteps {

  constructor(steps={}) {
    this.portfolio_visited = !!steps.portfolio_visited
    this.loan_requirements_step1_visited = !!steps.loan_requirements_step1_visited
    this.loan_requirements_step2_visited = !!steps.loan_requirements_step2_visited
    this.main_property_details_visited = !!steps.main_property_details_visited
    this.direct_debit_visited = !!steps.direct_debit_visited
    this.solicitor_visited = !!steps.solicitor_visited
    // this.document_upload_visited = !!steps.document_upload_visited

    makeAutoObservable(this)
  }
}