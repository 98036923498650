import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import axiosService from '../utils/AxiosService'
export const CurrentBorrowerContext = React.createContext({})

export default observer(function CurrentBorrowerProvider(props) {
  const [currentBorrower, setCurrentBorrower] = useState()

  return (
    <CurrentBorrowerContext.Provider value={{ currentBorrower, setCurrentBorrower }}>
      {props.children}
    </CurrentBorrowerContext.Provider>
  )
})
