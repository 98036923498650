import React from 'react'
import NumberFormat from "react-number-format";
import ErrorMessage from './ErrorMessage';
import Label from './Label';

export default function CurrencyField({
  addon,
  addonPosition,
  autoFocus,
  error,
  label,
  max,
  min,
  name,
  noRightPadding,
  onBlur,
  optional,
  placeholder,
  showAsterisk,
  tooltip,
  type = "text",
  thousandSeparator = ',',
  displayType = "input",
  decimalSeparator = ".",
  thousandsGroupStyle = "thousand",
  value,
  className,
  disabled,
  maxLength,
  setFieldValue = () => {},
  additionalAction,
  labelMarginBottom
}) {

  return (
    <>
      <Label
        label={label}
        noRightPadding={noRightPadding}
        showAsterisk={showAsterisk}
        tooltip={tooltip}
        marginBottom={labelMarginBottom}
      />

      <div className="relative">
        {addonPosition === 'start' && <p className="absolute left-32 top-1/2 transform -translate-y-1/2">{addon}</p>}

        <NumberFormat
          autoFocus={autoFocus}
          className={`
            ${className ? className : 'input'}
            ${error && 'error'}
            ${addonPosition === 'start' && 'text-right'}
          `}
          name={name}
          onBlur={onBlur}
          disabled={disabled}
          min={min}
          max={max}
          thousandsGroupStyle={thousandsGroupStyle}
          value={value}
          decimalSeparator={decimalSeparator}
          displayType={displayType}
          type={type}
          thousandSeparator={thousandSeparator}
          onValueChange={({ value }) => {
            setFieldValue(name, value, true)
            if(additionalAction && typeof additionalAction === 'function') additionalAction(value, name)
          }}
          placeholder={placeholder}
          maxLength={maxLength}
        />

        {addonPosition === 'end' && <p className="absolute right-32 top-1/2 transform -translate-y-1/2">{addon}</p>}

        {(optional && type !== 'number' || (optional && !value && value != 0 && type === 'number')) && <p className="optional-text">Optional</p>}
      </div>

      <ErrorMessage text={error} />
    </>
  )
}