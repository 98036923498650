import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axiosService from '../../utils/AxiosService'

export default function BorrowerApplications() {
  const history = useHistory()

  const [applications, setApplications] = useState([])

  const getApplications = () => {
    axiosService.get('/api/borrower/applications')
    .then(applications => {
      setApplications(applications)
    })
    .catch(error => console.log(error))
  }

  useEffect(() => {
    getApplications()
  }, [])

  const openApplication = application_id => () => history.push(`/borrower/applications/${application_id}/applicants`)

  const getState = state => {
    return {
      draft: 'In progress',
      for_review: 'Under Review',
      submitted: 'Submitted',
      failed: 'Incomplete',
      valuation_requested: 'Valuation Requested',
    }[state]
  }

  const getStateClass = state => {
    return {
      draft: 'text-bright-sun',
      for_review: '',
      submitted: 'text-turquoise',
      failed: 'text-punch',
      valuation_requested: '',
    }[state]
  }

  return (
    <div>
      <h2 className="form-title mb-40">Application(s)</h2>
      {applications.map(application => (
        <div className="table-outer-container" key={application.id}>
          <div className="table-container">
            <table className="table table--border table--borrower mb-6">
              <tbody>
                <tr>
                  <td style={{ width: 280 }}>
                    { <button disabled={application.state === 'failed'} className={`btn btn--primary ${application.state === 'failed' && 'opacity-50'}`} onClick={openApplication(application.id)}>Open application</button>}
                  </td>
                  <td className="font-bold" style={{ width: 210 }}><p className={`text-base text-center ${getStateClass(application.state)}`}>{getState(application.state)}</p></td>
                  <td className="fs-13">
                    <div className="flex justify-between">
                      <p>
                        <span className="text-timber-green-o-40">{application.apprivo_broker.name}</span>
                        {/* <br />
                        01 662 4600 */}
                      </p>

                    <p>Last modified: {moment(application.updated_at).format('DD/MM/YYYY')}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  )

}