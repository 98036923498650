import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../contexts/ApplicationContext'
import Applicant from '../../models/Applicant'
import axiosService from '../../utils/AxiosService'

export default observer(function ApplicantStepUpdater({ step }) {
  const { applicationState: { applicants, backend_url_part }} = useContext(ApplicationContext)
  const { application_id, applicant_id } = useParams()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicant_id, applicants, applicants.length])

  useEffect(() => {
    return function cleanup() {
      if(currentApplicant.id && !currentApplicant.steps[step]) {
        axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { steps: {...currentApplicant.steps, [step]: true } } })
        .then(() => {
          currentApplicant.steps[step] = true
        })
      }
    }
  }, [currentApplicant.id])

  return (
    <div>

    </div>
  )
})