import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../contexts/ApplicationErrorsContext'
import NextStepButton from '../../Form/NextStepButton'
import ProgressIndicator from '../ProgressIndicator'

export default observer(function LoanRequirementsActions({ save, purchase_type }) {
  const { application_id } = useParams()
  const { applicationErrorsState: { loanRequirementsStep1Errors, loanRequirementsStep2Errors }} = useContext(ApplicationErrorsContext)
  const { applicationState: { application, type, disabled }} = useContext(ApplicationContext)

  const url = `/${type}/applications/${application_id}/loan-requirements`

  const steps = useMemo(() => purchase_type === 'Purchase' ? 1 : 2, [purchase_type])

  const loanRequirementsErrors = useMemo(() => {
    return [
      { errors: loanRequirementsStep1Errors, visited: application.steps.loan_requirements_step1_visited },
      { errors: loanRequirementsStep2Errors, visited: application.steps.loan_requirements_step2_visited }
    ]
  }, [
    loanRequirementsStep1Errors,
    loanRequirementsStep2Errors,
    application.steps.loan_requirements_step1_visited,
    application.steps.loan_requirements_step2_visited
  ])

  return (
    <Fragment>
      <ProgressIndicator url={url} steps={steps} save={disabled ? () => {} : save} errors={loanRequirementsErrors} />

      <div className="flex justify-between mt-16">
        {!disabled && <button className="btn btn--secondary" type="submit">Save Progress</button>}
        <NextStepButton steps={steps} url={url} nextUrl={`/${type}/applications/${application_id}/main-property-details`} save={disabled ? () => {} : save}/>
      </div>
    </Fragment>
  )
})