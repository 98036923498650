import * as Yup from 'yup'

const youHaveAccountant = () => {
  return [
    'do_you_have_accountant',
    {
      is: true,
      then: Yup.string().required('This field is required.').max(35, 'This field must be maximum 35 characters long.')
    }
  ]
}

export const EmploymentStep3SelfEmployedSchema = Yup.object().shape({
  do_you_have_accountant: Yup.boolean().required('This field is required.'),
  accountants_firm: Yup.string().when(...youHaveAccountant()),
  accountant_email: Yup.string().when(
    'do_you_have_accountant',
    {
      is: true,
      then: Yup.string().email('Enter a valid email address.').required('This field is required.')
    }
  ),
  accountants_telephone_number: Yup.string().when(...youHaveAccountant()),
  contact_name: Yup.string().when(...youHaveAccountant()),
  accountants_post_code: Yup.string().when(...youHaveAccountant()),
  accountants_address_line1: Yup.string().when(...youHaveAccountant()),
  accountants_address_line2: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  accountants_city: Yup.string().max(35, 'This field must be maximum 35 characters long.'),
  accountants_county: Yup.string().when(...youHaveAccountant()),
  accountants_country: Yup.mixed().nullable().when(...youHaveAccountant()),
});