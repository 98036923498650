import React, { useEffect } from 'react'
import Checkbox from '../../Form/Checkbox'
import NextStepButton from '../../Form/NextStepButton'

import iconIdVerification from 'images/icons/identify-verification.svg'
import iconCamera from 'images/icons/camera.svg'
import iconIdCard from 'images/icons/id-card.svg'
import iconPortrait from 'images/icons/portrait.svg'
import { useParams } from 'react-router-dom'
import axiosService from '../../../utils/AxiosService'

export default function IdVerification() {
  const { application_id, id_verification_token } = useParams()

  useEffect(() => {
    axiosService.post('/api/borrower/identity_verification/mobile_flow_started', { id_verification_token: id_verification_token, application_id: application_id })
      .then(response => {
        console.log(response)
      })
      .catch(error => { console.log(error) })

    return () => {}
  }, [])

  const save = () => {}

  return (
    <>
      <h2 className="form-title mb-40">ID verification</h2>

      <p className="mb-40">Let us verify your identity in 3 easy steps. Please follow the instructions on the screen</p>

      <img alt="" className="mb-40 mx-auto" src={iconIdVerification} />

      <div className="flex flex-wrap mb-40 -mx-2 text-center text-xs">
        <div className="flex px-2 w-1/3">
          <div className="border border-turquoise p-4 rounded-lg w-full">
            <div className="p-4">
              <img alt="" className="mb-2 mx-auto" src={iconIdCard} />
            </div>

            <p>Select<br /> document</p>
          </div>
        </div>

        <div className="flex px-2 w-1/3">
          <div className="border border-turquoise p-4 rounded-lg w-full">
            <div className="p-4">
              <img alt="" className="mb-2 mx-auto" src={iconCamera} />
            </div>

            <p>Capture<br /> your ID</p>
          </div>
        </div>

        <div className="flex px-2 w-1/3">
          <div className="border border-turquoise p-4 rounded-lg w-full">
            <div className="p-4">
              <img alt="" className="mb-2 mx-auto" src={iconPortrait} />
            </div>

            <p>Capture a<br /> selfie</p>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <NextStepButton
          className="w-full sm:w-auto"
          nextUrl={`/borrower/applications/${application_id}/applicants/${id_verification_token}/identity-verification-mobile/document-type`}
          text="Start Verification"
          save={save}
        />
      </div>
    </>
  )
}
