import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { ApplicationContext } from '../../../../contexts/ApplicationContext'
import { ApplicationErrorsContext } from '../../../../contexts/ApplicationErrorsContext'
import Applicant from '../../../../models/Applicant'
import Document from '../../../../models/Document'
import Income from '../../../../models/Income'
import { EmploymentStep4SelfEmployedSchema } from '../../../../schemas/EmploymentSchemas/EmploymentStep4SelfEmployedSchema'
import axiosService from '../../../../utils/AxiosService'
import Divider from '../../../Divider'
import CurrencyField from '../../../Form/CurrencyField'
import { notifyProgressSaved } from '../../../Form/Notifications'
import { toastSuccess } from '../../../Form/Toasts'
import EmploymentActions from '../EmploymentActions'

export default observer(function SelfEmployedFormPart() {
  const { applicationState: { applicants, loading, disabled, backend_url_part, type }} = useContext(ApplicationContext)
  const applicationErrorsContext = useContext(ApplicationErrorsContext)
  const { application_id, applicant_id } = useParams()
  const formikRef = useRef()

  const currentApplicant = useMemo(() => applicants.find(applicant => +applicant.id === +applicant_id) || new Applicant(), [applicant_id, applicants])
  const fieldName = useMemo(() => {
    return {
      '1': 'year',
      '2': 'salary',
      '3': 'salary',
      '4': 'salary'
    }[currentApplicant.income.business_type]
  },[])

  const save = income => e => {
    if (type === 'borrower') return

    e?.preventDefault()
    touchFields()

    return axiosService.patch(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}`, { applicant: { income } })
    .then(() => {
      notifyProgressSaved()
      currentApplicant.income = new Income(income)
    })
    .then(() => {
      return axiosService.post(`/api/${backend_url_part}/applications/${application_id}/applicants/${applicant_id}/documents/calculation_pdf`)
    })
    .then((document) => {
      currentApplicant.documents = currentApplicant.documents.filter(document => !(document.document_type === "Broker Documents" && document.file_name.includes("SelfEmployedIncome.pdf")))
      currentApplicant.documents.push(new Document(document))
      toastSuccess("Document uploaded")
    })
  }

  const touchFields = () => formikRef.current.setTouched(currentFields.reduce((result, field) => { result[field] = true; return result }, {}))

  const currentFields = useMemo(() => {
    if(currentApplicant.income.business_type === '1') return ['business_type', 'year1', 'year2', 'year3']
    return ['business_type', 'salary1', 'salary2', 'salary3']
  }, [currentApplicant.income])

  useEffect(() => {
    if(currentApplicant.steps.employment_step4_visited) touchFields()
    applicationErrorsContext.validateEmploymentStep4SelfEmployed(currentApplicant.income, applicant_id)
  }, [loading, currentApplicant.income])

  return (
    <div>

      <Formik
        initialValues={currentApplicant.income}
        validationSchema={EmploymentStep4SelfEmployedSchema}
        enableReinitialize={loading}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
          <form onSubmit={save(values)}>

            { type === "borrower" &&
              <div className="mb-40">
                <h2 style={{ fontStyle: "italic" }}>For broker use only. Please discuss income figures with your broker.</h2>
              </div>
            }
            { type !== "borrower" &&
              <Fragment>
                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.turnover && errors.turnover}
                        label="Turnover – Most recent year"
                        name="turnover"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.turnover}
                        disabled={disabled}
                        />
                    </div>
                  </div>
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.turnover2 && errors.turnover2}
                        label="Turnover – 2 years ago"
                        name="turnover2"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.turnover2}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>

                <Divider text="Trading Profit" />

                <div className="flex flex-wrap -mx-12">
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.net_profit && errors.net_profit}
                        label="Net Profit – Most recent year"
                        name="net_profit"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.net_profit}
                        disabled={disabled}
                        additionalAction={value => {
                          const tradingProfit = +value + +values.deprecation + +values.interest_paid + +values.director_remuneration
                          const grossAnnualIncome = tradingProfit - +values.annual_loan_repayments
                          setFieldValue('trading_profit', tradingProfit, true)
                          setFieldValue(`${fieldName}2`, grossAnnualIncome, true)
                          setFieldValue(`${fieldName}1`, (grossAnnualIncome + values[`${fieldName}3`]) / 2, true)
                        }}
                        />
                    </div>
                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.deprecation && errors.deprecation}
                        label="Deprecation – Most recent year"
                        name="deprecation"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.deprecation}
                        disabled={disabled}
                        additionalAction={value => {
                          const tradingProfit = +values.net_profit + +value + +values.interest_paid + +values.director_remuneration
                          const grossAnnualIncome = tradingProfit - +values.annual_loan_repayments
                          setFieldValue('trading_profit', tradingProfit, true)
                          setFieldValue(`${fieldName}2`, grossAnnualIncome, true)
                          setFieldValue(`${fieldName}1`, (grossAnnualIncome + values[`${fieldName}3`]) / 2, true)
                        }}
                        />
                    </div>

                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.interest_paid && errors.interest_paid}
                        label="Interest Paid – Most recent year"
                        name="interest_paid"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.interest_paid}
                        disabled={disabled}
                        additionalAction={value => {
                          const tradingProfit = +values.net_profit + +values.deprecation + +value + +values.director_remuneration
                          const grossAnnualIncome = tradingProfit - +values.annual_loan_repayments
                          setFieldValue('trading_profit', tradingProfit, true)
                          setFieldValue(`${fieldName}2`, grossAnnualIncome, true)
                          setFieldValue(`${fieldName}1`, (grossAnnualIncome + values[`${fieldName}3`]) / 2, true)
                        }}
                        />
                    </div>
                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.director_remuneration && errors.director_remuneration}
                        label="Director Remuneration – Most recent year"
                        name="director_remuneration"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.director_remuneration}
                        disabled={disabled}
                        additionalAction={value => {
                          const tradingProfit = +values.net_profit + +values.deprecation + +values.interest_paid + +value
                          const grossAnnualIncome = tradingProfit - +values.annual_loan_repayments
                          setFieldValue('trading_profit', tradingProfit, true)
                          setFieldValue(`${fieldName}2`, grossAnnualIncome, true)
                          setFieldValue(`${fieldName}1`, (grossAnnualIncome + values[`${fieldName}3`]) / 2, true)
                        }}
                        />
                    </div>
                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.trading_profit && errors.trading_profit}
                        label="Trading Profit – Most recent year"
                        name="trading_profit"
                        value={values.trading_profit}
                        disabled
                        />
                    </div>
                  </div>
                  <div className="px-12 w-1/2">
                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.net_profit2 && errors.net_profit2}
                        label="Net Profit – 2 years ago"
                        name="net_profit2"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.net_profit2}
                        disabled={disabled}
                        additionalAction={value => {
                          const tradingProfit2 = +value + +values.deprecation2 + +values.interest_paid2 + +values.director_remuneration2
                          const grossAnnualIncome2 = tradingProfit2 - +values.annual_loan_repayments2
                          setFieldValue('trading_profit2', tradingProfit2, true)
                          setFieldValue(`${fieldName}3`, grossAnnualIncome2, true)
                          setFieldValue(`${fieldName}1`, (grossAnnualIncome2 + values[`${fieldName}2`]) / 2, true)
                        }}
                      />
                    </div>
                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.deprecation2 && errors.deprecation2}
                        label="Deprecation – 2 years ago"
                        name="deprecation2"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.deprecation2}
                        disabled={disabled}
                        additionalAction={value => {
                          const tradingProfit2 = +values.net_profit2 + +value + +values.interest_paid2 + +values.director_remuneration2
                          const grossAnnualIncome2 = tradingProfit2 - +values.annual_loan_repayments2
                          setFieldValue('trading_profit2', tradingProfit2, true)
                          setFieldValue(`${fieldName}3`, grossAnnualIncome2, true)
                          setFieldValue(`${fieldName}1`, (grossAnnualIncome2 + values[`${fieldName}2`]) / 2, true)
                        }}
                        />
                    </div>

                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.interest_paid2 && errors.interest_paid2}
                        label="Interest Paid – 2 years ago"
                        name="interest_paid2"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.interest_paid2}
                        disabled={disabled}
                        additionalAction={value => {
                          const tradingProfit2 = +values.net_profit2 + +values.deprecation2 + +value + +values.director_remuneration2
                          const grossAnnualIncome2 = tradingProfit2 - +values.annual_loan_repayments2
                          setFieldValue('trading_profit2', tradingProfit2, true)
                          setFieldValue(`${fieldName}3`, grossAnnualIncome2, true)
                          setFieldValue(`${fieldName}1`, (grossAnnualIncome2 + values[`${fieldName}2`]) / 2, true)
                        }}
                        />
                    </div>
                    <div className="mb-40">
                      <CurrencyField
                        labelMarginBottom="mb-7"
                        addon="€"
                        addonPosition="start"
                        error={touched.director_remuneration2 && errors.director_remuneration2}
                        label="Director Remuneration – 2 years ago"
                        name="director_remuneration2"
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        value={values.director_remuneration2}
                        disabled={disabled}
                        additionalAction={value => {
                          const tradingProfit2 = +values.net_profit2 + +values.deprecation2 + +values.interest_paid2 + +value
                          const grossAnnualIncome2 = tradingProfit2 - +values.annual_loan_repayments2
                          setFieldValue('trading_profit2', tradingProfit2, true)
                          setFieldValue(`${fieldName}3`, grossAnnualIncome2, true)
                          setFieldValue(`${fieldName}1`, (grossAnnualIncome2 + values[`${fieldName}2`]) / 2, true)
                        }}
                        />
                    </div>
                    <div className="mb-40">
                      <CurrencyField
                        addon="€"
                        addonPosition="start"
                        error={touched.trading_profit2 && errors.trading_profit2}
                        label="Trading Profit – 2 years ago"
                        name="trading_profit2"
                        value={values.trading_profit2}
                        disabled
                        />
                    </div>
                  </div>
                </div>

                <Divider text="Gross Annual Income" />
              </Fragment>
            }

            <div className="flex flex-wrap -mx-12">
              <div className="px-12 w-1/2">
                { type !== "borrower" &&
                  <div className="mb-40">
                    <CurrencyField
                      addon="€"
                      addonPosition="start"
                      error={touched.annual_loan_repayments && errors.annual_loan_repayments}
                      label="Annual Loan Repayments – Most recent year"
                      name="annual_loan_repayments"
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      value={values.annual_loan_repayments}
                      disabled={disabled}
                      additionalAction={value => {
                        const grossAnnualIncome = values.trading_profit - +value
                        setFieldValue(`${fieldName}2`, grossAnnualIncome, true)
                        setFieldValue(`${fieldName}1`, (grossAnnualIncome + values[`${fieldName}3`]) / 2, true)
                      }}
                      />
                  </div>
                }
                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={touched[`${fieldName}2`] && errors[`${fieldName}2`]}
                    label="Gross Annual Income – Most recent year"
                    name={`${fieldName}2`}
                    onChange
                    onBlur={handleBlur}
                    value={values[`${fieldName}2`]}
                    disabled
                  />
                </div>

                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={touched[`${fieldName}1`] && errors[`${fieldName}1`]}
                    label="Gross Annual Income – Average most recent 2 years"
                    name={`${fieldName}1`}
                    value={values[`${fieldName}1`]}
                    disabled
                  />
                </div>
              </div>
              <div className="px-12 w-1/2">
                { type !== "borrower" &&
                  <div className="mb-40">
                    <CurrencyField
                      labelMarginBottom="mb-7"
                      addon="€"
                      addonPosition="start"
                      error={touched.annual_loan_repayments2 && errors.annual_loan_repayments2}
                      label="Annual Loan Repayments – 2 years ago"
                      name="annual_loan_repayments2"
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      value={values.annual_loan_repayments2}
                      disabled={disabled}
                      additionalAction={value => {
                        const grossAnnualIncome2 = values.trading_profit2 - +value
                        setFieldValue(`${fieldName}3`, grossAnnualIncome2, true)
                        setFieldValue(`${fieldName}1`, (grossAnnualIncome2 + values[`${fieldName}2`]) / 2, true)
                      }}
                    />
                  </div>
                }
                <div className="mb-40">
                  <CurrencyField
                    addon="€"
                    addonPosition="start"
                    error={touched[`${fieldName}3`] && errors[`${fieldName}3`]}
                    label="Gross Annual Income – 2 years ago"
                    name={`${fieldName}3`}
                    onBlur={handleBlur}
                    value={values[`${fieldName}3`]}
                    disabled
                  />
                </div>
              </div>
            </div>

            <EmploymentActions save={save(values)} />
          </form>
        )}
      </Formik>
    </div>
  )
})