import { makeAutoObservable } from "mobx"

export default class Employment {
  constructor(employment={}) {
    this.job_title = employment.job_title || ''
    this.employer_name = employment.employer_name || ''
    this.employer_telephone = employment.employer_telephone || '+353'
    this.nature_of_business = employment.nature_of_business || null
    this.business_sector = employment.business_sector || null
    this.shareholding = employment.shareholding || ''
    this.date_joined = employment.date_joined || ''
    this.employment_type = employment.employment_type || null
    this.date_contract_started = employment.date_contract_started || ''
    this.date_contract_ends = employment.date_contract_ends || ''
    this.contract_remaining_details = employment.contract_remaining_details || ''
    this.on_probation = !!employment.on_probation
    this.probation_end_date = employment.probation_end_date || ''
    this.redundancy_notice = !!employment.redundancy_notice
    this.redundancy_remaining_details = employment.redundancy_remaining_details || ''
    this.reference_contact = employment.reference_contact || ''
    this.reference_contact_email = employment.reference_contact_email || ''
    this.address_post_code = employment.address_post_code || ''
    this.address_line1 = employment.address_line1 || ''
    this.address_line2 = employment.address_line2 || ''
    this.address_line3 = employment.address_line3 || ''
    this.address_city = employment.address_city || ''
    this.address_county = employment.address_county || ''
    this.address_country = employment.address_country || 'IRL'
    this.tax_payable = employment.tax_payable || ''
    this.basic_salary = employment.basic_salary || 0
    this.regular_overtime = employment.regular_overtime || 0
    this.guaranteed_overtime = employment.guaranteed_overtime || 0
    this.guaranteed_bonus = employment.guaranteed_bonus || 0
    this.commission = employment.commission || 0
    this.income_basic_pay = employment.income_basic_pay || ''
    this.other_income = employment.other_income || 0
    this.others_name = employment.others_name || ''
    this.allowance = employment.allowance || 0
    this.car_allowance = employment.car_allowance || 0
    this.dividends = employment.dividends || 0
    this.previous_job_title = employment.previous_job_title || ''
    this.previous_employer_name = employment.previous_employer_name || ''
    this.previous_address_post_code = employment.previous_address_post_code || ''
    this.previous_address_line1 = employment.previous_address_line1 || ''
    this.previous_address_line2 = employment.previous_address_line2 || ''
    this.previous_address_line3 = employment.previous_address_line3 || ''
    this.previous_address_city = employment.previous_address_city || ''
    this.previous_address_county = employment.previous_address_county || ''
    this.previous_address_country = employment.previous_address_country || 'IRL'
    this.previous_nature_of_business = employment.previous_nature_of_business || null
    this.previous_date_joined = employment.previous_date_joined || ''
    this.previous_date_left = employment.previous_date_left || ''
    this.previous_employer_telephone = employment.previous_employer_telephone || '+353'
    this.previous_basic_gross_income = employment.previous_basic_gross_income || ''
    this.are_you = employment.are_you || ''

    this.previous_job_title2 = employment.previous_job_title2 || ''
    this.previous_employer_name2 = employment.previous_employer_name2 || ''
    this.previous_address_post_code2 = employment.previous_address_post_code2 || ''
    this.previous2_address_line1 = employment.previous2_address_line1 || ''
    this.previous2_address_line2 = employment.previous2_address_line2 || ''
    this.previous2_address_line3 = employment.previous2_address_line3 || ''
    this.previous_address_city2 = employment.previous_address_city2 || ''
    this.previous_address_county2 = employment.previous_address_county2 || ''
    this.previous_address_country2 = employment.previous_address_country2 || 'IRL'
    this.previous_nature_of_business2 = employment.previous_nature_of_business2 || null
    this.previous_date_joined2 = employment.previous_date_joined2 || ''
    this.previous_date_left2 = employment.previous_date_left2 || ''
    this.previous_job_title3 = employment.previous_job_title3 || ''
    this.previous_employer_name3 = employment.previous_employer_name3 || ''
    this.previous_address_post_code3 = employment.previous_address_post_code3 || ''
    this.previous3_address_line1 = employment.previous3_address_line1 || ''
    this.previous3_address_line2 = employment.previous3_address_line2 || ''
    this.previous3_address_line3 = employment.previous3_address_line3 || ''
    this.previous_address_city3 = employment.previous_address_city3 || ''
    this.previous_address_county3 = employment.previous_address_county3 || ''
    this.previous_address_country3 = employment.previous_address_country3 || 'IRL'
    this.previous_nature_of_business3 = employment.previous_nature_of_business3 || null
    this.previous_date_joined3 = employment.previous_date_joined3 || ''
    this.previous_date_left3 = employment.previous_date_left3 || ''
    this.previous_job_title4 = employment.previous_job_title4 || ''
    this.previous_employer_name4 = employment.previous_employer_name4 || ''
    this.previous_address_post_code4 = employment.previous_address_post_code4 || ''
    this.previous4_address_line1 = employment.previous4_address_line1 || ''
    this.previous4_address_line2 = employment.previous4_address_line2 || ''
    this.previous4_address_line3 = employment.previous4_address_line3 || ''
    this.previous_address_city4 = employment.previous_address_city4 || ''
    this.previous_address_county4 = employment.previous_address_county4 || ''
    this.previous_address_country4 = employment.previous_address_country4 || 'IRL'
    this.previous_nature_of_business4 = employment.previous_nature_of_business4 || null
    this.previous_date_joined4 = employment.previous_date_joined4 || ''
    this.previous_date_left4 = employment.previous_date_left4 || ''
    this.previous_job_title5 = employment.previous_job_title5 || ''
    this.previous_employer_name5 = employment.previous_employer_name5 || ''
    this.previous_address_post_code5 = employment.previous_address_post_code5 || ''
    this.previous5_address_line1 = employment.previous5_address_line1 || ''
    this.previous5_address_line2 = employment.previous5_address_line2 || ''
    this.previous5_address_line3 = employment.previous5_address_line3 || ''
    this.previous_address_city5 = employment.previous_address_city5 || ''
    this.previous_address_county5 = employment.previous_address_county5 || ''
    this.previous_address_country5 = employment.previous_address_country5 || 'IRL'
    this.previous_nature_of_business5 = employment.previous_nature_of_business5 || null
    this.previous_date_joined5 = employment.previous_date_joined5 || ''
    this.previous_date_left5 = employment.previous_date_left5 || ''
    makeAutoObservable(this)
  }
}